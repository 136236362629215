import {_delete, _get, _patch, _post} from "./apiService";
import {ITrackerResponse} from "../types/tracker-types";
import {IAdminTracker} from "../main/reports/widgets/ReportTrackerActivityPreview";

export const getTrackerReports = (organizationId: string, dateFrom: string, dateTo: string, workspaceId?: string, projectId?: string) => {
    return _get(`report/${organizationId}/tracker?DateFrom=${encodeURIComponent(dateFrom)}&DateTo=${encodeURIComponent(dateTo)}&WorkspaceId=${workspaceId ?? ""}&ProjectId=${projectId ?? ""}`);
}

export const getReportsUser = (organizationId: string, userId: string, dateFrom: string, dateTo: string, workspaceId?: string, projectId?: string) => {
    return _get(`report/${organizationId}/user/${userId}?WorkspaceId=${workspaceId ?? ""}&ProjectId=${projectId ?? ""}&DateFrom=${encodeURIComponent(dateFrom)}&DateTo=${encodeURIComponent(dateTo)}`);
}

export const getReportsUserActivity = (organizationId: string, userId: string, dateFrom: string, dateTo: string, workspaceId?: string, projectId?: string) => {
    return _get(`report/${organizationId}/user/${userId}/activity?WorkspaceId=${workspaceId ?? ""}&ProjectId=${projectId ?? ""}&DateFrom=${encodeURIComponent(dateFrom)}&DateTo=${encodeURIComponent(dateTo)}`);
}
export const getReportsTeams = (organizationId: string, teamId: string, dateFrom: string, dateTo: string, workspaceId?: string, projectId?: string) => {
    return _get(`report/${organizationId}/team/${teamId}?WorkspaceId=${workspaceId ?? ""}&ProjectId=${projectId ?? ""}&DateFrom=${encodeURIComponent(dateFrom)}&DateTo=${encodeURIComponent(dateTo)}`);
}

export const getReportTeamUsersWorkload = (organizationId: string, teamId: string, dateFrom: string, dateTo: string) => {
    return _get(`report/${organizationId}/team/${teamId}/user-workload?DateFrom=${encodeURIComponent(dateFrom)}&DateTo=${encodeURIComponent(dateTo)}`);
}

export const getOrganizationTrackerActivity = (organizationId: string, dateFrom: string, dateTo: string, workspaceId?: string, projectId?: string, userId?: string) => {
    return _get(`report/${organizationId}/trackers?DateFrom=${encodeURIComponent(dateFrom)}&DateTo=${encodeURIComponent(dateTo)}&WorkspaceId=${workspaceId ?? ""}&ProjectId=${projectId ?? ""}&userId=${userId ?? ""}`)
}

export const getOrganizationEstimatedTime = (organizationId: string, dateFrom: string, dateTo: string, workspaceId?: string, projectId?: string, boardId?: number, statusId?: number) => {
    return _get(`report/${organizationId}/estimated-time?DateFrom=${encodeURIComponent(dateFrom)}&DateTo=${encodeURIComponent(dateTo)}&WorkspaceId=${workspaceId ?? ""}&ProjectId=${projectId ?? ""}&BoardId=${boardId ?? ""}&StatusId=${statusId ?? ""}`);
}

export const getTrackerTimeline = (organizationId: string, date: string, workspaceId?: string, projectId?: string) => {
    return _get(`report/${organizationId}/day-breakdown?Date=${encodeURIComponent(date)}&WorkspaceId=${workspaceId ?? ""}&ProjectId=${projectId ?? ""}`);
}

export const createAdminTracker = (tracker: IAdminTracker) => {
    return _post(`trackers/admin-manual`, {...tracker});
}
