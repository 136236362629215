import {IReports, IReportTrackerActivity, IReportUserActivity} from "../../../types/report-types";
import ReportUsersActivities from "../widgets/ReportUsersActivities";
import ReportTrackerReport from "../widgets/ReportTrackerReport";
import ReportTrackerProject from "../widgets/ReportTrackerByProject";
import ReportTrackerByCategory from "../widgets/ReportTrackerByCategory";
import ReportBoardWorkload from "../widgets/ReportBoardWorkload";
import ReportTrackerActivity from "../widgets/ReportTrackerActivity";

export default function ReportUsers(props: { data?: IReports, activities: IReportUserActivity[], userId?: string, trackerActivity: IReportTrackerActivity[] }) {
    return (
        <div>
            <div className="flex items-start">
                <ReportUsersActivities data={props.activities} className="mr-10 w-1/2"/>
                <ReportBoardWorkload data={""} className=" ml-10 w-1/2"/>
            </div>
            <ReportTrackerReport data={props.data}/>
            <ReportTrackerProject data={props.data?.byProjects}/>
            <ReportTrackerByCategory data={props.data?.byCategories}/>
            <ReportTrackerActivity data={props.trackerActivity} userId={props.userId}/>
        </div>
    )
}
