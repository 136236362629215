import {ITodo} from "../../../../types/todo-types";
import {CalendarIcon} from "@heroicons/react/outline";
import {RbRoundButton} from "../../../../shared-components/rb-components/RbRoundButton";
import React, {useEffect, useState} from "react";
import {MobileDateTimePicker} from "@mui/lab";
import {TextField} from "@mui/material";
import {updateTaskDueDate} from "../../../../services/todo/todoService";

export default function TodoDueDate(props: { todo: ITodo | undefined, refreshTodos: () => void, setTodo: React.Dispatch<ITodo | undefined> }) {
    const {todo} = props;
    const [open, setOpen] = useState<boolean>(false);
    const [date, setDate] = useState<Date | null>(null);

    useEffect(() => {
        if (todo?.dueDate) {
            setDate(new Date(todo.dueDate));
        }
    }, []);

    const handleChange = (newValue: Date | null) => {
        if (newValue !== null) {
            setDate(newValue);
            return;
        }
        if (!todo?.id || !date) return;
        updateTaskDueDate(todo.id, date.toISOString()).then(res => {
            if (res?.data?.error) return;
            props.setTodo({...todo, dueDate: date.toISOString()});
            props.refreshTodos();
        });
    };
    return (
        <>
            <h4 className="mb-10"><CalendarIcon className="w-20 mr-10 inline-block align-middle"/><span
                className="inline-block align-middle">Due date</span></h4>
            <RbRoundButton color="secondary"
                           onClick={() => setOpen(true)}
                           variant="contained">{todo?.dueDate ? new Date(todo.dueDate).toLocaleDateString("de-DE") : "Not set"}</RbRoundButton>
            <span className="hidden">
            <MobileDateTimePicker
                open={open}
                onClose={() => setOpen(false)}
                label="Date&Time picker"
                value={date}
                onChange={handleChange}
                onAccept={handleChange}
                renderInput={param => <TextField {...param}/>}
            />
            </span>
        </>
    )
}
