import {_delete, _get, _patch, _post} from "./apiService";
import {ITrackerCategory} from "../types/organization-settings-types";

export const toggleWorkDaySetting = (orgId: string, dayId: number) => {
    return _patch(`settings/${orgId}/update-working-day/${dayId}`, null);
}

export const getOrganizationSeats = (orgId: string) => {
    return _get(`settings/${orgId}/seats`);
}

export const renewLicence = (invoiceId: string) => {
    return _patch(`invoice/${invoiceId}/renew`, null);
}

export const deleteOrganizationSeats = (orgId: string, count: number = 1) => {
    return _delete(`settings/${orgId}/remove-seat/${count}`);
}

export const getFormerOrganizationUsers = (orgId: string) => {
    return _get(`settings/${orgId}/former-members`);
}

export const createNewWorkspace = (organizationId: string, name: string) => {
    return _post(`workspace/create`, {organizationId, name});
}

export const createNewProject = (workspaceId: string, name: string) => {
    return _post(`project/create`, {workspaceId, name});
}

export const deleteWorkspace = (workspaceId: string) => {
    return _delete(`settings/${workspaceId}/remove-workspace`);
}

export const deleteTrackerCategory = (categoryId: number) => {
    return _delete(`settings/delete-category/${categoryId}`);
}

export const getOrganizationUsers = (orgId: string) => {
    return _get(`settings/${orgId}/organization-users`);
}

export const createTeam = (organizationId: string, name: string) => {
    return _post(`teams/create-team`, {organizationId, name});
}

export const getWorkingDays = (organizationId: string) => {
    return _get(`settings/${organizationId}/working-days`);
}

export const getOrganizationBilling = (organizationId: string) => {
    return _get(`settings/${organizationId}/billing`);
}

export const getOrganizationBillingDetails = (organizationId: string) => {
    return _get(`settings/${organizationId}/billing-details`);
}

export const getLicences = () => {
    return _get(`settings/licences`);
}

export const getOrganizationSeatsInfo = (organizationId: string) => {
    return _get(`settings/${organizationId}/seats-info`);
}

export const getOrganizationInfo = (orgId: string) => {
    return _get(`settings/${orgId}/organization-info`)
}

export const updateOrganizationName = (organizationId: string, name: string) => {
    return _patch(`settings/${organizationId}/update-name?name=${name}`, null);
}

export const updateOrganizationEmail = (organizationId: string, email: string) => {
    return _patch(`settings/${organizationId}/update-email?email=${email}`, null);
}

export const getOrganizationUsersPermission = (orgId: string) => {
    return _get(`permission/${orgId}/organization-users`);
}

export const getAllProjectsInWorkspace = (workspaceId: string) => {
    return _get(`settings/${workspaceId}/projects`);
}

export const getMemberTypes = () => {
    return _get(`settings/organization/get-member-types`);
}

export const removeUserFromOrganization = (orgId: string, userId: string) => {
    return _delete(`settings/${orgId}/remove-user/${userId}`);
}

export const updateUserMemberTypeInOrganization = (orgId: string, userId: string, typeId: number) => {
    return _patch(`settings/${orgId}/member-type/${userId}/${typeId}`, null);
}

export const updateUserMemberTypeInProject = (projectId: string, userId: string, typeId: number) => {
    return _patch(`permission/${projectId}/project-member-type/${userId}/${typeId}`, null);
}

export const updateWorkspaceCurrency = (workspaceId: string, currencyId: number) => {
    return _patch(`settings/${workspaceId}/change-workspace-currency/${currencyId}`, null);
}

export const updateWorkspaceName = (workspaceId: string, name: string) => {
    return _patch(`settings/update-workspace-name`, {workspaceId: workspaceId, name});
}

export const deleteOrganization = (organizationId: string) => {
    return _delete(`settings/${organizationId}/delete`);
}

export const removeOrganizationSeats = (organizationId: string, count: number) => {
    return _delete(`settings/${organizationId}/remove-seat/${count}`);
}

export const addOrganizationSeats = (organizationId: string, count: number) => {
    return _post(`settings/${organizationId}/add-seat/${count}`, null);
}

export const deleteTeam = (teamId: string) => {
    return _delete(`teams/${teamId}/delete`);
}

export const getWorkspaceProjects = (workspaceId: string) => {
    return _get(`settings/${workspaceId}/projects`);
}

export const getWorkspaceUsers = (workspaceId: string) => {
    return _get(`settings/${workspaceId}/workspace-users`);
}
export const getProjectUsers = (projectId: string) => {
    return _get(`settings/${projectId}/project-users`);
}

export const getWorkspacePrices = (workspaceId: string) => {
    return _get(`settings/${workspaceId}/workspace-prices`);
}

export const inviteUserToOrganization = (organizationId: string, userId: string) => {
    return _post(`settings/${organizationId}/invite-user/${userId}`, null);
}

export const createOrganization = (name: string, email: string, seats: number, licenceId: number, monthlyPayment: boolean) => {
    return _post(`organization/create`, {name, email, seats, licenceId, monthlyPayment});
}

export const getLicencePriceListings = (licenceId: number) => {
    return _get(`settings/${licenceId}/price-list`);
}

export const getAllAdminOrganizationWorkspaces = (orgId: string) => {
    return _get(`settings/${orgId}/admin-workspaces`);
}
export const updateProjectName = (projectId: string, name: string) => {
    return _patch(`settings/update-project-name`, {projectId, name});
}
export const getAllAdminOrganizationProjects = (orgId: string) => {
    return _get(`settings/${orgId}/admin-projects`);
}
export const getProjectBoards = (projectId: string) => {
    return _get(`settings/${projectId}/boards`);
}
export const getProjectPrices = (projectId: string) => {
    return _get(`settings/${projectId}/project-prices`);
}
export const getOrganizationCategories = (orgId: string) => {
    return _get(`settings/${orgId}/categories`)
}
export const addOrganizationCategory = (orgId: string, category: ITrackerCategory) => {
    const data = {
        organizationId: orgId,
        name: category.name,
        color: category.color,
    }
    return _post(`settings/add-category`, data);
}

export const updateTrackerCategoryStatus = (categoryId: number, status: boolean) => {
    return _patch(`settings/update-category-status`, {categoryId, status});
}
export const updateTrackerColor = (categoryId: number, color: string) => {
    return _patch(`settings/update-category-color`, {categoryId, color});
}
export const getOrganizationTeams = (orgId: string) => {
    return _get(`teams/${orgId}/teams`);
}

export const getOrganizationUserRoles = (orgId: string, userId: string) => {
    return _get(`permission/${orgId}/${userId}/user-roles`);
}

export const addOrganizationUserRole = (orgId: string, userId: string, roleId: string) => {
    return _post(`permission/${orgId}/${userId}/add-role/${roleId}`, null);
}
export const removeOrganizationUserRole = (orgId: string, userId: string, roleId: string) => {
    return _delete(`permission/${orgId}/${userId}/remove-role/${roleId}`, null);
}
export const getOrganizationUserWorkspaces = (orgId: string, userId: string) => {
    return _get(`permission/${orgId}/${userId}/user-workspaces`);
}
export const getOrganizationUserProjects = (orgId: string, userId: string) => {
    return _get(`permission/${orgId}/${userId}/user-projects`);
}
export const getOrganizationUserBoards = (orgId: string, userId: string) => {
    return _get(`permission/${orgId}/${userId}/user-boards`);
}
export const updateWorkspaceMemberType = (workspaceId: string, userId: string, typeId: string) => {
    return _patch(`permission/${workspaceId}/workspace-member-type/${userId}/${typeId}`, null);
}
export const updateProjectMemberType = (workspaceId: string, userId: string, typeId: string) => {
    return _patch(`permission/${workspaceId}/project-member-type/${userId}/${typeId}`, null);
}
export const updateBoardMemberType = (boardId: string, userId: string, typeId: string) => {
    return _patch(`permission/${boardId}/board-member-type/${userId}/${typeId}`, null);
}
export const grantPermissionToUserForOrganization = (workspaceId: string, userId: string) => {
    return _post(`permission/${workspaceId}/add-to-workspace/${userId}`, null);
}
export const grantPermissionToUserForProject = (workspaceId: string, userId: string) => {
    return _post(`permission/${workspaceId}/add-to-project/${userId}`, null);
}
export const removeUserFromWorkspace = (workspaceId: string, userId: string) => {
    return _delete(`permission/${workspaceId}/remove-from-workspace/${userId}`)
}
export const removeUserFromProject = (projectId: string, userId: string) => {
    return _delete(`permission/${projectId}/remove-from-project/${userId}`)
}
export const removeUserFromBoard = (projectId: string, userId: string) => {
    return _delete(`permission/${projectId}/remove-from-board/${userId}`)
}
export const removeProject = (projectId: string) => {
    return _delete(`settings/${projectId}/remove-project `);
}
