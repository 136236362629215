import {styled} from "@mui/system";
import {List, ListItem} from "@mui/material";
import WidgetBase, {WidgetWidth} from "./WidgetBase";
import {formatToEUDate} from "../../../utils/utils";
import clsx from "clsx";

const RbSpan = styled('span')(() => ({
    '&': {
        width: "fit-content"
    },
    '& + span': {
        marginLeft: "1rem"
    }
}));

export default function WidgetList<T>(props: { id: number, className?: string, title: string, defaultWidth: WidgetWidth, titleIcon?: string, listSource: T[], displayAttributes: string[], renderItem?: (item: T) => JSX.Element, headerControl?: () => JSX.Element }) {
    function isId(attr: string): boolean {
        return attr.toLowerCase().startsWith("id") || attr.toLowerCase().endsWith("id");
    }

    return (
        <WidgetBase id={props.id} title={props.title} className={props.className} defaultWidth={props.defaultWidth}
                    headerControl={props.headerControl}>
            <List dense={true} className="overflow-auto h-256">
                {
                    props.listSource.map(item => {
                        return (
                            // @ts-ignore
                            <ListItem className="pl-10" key={item?.id ?? item[props.displayAttributes[0]]}>
                                {/*// @ts-ignore*/}
                                <p className={"first:basis-96 flex flex-1 justify-between"}>
                                    {
                                        props.displayAttributes.map(path => {
                                            return (
                                                props?.renderItem ? <span className="w-full"
                                                                          key={path}>{props.renderItem(item)}</span> :
                                                    <RbSpan key={path}
                                                        // @ts-ignore
                                                            className={clsx("justify-between", (props.displayAttributes.length > 1 && isId(path)) ? "basis-72" : "")}>{isId(path) && "#"}{formatToEUDate(item[path])}</RbSpan>
                                            )
                                        })
                                    }
                                </p>
                            </ListItem>
                        )
                    })
                }
            </List>
        </WidgetBase>
    )
}
