import React, {useEffect, useState} from "react";
import moment from "moment";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {ChevronDownIcon} from "@heroicons/react/outline";
import {DesktopDatePicker} from "@mui/lab";
import {TextField} from "@mui/material";
import RbSingleSelect from "../../../shared-components/rb-components/RbSingleSelect";
import {IBoardDropDown, IProjectDropDown, IWorkspace, UserOrganization} from "../../../types/common-types";
import {getAllProjectBoards, getOrganizationWorkspaces, getWorkspaceProjects} from "../../../services/common/common";
import {getOrganizationUsers} from "../../../services/organizationSettingsService";
import {getBoardCardStatuses} from "../../../services/boards/boardsService";
import {ICardStatus} from "../../../types/board-types";
import {IOrganizationUser} from "../../../types/organization-settings-types";

export interface IBoardCardsOptionChange {
    DateFrom?: string;
    DateTo?: string;
    workspace?: IWorkspace;
    project?: IProjectDropDown;
    board?: IBoardDropDown;
    user?: IOrganizationUser;
    status?: ICardStatus;
}

export default function BoardCardsBarControls(props: { options?: IBoardCardsOptionChange, onChange: (options: IBoardCardsOptionChange) => void, organization?: UserOrganization }) {
    const [options, setOptions] = useState<IBoardCardsOptionChange>();
    const dateValues = [{id: 0, name: "Today"}, {id: 1, name: "This week"}, {id: 2, name: "Last week"},
        {id: 3, name: "This month"}, {id: 4, name: "Last month"}, {id: 5, name: "Last 2 months"},
        {id: 6, name: "Last 3 months"}, {id: 7, name: "Custom"}];
    const [dateDisplay, setDateDisplay] = useState<string>("This week");
    const [dateFrom, setDateFrom] = useState<moment.Moment>(moment().startOf("week"));
    const [dateTo, setDateTo] = useState<moment.Moment>(moment().endOf("week"));
    const [dateFromReadonly, setDateFromReadonly] = useState<boolean>(true);
    const [dateToReadonly, setDateToReadonly] = useState<boolean>(true);
    const [openFrom, setOpenFrom] = useState<boolean>(false);
    const [openTo, setOpenTo] = useState<boolean>(false);
    const [dateAnchor, setDateAnchor] = useState<HTMLElement | null>(null);
    const [usersAnchor, setUsersAnchor] = useState<HTMLElement | null>(null);
    const [boardsAnchor, setBoardsAnchor] = useState<HTMLElement | null>(null);
    const [statusAnchor, setStatusAnchor] = useState<HTMLElement | null>(null);
    const [workspaceAnchor, setWorkspaceAnchor] = useState<HTMLElement | null>(null);
    const [projectAnchor, setProjectAnchor] = useState<HTMLElement | null>(null);

    const [cardStatuses, setCardStatuses] = useState<ICardStatus[]>([]);
    const [organizationUsers, setOrganizationUsers] = useState<IOrganizationUser[]>([]);
    const [projects, setProjects] = useState<IProjectDropDown[]>([]);
    const [workspaces, setWorkspaces] = useState<IWorkspace[]>([]);
    const [boards, setBoards] = useState<IBoardDropDown[]>([]);

    useEffect(() => {
        if (props?.options) {
            setOptions(props.options);
        }
    }, [props?.options]);

    useEffect(() => {
        updateOptions({...options, DateFrom: dateFrom?.toISOString(true), DateTo: dateTo?.toISOString(true)});
    }, []);

    useEffect(() => {
        updateOptions({...options, DateFrom: dateFrom?.toISOString(true), DateTo: dateTo?.toISOString(true)});
    }, [dateFrom, dateTo]);

    useEffect(() => {
        _getOrganizationWorkspaces();
        _getOrganizationUsers();
        _getBoardCardStatuses();
    }, [props?.organization?.id]);

    useEffect(() => {
        _getWorkspaceProjects();
    }, [options?.workspace]);

    useEffect(() => {
        _getAllProjectBoards();
    }, [options?.project]);

    function updateOptions(options: IBoardCardsOptionChange, emitChange: boolean = true) {
        setOptions(options);
        if (emitChange) {
            props.onChange(options);
        }
    }

    function _getAllProjectBoards() {
        if (!options?.project?.id) return;
        getAllProjectBoards(options.project.id).then(res => {
            if (res?.data?.error) return;
            setBoards(res.data.data);
        })
    }

    function _getWorkspaceProjects() {
        if (!options?.workspace?.id) return;
        getWorkspaceProjects(options.workspace.id).then(res => {
            if (res?.data?.error) return;
            setProjects(res.data.data);
        })
    }

    function _getOrganizationWorkspaces() {
        if (!props.organization?.id) return;
        getOrganizationWorkspaces(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setWorkspaces(res.data.data);
        });
    }

    function _getOrganizationUsers() {
        if (!props.organization?.id) return;
        getOrganizationUsers(props.organization?.id).then(res => {
            if (res?.data?.error) return;
            setOrganizationUsers(res.data.data);
        });
    }

    function _getBoardCardStatuses() {
        getBoardCardStatuses().then(res => {
            if (res?.data?.error) return;
            setCardStatuses(res.data.data);
        });
    }

    function handleDateRange(val: { id: number, name: string }) {
        setDateDisplay(val.name);
        switch (val.id) {
            case 0:
                setDateFrom(moment().startOf("day"));
                setDateTo(moment().endOf("day"));
                break;
            case 1:
                setDateFrom(moment().startOf("week"));
                setDateTo(moment().endOf("week"));
                break;
            case 2:
                setDateFrom(moment().startOf('week').subtract(1, "week"));
                setDateTo(moment().endOf('week').subtract(1, "week"));
                break;
            case 3:
                setDateFrom(moment().startOf("month"));
                setDateTo(moment().endOf("month"));
                break;
            case 4:
                setDateFrom(moment().subtract(1, "month").startOf('month'));
                setDateTo(moment().subtract(1, "month").endOf('month'));
                break;
            case 5:
                setDateFrom(moment().subtract(2, "month").startOf('day'));
                setDateTo(moment().endOf('day'));
                break;
            case 6:
                setDateFrom(moment().subtract(3, "month").startOf('day'));
                setDateTo(moment().endOf('day'));
                break;
            case 7:
                setDateToReadonly(false);
                setDateFromReadonly(false);
                return;
        }
        setDateToReadonly(true);
        setDateFromReadonly(true);
    }

    return (
        <div className="mt-20">
            <div className="flex items-center mb-14">
                <RbBox onClick={e => setUsersAnchor(e.currentTarget)}
                       className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-28 mr-12 whitespace-nowrap overflow-hidden">
                    <span>{options?.user?.name ?? "Users"}</span>
                    <ChevronDownIcon className="w-20"/>
                </RbBox>
                <RbBox onClick={e => setWorkspaceAnchor(e.currentTarget)}
                       className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-28 mr-12 whitespace-nowrap overflow-hidden">
                    <span>{options?.workspace?.name ?? "Workspace"}</span>
                    <ChevronDownIcon className="w-20"/>
                </RbBox>
                <RbBox onClick={e => setProjectAnchor(e.currentTarget)}
                       className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-28 mr-12 whitespace-nowrap overflow-hidden">
                    <span>{options?.project?.name ?? "Project"}</span>
                    <ChevronDownIcon className="w-20"/>
                </RbBox>
                <RbBox onClick={e => setBoardsAnchor(e.currentTarget)}
                       className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-28 mr-12 whitespace-nowrap overflow-hidden">
                    <span>{options?.board?.name ?? "Board"}</span>
                    <ChevronDownIcon className="w-20"/>
                </RbBox>
                <RbBox onClick={e => setStatusAnchor(e.currentTarget)}
                       className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-28 whitespace-nowrap overflow-hidden">
                    <span>{options?.status?.name ?? "Status"}</span>
                    <ChevronDownIcon className="w-20"/>
                </RbBox>
            </div>
            <div className="flex">
                <div className="flex items-center">
                    <RbBox onClick={e => setDateAnchor(e.currentTarget)}
                           className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-36">
                        <span className="capitalize">{dateDisplay}</span>
                        <ChevronDownIcon className="w-20"/>
                    </RbBox>
                    <DesktopDatePicker
                        open={openFrom}
                        label="Date from"
                        value={dateFrom}
                        onOpen={() => {
                            if (!dateFromReadonly) {
                                setOpenFrom(true);
                            }
                        }}
                        onClose={() => {
                            setOpenFrom(false);
                        }}
                        ignoreInvalidInputs={true}
                        disabled={dateFromReadonly}
                        inputFormat="dd.MM.yyyy"
                        onChange={(e) => setDateFrom(moment(e?.toISOString() ?? ""))}
                        renderInput={(params) => <TextField {...params}
                                                            InputProps={
                                                                {
                                                                    endAdornment: null,
                                                                    readOnly: true
                                                                }
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            className="w-128 ml-10"
                                                            onClick={() => {
                                                                if (!dateFromReadonly) {
                                                                    setOpenFrom(true);
                                                                }
                                                            }}
                        />}/>
                    <DesktopDatePicker
                        open={openTo}
                        label="Date to"
                        value={dateTo}
                        onOpen={() => {
                            if (!dateToReadonly) {
                                setOpenTo(true);
                            }
                        }}
                        onClose={() => {
                            setOpenTo(false);
                        }}
                        ignoreInvalidInputs={true}
                        disabled={dateToReadonly}
                        inputFormat="dd.MM.yyyy"
                        onChange={(e) => setDateTo(moment(e?.toISOString() ?? ""))}
                        renderInput={(params) => <TextField {...params}
                                                            InputProps={
                                                                {
                                                                    endAdornment: null,
                                                                    readOnly: true
                                                                }
                                                            }
                                                            variant="outlined"
                                                            size="small"
                                                            className="w-128 ml-10"
                                                            onClick={() => {
                                                                if (!dateToReadonly) {
                                                                    setOpenTo(true);
                                                                }
                                                            }}
                        />}/>
                </div>
            </div>
            <RbSingleSelect items={dateValues} search={false} anchorEl={dateAnchor} onClose={() => setDateAnchor(null)}
                            onSelect={handleDateRange} hasClear={false} renderItem={item => item.name}/>

            <RbSingleSelect items={organizationUsers} anchorEl={usersAnchor} onClose={() => setUsersAnchor(null)}
                            onSelect={u => updateOptions({...options, user: u})} renderItem={item => item.name}/>
            <RbSingleSelect items={workspaces} anchorEl={workspaceAnchor} onClose={() => setWorkspaceAnchor(null)}
                            onSelect={w => updateOptions({...options, workspace: w})} renderItem={item => item.name}/>
            <RbSingleSelect items={projects} anchorEl={projectAnchor} onClose={() => setProjectAnchor(null)}
                            onSelect={p => updateOptions({...options, project: p})} renderItem={item => item.name}/>
            <RbSingleSelect items={boards} anchorEl={boardsAnchor} onClose={() => setBoardsAnchor(null)}
                            onSelect={b => updateOptions({...options, board: b})} renderItem={item => item.name}/>
            <RbSingleSelect items={cardStatuses} anchorEl={statusAnchor} onClose={() => setStatusAnchor(null)}
                            onSelect={s => updateOptions({...options, status: s})} renderItem={item => item.name}/>
        </div>
    )
}
