import {_delete, _get, _patch, _post} from "../apiService";

export const getMyDayList = () => {
    return _get(`to-do/lists/my-day`);
}

export const getNoTrackerActivity = (orgId: string, date: string) => {
    return _get(`dashboard/${orgId}/no-tracker-activity?date=${date}`);
}

export const getOrganizationTrackersForUser = (orgId: string, dateFrom: string, dateTo: string, userId: string | undefined = undefined) => {
    return _get(`dashboard/${orgId}/tracker-activity-range?dateFrom=${encodeURIComponent(dateFrom)}&DateTo=${encodeURIComponent(dateTo)}&userId=${userId ?? ""}`);
}

export const getOrganizationLiveTrackers = (orgId: string) => {
    return _get(`trackers/${orgId}/live-trackers`);
}

export const getOrganizationWidgets = (orgId: string) => {
    return _get(`dashboard/${orgId}/user-widgets`);
}

export const toggleOrganizationWidget = (orgId: string, widgetId: number) => {
    return _post(`dashboard/${orgId}/${widgetId}/toggle-active`, null);
}
export const updateWidgetWidth = (orgId: string, widgetId: number, size: string) => {
    return _patch(`dashboard/${orgId}/${widgetId}/change-size/${size}`, null);
}
