import {useState} from "react";
import {getNoTrackerActivity} from "../../../services/dashboard/dashboardService";
import {INoTrackerActivity} from "../../../types/dashboard-types";
import WidgetBase, {WidgetWidth} from "./WidgetBase";
import * as React from "react";
import WidgetList from "./WidgetList";
import {RbTableSelect} from "../../../shared-components/rb-components/RbDataTable/RbTableSelect";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import {UserOrganization} from "../../../types/common-types";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";

function WidgetNoTrackerActivity(props: { organization: UserOrganization, id: number, defaultWidth: WidgetWidth }) {
    const [noTrackers, setNoTracker] = useState<INoTrackerActivity[]>([]);
    const today = moment().startOf("day");

    function _getNoTrackerActivity(date: string) {
        if (!props?.organization?.id) return;
        getNoTrackerActivity(props.organization.id, date).then(res => {
            if (res?.data?.error) return;
            setNoTracker(res.data.data);
        });
    }

    function renderMenuItems() {
        const list: any[] = [];
        list.push(<MenuItem key="today" value={today.toISOString()}>Today</MenuItem>);
        list.push(<MenuItem key="yesterday"
                            value={moment(today).subtract(1, "day").toISOString()}>Yesterday</MenuItem>);
        for (let i = 2; i < 7; i++) {
            const tmp = moment(today).subtract(i, "day");
            list.push(<MenuItem key={tmp.format("dddd")} value={tmp.toISOString()}>{tmp.format("dddd")}</MenuItem>);

        }
        return list;
    }

    function generateHeaderControls() {
        return (
            <RbTableSelect
                defaultValue={today.toISOString()}
                variant="filled"
                disableUnderline={true}
                onChange={e => _getNoTrackerActivity(e.target.value as string)}>
                {
                    renderMenuItems()
                }
            </RbTableSelect>
        )
    }

    return (
        <WidgetList id={props.id} title="No tracker activity" headerControl={generateHeaderControls}
                    defaultWidth={props.defaultWidth}
                    listSource={noTrackers} displayAttributes={["name", "date"]}/>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(WidgetNoTrackerActivity));
