import {IRbDisplayHeader} from "../../../types/common-types";
import {IReportTrackerActivity} from "../../../types/report-types";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {CurrencyDollarIcon} from "@heroicons/react/outline";
import React, {useState} from "react";
import ReportTrackerActivityPreview from "./ReportTrackerActivityPreview";
import HeroIcon from "../../../../@fuse/core/HeroIcon/HeroIcon";
import {DialogContent, Popover} from "@mui/material";
import {RbDialog} from "app/shared-components/dialogs/RbDialog";
import {getOrganizationUsers} from "../../../services/organizationSettingsService";
import moment from "moment";

export default function ReportTrackerActivity(props: { userId?: string, data: IReportTrackerActivity[] | undefined, refreshActivity?: () => void }) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [selectedTracker, setSelectedTracker] = useState<IReportTrackerActivity>();
    const [addTrackerOpen, setAddTrackerOpen] = useState<boolean>(false);

    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "Name",
            objAttribute: "user.name"
        },
        {
            name: "Project",
            objAttribute: "projectName"
        },
        {
            name: "Board",
            objAttribute: "boardName"
        },
        {
            name: "Card",
            objAttribute: "cardName",
        },
        {
            name: "Category",
            objAttribute: "labelName",
            handleRendering: renderLabel
        },
        {
            name: "Billable",
            objAttribute: "",
            handleRendering: renderBillable
        },
        {
            name: "Type",
            objAttribute: "type",
            handleRendering: renderType
        },
        {
            name: "Edited",
            objAttribute: "",
            handleRendering: renderEdited
        },
        {
            name: "Start",
            objAttribute: "",
            handleRendering: renderStartTime
        },
        {
            name: "Duration",
            objAttribute: "duration",
        }
    ];

    function renderBillable(tracker: IReportTrackerActivity) {
        return <CurrencyDollarIcon className={`w-20 ${!tracker?.billable ? "opacity-20" : "opacity-70"}`}/>
    }

    function renderLabel(tracker: IReportTrackerActivity) {
        return <span className="px-10 py-5 rounded-8"
                     style={{backgroundColor: tracker?.labelColor ?? "transparent"}}>{tracker.labelName}</span>
    }


    function renderEdited(tracker: IReportTrackerActivity) {
        return <span className="w-20 block opacity-70">
            <HeroIcon icon={tracker?.isEdited ? "XCircleIcon" : "CheckCircleIcon"} outline={true}/>
        </span>
    }

    function renderStartTime(tracker: IReportTrackerActivity) {
        return <span>{moment(tracker.startDate).format("DD.MM.yyy HH:mm:ss")}</span>
    }

    function renderType(tracker: IReportTrackerActivity) {
        return <span>{tracker?.isAutomatic ? "Automatic" : "Manual"}</span>
    }


    function handleRowClick(e: React.MouseEvent<HTMLTableRowElement>, tracker: IReportTrackerActivity) {
        setSelectedTracker(tracker);
        setAnchorEl(e.currentTarget);
    }

    return (
        <RbBox className="py-24 px-0">
            <div className="flex justify-between items-center px-24">
                <h2 className="font-bold">Tracker activity</h2>
                <RbRoundButton variant="contained" onClick={() => setAddTrackerOpen(true)}>Add tracker</RbRoundButton>
            </div>
            <RbDataTable headerColumns={displayHeaders} dataSource={props.data ?? []} paginator={true} search={true}
                         onRowClick={handleRowClick} rowClassName="cursor-pointer" pageSizes={[25, 50, 75, 100]}/>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="p-20">
                    <ReportTrackerActivityPreview data={selectedTracker} refreshActivity={props.refreshActivity}/>
                </div>
            </Popover>
            <RbDialog
                open={addTrackerOpen}
                onClose={() => setAddTrackerOpen(false)}>
                <DialogContent>
                    <ReportTrackerActivityPreview onClose={() => setAddTrackerOpen(false)} userId={props?.userId}
                                                  refreshActivity={props.refreshActivity}/>
                </DialogContent>
            </RbDialog>
        </RbBox>
    )
}
