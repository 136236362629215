import {styled} from "@mui/system";
import {AvatarGroup} from "@mui/material";

export const RbAvatarGroup = styled(AvatarGroup)(() => ({
    '&': {
        justifyContent: "flex-end",
    },
    '& .MuiAvatar-circular': {
        width: "2.4rem",
        height: "2.4rem",
        fontSize: "1rem"
    },
}));
