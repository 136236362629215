import TicketsOfSystem from "./tickets-preview/TicketsOfSystem";
import TicketSystems from "./TicketSystems";

const TicketsConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'ticket-systems',
            element: <TicketSystems/>,
        },
        {
            path: 'ticket-systems/tickets/:id',
            element: <TicketsOfSystem/>,
        },
    ],
};

export default TicketsConfig;
