import {IconButton} from "@mui/material";
import * as React from "react";
import {styled} from "@mui/system";
import HeroIcon from "../../../../@fuse/core/HeroIcon/HeroIcon";

export default function RbTableActionButton(props: { onClick?: (e?: any) => void, className?: string, icon: string, outline?: boolean }) {
    const RbTableStyledButton = styled(IconButton)(() => ({
        "&": {
            marginLeft: "0.5rem"
        },
        "&:hover svg": {
            color: props?.icon === "TrashIcon" ? "#EF4444" : "",
            transition: "color 150ms linear"
        },
    }));

    return (<RbTableStyledButton onClick={props?.onClick} className={props?.className ?? ""} color="inherit"
                                 aria-label="Delete record">
        <HeroIcon outline={props?.outline ?? true} icon={props.icon} size={20}/>
    </RbTableStyledButton>)
}
