import {styled} from "@mui/system";
import {Dialog} from "@mui/material";

export const RbDialog = styled(Dialog)(({theme}) => ({
    '& .MuiPaper-root': {
        maxWidth: "none",
        minWidth: "32rem",
        width: "min-content"
    },
    '& .MuiDialogTitle-root': {
        marginBottom: "2rem",
        whiteSpace: "nowrap"
    },
    '& .MuiDialogTitle-root.delete': {
        backgroundColor: "#EF4444",
    },
    '& .MuiDialogTitle-root:not(.delete)': {
        backgroundColor: "#06b6d4",
    },
    "& .MuiDialogContentText-root": {
        color: "inherit",
    }
}));
