import {combineReducers} from '@reduxjs/toolkit';
import dialog from './dialogSlice';
import message from './messageSlice';
import navbar from './navbarSlice';
import navigation from './navigationSlice';
import settings from './settingsSlice';
import organization from "../../store/rubicon/organizationSlice";
import appInfo from "../../store/rubicon/appInfoSlice";
import search from "../../store/rubicon/searchSlice";
import runningTracker from "../../store/rubicon/globalTrackerSlice";
import subNavigation from "../../store/rubicon/subNavigationSlice";

const fuseReducers = combineReducers({
    navigation, settings, navbar, message, runningTracker, dialog, search, organization, appInfo, subNavigation
});

export default fuseReducers;
