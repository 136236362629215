import {styled} from "@mui/system";
import Switch from "@mui/material/Switch";

export const RbSwitch = styled(Switch)(() => ({
    '& .Mui-checked': {
        color: "#06B6D4",
        '& + .MuiSwitch-track': {
            backgroundColor: "#83c7d5"
        }
    },
}));
