import {SunIcon} from "@heroicons/react/outline";
import {RbSwitch} from "../../../../shared-components/rb-components/RbSwitch";
import {toggleMyDay} from "../../../../services/todo/todoService";
import React from "react";
import {ITodo} from "../../../../types/todo-types";

export default function TodoMyDay(props: { todo: ITodo | undefined, refreshTodos: (all?: boolean) => void }) {
    const {todo} = props;

    function handleMyDayChange() {
        if (!todo?.id) return;
        toggleMyDay(todo.id).then(res => {
            if (res?.data?.error) return;
            props.refreshTodos(true);
        });
    }

    return (
        <>
                <span>
                    <SunIcon className="w-20 inline-block align-middle mr-10"/>
                    <span className="inline-block align-middle">Add to my day</span>
                </span>
            <RbSwitch size="small" defaultChecked={todo?.isMyDay} onChange={handleMyDayChange}/>
        </>
    )
}
