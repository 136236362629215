import i18next from 'i18next';
import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';
import GeneralOrganizationSettings from "./general/GeneralOrganizationSettings";
import WorkspaceOrganizationSettings from "./workspace/WorkspaceOrganizationSettings";
import ProjectOrganizationSettings from "./projects/ProjectOrganizationSettings";
import TrackerCategoriesOrganizationSettings from "./tracker-categories/TrackerCategoriesOrganizationSettings";
import TeamsOrganizationSettings from "./teams/TeamsOrganizationSettings";
import BillingOrganizationSettings from "./billing/BillingOrganizationSettings";
import UserPermissionsOrganizationSettings from "./user-permissions/UserPermissionsOrganizationSettings";
import {INavigationBlock} from "../../store/rubicon/subNavigationSlice";

i18next.addResourceBundle('en', 'organizationSettingsPage', en);
i18next.addResourceBundle('tr', 'organizationSettingsPage', tr);
i18next.addResourceBundle('ar', 'organizationSettingsPage', ar);

const SettingsConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'organization-settings/general',
            element: <GeneralOrganizationSettings/>,
        },
        {
            path: 'organization-settings/workspaces',
            element: <WorkspaceOrganizationSettings/>,
        },
        {
            path: 'organization-settings/projects',
            element: <ProjectOrganizationSettings/>,
        },
        {
            path: 'organization-settings/tracker-categories',
            element: <TrackerCategoriesOrganizationSettings/>,
        },
        {
            path: 'organization-settings/user-permissions',
            element: <UserPermissionsOrganizationSettings/>,
        },
        {
            path: 'organization-settings/teams',
            element: <TeamsOrganizationSettings/>,
        },
        {
            path: 'organization-settings/billing',
            element: <BillingOrganizationSettings/>,
        },
    ],
};

export const organizationSettingsSubNavigation: INavigationBlock[] = [
    {
        id: 'organization-settings',
        title: 'Organization Settings',
        translate: 'OrganizationSettings',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'organization-settings-general',
                title: 'General',
                translate: 'generalsettings',
                type: 'item',
                icon: 'TemplateIcon',
                url: 'organization-settings/general',
            },
            {
                id: 'organization-settings-workspace',
                title: 'Workspaces',
                translate: 'workspaces',
                type: 'item',
                icon: 'ViewGridAddIcon',
                url: 'organization-settings/workspaces',
            },
            {
                id: 'organization-settings-projects',
                title: 'Projects',
                translate: 'projects',
                type: 'item',
                icon: 'DocumentDuplicateIcon',
                url: 'organization-settings/projects',
            },
            {
                id: 'organization-settings-tracker-categories',
                title: 'Tracker Categories',
                translate: 'tracker-categories',
                type: 'item',
                icon: 'TagIcon',
                url: 'organization-settings/tracker-categories',
            },
            {
                id: 'organization-settings-user-permissions',
                title: 'User permissions',
                translate: 'user-permissions',
                type: 'item',
                icon: 'ShieldCheckIcon',
                url: 'organization-settings/user-permissions',
            },
            {
                id: 'organization-settings-teams',
                title: 'Teams',
                translate: 'teams',
                type: 'item',
                icon: 'UserGroupIcon',
                url: 'organization-settings/teams',
            },
            {
                id: 'organization-settings-billing',
                title: 'Billing',
                translate: 'billing',
                type: 'item',
                icon: 'CashIcon',
                url: 'organization-settings/billing',
            },
        ]
    }];

export default SettingsConfig;
