import React from "react";
import {IBoardCardDetails} from "../../../types/board-types";
import RbIconTitle from "../../../shared-components/rb-components/RbIconTitle";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";

export default function BoardCardReminder(props: { card: IBoardCardDetails | undefined }) {
    const {card} = props;
    return (
        <>
            <RbIconTitle icon="ClockIcon" title="Reminder" outline={false} container="h4" className="mb-10"/>
            <RbRoundButton color="secondary"
                           variant="contained">Not set</RbRoundButton>
        </>
    )
}
