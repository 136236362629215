import Drawer from "@mui/material/Drawer";
import {
    ArchiveIcon,
    PencilIcon,
    PlusCircleIcon,
    TagIcon,
    TemplateIcon,
    TrashIcon,
    XIcon
} from "@heroicons/react/outline";
import {Button, IconButton} from "@mui/material";
import RbContentEditable from "../../../shared-components/rb-components/RbContentEditable";
import {IBoard} from "../../../types/board-types";
import {deleteBoard, toggleArchiveBoard} from "../../../services/boards/boardsService";
import {useTheme} from "@mui/styles";
import {RbTheme} from "../../../types/common-types";
import React, {useState} from "react";
import RbConfirmationDialog from "../../../shared-components/dialogs/RbConfirmationDialog";
import BoardSettingsTemplates from "./BoardSettingsTemplates";
import BoardSettingsLabels from "./BoardSettingsLabels";
import {useNavigate} from "react-router-dom";

export default function BoardSettings(props: { refreshBoard: () => void, board: IBoard, settingsOpen: boolean, onClose: () => void, handleBoardRename: (name: string) => void }) {
    const theme: RbTheme = useTheme();
    const [labelsOpen, setLabelsOpen] = useState<boolean>(false);
    const [templatesOpen, setTemplatesOpen] = useState<boolean>(false);
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    function _toggleArchiveBoard() {
        if (!props?.board?.id) return;
        toggleArchiveBoard(props.board.id).then(res => {
            if (res?.data?.error) return;
            props.refreshBoard();
        });
    }

    function handleDeleteBoard() {
        if (!props?.board?.id) return;
        deleteBoard(props.board.id).then(res => {
            if (res?.data?.error) return;
            navigate("/boards");
        });
    }

    return (
        <Drawer
            anchor="right"
            open={props.settingsOpen}
            onClose={props.onClose}
        >
            <div className="p-20">
                <div className="flex justify-between items-center">
                    <h2>Settings</h2>
                    <IconButton onClick={props.onClose}>
                        <XIcon className="w-20"/>
                    </IconButton>
                </div>
                <hr className="w-320 mt-10 mb-20"/>

                <Button variant="text" className="w-full my-5 py-7 text-16 block text-left cursor-pointer"
                        onClick={() => setTemplatesOpen(true)}>
                    <TemplateIcon className="w-20 mr-10 inline-block align-middle"/>
                    <span>Templates</span>
                </Button>
                <Button variant="text" className="w-full my-5 py-7 text-16 block text-left cursor-pointer">
                    <PlusCircleIcon className="w-20 mr-10 inline-block align-middle"/>
                    <span>Create new board from this one</span>
                </Button>
                <Button variant="text" className="w-full my-5 py-7 text-16 block text-left cursor-pointer"
                        onClick={() => setLabelsOpen(true)}>
                    <TagIcon className="w-20 mr-10 inline-block align-middle"/>
                    <span>Board labels</span>
                </Button>
                <Button variant="text" className="w-full my-7 py-7 text-16 block text-left cursor-pointer">
                    <PencilIcon className="w-20 mr-10 inline-block align-middle"/>
                    <RbContentEditable onConfirm={props.handleBoardRename} className="inline-block w-[calc(100%-3rem)]">
                        <span>Rename board</span>
                    </RbContentEditable>
                </Button>
                <Button variant="text" className="w-full my-5 py-7 text-16 block text-left cursor-pointer"
                        onClick={_toggleArchiveBoard}>
                    <ArchiveIcon className="w-20 mr-10 inline-block align-middle"/>
                    <span>{props.board.archived ? "Reactivate board" : "Archive board"}</span>
                </Button>
                <Button variant="text" className="w-full my-5 py-7 text-16 block text-left cursor-pointer"
                        style={{color: theme.palette.warning.main}}
                        onClick={() => setDeleteOpen(true)}>
                    <TrashIcon className="w-20 mr-10 inline-block align-middle"/>
                    <span>Delete board</span>
                </Button>
            </div>
            <RbConfirmationDialog title="Delete board" isDelete={true} open={deleteOpen}
                                  onClose={() => setDeleteOpen(false)} onConfirm={handleDeleteBoard}>
                <span>Are you sure you want to delete this board?</span>
            </RbConfirmationDialog>
            <BoardSettingsTemplates board={props.board} open={templatesOpen} onClose={() => setTemplatesOpen(false)}/>
            <BoardSettingsLabels refreshBoard={props.refreshBoard} board={props.board} open={labelsOpen}
                                 onClose={() => setLabelsOpen(false)}/>
        </Drawer>
    )
}
