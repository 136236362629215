import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ITracker} from "../../types/tracker-types";

export interface IGlobalTrackerSlice {
    value: ITracker | null;
}

const initialState: IGlobalTrackerSlice = {
    value: null
}

const trackerSlice = createSlice({
    name: "global_tracker", initialState, reducers: {
        updateGlobalTracker(state, action: PayloadAction<ITracker | null>) {
            state.value = action.payload;
        }
    }
});


export const {updateGlobalTracker} = trackerSlice.actions;
export default trackerSlice.reducer;
