import {Popover} from "@mui/material";
import {RbTextField} from "./RbTextField";
import React, {useState} from "react";
import MenuItem from "@mui/material/MenuItem";

export default function RbSingleSelect<T>(props: { onSelect?: (item: T) => void, hasClear: boolean, onClear?: () => void, renderItem?: (item: T) => JSX.Element | string, items: T[], anchorEl: HTMLElement | SVGSVGElement | null, searchPlaceholder?: string, search?: boolean, onClose: () => void }) {
    const [filter, setFilter] = useState<string>("");

    function getFilteredItems(): T[] {
        if (!props?.items || props?.items?.length <= 0) return [];
        const keys = Object.keys(props.items[0]);
        return props?.items.filter(item => {
            for (let i = 0; i < keys.length; i++) {
                // @ts-ignore
                if (typeof item[keys[i]] === "string" && item[keys[i]].toLowerCase().startsWith(filter)) return item;
            }
        });
    }

    return (
        <Popover
            open={!!props.anchorEl}
            anchorEl={props.anchorEl}
            onClose={(e) => {
                // @ts-ignore
                e.stopPropagation();
                props.onClose();
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className="p-10 min-w-128">
                {
                    (props?.searchPlaceholder || props?.search !== false) &&
                    <RbTextField autoFocus={true}
                                 fullWidth
                                 placeholder={props?.searchPlaceholder ?? "Search"}
                                 variant="filled"
                                 hiddenLabel={true}
                                 size="small"
                                 value={filter}
                                 className="mb-10"
                                 onChange={e => setFilter(e.target.value)}
                                 color="primary"/>
                }
                <div className="max-h-160 overflow-auto">
                    {
                        (props.hasClear && props?.items?.length > 0) &&
                        <MenuItem className="block py-5 px-5 w-full cursor-pointer"
                                  key="clear-items"
                                  onClick={() => {
                                      if (props?.onClear) props.onClear();
                                      if (props?.onSelect) {
                                          props.onClose();
                                          // @ts-ignore
                                          props.onSelect(undefined);
                                      }
                                  }}>Clear</MenuItem>
                    }
                    {
                        getFilteredItems().map((item) => {
                            return (
                                <MenuItem className="block py-5 px-5 w-full cursor-pointer"
                                    // @ts-ignore
                                          key={item?.id ? item.id : item}
                                          onClick={() => {
                                              if (props?.onSelect) {
                                                  props.onClose();
                                                  props.onSelect(item);
                                              }
                                          }}>{props?.renderItem ? props.renderItem(item) : item}</MenuItem>
                            )
                        })

                    }
                </div>
            </div>
        </Popover>
    )
}

RbSingleSelect.defaultProps = {
    hasClear: true
}
