import i18next from 'i18next';
import Tracker from './Tracker';
import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';

i18next.addResourceBundle('en', 'trackerPage', en);
i18next.addResourceBundle('tr', 'trackerPage', tr);
i18next.addResourceBundle('ar', 'trackerPage', ar);

const TrackerConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'tracker',
            element: <Tracker/>,
        },
    ],
};

export default TrackerConfig;
