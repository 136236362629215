import {IProjectOverviewBoard} from "../../../types/project-overview-types";
import ProjectOverviewCard from "../overview/ProjectOverviewCard";

export default function ProjectOverviewBoardInfos(props: { data: IProjectOverviewBoard | undefined }) {
    return (
        <div className="flex justify-between">
            <ProjectOverviewCard className="w-[calc(25%-2rem)]" value={props.data?.activeMembers} title="Active members"
                                 subTitle="Total users"
                                 subValue={props.data?.totalMembers}/>
            <ProjectOverviewCard className="w-[calc(25%-2rem)]" value={props.data?.totalTasks} title="Tasks"
                                 subTitle="Marked as done"
                                 subValue={props.data?.markAsDone}/>
            <ProjectOverviewCard className="w-[calc(25%-2rem)]" value={props.data?.scheduled} title="Scheduled tasks"
                                 subTitle="Overdue"
                                 subValue={props.data?.overdue}/>
            <ProjectOverviewCard className="w-[calc(25%-2rem)]" value={props.data?.trackers.length} title="Trackers"
                                 subTitle="Time tracked"
                                 subValue={props.data?.totalTime}/>
        </div>
    )
}
