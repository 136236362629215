import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {Menu, MenuItem} from "@mui/material";
import {PlusCircleIcon, ViewBoardsIcon, ViewGridIcon} from "@heroicons/react/outline";
import React, {useState} from "react";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import CreateNewBoardDialog from "./CreateNewBoardDialog";
import CreateFromTemplateBoardDialog from "./CreateFromTemplateBoardDialog";

export default function CreateBoard(props: { updateBoards: () => void }) {
    const theme: RbTheme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [newBoardOpen, setNewBoardOpen] = useState<boolean>(false);
    const [fromTemplateOpen, setFromTemplateOpen] = useState<boolean>(false);

    function newBoard() {
        setAnchorEl(null);
        setNewBoardOpen(true);
    }

    function fromTemplate() {
        setAnchorEl(null);
    }

    function fromExisting() {
        setAnchorEl(null);
    }

    return (
        <>
            <RbRoundButton variant="contained"
                           style={{color: theme.palette.text.primary}}
                           onClick={(e) => setAnchorEl(e.currentTarget)}>+ Create board</RbRoundButton>

            <Menu
                id="new-board-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={newBoard} className="flex justify-between">
                    New <PlusCircleIcon className="w-20 ml-10"/>
                </MenuItem>
                <MenuItem onClick={fromTemplate} className="flex justify-between">
                    From template <ViewGridIcon className="w-20 ml-10"/>
                </MenuItem>
                <MenuItem onClick={fromExisting} className="flex justify-between">
                    From existing <ViewBoardsIcon className="w-20 ml-10"/>
                </MenuItem>
            </Menu>
            <CreateNewBoardDialog open={newBoardOpen} onClose={(update: boolean) => {
                if (update) props.updateBoards();
                setNewBoardOpen(false)
            }}/>
            <CreateFromTemplateBoardDialog open={fromTemplateOpen} onClose={(update: boolean) => {
                if (update) props.updateBoards();
                setFromTemplateOpen(false)
            }}/>
        </>
    )
}
