import {_delete, _get, _patch, _post} from "./apiService";

export const getProjectOverview = (projectId: string, dateFrom: string, dateTo: string) => {
    return _get(`overview/${projectId}?DateFrom=${encodeURIComponent(dateFrom)}&DateTo=${encodeURIComponent(dateTo)}`);
}

export const getProjectOverviewTrackers = (projectId: string, dateFrom: string, dateTo: string) => {
    return _get(`overview/${projectId}/trackers?DateFrom=${encodeURIComponent(dateFrom)}&DateTo=${encodeURIComponent(dateTo)}`);
}

export const getProjectOverviewBoards = (projectId: string, boardId: number) => {
    return _get(`overview/${projectId}/board/${boardId}`);
}
export const getProjectOverviewMembers = (projectId: string) => {
    return _get(`overview/${projectId}/members`);
}

