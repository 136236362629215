import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";
import {useEffect, useState} from "react";
import {ITicketDetails, ITicketStatus} from "../../../types/tickets-types";
import {formatToEUDate} from "../../../utils/utils";
import RbSingleSelect from "../../../shared-components/rb-components/RbSingleSelect";
import * as React from "react";
import {getTicketDetails, getTicketStatuses, updateTicketStatus} from "../../../services/ticketsService";
import {useParams} from "react-router-dom";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import RbText from "../../../shared-components/rb-components/RbText";
import RbIconTitle from "../../../shared-components/rb-components/RbIconTitle";
import {ChevronDownIcon} from "@heroicons/react/outline";
import TicketCloseDialog from "./TicketCloseDialog";
import moment from "moment";

export default function TicketDetails(props: { className?: string, edit: boolean }) {
    const {id} = useParams();
    const [ticketStatuses, setTicketStatuses] = useState<ITicketStatus[]>([]);
    const [ticketClose, setTicketClose] = useState<boolean>(false);
    const [ticketDetails, setTicketDetails] = useState<ITicketDetails>();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    useEffect(() => {
        if (props.edit) {
            _getTicketStatuses();
        }
        _getTicketDetails();
    }, []);

    function _getTicketStatuses() {
        getTicketStatuses().then(res => {
            if (res?.data?.error) return;
            setTicketStatuses(res.data.data);
        });
    }

    function _getTicketDetails() {
        if (!id) return;
        getTicketDetails(id).then(res => {
            if (res?.data?.error) return;
            setTicketDetails(res.data.data);
        });
    }

    function _updateTicketStatus(status: ITicketStatus) {
        if (!ticketDetails?.id || !status.id) return;
        if (status.name === "Closed") {// id = 3
            setTicketClose(true);
        } else {
            updateTicketStatus(ticketDetails.id, status.id).then(res => {
                if (res?.data?.error) return;
                setTicketDetails({...ticketDetails, statusId: status.id, statusName: status.name});
            });
        }
    }

    return (
        <div className={props.className ?? ""}>
            <div className="flex justify-between items-center">
                <h3>Created: {ticketDetails?.createdAt ? formatToEUDate(moment(ticketDetails.createdAt).toISOString(true)) : ""}</h3>
                {
                    props.edit && <RbRoundButton color="primary" variant="contained" onClick={() => {
                        if (props.edit) {
                            setTicketClose(true);
                        }
                    }}>Close ticket</RbRoundButton>
                }
                <TicketCloseDialog ticketId={ticketDetails?.id} open={ticketClose} onClose={update => {
                    if (update && props.edit) {
                        _getTicketDetails();
                    }
                    setTicketClose(false);
                }}/>
            </div>
            <div className="flex items-center mb-16">
                <h3 className="mr-10">Status: </h3>
                <RbRoundButton color="secondary" variant="contained" className="flex items-center"
                               onClick={e => {
                                   if (props?.edit === true) {
                                       setAnchorEl(e.currentTarget);
                                   }
                               }}>
                    <span>{ticketDetails?.statusName ?? "Not set"}</span>
                    <ChevronDownIcon className="w-16 ml-7"/>
                </RbRoundButton>
                <RbSingleSelect anchorEl={anchorEl} onClose={() => setAnchorEl(null)} items={ticketStatuses}
                                renderItem={i => i.name} search={false} hasClear={false}
                                onSelect={_updateTicketStatus}/>
            </div>
            <h1 className="mb-10 text-[2.6rem]">Title: {ticketDetails?.title ?? ""}</h1>
            <div className="block mb-20">
                <span className="mb-10 block">Category: {ticketDetails?.category ?? ""}</span>
                <span className="mb-10 block">Subcategory: {ticketDetails?.subcategory ?? ""}</span>
            </div>
            <div className="block mb-10">
                <RbTextField className="w-full block min-h-32"
                             variant="outlined"
                             label="Description"
                             rows="4"
                             multiline={true}
                             disabled={true}
                             InputLabelProps={{
                                 shrink: true,
                             }}
                             value={ticketDetails?.description}
                             color="primary"/>
            </div>
            <span className="flex items-center mb-10">
                <RbText theme="secondary" className="mr-5">Closed at: </RbText>
                <span>{ticketDetails?.closeDate ? formatToEUDate(moment(ticketDetails.closeDate).toISOString(true)) : ""}</span>
            </span>
            <span className="flex items-center mb-10">
                <RbText theme="secondary">Closed status:</RbText>
                <span className="ml-5"> {ticketDetails?.closeStatusName ?? ""}</span>
            </span>

            <div className="w-full overflow-auto">
                <RbIconTitle icon="PaperClipIcon" title="Attachments" container="h4"/>
                <div className="mt-16">
                    <i><span>ATTACHMENTS PLACEHOLDER</span></i>
                </div>
            </div>
        </div>
    )
}

TicketDetails.defaultProps = {
    edit: true
}
