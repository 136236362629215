import {
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Step,
    StepButton,
    StepLabel,
    Stepper
} from "@mui/material";
import {RbDialog} from "app/shared-components/dialogs/RbDialog";
import React, {useEffect, useState} from "react";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {TabContext, TabPanel} from "@mui/lab";
import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/outline";
import {
    createOrganization,
    getLicencePriceListings,
    getLicences,
    getOrganizationSeatsInfo
} from "../../../services/organizationSettingsService";
import {useTheme} from "@mui/material/styles";
import {RbTheme, UserOrganization} from "../../../types/common-types";
import {ILicence, IOrganizationSeatsInfo} from "../../../types/organization-settings-types";

export default function OrganizationCreateDialog(props: { open: boolean, onClose: (update?: boolean) => void, organization?: UserOrganization }) {
    const [monthlyPayment, setMonthlyPayment] = useState<boolean>(false);
    const [seatsInfo, setSeatsInfo] = useState<IOrganizationSeatsInfo>();
    const [licences, setLicences] = useState<ILicence[]>([]);
    const [licence, setLicence] = useState<ILicence>();
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [seats, setSeats] = useState<number>(5);
    const [activeStep, setActiveStep] = useState<number>(0);
    const theme: RbTheme = useTheme();

    useEffect(() => {
        if (!props.open) return;
        _getLicences();
    }, [props?.open]);

    useEffect(() => {
        if (!props.open) return;
        _getLicencePriceListings();
    }, [licence?.id]);

    function handleNext() {
        switch (activeStep) {
            case 2:
                _createOrganization();
                break;
            case 3:
                props.onClose(true);
                resetDialogData();
                break;
            default:
                setActiveStep(activeStep + 1);
                break;
        }
    }

    function resetDialogData() {
        setTimeout(() => {
            setSeats(5);
            setName("");
            setEmail("");
            setActiveStep(0);
            setLicences([]);
            setMonthlyPayment(false);
            setSeatsInfo(undefined);
            setLicence(undefined);
        }, 1000);
    }

    function _getLicencePriceListings() {
        if (!licence?.id) return;
        getLicencePriceListings(licence.id).then(res => {
            if (res?.data?.error) return;
            setSeatsInfo(res.data.data);
            setSeats((res.data.data as IOrganizationSeatsInfo).min);
        });
    }

    function _createOrganization() {
        if (!licence?.id) return;
        createOrganization(name, email, seats, licence.id, monthlyPayment).then(res => {
            if (res?.data?.error) return;
            setActiveStep(activeStep + 1);
        });
    }


    function _getLicences() {
        getLicences().then(res => {
            if (res?.data?.error) return;
            setLicences(res.data.data);
        })
    }

    function shouldDisable(step: number | undefined = undefined): boolean {
        switch (step ?? activeStep) {
            case 0:
                return !name || !email;
            case 1:
                return !licence?.id;
            case 2:
                return !seatsInfo;
            default:
                return activeStep < 3;
        }
    }

    const getSeatObject = React.useMemo(
        () => () => {
            return (seatsInfo?.seats ?? []).find(s => s.seatNo === seats);
        },
        [seats]
    );

    return (
        <RbDialog
            open={props.open}
            onClose={(e, reason) => {
                if (reason && reason === "backdropClick") return;
                props.onClose();
            }}
        >
            <DialogTitle>Create new organization</DialogTitle>

            <DialogContent className="w-512 pb-0">
                <Stepper activeStep={activeStep}>
                    {
                        ["Basic info", "Licence", "Payment", "Finalizing"].map((title, i) => {
                            return (
                                <Step key={i}>
                                    <StepButton onClick={() => setActiveStep(i)}>
                                        <StepLabel className="flex-col gap-5" StepIconComponent={() => {
                                            return (
                                                <span
                                                    className="w-24 aspect-square flex justify-center items-center rounded-full"
                                                    style={{backgroundColor: i <= activeStep ? theme.palette.special : theme.palette.secondary.main}}>{i + 1}</span>
                                            )
                                        }}>{title}</StepLabel>
                                    </StepButton>
                                </Step>
                            )
                        })
                    }
                </Stepper>
                <TabContext value={String(activeStep)}>
                    <TabPanel value={"0"} className="px-0">
                        <RbTextField className="mb-24"
                                     placeholder="Name"
                                     label="Organization name"
                                     variant="outlined"
                                     value={name}
                                     onChange={e => setName(e.target.value)}
                                     color="primary"/>
                        <RbTextField className="mb-16"
                                     placeholder="Email"
                                     label="Organization email"
                                     variant="outlined"
                                     value={email}
                                     onChange={e => setEmail(e.target.value)}
                                     color="primary"/>
                    </TabPanel>

                    <TabPanel value={"1"} className="px-0">
                        <div className="flex flex-col gap-10">
                            {
                                licences.map((lic, i) => {
                                    return (
                                        <RbRoundButton key={i}
                                                       variant={lic.id === licence?.id ? "contained" : "outlined"}
                                                       className="rounded-6 text-left m-0 px-10 py-5 box-border"
                                                       onClick={() => setLicence(lic)}
                                                       style={{border: `1px solid ${theme.palette.special}`}}>
                                            <h4 className="font-bold">{lic?.name}</h4>
                                            <p>{lic?.description}</p>
                                        </RbRoundButton>
                                    )
                                })
                            }
                        </div>
                    </TabPanel>

                    <TabPanel value={"2"} className="px-0">
                        <div className="flex flex-col">
                            <div className="flex gap-20 mb-24">
                                <RbRoundButton className="rounded-6 m-0 px-10 py-5 box-border w-full"
                                               onClick={() => setMonthlyPayment(false)} variant="contained"
                                               color={monthlyPayment ? "secondary" : "primary"}>Annually</RbRoundButton>

                                <RbRoundButton className="rounded-6 m-0 px-10 py-5 box-border w-full"
                                               onClick={() => setMonthlyPayment(true)} variant="contained"
                                               color={!monthlyPayment ? "secondary" : "primary"}>Monthly</RbRoundButton>
                            </div>
                            <div className="flex items-center">
                                <IconButton className="p-5" disabled={!seatsInfo}>
                                    <MinusCircleIcon className="w-36" strokeWidth="1.5" onClick={() => {
                                        if (seatsInfo?.min && seats > seatsInfo.min) {
                                            setSeats(seats - 1);
                                        }
                                    }}/>
                                </IconButton>
                                <RbTextField className="mx-10"
                                             disabled={true}
                                             label="Seats"
                                             InputLabelProps={{
                                                 shrink: true,
                                             }}
                                             InputProps={{
                                                 inputProps: {className: "text-center"}
                                             }}
                                             variant="outlined"
                                             value={seats}
                                             color="primary"/>
                                <IconButton className="p-5" disabled={!seatsInfo}>
                                    <PlusCircleIcon className="w-36" strokeWidth="1.5"
                                                    onClick={() => {
                                                        if (seatsInfo?.max && seats < seatsInfo.max) {
                                                            setSeats(seats + 1);
                                                        }
                                                    }}/>
                                </IconButton>
                            </div>
                            <hr className="my-24"/>
                            <p className="flex justify-between items-center mb-24">
                                <span>{monthlyPayment ? "Monthly price for single seat" : "Annual price for single seat"}</span>
                                <span>{monthlyPayment ? (getSeatObject()?.priceMonthly ?? 0) / seats : (getSeatObject()?.priceAnnually ?? 0) / 12}$</span>
                            </p>
                            <p className="flex justify-between items-center">
                                <span>Total</span>
                                <span>{monthlyPayment ? getSeatObject()?.priceMonthly ?? 0 : getSeatObject()?.priceAnnually ?? 0}$</span>
                            </p>
                        </div>
                    </TabPanel>

                    <TabPanel value={"3"} className="px-0">
                        <h2>Thank you. Your organization has been created successfully</h2>
                    </TabPanel>
                </TabContext>
            </DialogContent>

            <DialogActions className="mb-10 px-24">
                <RbRoundButton disabled={false} variant="text" className="mr-10"
                               onClick={() => {
                                   resetDialogData();
                                   props.onClose();
                               }}>Cancel</RbRoundButton>
                <RbRoundButton disabled={shouldDisable()} variant="contained" className=""
                               onClick={handleNext}>{activeStep < 3 ? "Next" : "Finish"}</RbRoundButton>
            </DialogActions>
        </RbDialog>
    )
}
