import {IRbDisplayHeader, RbTheme} from "../../../types/common-types";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";
import {IReportEstimatedTime} from "../../../types/report-types";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {RbLink} from "app/shared-components/rb-components/RbLink";
import {useTheme} from "@mui/material/styles";

function ReportEstimatedTime(props: { data: IReportEstimatedTime[], saveEstimatedSettings: () => void }) {

    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "Card created",
            objAttribute: "createdAt"
        },
        {
            name: "Task ID",
            objAttribute: "cardId",
            handleRendering: data => <RbLink to={`/board/card/details/${data.boardId}/${data.cardId}`}
                                             onClick={props.saveEstimatedSettings}>
                <span className="underline underline-offset-4">#{data.cardNumber}</span></RbLink>
        },
        {
            name: "Task name",
            objAttribute: "cardName"
        },
        {
            name: "Board",
            objAttribute: "boardName",
            handleRendering: data => <RbLink to={`/board-view/${data.boardId}`} onClick={props.saveEstimatedSettings}>
                <span className="underline underline-offset-4">#{data.boardName}</span></RbLink>
        },
        {
            name: "Status",
            objAttribute: "statusName",
            handleRendering: data => <span
                className={data?.statusId === 1 ? `text-[#059669] font-bold` : ""}>{data?.statusName}</span>
        },
        {
            name: "ETC",
            objAttribute: "estimated"
        },
        {
            name: "Trackers",
            objAttribute: "count"
        },
        {
            name: "Tracked time",
            objAttribute: "trackerDuration"
        },
    ];

    return (
        <RbBox className="mt-20">
            <h2 className="mb-12">Estimated time</h2>
            <RbDataTable dataSource={props.data ?? []} headerColumns={displayHeaders} paginator={true}
                         pageSizes={[25, 50, 75, 100]} defaultPageSize={25}/>
        </RbBox>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value
    }
}

export default withRouter(connect(mapStateToProps)(ReportEstimatedTime));

