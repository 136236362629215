import TicketDetails from "./TicketDetails";
import TicketCommentsAndActivity from "./TicketCommentsAndActivity";

export default function SystemTicketPreview() {
    return (
        <div className="flex h-full">
            <TicketDetails className="w-3/5 mt-20 px-40 border-r-1 h-full"/>
            <TicketCommentsAndActivity className="w-2/5 px-20"/>
        </div>
    )
}
