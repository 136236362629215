import BoardCardDetailsPreview from "./board-details/BoardCardDetailsPreview";

const BoardsCardDetailsConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    routes: [
    ],
};

export default BoardsCardDetailsConfig;
