import FuseSearch from '@fuse/core/FuseSearch';
import {ThemeProvider} from '@mui/material/styles';
import FuseShortcuts from '@fuse/core/FuseShortcuts';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import QuickPanelToggleButton from 'app/fuse-layouts/shared-components/quickPanel/QuickPanelToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import clsx from 'clsx';
import {memo} from 'react';
import {useSelector} from 'react-redux';
import {selectToolbarTheme} from 'app/store/fuse/settingsSlice';
import AdjustFontSize from '../../shared-components/AdjustFontSize';
import FullScreenToggle from '../../shared-components/FullScreenToggle';
import LanguageSwitcher from '../../shared-components/LanguageSwitcher';

function ToolbarLayout2(props) {
    const config = useSelector(({fuse}) => fuse.settings.current.layout.config);
    const toolbarTheme = useSelector(selectToolbarTheme);

    return (<ThemeProvider theme={toolbarTheme}>
        <AppBar
            id="fuse-toolbar"
            className={clsx('flex relative z-20 shadow-none', props.className)}
            color="default"
            style={{backgroundColor: toolbarTheme.palette.background.paper}}
        >
            <Toolbar className="container p-0 lg:px-24 min-h-48 md:min-h-64">
                {config.navbar.display && (<NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8"/>)}

                {/*<div className="flex flex-1">*/}
                {/*    <Hidden lgDown>*/}
                {/*        <FuseShortcuts/>*/}
                {/*    </Hidden>*/}
                {/*</div>*/}

                <div className="flex items-center px-8 h-full overflow-x-auto">
                    {/*<LanguageSwitcher/>*/}

                    {/*<AdjustFontSize/>*/}

                    <FullScreenToggle/>

                    <FuseSearch/>

                    <QuickPanelToggleButton/>

                    <UserMenu/>
                </div>
            </Toolbar>
        </AppBar>
    </ThemeProvider>);
}

export default memo(ToolbarLayout2);
