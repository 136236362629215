import {
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel, MenuItem, Select,
} from "@mui/material";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import React, {useEffect, useState} from "react";
import {RbDialog} from "app/shared-components/dialogs/RbDialog";
import {IProjectDropDown, RbTheme, UserOrganization} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {getAllOrganizationProjects} from "../../../services/common/common";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {RbSubSection, RbSubSectionItem} from "app/shared-components/rb-components/RbSections";
import {createNewBoard} from "../../../services/boards/boardsService";
import {IBoardTemplate} from "../../../types/board-types";
import withRouter from "@fuse/core/withRouter";
import {connect} from "react-redux";

function CreateFromTemplateBoardDialog(props: { open: boolean, onClose: (update?: boolean) => void, organization: UserOrganization }) {
    const theme: RbTheme = useTheme();
    const [projects, setProjects] = useState<IProjectDropDown[]>([]);
    const [project, setProject] = useState<IProjectDropDown>({id: ""} as any);
    const [boardTitle, setBoardTitle] = useState<string>("");
    const [templates, setTemplates] = useState<IBoardTemplate[]>([]);
    const [template, setTemplate] = useState<IBoardTemplate>({id: ""} as any);

    useEffect(() => {
        _getAllOrganizationProjects();
    }, [props?.organization?.id]);

    function _getAllOrganizationProjects() {
        if (!props?.organization?.id) return;
        getAllOrganizationProjects(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setProjects(res.data.data);
        });
    }

    function _createNewBoard() {
        createNewBoard(boardTitle, project.id).then(res => {
            if (res?.data?.error) return;
            props.onClose();
        });
    }

    return (
        <RbDialog
            open={props.open}
            onClose={() => props.onClose()}
        >
            <DialogTitle>Create board</DialogTitle>
            <DialogContent>
                <RbSubSectionItem>
                    <FormControl fullWidth>
                        <RbTextField className="w-512"
                                     variant="outlined"
                                     label="Title"
                                     autoFocus={true}
                                     onChange={e => setBoardTitle(e.target.value)}
                                     color="primary"/>
                    </FormControl>
                </RbSubSectionItem>

                <RbSubSection>
                    <FormControl fullWidth>
                        <InputLabel id="project">Project</InputLabel>
                        <Select
                            labelId="project"
                            label="Project"
                            variant="outlined"
                            value={project?.id}
                        >
                            {projects.map((proj: IProjectDropDown) => (
                                <MenuItem key={proj.id}
                                          onClick={() => setProject(proj)}
                                          value={proj?.id}>{proj?.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </RbSubSection>

                <RbSubSection>
                    <FormControl fullWidth>
                        <InputLabel id="template">Template</InputLabel>
                        <Select
                            labelId="template"
                            label="Template"
                            variant="outlined"
                            value={template?.id}
                        >
                            {templates.map(template => (
                                <MenuItem key={template.id}
                                          onClick={() => setTemplate(template)}
                                          value={template?.id}>{template?.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </RbSubSection>
            </DialogContent>
            <DialogActions>
                <RbRoundButton disabled={!project?.id || !boardTitle || !template?.id}
                               style={{color: theme.palette.text.primary}}
                               variant="contained" onClick={_createNewBoard}>Create</RbRoundButton>
            </DialogActions>
        </RbDialog>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(CreateFromTemplateBoardDialog));
