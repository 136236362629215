import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {PencilIcon, PlusIcon, RefreshIcon} from "@heroicons/react/outline";
import * as React from "react";
import {FormControl, MenuItem} from "@mui/material";
import {useEffect, useState} from "react";
import {getAllCurrencies} from "../../../services/common/common";
import {ICurrency, IMemberType, IRbDisplayHeader, RbTheme, UserOrganization,} from "../../../types/common-types";
import {
    deleteWorkspace,
    getAllAdminOrganizationWorkspaces,
    getMemberTypes, getWorkspacePrices,
    getWorkspaceProjects, getWorkspaceUsers, removeProject, removeUserFromWorkspace,
    updateUserMemberTypeInOrganization,
    updateWorkspaceCurrency, updateWorkspaceName
} from "../../../services/organizationSettingsService";
import {RbFilledSelect} from "app/shared-components/rb-components/RbDataTable/RbFilledSelect";
import {RbSection, RbSubSection, RbSubSectionItem} from "app/shared-components/rb-components/RbSections";
import {IAdminWorkspace, IProject, IWorkspacePrice, IWorkspaceUser} from "../../../types/organization-settings-types";
import {RbTableSelect} from "../../../shared-components/rb-components/RbDataTable/RbTableSelect";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {useTheme} from "@mui/material/styles";
import RbTableActionButton from "../../../shared-components/rb-components/RbDataTable/RbTableActionButton";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";
import OrganizationProjectCreateDialog from "./OrganizationProjectCreateDialog";
import OrganizationWorkspaceCreateDialog from "./OrganizationWorkspaceCreateDialog";
import RbConfirmationDialog from "../../../shared-components/dialogs/RbConfirmationDialog";
import RbProtectedTextField from "../../../shared-components/rb-components/RbProtectedTextField";

function WorkspaceOrganizationSettings(props: { organization: UserOrganization }) {
    const [currencies, setCurrencies] = useState<ICurrency[]>([]);
    const [workspaces, setWorkspaces] = useState<IAdminWorkspace[]>([]);
    const [workspace, setWorkspace] = useState<IAdminWorkspace | undefined>();
    const [workspaceOpen, setWorkspaceOpen] = useState<boolean>(false);
    const [deleteWorkspaceOpen, setDeleteWorkspaceOpen] = useState(false);

    const [projects, setProjects] = useState<IProject[]>([]);
    const [projectOpen, setProjectOpen] = useState<boolean>(false);
    const [workUsers, setWorkUsers] = useState<IWorkspaceUser[]>([]);
    const [workPrices, setWorkPrices] = useState<IWorkspacePrice[]>([]);
    const [memberTypes, setMemberTypes] = useState<IMemberType[]>([]);

    const [deleteItem, setDeleteItem] = useState<IWorkspaceUser | IProject>()
    const theme: RbTheme = useTheme();

    const displayWorkUsersHeaders: IRbDisplayHeader[] = [
        {
            name: "User",
            objAttribute: "name"
        },
        {
            name: "Email",
            objAttribute: "email"
        },
        {
            name: "Type",
            objAttribute: "typeId",
            handleRendering: renderTableMemberType
        },
        {
            name: "Added",
            objAttribute: "dateInsert"
        },
        {
            name: "",
            objAttribute: "",
            handleRendering: renderMemberTableActions,
            className: "text-right"
        }
    ];
    const displayProjectsHeaders: IRbDisplayHeader[] = [
        {
            name: "Project",
            objAttribute: "name"
        },
        {
            name: "Inserted at",
            objAttribute: "dateInsert"
        },
        {
            name: "Inserted by",
            objAttribute: "insertBy"
        },
        {
            name: "",
            objAttribute: "",
            handleRendering: renderProjectTableActions,
            className: "text-right"
        }
    ];
    const displayPricesHeaders: IRbDisplayHeader[] = [
        {
            name: "Valid from",
            objAttribute: "dateFrom"
        },
        {
            name: "Inserted at",
            objAttribute: "dateInsert"
        },
        {
            name: "Price",
            objAttribute: "price"
        },
        {
            name: "Inserted by",
            objAttribute: "name"
        },
    ];

    useEffect(() => {
        _getAllCurrencies();
        _getOrganizationWorkspaces();
        _getMemberTypes();
    }, [props?.organization?.id]);

    useEffect(() => {
        if (workspace) {
            _getWorkspaceProjects();
            _getWorkspaceUsers();
            _getWorkspacePrices();
        } else {
            setWorkUsers([]);
            setWorkPrices([]);
            setProjects([]);
        }
    }, [workspace]);

    useEffect(() => {
        if (!workspace?.id && workspaces.length > 0) {
            setWorkspace(workspaces[0]);
        }
    }, [workspaces]);

    function renderMemberTableActions(obj: IWorkspaceUser) {
        return <RbTableActionButton icon="TrashIcon" onClick={() => {
            if (!workspace?.id) return;
            setDeleteItem(obj);
        }
        }/>;
    }

    function renderProjectTableActions(obj: IProject) {
        return <RbTableActionButton icon="TrashIcon" onClick={() => {
            setDeleteItem(obj);
        }}/>;
    }

    function handleDeleteAction() {
        if (!deleteItem?.id || !workspace?.id) return;
        // @ts-ignore
        (deleteItem?.avatar ? removeUserFromWorkspace(workspace.id, deleteItem.id) : removeProject(deleteItem.id)).then(res => {
            if (res?.data?.error) return;
            // @ts-ignore
            if (deleteItem?.avatar) {
                _getWorkspaceUsers();
            } else {
                _getWorkspaceProjects();
            }
            setDeleteItem(undefined);
        })

    }

    function renderTableMemberType(data: IWorkspaceUser) {
        return (<FormControl key={data.id} size="small">
            <RbTableSelect
                value={data.typeId}
                placeholder="Member type"
                variant="filled"
                disableUnderline={true}
                onChange={e => handleChangeUserMemberType(e, data.id)}
            >
                {
                    memberTypes.map((type, i) => {
                        return (
                            <MenuItem key={i} value={type?.id}>{type?.name}</MenuItem>
                        )
                    })
                }
            </RbTableSelect>
        </FormControl>)
    }

    function _getMemberTypes() {
        getMemberTypes().then(res => {
            if (!res?.data?.error) {
                setMemberTypes(res.data.data);
            }
        })
    }

    function handleChangeUserMemberType(e: any, userId: string) {
        if (!props?.organization?.id) return;
        updateUserMemberTypeInOrganization(props.organization.id, userId, e.target.value).then(res => {
            if (!res?.data?.error) {
                _getWorkspaceUsers();
            }
        });
    }

    function _getWorkspaceProjects() {
        if (!workspace) return;
        getWorkspaceProjects(workspace.id).then(res => {
            if (!res?.data?.error) {
                setProjects(res.data.data);
            }
        })
    }

    function _getWorkspaceUsers() {
        if (!workspace) return;
        getWorkspaceUsers(workspace.id).then(res => {
            if (!res?.data?.error) {
                setWorkUsers(res.data.data);
            }
        });
    }

    function _getWorkspacePrices() {
        if (!workspace) return;
        getWorkspacePrices(workspace.id).then(res => {
            if (!res?.data?.error) {
                setWorkPrices(res.data.data);
            }
        });
    }


    function _getAllCurrencies() {
        getAllCurrencies().then(res => {
            if (!res?.data?.error) {
                setCurrencies(res.data.data);
            }
        });
    }

    function _updateWorkspaceCurrency(curr: ICurrency) {
        if (!curr) return;

        if (!workspace) return;
        updateWorkspaceCurrency(workspace.id, curr.id).then(res => {
            if (!res?.data?.error) {
                _getOrganizationWorkspaces();
            }
        });
    }

    function _getOrganizationWorkspaces() {
        if (!props?.organization?.id) return;
        getAllAdminOrganizationWorkspaces(props.organization.id).then(res => {
            if (!res?.data?.error) {
                setWorkspaces(res.data.data);
            }
        });
    }

    function handleSetWorkspace(e: any) {
        const worksp = workspaces.find(el => el.id === e.target.value);
        if (!worksp) return;
        setWorkspace(worksp);
    }

    function handleDeleteOfWorkspace() {
        if (!workspace?.id) return;
        deleteWorkspace(workspace.id).then(res => {
            if (res?.data?.error) return;
            setWorkspace(undefined);
            _getOrganizationWorkspaces();
        })

    }

    function _updateWorkspaceName(name: string) {
        if (!workspace?.id || !name) return;
        updateWorkspaceName(workspace.id, name).then(res => {
            if (res?.data?.error) return;
            _getOrganizationWorkspaces();
        })
    }

    return (
        <div className="mt-48 md:ml-28 sm:ml-28 sm:mr-28 ml-64 mr-64 mb-48">
            <div className="workspace-controls">
                <RbSubSection>
                    <div className="w-full flex justify-between items-center">
                        <FormControl size="medium">
                            <RbFilledSelect
                                variant="filled"
                                label="age"
                                className="w-256"
                                value={workspace?.id ?? ""}
                                onChange={(e) => handleSetWorkspace(e)}
                            >
                                <MenuItem key={-1} value={""}
                                          style={{display: `${!workspace?.id ? "block" : "none"}`}}><b>Workspace
                                    name</b></MenuItem>
                                {
                                    workspaces.map(wspace => {
                                        return <MenuItem key={wspace?.id} value={wspace.id}>{wspace?.name}</MenuItem>
                                    })
                                }
                            </RbFilledSelect>
                        </FormControl>
                        <RbRoundButton style={{color: theme.palette.text.primary}}
                                       onClick={() => setWorkspaceOpen(true)}
                                       variant="contained"><PlusIcon className="w-20 inline-block mr-5 align-middle"/>
                            <span className="inline-block align-middle">Create workspace</span></RbRoundButton>
                    </div>
                    <OrganizationWorkspaceCreateDialog open={workspaceOpen} onClose={() => {
                        setWorkspaceOpen(false);
                        _getOrganizationWorkspaces();
                    }} organizationId={props?.organization?.id}/>
                </RbSubSection>
                <RbSubSection>
                    <RbProtectedTextField size="small" slim={true} value={workspace?.name ?? ""}
                                          onSave={_updateWorkspaceName}/>
                </RbSubSection>
                <RbSubSection>
                    <FormControl size="medium">
                        <RbFilledSelect
                            className="w-256"
                            variant="filled"
                            value={workspace?.currencyId ?? -1}>
                            <MenuItem key={-1} value={-1}
                                      style={{display: `${!workspace?.currencyId ? "block" : "none"}`}}><b>Currency</b></MenuItem>
                            {
                                currencies.map((curr, i) => {
                                    return <MenuItem key={i} value={curr.id}
                                                     onClick={() => _updateWorkspaceCurrency(curr)}>{curr?.name}</MenuItem>
                                })
                            }
                        </RbFilledSelect>
                    </FormControl>
                </RbSubSection>
                <RbConfirmationDialog onClose={() => {
                    setTimeout(() => {
                        setDeleteItem(undefined);
                    }, 1000);
                }} onConfirm={handleDeleteAction}
                                      open={!!deleteItem} isDelete={true}>
                    Are you sure you want to remove <span className="font-bold">{deleteItem?.name ?? ""}</span>?
                </RbConfirmationDialog>
                <RbSection>
                    <h2 className="font-semibold">Workspace members</h2>
                    <RbSubSectionItem>
                        <RbDataTable search={true} dataSource={workUsers} headerColumns={displayWorkUsersHeaders}/>
                    </RbSubSectionItem>
                </RbSection>
                <RbSection>
                    <div className="w-full flex justify-between">
                        <h2 className="font-semibold">Projects</h2>
                        <RbRoundButton style={{color: theme.palette.text.primary}} onClick={() => setProjectOpen(true)}
                                       variant="contained"><PlusIcon className="w-20 inline-block mr-5 align-middle"/>
                            <span className="inline-block align-middle">Create project</span></RbRoundButton>
                    </div>
                    <OrganizationProjectCreateDialog open={projectOpen} onClose={() => {
                        setProjectOpen(false);
                        _getWorkspaceProjects();
                    }} workspaceId={workspace?.id}/>
                    <RbSubSectionItem>
                        <RbDataTable search={true} dataSource={projects} headerColumns={displayProjectsHeaders}/>
                    </RbSubSectionItem>
                </RbSection>
                <RbSection>
                    <div className="w-full flex justify-between">
                        <h2 className="font-semibold">Price per hour: {workspace?.currentPrice ?? 0}$</h2>
                        <RbRoundButton style={{color: theme.palette.text.primary}}
                                       variant="contained"><RefreshIcon
                            className="w-20 inline-block mr-5 align-middle"/> <span
                            className="inline-block align-middle">Change price</span></RbRoundButton>
                    </div>
                    <RbSubSectionItem>
                        <RbDataTable search={true} dataSource={workPrices} headerColumns={displayPricesHeaders}/>
                    </RbSubSectionItem>
                </RbSection>
            </div>
            <RbConfirmationDialog onClose={() => setDeleteWorkspaceOpen(false)} onConfirm={handleDeleteOfWorkspace}
                                  open={deleteWorkspaceOpen} isDelete={true}>
                Are you sure you want to delete <span className="font-bold italic">{workspace?.name ?? ""}</span>?
            </RbConfirmationDialog>
            <RbSubSection>
                <RbRoundButton
                    onClick={() => setDeleteWorkspaceOpen(true)}
                    variant="text" color="error" className="mb-10 px-20 text-left">
                    <span style={{color: theme.priority.high}}>Delete workspace</span>
                    <p>Caution! All related data to this workspace will not be available anymore.</p>
                </RbRoundButton>
            </RbSubSection>
        </div>
    )

}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(WorkspaceOrganizationSettings));
