import Avatar from "@mui/material/Avatar";
import RbBox from "../../shared-components/rb-components/RbBox";
import {connect, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {BellIcon} from "@heroicons/react/outline";
import {RbRoundButton} from "../../shared-components/rb-components/RbRoundButton";
import {useTheme} from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import DashboardWidgetSettings from "./widgetsSettings/DashboardWidgetSettings";
import WidgetMyDay from "./widgets/WidgetMyDay";
import WidgetNoTrackerActivity from "./widgets/WidgetNoTrackerActivity";
import WidgetTrackerActivity from "./widgets/WidgetTrackerActivity";
import WidgetRunningTrackers from "./widgets/WidgetRunningTrackers";
import {getOrganizationWidgets, toggleOrganizationWidget} from "../../services/dashboard/dashboardService";
import {IWidget} from "../../types/dashboard-types";
import {WidgetWidth} from "./widgets/WidgetBase";
import {UserOrganization} from "../../types/common-types";
import withRouter from "../../../@fuse/core/withRouter";
import OrganizationCreateDialog from "../organization-settings/create-organization/OrganizationCreateDialog";
import {getAllUserOrganizations} from "../../services/sidebar/sidebarService";
import {useRbDispatch} from "../../rb-hooks/hooks";
import {updateUserOrganization} from "../../store/rubicon/organizationSlice";
import {getRunningTracker} from "../../services/tracker/trackerService";
import {updateGlobalTracker} from "../../store/rubicon/globalTrackerSlice";
import {ITracker} from "../../types/tracker-types";

function Dashboard(props: { organization: UserOrganization }) {
    const user = useSelector((state: any) => state.auth.user.data);
    const theme = useTheme();
    const [organizationCreate, setOrganizationCreate] = useState<boolean>(false);
    const [widgetsOpen, setWidgetsOpen] = useState<boolean>(false);
    const [widgets, setWidgets] = useState<IWidget[]>([]);
    const dispatch = useRbDispatch();

    useEffect(() => {
        if (!props.organization?.id) return;
        _getOrganizationWidgets();
        _getRunningTracker();
    }, [props.organization?.id]);

    function _getRunningTracker() {
        getRunningTracker().then((res) => {
            if (!res?.data?.data?.id) return;
            dispatch(updateGlobalTracker(res.data.data as ITracker));
        });
    }

    function _getOrganizationWidgets() {
        getOrganizationWidgets(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setWidgets(res.data.data);
        });
    }

    function toggleWidget(widget: IWidget) {
        toggleOrganizationWidget(props.organization.id, widget.id).then(res => {
            if (res?.data?.error) return;
            _getOrganizationWidgets();
        });
    }

    function _getAllUserOrganizations() {
        getAllUserOrganizations().then(res => {
            if (res?.data?.error) return;
            if (res.data.data.length > 0 && !props.organization?.id) {
                dispatch(updateUserOrganization(res.data.data[0]));
            }
        });
    }


    return (
        <>
            <RbBox style={{padding: "2.2rem"}} className="rounded-none flex justify-between items-center">
                <div className="flex items-center">
                    <Avatar src={user?.photoURL ?? ""} sx={{width: 60, height: 60}}
                            className=""/>
                    <div className="ml-32">
                        <h1 className="font-bold">Welcome
                            back, {(user?.displayName ?? "").split(" ")[0] ?? ""}</h1>
                        {/* <p className="flex"><BellIcon className="w-12 mr-5"/> You have 2 new messages and 15 new
                            tasks
                        </p> */}
                    </div>
                </div>
                <RbRoundButton style={{color: theme.palette.text.primary}}
                               onClick={() => setWidgetsOpen(true)}
                               className="border-1 border-solid h-36 w-128">Widgets</RbRoundButton>
            </RbBox>

            {
                !!props?.organization?.id ? <>
                        <div className="p-16 pt-28 pb-28">
                            {
                                widgets.filter(w => w.active).map(w => {
                                    return (
                                        mapToWidget(w)
                                    )
                                })
                            }
                        </div>

                        <Drawer
                            anchor="right"
                            open={widgetsOpen}
                            onClose={() => setWidgetsOpen(false)}
                        >
                            <DashboardWidgetSettings toggleWidget={toggleWidget} widgets={widgets}/>
                        </Drawer>
                    </> :
                    <>
                        <OrganizationCreateDialog open={organizationCreate} organization={props?.organization}
                                                  onClose={(update) => {
                                                      setOrganizationCreate(false);
                                                      if (update) {
                                                          _getAllUserOrganizations();
                                                      }
                                                  }}/>
                        <div className="w-full h-full grid justify-center content-center">
                            <div className="w-360 text-center">
                                <img className="m-auto" src="/assets/images/illustrations/newOrganization.svg" alt=""/>
                                <p className="mt-20">To use RubiconTrack to it’s fullest, <span
                                    className="underline cursor-pointer"
                                    onClick={() => setOrganizationCreate(true)}>create an organization</span> and get
                                    access to all
                                    modules</p>
                            </div>
                        </div>
                    </>
            }
        </>
    );

    function mapToWidget(widget: IWidget) {
        switch (widget.id) {
            case 1:
                return <WidgetMyDay key={widget.id} id={widget.id} defaultWidth={widget.size as WidgetWidth}/>
            case 11:
                return <WidgetRunningTrackers key={widget.id} id={widget.id} defaultWidth={widget.size as WidgetWidth}/>
            case 12:
                return <WidgetTrackerActivity key={widget.id} id={widget.id} defaultWidth={widget.size as WidgetWidth}/>
            case 13:
                return <WidgetNoTrackerActivity key={widget.id} id={widget.id}
                                                defaultWidth={widget.size as WidgetWidth}/>
            default:
                return;
        }
    }
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(Dashboard));
