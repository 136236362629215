import {IOrganizationBoard} from "../../../types/board-types";
import {RbTheme, UserOrganization} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {RbAvatarGroup} from "../../../shared-components/rb-components/RbAvatarGroup";
import {Avatar, IconButton, Tooltip} from "@mui/material";
import {getUserInitials} from "../../../utils/RbUtils";
import HeroIcon from "../../../../@fuse/core/HeroIcon/HeroIcon";
import {toggleBoardFavorite} from "../../../services/boards/boardsService";
import moment from "moment";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";

function BoardBar(props: { board: IOrganizationBoard, className?: string, refreshBoards: () => void, organization?: UserOrganization }) {
    const theme: RbTheme = useTheme();

    function handleFavoriteBoardChange() {
        if (!props?.board?.id || !props?.organization?.id) return;
        toggleBoardFavorite(props.organization.id, props.board.id).then(res => {
            if (res?.data?.error) return;
            props.refreshBoards();
        })
    }

    return (
        <RbBox className="flex cursor-pointer justify-between mb-16 items-center hover:shadow-2 shadow-sm transition-shadow">
            <div className="">
                <IconButton className="p-6 mr-10" onClick={handleFavoriteBoardChange}>
                        <span className="w-20" style={{color: props?.board?.isFavorite ? "gold" : "inherit"}}>
                            <HeroIcon icon="StarIcon" outline={!props?.board?.isFavorite}/>
                        </span>
                </IconButton>
                <h3 className="inline-block align-middle w-256"
                    style={{color: theme.palette.text.primary}}>{props.board.boardName}</h3>
            </div>
            <div className="flex flex-col text-center">
                <p style={{color: theme.palette.text.primary}}>{props?.board?.projectName ?? "Project name"}</p>
                <span>
                        <span style={{color: theme.palette.secondary.light, fontSize: 10}}>
                            Edited: {moment(props.board.lastModified).fromNow()}
                        </span>
                    </span>
            </div>
            <RbAvatarGroup className="basis-[12rem] content-end">
                {
                    (props?.board?.members ?? []).map((user, i) => {
                        return (
                            <Tooltip title={user?.name ?? ""} key={i}>
                                <Avatar key={i} src={user?.avatar}
                                        alt={user.name}>{getUserInitials(user.name)}</Avatar>
                            </Tooltip>
                        )
                    })
                }
            </RbAvatarGroup>
        </RbBox>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(BoardBar));
