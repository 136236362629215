import React, {useState} from "react";
import {RbDialog} from "../../../shared-components/dialogs/RbDialog";
import {DialogActions, DialogContent, DialogTitle, FormControl} from "@mui/material";
import {RbSubSection, RbSubSectionItem} from "../../../shared-components/rb-components/RbSections";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {createNewBoard} from "../../../services/boards/boardsService";

export default function OrganizationBoardCreateDialog(props: { open: boolean, onClose: () => void, projectId?: string }) {
    const [boardFullName, setBoardFullName] = useState<string>("");
    const [boardName, setBoardName] = useState<string>("");

    function _createNewBoard() {
        if (!props?.projectId) return;
        createNewBoard(boardFullName, props.projectId).then(res => {
            if (res?.data?.error) return;
            closeAndReset();
        });
    }

    function closeAndReset() {
        props.onClose();
        setTimeout(() => {
            setBoardName("");
            setBoardFullName("");
        }, 1000);
    }

    return (
        <RbDialog
            open={props.open}
            onClose={() => props.onClose()}
        >
            <DialogTitle>Create project</DialogTitle>
            <DialogContent>
                <RbSubSectionItem>
                    <FormControl fullWidth>
                        <RbTextField className="w-512"
                                     variant="outlined"
                                     label="Board full name"
                                     autoFocus={true}
                                     value={boardFullName}
                                     onChange={e => setBoardFullName(e.target.value)}
                                     color="primary"/>
                    </FormControl>
                </RbSubSectionItem>

                {/*<RbSubSection>*/}
                {/*    <FormControl fullWidth>*/}
                {/*        <RbTextField className="w-512"*/}
                {/*                     variant="outlined"*/}
                {/*                     label="Board short name"*/}
                {/*                     value={boardName}*/}
                {/*                     onChange={e => setBoardName(e.target.value)}*/}
                {/*                     color="primary"/>*/}
                {/*    </FormControl>*/}
                {/*</RbSubSection>*/}
            </DialogContent>
            <DialogActions className="mr-20 mb-20">
                <RbRoundButton className="mr-10" variant="text" onClick={closeAndReset}>Close</RbRoundButton>
                <RbRoundButton disabled={!props.projectId || !boardFullName}
                               variant="contained" onClick={_createNewBoard}>Create</RbRoundButton>
            </DialogActions>
        </RbDialog>
    )
}
