import WidgetBase, {WidgetWidth} from "./WidgetBase";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {IRbDisplayHeader, UserOrganization} from "../../../types/common-types";
import {useEffect, useState} from "react";
import {ILiveTracker, ITrackerActivity} from "../../../types/dashboard-types";
import {getOrganizationLiveTrackers} from "../../../services/dashboard/dashboardService";
import {formatTime} from "../../../utils/utils";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";
import {RbLink} from "../../../shared-components/rb-components/RbLink";
import * as React from "react";

function WidgetRunningTrackers(props: { organization: UserOrganization, id: number, defaultWidth: WidgetWidth }) {
    const [liveTrackers, setLiveTrackers] = useState<ILiveTracker[]>([]);

    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "Name",
            objAttribute: "user"
        },
        {
            name: "Project",
            objAttribute: "project"
        },
        {
            name: "Board",
            objAttribute: "board",
            handleRendering: renderBoardLink
        },
        {
            name: "Card",
            objAttribute: "card",
            handleRendering: renderCard
        },
        {
            name: "Category",
            objAttribute: "category",
            handleRendering: renderLabel
        },
        {
            name: "Start",
            objAttribute: "start",
            handleRendering: handleRenderStart
        },
    ];

    useEffect(() => {
        if (!props?.organization?.id) return;
        getOrganizationLiveTrackers(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setLiveTrackers(res.data.data);
        });
    }, []);

    function handleRenderStart(liveTracker: ILiveTracker) {
        return (
            <span>{formatTime(liveTracker.startDate)}</span>
        )
    }

    function renderCard(tracker: ITrackerActivity) {
        return tracker?.cardId ? <RbLink to={`/board/card/details/${tracker.boardId}/${tracker.cardId}`}>
            <span className="underline underline-offset-4">{tracker?.card}</span></RbLink> : "";
    }

    function renderLabel(tracker: ITrackerActivity) {
        return <span className="px-10 py-5 rounded-8"
                     style={{backgroundColor: tracker?.categoryColor ?? "transparent"}}>{tracker?.category}</span>
    }

    function renderBoardLink(tracker: ITrackerActivity) {
        return <RbLink to={`/board-view/${tracker?.boardId}`}>
            <span className="underline underline-offset-4">{tracker?.board}</span></RbLink>
    }

    return (
        <WidgetBase id={props.id} title="Running trackers" defaultWidth={props.defaultWidth}>
            <RbDataTable dataSource={liveTrackers} headerColumns={displayHeaders}/>
        </WidgetBase>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(WidgetRunningTrackers));
