import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface AppInfoSlice {
    title: string;
    icon?: string;
    message: string | JSX.Element;
    theme: "error" | "info" | "warn";
}

interface AppInfoValue {
    value: AppInfoSlice | null;
}

const initialState: AppInfoValue = {
    value: {
        title: "",
        message: "",
        icon: "ShieldExclamationIcon",
        theme: "warn",
    }
}

const appInfo = createSlice({
    name: "appInfo", initialState, reducers: {
        updateAppInfo(state, action: PayloadAction<AppInfoSlice | null>) {
            state.value = action?.payload ?? initialState.value;
        }
    }
});


export const {updateAppInfo} = appInfo.actions;
export default appInfo.reducer;
