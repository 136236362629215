import {IconButton} from "@mui/material";
import {ViewBoardsIcon, ViewGridIcon} from "@heroicons/react/outline";
import React from "react";

export type BoardGroup = "tile" | "bar";
export default function BoardGrouping(props: { type: BoardGroup, onChange: (type: BoardGroup) => void }) {
    return (
        <div className="flex ml-20">
            <IconButton aria-label="Tile view" className={props.type === "tile" ? "" : "opacity-50"} onClick={e => {
                e.stopPropagation();
                props.onChange("tile");
            }}>
                <ViewGridIcon className="w-20 color-inherit"/>
            </IconButton>
            <IconButton aria-label="Category view" className={props.type === "bar" ? "" : "opacity-50"} onClick={e => {
                e.stopPropagation();
                props.onChange("bar");
            }}>
                <ViewBoardsIcon style={{rotate: "90deg"}} className="w-20 color-inherit"/>
            </IconButton>
        </div>
    )
}
