import React, {useEffect} from "react";
import {IBoardCardDetails, IBoardMember} from "../../../types/board-types";
import RbIconTitle from "../../../shared-components/rb-components/RbIconTitle";
import {Avatar} from "@mui/material";
import {getUserInitials} from "../../../utils/RbUtils";
import {RbAvatarGroup} from "app/shared-components/rb-components/RbAvatarGroup";
import RbMultiSelect from "../../../shared-components/rb-components/RbMultiSelect";
import {assignUserToBoardCard, unassignUserToBoardCard} from "../../../services/boards/boardsService";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";

export default function BoardCardMembers(props: { card: IBoardCardDetails | undefined, members: IBoardMember[], refreshCard: () => void }) {
    const {card} = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    function addMemberToCard(member: IBoardMember) {
        if (!card?.id) return;
        assignUserToBoardCard(card?.id, member.id).then(res => {
            if (res?.data?.error) return;
            props.refreshCard();
        });
    }

    function removeMemberFromCard(member: IBoardMember) {
        if (!card?.id) return;
        unassignUserToBoardCard(card?.id, member.id).then(res => {
            if (res?.data?.error) return;
            props.refreshCard();
        });
    }

    return (
        <div className="cursor-pointer" onClick={e => setAnchorEl(e.currentTarget)}>
            <RbIconTitle icon="UserCircleIcon" title="Members" container="h4" className="mb-10"/>
            {
                (card?.members ?? []).length > 0 ?
                    <RbAvatarGroup max={7}>
                        {
                            (card?.members ?? []).map(user => {
                                return (
                                    <Avatar key={`${user.name}`} src={user.avatar}
                                            alt={user.name}>{getUserInitials(user.name)}</Avatar>
                                )
                            })
                        }
                    </RbAvatarGroup> :
                    <RbRoundButton color="secondary"
                                   variant="contained">Not set</RbRoundButton>
            }
            <RbMultiSelect onClose={() => {
                setAnchorEl(null);
            }} anchorEl={anchorEl} items={props.members}
                           renderItem={item => item.name} searchPlaceholder="Search for members"
                           checkedItems={card?.members}
                           onItemAdd={addMemberToCard}
                           onItemRemove={removeMemberFromCard}
            />
        </div>
    )
}
