import {styled} from "@mui/system";
import {Checkbox} from "@mui/material";

export const RbCheckBoxIcon = styled(Checkbox)(() => ({
    '&.MuiCheckbox-root': {
        padding: 0,
        transition: "opacity 150ms linear"
    },
    '&:not(.star).MuiCheckbox-root': {
        color: "#06B6D4",
    },
    "&:not(.Mui-checked)": {
        opacity: 0.3
    },
    "&:not(.Mui-checked):hover": {
        opacity: 0.5
    },
}))

export const RbCheckBox = styled(Checkbox)(() => ({
    '&.Mui-checked': {
        color: "#06B6D4",
    },
}))
