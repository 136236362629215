import {FormControl, MenuItem} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
    IOrganizationUser, IUserBoard, IUserProject,
    IUserRole,
    IUserWorkspace
} from "../../../types/organization-settings-types";
import {
    addOrganizationUserRole,
    getMemberTypes,
    getOrganizationUserProjects,
    getOrganizationUserRoles,
    getOrganizationUserWorkspaces,
    grantPermissionToUserForOrganization,
    removeOrganizationUserRole,
    updateWorkspaceMemberType,
    updateProjectMemberType,
    grantPermissionToUserForProject,
    removeUserFromWorkspace,
    removeUserFromProject,
    getOrganizationUsersPermission,
    getOrganizationUserBoards,
    updateBoardMemberType
} from "../../../services/organizationSettingsService";
import {RbTableSelect} from "app/shared-components/rb-components/RbDataTable/RbTableSelect";
import {RbSection, RbSubSection, RbSubSectionItem} from "app/shared-components/rb-components/RbSections";
import {RbCheckBoxIcon} from "../../../shared-components/rb-components/RbCheckBoxIcon";
import {CheckCircle, CheckCircleOutline} from "@mui/icons-material";
import {IMemberType, IRbDisplayHeader, UserOrganization} from "../../../types/common-types";
import RbTableActionButton from "../../../shared-components/rb-components/RbDataTable/RbTableActionButton";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";
import {addMemberToBoard, removeMemberFromBoard} from "../../../services/boards/boardsService";
import RbConfirmationDialog from "../../../shared-components/dialogs/RbConfirmationDialog";
import RbSelectDialog from "../../../shared-components/rb-components/RbSelectDialog";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";
import {useLocation} from "react-router-dom";

function UserPermissionsOrganizationSettings(props: { organization: UserOrganization }) {
    const userId = new URLSearchParams(useLocation().search).get('userId');

    const [users, setUsers] = useState<IOrganizationUser[]>([]);
    const [user, setUser] = useState<IOrganizationUser>({id: "", name: ""} as any);
    const [userRoles, setUserRoles] = useState<IUserRole[]>([]);
    const [memberTypes, setMemberTypes] = useState<IMemberType[]>([]);
    const [boards, setBoards] = useState<IUserBoard[]>([]);
    const [workspaces, setWorkspaces] = useState<IUserWorkspace[]>([]);
    const [projects, setProjects] = useState<IUserProject[]>([]);
    const [boardId, setBoardId] = useState<string>();
    const [projectId, setProjectId] = useState<string>();
    const [workspaceId, setWorkspaceId] = useState<string>();
    const [workspaceOpen, setWorkspaceOpen] = useState<boolean>(false);
    const [boardOpen, setBoardOpen] = useState<boolean>(false);
    const [projectOpen, setProjectOpen] = useState<boolean>(false);

    const displayUserWorkspaceHeaders: IRbDisplayHeader[] = [
        {
            name: "Workspace name",
            objAttribute: "workspaceName"
        },
        {
            name: "Type",
            objAttribute: "memberTypeId",
            handleRendering: renderMemberDropDown
        },
        {
            name: "Added",
            objAttribute: "dateInsert"
        },
        {
            name: "",
            objAttribute: "",
            handleRendering: renderTableActions,
            className: "text-right"
        }
    ];


    const displayUserProjectHeaders: IRbDisplayHeader[] = [
        {
            name: "Project name",
            objAttribute: "projectName"
        },
        {
            name: "Workspace name",
            objAttribute: "workspaceName",
        },
        {
            name: "Type",
            objAttribute: "memberTypeId",
            handleRendering: renderProjectMemberDropDown
        },
        {
            name: "Added",
            objAttribute: "dateInsert"
        },
        {
            name: "",
            objAttribute: "",
            handleRendering: renderProjectTableActions,
            className: "text-right"
        }
    ]

    const displayUserBoardsHeaders: IRbDisplayHeader[] = [
        {
            name: "Board name",
            objAttribute: "boardName"
        },
        {
            name: "Project name",
            objAttribute: "projectName"
        },
        {
            name: "Type",
            objAttribute: "memberTypeId",
            handleRendering: renderBoardMemberDropDown
        },
        {
            name: "Added",
            objAttribute: "dateInsert"
        },
        {
            name: "",
            objAttribute: "",
            handleRendering: renderBoardTableActions,
            className: "text-right"
        }
    ]

    useEffect(() => {
        _getOrganizationUsers();
        _getMemberTypes();
    }, [props?.organization?.id])

    useEffect(() => {
        if (!user?.id || user?.id === "") return;

        _getOrganizationUserWorkspaces();
        _getOrganizationUserProjects()
        _getOrganizationUserBoards()
        _getOrganizationUserRoles();
    }, [user]);


    function renderTableActions(data: IUserWorkspace) {
        return data.memberTypeId ? <RbTableActionButton icon="TrashIcon"
                                                        onClick={() => setWorkspaceId(data.workspaceId)}/> : <></>;
    }

    function renderProjectTableActions(data: IUserProject) {
        return data.memberTypeId ? <RbTableActionButton icon="TrashIcon"
                                                        onClick={() => setProjectId(data.projectId)}/> : <></>;
    }

    function renderBoardTableActions(data: any) {
        return data.memberTypeId ? <RbTableActionButton icon="TrashIcon"
                                                        onClick={() => setBoardId(data.boardId)}/> : <></>;
    }

    function handleAdduserToWorkspace(workspaceId: string) {
        if (!workspaceId || !user?.id) return;
        grantPermissionToUserForOrganization(workspaceId, user.id).then(res => {
            if (res?.data?.error) return;
            _getOrganizationUserWorkspaces();
        });
    }

    function handleAdduserToProject(projectId: string) {
        if (!projectId || !user?.id) return;
        grantPermissionToUserForProject(projectId, user.id).then(res => {
            if (res?.data?.error) return;
            _getOrganizationUserProjects();
        });
    }

    function handleAdduserToBoard(boardId: string) {
        if (!boardId || !user?.id) return;
        addMemberToBoard(boardId, user.id).then(res => {
            if (res?.data?.error) return;
            _getOrganizationUserBoards();
        });
    }

    function renderMemberDropDown(data: IUserWorkspace) {
        return (
            <FormControl key={data.workspaceId} size="small">
                <RbTableSelect
                    value={data.memberTypeId}
                    placeholder="Member type"
                    variant="filled"
                    disableUnderline={true}
                    onChange={e => handleChangeUserMemberType(data.workspaceId, e.target.value as string)}
                >
                    {
                        memberTypes.map((type, i) => {
                            return (
                                <MenuItem key={i} value={type?.id}>{type?.name}</MenuItem>
                            )
                        })
                    }
                </RbTableSelect>
            </FormControl>
        );
    }

    function renderProjectMemberDropDown(data: IUserProject) {
        return (
            <FormControl key={data.projectId} size="small">
                <RbTableSelect
                    value={data.memberTypeId}
                    placeholder="Member type"
                    variant="filled"
                    disableUnderline={true}
                    onChange={e => handleChangeUserMemberTypeForProject(data.projectId, e.target.value as string)}
                >
                    {
                        memberTypes.map((type, i) => {
                            return (
                                <MenuItem key={i} value={type?.id}>{type?.name}</MenuItem>
                            )
                        })
                    }
                </RbTableSelect>
            </FormControl>
        );
    }

    function renderBoardMemberDropDown(data: any) {
        return (
            <FormControl key={data?.boardId} size="small">
                <RbTableSelect
                    value={data.memberTypeId}
                    placeholder="Member type"
                    variant="filled"
                    disableUnderline={true}
                    onChange={e => handleChangeUserMemberTypeForBoard(data.projectId, e.target.value as string)}
                >
                    {
                        memberTypes.map((type, i) => {
                            return (
                                <MenuItem key={i} value={type?.id}>{type?.name}</MenuItem>
                            )
                        })
                    }
                </RbTableSelect>
            </FormControl>
        );
    }

    function _getMemberTypes() {
        getMemberTypes().then(res => {
            if (!res?.data?.error) {
                setMemberTypes(res.data.data);
            }
        })
    }

    function handleChangeUserMemberType(workspaceId: string, typeId: string) {
        if (!workspaceId || !user?.id) return;
        updateWorkspaceMemberType(workspaceId, user.id, typeId).then(res => {
            if (!res?.data?.error) {
                _getOrganizationUserWorkspaces();
            }
        });
    }

    function handleChangeUserMemberTypeForProject(projectId: string, typeId: string) {
        if (!projectId || !user?.id) return;
        updateProjectMemberType(projectId, user.id, typeId).then(res => {
            if (!res?.data?.error) {
                _getOrganizationUserProjects();
            }
        });
    }

    function handleChangeUserMemberTypeForBoard(boardId: string, typeId: string) {
        if (!boardId || !user?.id) return;
        updateBoardMemberType(boardId, user.id, typeId).then(res => {
            if (!res?.data?.error) {
                _getOrganizationUserBoards();
            }
        });
    }

    function _getOrganizationUserWorkspaces() {
        if (!props?.organization?.id) return;
        getOrganizationUserWorkspaces(props.organization.id, user.id).then(res => {
            if (!res?.data?.error) {
                setWorkspaces(res.data.data);
            }
        });
    }

    function _getOrganizationUserProjects() {
        if (!props?.organization?.id) return;
        getOrganizationUserProjects(props.organization.id, user.id).then(res => {
            if (!res?.data?.error) {
                setProjects(res.data.data);
            }
        });
    }

    function _getOrganizationUserBoards() {
        if (!props?.organization?.id) return;
        getOrganizationUserBoards(props.organization.id, user.id).then(res => {
            if (res?.data?.error) return;
            setBoards(res.data.data);
        })
    }

    function _getOrganizationUserRoles() {
        if (!props?.organization?.id) return;
        getOrganizationUserRoles(props.organization.id, user.id).then(res => {
            if (res?.data?.error) return;
            setUserRoles(res.data.data);
        })
    }

    function _getOrganizationUsers() {
        if (!props?.organization?.id) return;
        getOrganizationUsersPermission(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setUsers(res.data.data);
            if (userId) {
                // @ts-ignore
                setUser((res.data.data as IOrganizationUser[]).find(u => u.id === userId) ?? {id: "", name: ""});
            } else if (res.data.data.length > 0) {
                setUser(res.data.data[0]);
            }
        });
    }

    function handleUserRoleChange(role: IUserRole) {
        if (!props?.organization?.id) return;
        (role.hasRole ? removeOrganizationUserRole(props.organization.id, user.id, role.roleId) :
            addOrganizationUserRole(props.organization.id, user.id, role.roleId)).then(res => {
            if (!res?.data?.error) {
                _getOrganizationUserRoles();
            }
        });
    }

    function confirmDeleteItem() {
        if (projectId) {
            removeUserFromProject(projectId, user.id).then(res => {
                if (res?.data?.error) return;
                setProjectId(undefined);
                _getOrganizationUserProjects();
            })
            return;
        }

        if (boardId) {
            removeMemberFromBoard(boardId, user.id).then(res => {
                if (res?.data?.error) return;
                setBoardId(undefined);
                _getOrganizationUserBoards();
            })
            return;
        }

        if (workspaceId) {
            removeUserFromWorkspace(workspaceId, user.id).then(res => {
                if (res?.data?.error) return;
                setWorkspaceId(undefined);
                _getOrganizationUserWorkspaces();
            })
            return;
        }
    }

    return (
        <div className="mt-48 md:ml-28 sm:ml-28 sm:mr-28 ml-64 mr-64 mb-48">
            <div>
                <RbTableSelect
                    className="w-256"
                    placeholder="User"
                    disableUnderline
                    variant="standard"
                    value={user.id}
                >
                    <MenuItem value={''} onClick={() => setUser({id: ""} as any)}
                              key={`user-none`}><b>User</b></MenuItem>
                    {users.map((user: IOrganizationUser) => (
                        <MenuItem value={user.id} onClick={() => setUser(user)}
                                  key={`${user?.id}`}>{user?.name ?? ""}</MenuItem>
                    ))}
                </RbTableSelect>
                <RbConfirmationDialog isDelete={true} onClose={() => {
                    setBoardId(undefined);
                    setWorkspaceId(undefined);
                    setProjectId(undefined);
                }} onConfirm={confirmDeleteItem} open={!!projectId || !!workspaceId || !!boardId} title="Remove user">
                    <p>Are you sure?</p>
                </RbConfirmationDialog>
                <div>
                    <RbSubSection className="w-3/12 inline-block align-top">
                        {
                            userRoles.map((role, i) => {
                                return (
                                    <div className="mt-10" key={i}>
                                        <RbCheckBoxIcon
                                            onChange={() => handleUserRoleChange(role)}
                                            checked={role.hasRole}
                                            icon={<CheckCircleOutline/>}
                                            checkedIcon={<CheckCircle/>}/>
                                        <span className="ml-10">{role.name}</span>
                                    </div>
                                )
                            })
                        }
                    </RbSubSection>
                    <RbSubSection className="inline-block align-top sm:w-full md:w-full lg:w-full w-8/12 xl:ml-32">
                        <div className="flex items-center justify-between">
                            <h2>Workspace membership</h2>
                            <RbRoundButton color="primary" variant="contained" onClick={() => setWorkspaceOpen(true)}>
                                Add to workspace
                            </RbRoundButton>
                        </div>
                        <RbSubSectionItem>
                            <RbDataTable paginator={true} search={true} dataSource={workspaces.filter(s => s.isMember)}
                                         headerColumns={displayUserWorkspaceHeaders}/>
                            <RbSelectDialog open={workspaceOpen} renderAttribute="workspaceName" onClose={() => {
                                setWorkspaceOpen(false);
                                // _getOrganizationUserWorkspaces();
                            }}
                                            title="Add to workspace" data={workspaces.filter(s => !s.isMember)}
                                            onSelect={item => handleAdduserToWorkspace(item.workspaceId)}/>
                        </RbSubSectionItem>
                        <RbSection>
                            <div className="flex items-center justify-between">
                                <h2>Project membership</h2>
                                <RbRoundButton color="primary" variant="contained" onClick={() => setProjectOpen(true)}>
                                    Add to project
                                </RbRoundButton>
                            </div>
                            <RbSubSectionItem>
                                <RbDataTable paginator={true} search={true}
                                             dataSource={projects.filter(s => s.isMember)}
                                             headerColumns={displayUserProjectHeaders}/>
                                <RbSelectDialog open={projectOpen} renderAttribute="projectName" onClose={() => {
                                    setProjectOpen(false);
                                    // _getOrganizationUserProjects();
                                }}
                                                title="Add to project" data={projects.filter(s => !s.isMember)}
                                                onSelect={item => handleAdduserToProject(item.projectId)}/>
                            </RbSubSectionItem>
                        </RbSection>

                        <RbSection>
                            <div className="flex items-center justify-between">
                                <h2>Board membership</h2>
                                <RbRoundButton color="primary" variant="contained" onClick={() => setBoardOpen(true)}>
                                    Add to board
                                </RbRoundButton>
                            </div>
                            <RbSubSectionItem>
                                <RbDataTable paginator={true} search={true} dataSource={boards.filter(s => s.isMember)}
                                             headerColumns={displayUserBoardsHeaders}/>
                                <RbSelectDialog open={boardOpen} renderAttribute="boardName" onClose={() => {
                                    setBoardOpen(false);
                                    // _getOrganizationUserBoards();
                                }}
                                                title="Add to board" data={boards.filter(s => !s.isMember)}
                                                onSelect={item => handleAdduserToBoard(item.boardId)}/>
                            </RbSubSectionItem>
                        </RbSection>
                    </RbSubSection>
                </div>
            </div>
        </div>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(UserPermissionsOrganizationSettings));
