import i18next from '../../i18n';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [{
    id: 'applications', title: 'Applications', translate: 'APPLICATIONS', type: 'group', icon: 'apps', children: [{
        id: 'dashboard-component',
        title: 'Dashboard',
        translate: 'Dashboard',
        type: 'item',
        icon: 'CollectionIcon',
        url: 'dashboard',
    }, {
        id: 'boards-component',
        title: 'Boards',
        translate: 'Boards',
        type: 'item',
        icon: 'ViewBoardsIcon',
        url: 'boards',
        roles: ["brdto"]
    }, {
        id: 'tracker-component',
        title: 'Tracker',
        translate: 'Tracker',
        type: 'item',
        icon: 'ClockIcon',
        url: 'tracker',
        roles: ["trckr"]
    }, {
        id: 'project-overview-component',
        title: 'Project Overview',
        translate: 'ProjectOverview',
        type: 'item',
        icon: 'ChartPieIcon',
        url: 'project-overview',
        roles: ["prjct"]
    }, {
        id: 'reports-component',
        title: 'Reports',
        translate: 'Reports',
        type: 'item',
        icon: 'ChartSquareBarIcon',
        url: 'reports',
        roles: ["rprts"]
    }, {
        id: 'personal-to-do-component',
        title: 'Personal To Do',
        translate: 'PersonalToDo',
        type: 'item',
        icon: 'ClipboardListIcon',
        url: 'todo',
        roles: ["prnal"]
    }, {
        id: 'tickets-component',
        title: 'tickets',
        translate: 'Tickets',
        type: 'item',
        icon: 'TicketIcon',
        url: 'ticket-systems',
        roles: ["tckts"]
    }, {
        id: 'organization-settings-component',
        title: 'Organization Settings',
        translate: 'OrganizationSettings',
        type: 'item',
        icon: 'UserGroupIcon',
        url: 'organization-settings/general',
        roles: ["settings"]
    }],
}, {
    id: 'custom-function',
    title: 'Custom Function',
    translate: 'CUSTOM_FUNCTION',
    type: 'group',
    icon: 'apps',
    children: [{
        id: 'themes', title: 'Theme Color Schemes', translate: 'ThemeColorSchemes', type: 'button', icon: 'CogIcon',
    }]
}];

export default navigationConfig;
