import * as React from 'react';
import Popover from '@mui/material/Popover';
import {QuestionMarkCircleIcon} from "@heroicons/react/solid";
import {useTheme} from "@mui/material/styles";
import HelpMenuDialog from "./HelpMenuDialog";

const HelpMenu = (props: any) => {
    const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
    const theme = useTheme();

    const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'help-menu-popover' : undefined;

    return (
        <span className={props.className}>
            <QuestionMarkCircleIcon onClick={handleClick} className="w-[25px] cursor-pointer"
                                    style={{color: theme.palette.text.secondary}}/>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <HelpMenuDialog/>
            </Popover>
        </span>
    );
}

export default HelpMenu;
