import {useEffect, useState} from "react";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {useTheme} from "@mui/material/styles";
import {getAllUserOrganizationsInfo, leaveUserOrganization} from "../../../services/user-settings/userSettingsService";
import {IUserOrganizationInfo} from "../../../types/user-settings-types";
import {formatToEUDate} from "../../../utils/utils";
import {RbTheme} from "../../../types/common-types";

function UserOrganizationsUserSettings() {
    const [organizations, setUserOrganizations] = useState<IUserOrganizationInfo[]>([]);
    const theme: RbTheme = useTheme();

    useEffect(() => {
        _getAllUserOrganizations();
    }, []);


    function _getAllUserOrganizations() {
        getAllUserOrganizationsInfo().then(res => {
            if (res?.data?.data) {
                setUserOrganizations(res.data.data);
            }
        });
    }

    function leaveOrganization(orgId: string) {
        leaveUserOrganization(orgId).then(res => {
            // TODO: toast message
        });
    }

    return (
        <div className="mt-48 md:ml-28 sm:ml-28 ml-64 mb-48">
            <h2 className="font-bold mb-32">User organizations</h2>
            <table className="text-left max-w-[1024px] w-full">
                <thead>
                <tr className="border-b-solid border-b-1">
                    <th className="w-1/5">Organization</th>
                    <th className="w-1/5">Invited by</th>
                    <th className="w-1/5">Role</th>
                    <th className="w-1/5">Member since</th>
                    <th className="w-1/5">Action</th>
                </tr>
                </thead>
                <tbody>
                {
                    organizations.map(org => {
                        return <tr key={org.organizationId}>
                            <td className="pt-5 pb-5">{org.organizationName}</td>
                            <td className="pt-5 pb-5">{org.userInsert}</td>
                            <td className="pt-5 pb-5">{org.role}</td>
                            <td className="pt-5 pb-5">{formatToEUDate(org.dateInsert)}</td>
                            <td className="pt-5 pb-5">
                                <RbRoundButton color="warning" variant="contained"
                                               style={{color: theme.palette.text.primary}}
                                               onClick={() => leaveOrganization(org.organizationId)}>Leave</RbRoundButton>
                            </td>
                        </tr>
                    })
                }
                </tbody>
            </table>
        </div>
    );
}

export default UserOrganizationsUserSettings;
