import React from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {CurrencyDollarIcon} from "@heroicons/react/outline";
import {getTimeFromDate} from "../../../../../utils/utils";
import clsx from "clsx";
import FormControl from "@mui/material/FormControl";
import {editBillable} from "../../../../../services/tracker/trackerService";

const TrackerRowTablet = (props: any) => {
    const {tracker, tableCellTextColor, tableRowBorderBottom} = props;
    const _editBillable = (billable: boolean) => {
        console.log(billable, "Billable")
        editBillable(tracker.id, billable).then(res => {
            // refreshTracker.next();
        });
    }
    return (
        <>
            <TableRow
                sx={{'& > *': {borderBottom: 'unset text-sm'}}}
                className={'hidden md:table-row  text-sm'}>
                <TableCell
                    colSpan={2}
                    style={{color: tableCellTextColor}}
                    className={'hidden md:table-cell font-bold border-none'}>
                    Category
                </TableCell>
                <TableCell
                    style={{color: tableCellTextColor}}
                    className={'hidden md:table-cell  font-bold border-none'}>
                    Billable
                </TableCell>
                <TableCell
                    style={{color: tableCellTextColor}}
                    className={'hidden md:table-cell  font-bold border-none'}>
                    Start time
                </TableCell>
                <TableCell
                    style={{color: tableCellTextColor}}
                    className={'hidden md:table-cell  font-bold border-none'}>
                    End time
                </TableCell>
                <TableCell
                    style={{color: tableCellTextColor}}
                    colSpan={2}
                    className={'hidden md:table-cell  font-bold border-none'}>
                    Duration
                </TableCell>
            </TableRow>

            <TableRow
                sx={{'& > *': {borderBottom: 'unset text-sm'}}}
                className={'hidden md:table-row text-sm'}
            >
                <TableCell colSpan={2} className={'hidden md:table-cell text-sm'}>{tracker?.labelName}</TableCell>
                <TableCell align={"center"} className={'hidden md:table-cell text-sm'}>
                    <FormControl>
                        <CurrencyDollarIcon height={24} onClick={() => {
                            tracker.billable = !tracker.billable;
                            _editBillable(tracker.billable)
                        }} className={clsx("cursor-pointer", tracker?.billable ? "opacity-100" : "opacity-30")}/>
                    </FormControl>
                </TableCell>
                <TableCell
                    className={'hidden md:table-cell text-sm'}>{getTimeFromDate(tracker?.startDate)}</TableCell>
                <TableCell
                    className={'hidden md:table-cell text-sm'}>{getTimeFromDate(tracker?.endDate)}</TableCell>
                <TableCell colSpan={2} className={'hidden md:table-cell text-sm'}>{tracker?.duration}</TableCell>
            </TableRow>
        </>
    );
};

export default TrackerRowTablet;
