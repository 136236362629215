import {ITodo} from "../../../../types/todo-types";
import {ClockIcon} from "@heroicons/react/outline";
import {RbRoundButton} from "../../../../shared-components/rb-components/RbRoundButton";
import React from "react";

export default function TodoReminder(props: { todo: ITodo | undefined }) {
    const {todo} = props;
    return (
        <>
            <h4 className="mb-10"><ClockIcon className="w-20 mr-10 inline-block align-middle"/><span
                className="inline-block align-middle">Reminder</span></h4>
            <RbRoundButton color="secondary"
                           variant="contained">Not set</RbRoundButton>
        </>
    )
}
