import {Drawer, IconButton} from "@mui/material";
import {ColorSwatchIcon, PlusIcon, TrashIcon, XIcon} from "@heroicons/react/outline";
import React, {useState} from "react";
import {IBoard, IBoardLabel} from "../../../types/board-types";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";
import RbColorPickerMenu from "../../../shared-components/rb-components/RbColorMenu/RbColorPickerMenu";
import RbContentEditable from "../../../shared-components/rb-components/RbContentEditable";
import {createBoardLabel, deleteBoardLabel, updateBoardLabel} from "../../../services/boards/boardsService";
import RbConfirmationDialog from "../../../shared-components/dialogs/RbConfirmationDialog";

export default function BoardSettingsLabels(props: { board: IBoard, open: boolean, onClose: () => void, refreshBoard: () => void }) {
    const theme: RbTheme = useTheme();
    const [name, setName] = useState<string>("");
    const [color, setColor] = useState<string>(theme.palette.background.paper);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [labelEdit, setLabelEdit] = useState<IBoardLabel | null>(null);
    const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

    function _createBoardLabel() {
        createBoardLabel(props.board.id, name, color).then(res => {
            if (res?.data?.error) return;
            setColor(theme.palette.background.paper);
            setName("");
            props.refreshBoard();
        });
    }

    function _updateBoardLabel(lbl: IBoardLabel) {
        updateBoardLabel(lbl.id, lbl.name, lbl.color).then(res => {
            if (res?.data?.error) return;
            setLabelEdit(null);
            props.refreshBoard();
        });
    }

    function handleDeleteLabel() {
        if (!labelEdit?.id) return;
        deleteBoardLabel(labelEdit?.id).then(res => {
            if (res?.data?.error) return;
            setLabelEdit(null);
            props.refreshBoard();
        });
    }

    return (
        <Drawer
            anchor="right"
            open={props.open}
            onClose={props.onClose}
            hideBackdrop={true}>
            <div className="p-20">
                <div className="flex justify-between items-center">
                    <h2>Board labels</h2>
                    <IconButton onClick={props.onClose}>
                        <XIcon className="w-20"/>
                    </IconButton>
                </div>
                <hr className="mt-10 mb-20"/>

                <RbBox className="flex items-center">
                    <RbBox style={{backgroundColor: color}} className="p-5 cursor-pointer transition-colors"
                           onClick={e => setAnchorEl(e.currentTarget)}>
                        <ColorSwatchIcon className="w-20"/>
                    </RbBox>
                    <RbTextField
                        className="mx-10"
                        autoFocus={true}
                        fullWidth
                        placeholder="Label name"
                        variant="standard"
                        value={name}
                        onChange={e => setName(e.target.value)}
                        color="primary"/>
                    <RbRoundButton disabled={!color || !name} className="flex" variant="contained"
                                   onClick={_createBoardLabel}>
                        <PlusIcon className="mr-5 w-40"/>
                        <span>Add</span>
                    </RbRoundButton>
                </RbBox>

                <div className="mt-16">
                    {
                        (props?.board?.labels ?? []).map(label => {
                            return (
                                <div className="flex items-center my-5 py-5" onClick={() => setLabelEdit(label)}>
                                    <RbBox style={{backgroundColor: label.color}}
                                           className="p-5 mr-10 cursor-pointer transition-colors"
                                           onClick={e => setAnchorEl(e.currentTarget)}>
                                        <ColorSwatchIcon className="w-20"/>
                                    </RbBox>
                                    <RbContentEditable childClassName="w-full cursor-pointer" defaultValue={label.name}
                                                       onConfirm={name => {
                                                           if (labelEdit) {
                                                               _updateBoardLabel({...labelEdit, name: name});
                                                           }
                                                       }}>
                                        <span>{label.name}</span>
                                    </RbContentEditable>
                                    <IconButton color="warning" className="p-5" onClick={() => setDeleteOpen(true)}>
                                        <TrashIcon className="w-20"/>
                                    </IconButton>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <RbColorPickerMenu anchorEl={anchorEl} handleMenuClose={() => setAnchorEl(null)}
                               onSelect={color => {
                                   setAnchorEl(null);
                                   if (labelEdit) {
                                       _updateBoardLabel({...labelEdit, color: color})
                                   } else {
                                       setColor(color);
                                   }
                               }}/>
            <RbConfirmationDialog isDelete={true} title="Delete label" open={deleteOpen}
                                  onClose={() => setDeleteOpen(false)}
                                  onConfirm={handleDeleteLabel}>
                <span>Are you sure you want to delete <span className="font-bold">{labelEdit?.name}</span> label?</span>
            </RbConfirmationDialog>
        </Drawer>
    )
}
