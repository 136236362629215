import * as React from 'react';
import Popover from '@mui/material/Popover';
import {useTheme} from "@mui/material/styles";
import {forwardRef, memo, useEffect, useImperativeHandle} from "react";
import {CheckCircleIcon, MinusCircleIcon, MoonIcon, XCircleIcon} from "@heroicons/react/solid";
import {CogIcon} from "@heroicons/react/outline";
import {useNavigate} from "react-router-dom";
import {IUserStatus} from "../../types/user-settings-types";
import {getAllUserStatuses, updateUserStatus} from "../../services/sidebar/sidebarService";
import {RbTheme, UserOrganization} from "../../types/common-types";
import {connect, useDispatch} from "react-redux";
import withRouter from "../../../@fuse/core/withRouter";
import {updateSubNavigation} from "../../store/rubicon/subNavigationSlice";
import {userSettingsSubNavigation} from "../../main/user-settings/UserSettingsConfig";

const UserStatusMenu = (props: { organization: UserOrganization, anchorEl: HTMLElement | null, onClose: () => void, setStatusColor: (string: string) => void }) => {
    const [statuses, setStatuses] = React.useState<IUserStatus[]>([]);
    const theme: RbTheme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!props.organization?.id) return;
        getAllUserStatuses().then(res => {
            if (res.data.data) {
                setStatuses(res.data.data);
            }
        })
    }, [props.organization?.id]);

    function handleMenuItemClick(status: IUserStatus | { name: "settings", id: number }) {
        if (status?.id >= 0) {
            updateUserStatus(status.id).then(res => {
                if (!res?.data?.error) {
                    props.setStatusColor(getStatusColor(status as IUserStatus));
                }
            });
        } else {
            dispatch(updateSubNavigation(userSettingsSubNavigation));
            navigate("user-settings/account");
        }
        props.onClose();
    }

    function getStatusColor(status: IUserStatus) {
        switch (status?.name ?? "") {
            case "Online":
                return theme.priority.low;
                break;
            case "Away":
                return theme.priority.medium;
                break;
            case "Do not disturb":
                return theme.priority.high;
                break;
            default:
                return "#71717a";
                break;
        }
    }

    function renderStatusMenuItem(status: IUserStatus) {
        switch (status?.name ?? "??") {
            case "Online":
                return <>
                    <CheckCircleIcon
                        className="mr-10 w-20 h-20 transition-opacity inline-block rounded-full inline-block align-middle"
                        style={{color: theme.palette.success.main}}/>
                    <span className="inline-block align-middle">Online</span>
                </>
                break;
            case "Away":
                return <>
                    <MoonIcon
                        className="mr-10 w-20 h-20 transition-opacity inline-block rounded-full inline-block align-middle"
                        // @ts-ignore
                        style={{color: theme.priority.medium}}/>
                    <span className="inline-block align-middle">Away</span>
                </>
                break;
            case "Do not disturb":
                return <>
                    <MinusCircleIcon
                        className="w-20 h-20 transition-opacity mr-10 inline-block rounded-full inline-block align-middle"
                        // @ts-ignore
                        style={{color: theme.priority.high}}/>
                    <span className="inline-block align-middle">Do not disturb</span>
                </>
                break;
            default:
                return <>
                    <XCircleIcon
                        className="w-20 h-20 transition-opacity mr-10 inline-block rounded-full inline-block align-middle"
                        style={{color: "#71717a"}}/>
                    <span className="inline-block align-middle">{status?.name ?? "??"}</span>
                </>
                break;
        }
    }


    return (
        <Popover
            id="user-menu-popover"
            open={!!props.anchorEl}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div className="p-5" style={{backgroundColor: theme.palette.background.paper}}>
                {
                    statuses.map(stat => {
                        return <div
                            key={stat.name}
                            onClick={() => handleMenuItemClick(stat)}
                            className="p-7 transition-colors transition-colors hover:bg-black/[.1] rounded-[0.5rem] cursor-pointer">
                            {renderStatusMenuItem(stat)}
                        </div>
                    })
                }
                <hr/>
                <div
                    onClick={() => handleMenuItemClick({name: "settings", id: -1})}
                    className="p-7 transition-colors transition-colors hover:bg-black/[.1] rounded-[0.5rem] cursor-pointer">
                    <CogIcon
                        className="w-20 h-20 transition-opacity mr-10 inline-block rounded-full inline-block align-middle"
                    />
                    <span className="inline-block align-middle">User settings</span>
                </div>
            </div>
        </Popover>
    );
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(UserStatusMenu));
