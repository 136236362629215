const settingsConfig = {
  layout: {
    style: 'layout1', // layout1 layout2 layout3
    config: {
      navbar: {
        style: "style-2",
        folded: false
      }
    }, // checkout default layout configs at app/fuse-layouts for dashboard  app/fuse-layouts/layout1/Layout1Config.js
  },
  customScrollbars: true,
  direction: 'ltr', // rtl, ltr
  theme: {
    main: 'default',
    navbar: 'greyDark',
    toolbar: 'mainThemeLight',
    footer: 'mainThemeDark',
  },
  loginRedirectUrl: '/', // Default redirect url for the logged-in user
};

export default settingsConfig;
