import {Accordion, AccordionDetails, AccordionSummary, Drawer, IconButton} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
    addTicketSystemCategory,
    addTicketSystemSubCategory, archiveTicketSystemCategory,
    archiveTicketSystemSubCategory,
    getTicketSystemCategories,
    getTicketSystemSubcategories
} from "../../../services/ticketsService";
import {ISystemTickets, ITicketSystemCategory} from "../../../types/tickets-types";
import {ChevronDownIcon, DotsVerticalIcon, PlusCircleIcon, XIcon} from "@heroicons/react/outline";
import RbContentEditable from "../../../shared-components/rb-components/RbContentEditable";
import HeroIcon from "../../../../@fuse/core/HeroIcon/HeroIcon";
import Popover from "@mui/material/Popover";
import {styled} from "@mui/system";
import MenuItem from "@mui/material/MenuItem";

export const RbAccordion = styled(Accordion)(() => ({
    '& .Mui-expanded': {
        'span > svg': {
            transform: "rotate(180deg)"
        }
    },
}));

export default function TicketSystemCategories(props: { ticketSystem: ISystemTickets | undefined, open: boolean, onClose: () => void }) {
    const [ticketSubcategories, setTicketSubcategories] = useState<Map<string, ITicketSystemCategory[]>>(new Map());
    const [selectedCategory, setSelectedCategory] = useState<ITicketSystemCategory | null>(null);
    const [ticketCategories, setTicketCategories] = useState<ITicketSystemCategory[]>([]);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    useEffect(() => {
        _getTicketSystemCategories();
    }, [props.ticketSystem?.id]);

    function _getTicketSystemCategories() {
        if (!props?.ticketSystem?.id) return;
        getTicketSystemCategories(props.ticketSystem.id).then(res => {
            if (res?.data?.error) return;
            setTicketCategories(res.data.data);
        });
    }

    function _getTicketSystemSubcategories(category: ITicketSystemCategory) {
        if (!category?.id) return;
        getTicketSystemSubcategories(category.id).then(res => {
            if (res?.data?.error) return;
            setTicketSubcategories(new Map(ticketSubcategories.set(category.id, res.data.data)));
            setSelectedCategory(category);
        });
    }

    function _addTicketSystemSubCategory(cat: ITicketSystemCategory, name: string) {
        if (!cat?.id) return;
        addTicketSystemSubCategory(cat.id, name).then(res => {
            if (res?.data?.error) return;
            _getTicketSystemSubcategories(cat);
        });
    }

    function _addTicketSystemCategory(name: string) {
        if (!props?.ticketSystem?.id || !name) return;
        addTicketSystemCategory(props.ticketSystem.id, name).then(res => {
            if (res?.data?.error) return;
            _getTicketSystemCategories();
        });
    }

    function handleCategoryMenu(e: any, cat: ITicketSystemCategory) {
        e.preventDefault();
        e.stopPropagation();
        setSelectedCategory(cat);
        setAnchorEl(e.currentTarget);
    }

    function _archiveTicketSystemCategory() {
        setAnchorEl(null);
        if (!selectedCategory) return;
        archiveTicketSystemCategory(selectedCategory.id, !selectedCategory.active).then(res => {
            if (res?.data?.error) return;
            _getTicketSystemCategories();
        });
    }

    function _archiveTicketSystemSubCategory(cat: ITicketSystemCategory, sub: ITicketSystemCategory) {
        archiveTicketSystemSubCategory(sub.id, !sub.active).then(res => {
            if (res?.data?.error) return;
            _getTicketSystemSubcategories(cat);
        });
    }

    function getSubcategories(id: string): ITicketSystemCategory[] {
        return ticketSubcategories.get(id) ?? [];
    }

    return (
        <Drawer
            anchor="right"
            open={props?.open ?? false}
            onClose={props?.onClose}
            hideBackdrop={true}>
            <div className="p-20 w-400">
                <div className="flex justify-between items-center">
                    <h2>Categories</h2>
                    <IconButton onClick={props.onClose}>
                        <XIcon className="w-20"/>
                    </IconButton>
                </div>
                <hr/>

                {
                    ticketCategories.map((cat, i) => {
                        return (
                            <RbAccordion key={i} disableGutters={true} defaultExpanded={false}
                                         className={`shadow-none ${cat.active ? "opacity-100" : "opacity-50"}`}
                                         onClick={() => {
                                             if (!ticketSubcategories.has(cat.id)) {
                                                 _getTicketSystemSubcategories(cat);
                                             }
                                         }}>
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <div className="flex justify-between items-center w-full">
                                        <span className="flex justify-between items-center">
                                            <ChevronDownIcon className="w-16 mr-5 transition-transform"/>
                                            <span>{cat?.name ?? "Name"}</span>
                                        </span>
                                        <IconButton onClick={e => handleCategoryMenu(e, cat)} className="p-5">
                                            <DotsVerticalIcon className="w-16"/>
                                        </IconButton>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className="ml-10 pt-0 pl-20">
                                    {
                                        getSubcategories(cat.id).map((sub, si) => {
                                            return (
                                                <span
                                                    className={`flex justify-between ${!sub.active && cat.active ? "opacity-50" : "opacity-100"}`}
                                                    key={si}>
                                                    <span className="py-5 block">{sub?.name}</span>
                                                    <IconButton className="p-5"
                                                                onClick={() => _archiveTicketSystemSubCategory(cat, sub)}>
                                                        <span className="w-16">
                                                            <HeroIcon icon="ArchiveIcon" outline={sub?.active ?? true}/>
                                                        </span>
                                                    </IconButton>
                                                </span>
                                            )
                                        })
                                    }

                                    {
                                        cat.active ?
                                            <span className="cursor-pointer flex items-center mt-10">
                                        <PlusCircleIcon className="w-16 mr-5"/>
                                        <RbContentEditable
                                            onConfirm={name => _addTicketSystemSubCategory(cat, name)}>
                                            <span>Create new subcategory</span>
                                        </RbContentEditable>
                                    </span> : <></>
                                    }
                                </AccordionDetails>
                            </RbAccordion>
                        )
                    })
                }
                <hr/>
                <span className="ml-16 cursor-pointer flex items-center mt-10">
                    <PlusCircleIcon className="w-16 mr-5"/>
                    <RbContentEditable onConfirm={name => _addTicketSystemCategory(name)}>
                        <span>Create new category</span>
                    </RbContentEditable>
                </span>
            </div>
            <Popover
                id="organization-menu"
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem
                    onClick={_archiveTicketSystemCategory}>
                    {selectedCategory?.active === true ? "Archive" : "Activate"}</MenuItem>
            </Popover>
        </Drawer>
    )
}
