import moment from "moment";

export const formatDate = (date: any) => {
    return moment(date).format('YYYY-MM-DD');
}

export const formatToEUDate = (date: string | undefined) => {
    if (!date) return date;
    if (!isNaN(+date)) return date;

    if (!isNaN(Date.parse(date.replaceAll(" ", "")))) {
        return moment(date).format('DD.MM.yyyy');
    } else {
        return date;
    }
}

export const formatTime = (date: any) => {
    return moment(date).format('HH:mm:ss');
}

export const parseTime = (durationHour: number, durationMinute: number, durationSecond: number): string => {
    return `${String(durationHour).padStart(2, "0")}:${String(durationMinute).padStart(2, "0")}:${String(durationSecond).padStart(2, "0")}`;
};

export const getTimeFromDate = (date: string): any => {
    return new Date(date).toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, '$1');
}

export const getHoursFromMilliseconds = (time: any): number => {
    return Math.floor(time / 3600);
}

export const getMinutesFromMilliseconds = (time: any): number => {
    return Math.floor((time % 3600) / 60);
}

export const getSecondsFromMillisecond = (time: number): number => {
    return Math.floor((time % 3600) % 60);
}
