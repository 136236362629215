import React from "react";
import {IBoardCardDetails} from "../../../types/board-types";
import RbIconTitle from "../../../shared-components/rb-components/RbIconTitle";
import {IconButton} from "@mui/material";
import {TrashIcon} from "@heroicons/react/outline";

export default function BoardCardAttachments(props: { card: IBoardCardDetails | undefined }) {
    const {card} = props;

    return (
        <>
            <RbIconTitle icon="PaperClipIcon" title="Attachments" container="h4" className="mb-10"/>
            <div className="overflow-auto whitespace-nowrap pb-16">

            </div>
        </>
    )
}
