import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {signoutRedirect} from "../../services/authService";

function SignoutOidc() {
    const navigate = useNavigate();
    useEffect(() => {
        signoutRedirect().then(() => {
            navigate('/login');
        });
    }, []);


    return (
        <>
            Redirecting...
        </>
    );
}

export default SignoutOidc;
