import {RbTabs} from "../../../shared-components/rb-components/RbTabs/RbTabs";
import {Avatar, IconButton, Tab} from "@mui/material";
import React, {useEffect, useState} from "react";
import {TabContext, TabPanel} from "@mui/lab";
import {ChatIcon, CursorClickIcon, DotsVerticalIcon} from "@heroicons/react/outline";
import {getUserInitials} from "../../../utils/RbUtils";
import moment from "moment";
import {useSelector} from "react-redux";
import RbText from "../../../shared-components/rb-components/RbText";
import {addCommentToTicket, getTicketActivities, getTicketComments} from "../../../services/ticketsService";
import {ITicketComment, ITTicketActivity} from "../../../types/tickets-types";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {useParams} from "react-router-dom";

export default function TicketCommentsAndActivity(props: { className?: string }) {
    const {id} = useParams();
    const [activities, setActivities] = useState<ITTicketActivity[]>([]);
    const [comments, setComments] = useState<ITicketComment[]>([]);
    const user = useSelector((state: any) => state.auth.user).data;
    const [value, setValue] = useState<"comments" | "activity">("comments");
    const [comment, setComment] = useState<string>("");

    useEffect(() => {
        _getTicketComments();
        _getTicketActivity();
    }, []);

    function _getTicketComments() {
        if (!id) return;
        getTicketComments(id).then(res => {
            if (res?.data?.error) return;
            setComments(res.data.data);
        });
    }

    function _getTicketActivity() {
        if (!id) return;
        getTicketActivities(id).then(res => {
            if (res?.data?.error) return;
            setActivities(res.data.data);
        });
    }

    function handleDeleteComment(id: string) {

    }

    function handleKeyPress(e: any) {
        if (!id) return;
        if (e.key === 'Enter' && !!comment) {
            e.preventDefault();
            addCommentToTicket(id, comment).then(res => {
                if (res?.data?.error) return;
                setComment("");
                _getTicketComments();
            });
        }
    }


    return (
        <div className={props?.className ?? ""}>
            <RbTabs value={value} onChange={(e, val) => setValue(val)}>
                <Tab value="comments"
                     className="mr-20"
                     label={
                         <span className="flex items-center">
                        <ChatIcon className="w-20 mr-5"/>
                        <span>Comments</span>
                    </span>}
                />
                <Tab value="activity"
                     label={
                         <span className="flex items-center">
                        <CursorClickIcon className="w-20 mr-5"/>
                        <span>Tracker activity</span>
                    </span>}
                />
            </RbTabs>


            <TabContext value={value}>
                <TabPanel value={"comments"} className="px-0">
                    <div className="flex items-center">
                        <Avatar src={user?.photoURL ?? ""}
                                sx={{width: 38, height: 38}}
                                className="mt-10 mr-10"
                                alt={user?.displayName ?? ""}>{user ? getUserInitials(user?.displayName) : ""}</Avatar>
                        <RbTextField className="mt-10"
                                     variant="outlined"
                                     size="small"
                                     value={comment}
                                     label="Comment"
                                     onChange={e => setComment(e.target.value)}
                                     onKeyDown={handleKeyPress}
                                     multiline={true}
                                     color="primary"/>
                    </div>
                    {
                        comments.map((comm, i) => {
                            return (
                                <div className="flex items-start mt-12" key={i}>
                                    <Avatar src={comm?.user?.avatar}
                                            alt={comm?.user?.name}
                                            className="mr-10">{getUserInitials(comm?.user?.name)}</Avatar>
                                    <span>
                                        <span className="block">
                                            <span className="font-bold">{comm?.user?.name}</span>
                                            <RbText theme="secondary" className="text-[1rem] ml-5">
                                                {comm.createdAt ? moment(new Date(comm.createdAt)).fromNow() : ""}
                                            </RbText>
                                        </span>
                                        <span>{comm.comment}</span>
                                    </span>
                                    <span className="basis-[2rem]">
                                        {
                                            user?.uid === comm?.user?.id &&
                                            <IconButton className="p-5" onClick={() => handleDeleteComment(comm.id)}>
                                                <DotsVerticalIcon className="w-14"/>
                                            </IconButton>
                                        }
                                    </span>
                                </div>
                            )
                        })
                    }
                </TabPanel>
                <TabPanel value={"activity"} className="px-0">
                    {
                        activities.map((act, i) => {
                            return (
                                <div className="flex items-start mt-12" key={i}>
                                    <Avatar src={act?.user?.avatar}
                                            alt={act?.user?.name}
                                            className="mr-10">{getUserInitials(act?.user?.name)}</Avatar>
                                    <span>
                                        <span className="block">
                                            <span className="font-bold">{act?.user?.name}</span>
                                            <RbText theme="secondary" className="text-[1rem] ml-5">
                                                {act.createdAt ? moment(new Date(act.createdAt)).fromNow() : ""}
                                            </RbText>
                                        </span>
                                        <span>{act?.activity ?? ""}</span>
                                    </span>
                                </div>
                            )
                        })
                    }
                </TabPanel>
            </TabContext>
        </div>
    )
}
