import {TabContext, TabPanel} from "@mui/lab";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";
import React, {useEffect, useState} from "react";
import {Avatar} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {getAllUserOrganizations} from "../../services/sidebar/sidebarService";
import {connect} from "react-redux";
import withRouter from "../../../@fuse/core/withRouter";
import FuseSplashScreen from "../../../@fuse/core/FuseSplashScreen";

function LandingPage(props: { user: any }) {
    const [hasOrganizations, setHasOrganizations] = useState<boolean | undefined>();
    const [activeStep, setActiveStep] = useState(0);
    const [selectedAvatar, setSelectedAvatar] = useState<number>(0);
    const navigate = useNavigate();

    useEffect(() => {
        if (!props?.user?.data) return;
        _getAllUserOrganizations();
    }, [props?.user]);

    function _getAllUserOrganizations() {
        getAllUserOrganizations().then(res => {
            if (res?.data?.error) return;
            if (res?.data?.data.length > 0) {
                navigate("/dashboard");
            } else {
                setHasOrganizations(false);
            }
        });
    }

    return (
        <>
            {(hasOrganizations === undefined) && <FuseSplashScreen/>}
            <div className="flex items-start h-[100vh]" style={{backgroundColor: "#111827"}}>
                <div className="w-1/6 min-w-160 pt-32 h-full mr-72 bg-repeat" style={{
                    backgroundImage: "url('/assets/images/illustrations/rubicon_mesh_tile.svg')"
                }}>
                    <img className="w-96 m-auto" src="/assets/images/logos/rubicon.svg" alt="Rubicon logo"/>
                </div>
                <TabContext value={String(activeStep)}>
                    <TabPanel value={"0"} className="p-0 m-0 flex flex-col justify-center h-full gap-28 text-white">
                        <h1 className="text-4xl">Welcome First Last Name!</h1>
                        <p className="text-xl">Let’s get you on track!</p>
                        <RbRoundButton className="w-fit px-20" style={{backgroundColor: "#075985"}} variant="contained"
                                       onClick={() => setActiveStep(activeStep + 1)}>Let's start</RbRoundButton>
                    </TabPanel>
                </TabContext>

                <TabContext value={String(activeStep)}>
                    <TabPanel value={"1"} className="p-0 m-0 flex flex-col justify-center h-full gap-28 text-white">
                        <h1 className="text-4xl">Select your avatar</h1>

                        <div className="flex items-center">
                            <Avatar sx={{width: 64, height: 64}}
                                    src={`/assets/images/avatars/avatar_${selectedAvatar}.svg`}>FN</Avatar>
                            <div className="ml-48 pl-48 border-l-1" style={{borderLeftColor: "#64748B"}}>
                                <div className="flex gap-24 mb-24">
                                    {
                                        Array(6).fill(0).map((_, i) => {
                                            return (
                                                <Avatar sx={{width: 64, height: 64}}
                                                        onClick={() => setSelectedAvatar(i + 1)}
                                                        className="cursor-pointer box-border border-solid border-2 border-transparent hover:border-green-700 transition-colors"
                                                        src={`/assets/images/avatars/avatar_${i + 1}.svg`}>NN</Avatar>
                                            )
                                        })
                                    }
                                </div>

                                <div className="flex gap-24">
                                    {
                                        Array(6).fill(0).map((_, i) => {
                                            return (
                                                <Avatar sx={{width: 64, height: 64}}
                                                        onClick={() => setSelectedAvatar(i + 7)}
                                                        className="cursor-pointer box-border border-solid border-2 border-transparent hover:border-green-700 transition-colors"
                                                        src={`/assets/images/avatars/avatar_${i + 7}.svg`}>NN</Avatar>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <RbRoundButton className="w-fit px-20" style={{backgroundColor: "#075985"}} variant="contained"
                                       onClick={() => setActiveStep(activeStep + 1)}>Next</RbRoundButton>
                    </TabPanel>
                </TabContext>
            </div>
        </>
    )
}

// @ts-ignore
function mapStateToProps({fuse, auth}) {
    return {
        organization: fuse.organization.value,
        user: auth?.user
    }
}

export default withRouter(connect(mapStateToProps)(LandingPage));
