import AppBar from '@mui/material/AppBar';
import {styled, useTheme} from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import {connect, useSelector} from 'react-redux';
import OrganizationMenu from "../../shared-components/organization/OrganizationMenu";
import {useEffect, useState} from "react";
import * as React from "react";
import UserStatusMenu from "../../shared-components/user-menu/UserStatusMenu";
import {getUserInfo} from "../../services/user-settings/userSettingsService";
import withRouter from '@fuse/core/withRouter';

const StyledAppBar = styled(AppBar)(({theme}) => ({
    '& .username, & .email': {
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest, easing: theme.transitions.easing.easeInOut,
        }),
    },

    '& .avatar': {
        background: theme.palette.background.default, transition: theme.transitions.create('all', {
            duration: theme.transitions.duration.shortest, easing: theme.transitions.easing.easeInOut,
        }), bottom: 0, '& > img': {
            borderRadius: '50%',
        },
    },
}));

function UserNavbarHeader({organization}) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [statusColor, setStatusColor] = React.useState("#71717a");
    const [userInfo, setUserInfo] = useState(null);
    const user = useSelector(({auth}) => auth.user);

    useEffect(() => {
        if (!organization?.id) return;
        getUserInfo().then(res => {
            if (res.data.data) {
                setUserInfo(res.data.data);
            }
        })
    }, [organization?.id]);

    useEffect(() => {
        if (!userInfo) return;
        _updateUserStatusIndicator();
    }, [userInfo]);

    function getUserInitials() {
        return user?.data?.displayName.split(' ').map(part => part[0].toUpperCase()).join("");
    }

    function _updateUserStatusIndicator() {
        switch (userInfo?.status) {
            case "Online":
                setStatusColor(theme.palette.success.main);
                break;
            case "Away":
                // @ts-ignore
                setStatusColor(theme.priority.medium);
                break;
            case "Do not disturb":
                // @ts-ignore
                setStatusColor(theme.priority.high);
                break;
            default:
                setStatusColor("#71717a");
                break;
        }
    }

    return (<StyledAppBar
        position="static"
        color="primary"
        className="user relative flex flex-col items-center justify-center z-0 shadow-0"
    >
        <OrganizationMenu className="mt-16 mb-16 text-center w-3/4 m-auto"/>
        <div className="relative">
            <Avatar
                onClick={(event) => setAnchorEl(event.currentTarget)}
                className="avatar w-92 h-92 p-8 box-content cursor-pointer"
                alt="user photo"
                src={user?.data?.photoURL && user?.data?.photoURL !== '' ? user?.data?.photoURL : 'assets/images/avatars/profile.jpg'}
            />
            <UserStatusMenu onClose={() => setAnchorEl(null)} anchorEl={anchorEl} setStatusColor={setStatusColor}/>
            <div
                className="avatar-status absolute bottom-0 right-0 rounded-full pointer-events-none border-white w-24 h-24 m-6 border-4"
                style={{borderColor: theme.palette.background.default, backgroundColor: statusColor}}></div>
        </div>
        <Typography className="username text-18 whitespace-nowrap font-semibold mb-4" color="inherit">
            {user?.data?.displayName}
        </Typography>
        <Typography
            className="email text-13 opacity-50 whitespace-nowrap font-medium"
            color="inherit"
        >
            {user?.data?.email}
        </Typography>
        <span className="username-initials hidden">{getUserInitials()}</span>
    </StyledAppBar>);
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(UserNavbarHeader));
