import * as React from 'react';
import {useEffect, useState} from 'react';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {KeyboardArrowRight} from "@mui/icons-material";
import TrackerRow from "./tracker-row/TrackerRow";
import clsx from "clsx";
import {getAllOrganizationProjects} from "../../../services/common/common";
import {getTrackerCategories} from "../../../services/tracker/trackerService";
import {IProjectDropDown} from "../../../types/common-types";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";

const BoardTracker = (props: any) => {
    const {tables, refreshTrackers, organization} = props;
    const [selectedTable, setSelectedTable]: any[] = useState([]);
    const [projects, setProjects] = useState<IProjectDropDown[]>([]);
    const [categories, setCategories] = useState([]);


    useEffect(() => {
        setSelectedTable(tables);
        _getAllProjects();
        _getTrackerCategories();
    }, [tables, organization?.id])

    const _getAllProjects = () => {
        if (!organization?.id) return;
        getAllOrganizationProjects(organization.id).then(data => {
            setProjects(data?.data?.data);
        });
    }

    function _getTrackerCategories() {
        if (!organization?.id) return;
        getTrackerCategories(organization.id).then(res => {
            if (!res?.data?.error) {
                setCategories(res.data.data);
            }
        });
    }


    const refresh = () => {
        refreshTrackers();
    }

    const toggleTables = (data: any) => {
        const table = data;
        if (selectedTable) {
            if (!selectedTable.includes(table)) {
                setSelectedTable((oldArray: any) => [...oldArray, table])
                console.log(selectedTable)
            } else {
                // closeDetails();
                closeTables(table);
            }
        }
    }

    const closeTables = (table: any) => {
        if (selectedTable) {
            const index = selectedTable.indexOf(table);
            if (index > -1) {
                setSelectedTable(selectedTable.filter((item: any) => item.date !== table.date));
            }
        }
    }

    return (
        <div className={"p-12"}>
            {tables && tables.map((t: any) => (
                <TableContainer key={t?.id}
                                className={
                                    clsx("rounded-2xl mt-24",
                                        !selectedTable?.includes(t) ? "rounded-b-2xl" : "rounded-b-none")
                                }>
                    <Table key={`thead-${t?.id}`}>
                        <TableHead
                            className={"cursor-pointer"}
                            onClick={() => toggleTables(t)}
                        >
                            <TableCell
                                style={{borderBottom: "none"}}
                                className={"flex items-center"}>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                >
                                    {selectedTable?.includes(t) ? <KeyboardArrowRight/> :
                                        <KeyboardArrowDownIcon/>}
                                </IconButton>
                                <p>{t?.date}</p>
                            </TableCell>
                            <TableCell
                                style={{borderBottom: "none"}}
                                align={"right"}>
                                Total: {t?.totalTime}
                            </TableCell>
                        </TableHead>
                    </Table>

                    <Collapse key={`collapse-${t?.id}`} in={selectedTable?.includes(t)} timeout="auto" unmountOnExit>
                        <Table key={t?.id}>
                            <TableRow
                                key={`trow-${t?.id}`}
                                className={'table-row sm:hidden'}
                            >
                                <TableCell
                                    className={'table-cell sm:hidden'}
                                />
                                <TableCell
                                    colSpan={3}
                                    className={'table-cell font-bold'}
                                >Description
                                </TableCell>
                                <TableCell
                                    colSpan={2}
                                    className={'table-cell font-bold'}>
                                    Project/card
                                </TableCell>
                                <TableCell
                                    className={'table-cell md:hidden font-bold'}>
                                    Category
                                </TableCell>
                                <TableCell
                                    className={'table-cell md:hidden font-bold'}>
                                    Billable
                                </TableCell>
                                <TableCell
                                    className={'table-cell md:hidden font-bold'}>
                                    Start time
                                </TableCell>
                                <TableCell
                                    className={'table-cell md:hidden font-bold'}>
                                    End time
                                </TableCell>
                                <TableCell
                                    className={'table-cell md:hidden font-bold'}>
                                    Duration
                                </TableCell>
                                <TableCell/>
                            </TableRow>

                            {t?.trackers.map((tracker: any) => (
                                <TrackerRow key={tracker.id} tracker={tracker} refreshTrackers={refresh}
                                            categories={categories} projects={projects}/>
                            ))}
                        </Table>
                    </Collapse>
                </TableContainer>
            ))}
            {tables && tables.length === 0 && (
                <div style={{marginTop: "10%"}}>
                    <img
                        className={"ml-auto mr-auto block w-1/3 md:w-1/2 sm:w-3/4"}
                        src={"assets/images/backgrounds/NoTrackers-Illustration.png"}
                        alt={"Illustration"}
                    />
                    <p className={"text-center mt-24"}>No trackers in this period. Start new tracker.</p>
                </div>
            )}
        </div>
    );
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(BoardTracker));

