import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Step, StepButton,
    StepLabel,
    Stepper
} from "@mui/material";
import React, {useState} from "react";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {useTheme} from "@mui/material/styles";
import {TabContext, TabPanel} from "@mui/lab";
import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/outline";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {removeOrganizationSeats} from "../../../services/organizationSettingsService";
import {RbTheme} from "../../../types/common-types";
import {RbDialog} from "app/shared-components/dialogs/RbDialog";

export default function RbDeleteOrganizationSeatsDialog(props: { organizationId: string, open: boolean, onClose: () => void, onSuccess: () => void }) {
    const [seats, setSeats] = useState<number>(1);
    const theme: RbTheme = useTheme();

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        if (activeStep === 0) {
            _removeOrganizationSeats();
        } else {
            props.onClose();
            resetComponent();
        }
    };

    function _removeOrganizationSeats() {
        if (!props?.organizationId || seats === 0) return;
        removeOrganizationSeats(props?.organizationId, seats).then(res => {
            if (res?.data?.error) return;
            setActiveStep(1);
            props.onSuccess();
        })
    }

    function resetComponent() {
        setTimeout(() => {
            setSeats(0);
            setActiveStep(0);
        }, 1000);
    }

    return (
        <RbDialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="organization-seats-decrease"
        >
            <DialogTitle id="organization-seats-decrease" className="delete min-w-400">Remove seats</DialogTitle>
            <DialogContent>
                <Stepper activeStep={activeStep}>
                    {
                        ["Seats", "Remove"].map((title, i) => {
                            return (
                                <Step key={i}>
                                    <StepButton onClick={() => setActiveStep(i)}>
                                        <StepLabel StepIconComponent={() => {
                                            return (
                                                <span
                                                    className="w-24 aspect-square flex justify-center items-center rounded-full"
                                                    style={{backgroundColor: i <= activeStep ? theme.palette.special : theme.palette.secondary.main}}>{i + 1}</span>
                                            )
                                        }}>{title}</StepLabel>
                                    </StepButton>
                                </Step>
                            )
                        })
                    }
                </Stepper>
                <TabContext value={String(activeStep)}>
                    <TabPanel value={"0"} className="px-0 pb-0">
                        <div className="flex items-center">
                            <IconButton className="p-5">
                                <MinusCircleIcon className="w-36" strokeWidth="1.5" onClick={() => {
                                    if (seats > 0) {
                                        setSeats(seats - 1);
                                    }
                                }}/>
                            </IconButton>
                            <RbTextField className="mx-10"
                                         disabled={true}
                                         label="Seats"
                                         InputLabelProps={{
                                             shrink: true,
                                         }}
                                         InputProps={{
                                             inputProps: {className: "text-center"}
                                         }}
                                         variant="outlined"
                                         value={seats}
                                         color="primary"/>
                            <IconButton className="p-5">
                                <PlusCircleIcon className="w-36" strokeWidth="1.5"
                                                onClick={() => setSeats(seats + 1)}/>
                            </IconButton>
                        </div>
                    </TabPanel>

                    <TabPanel value={"1"} className="px-0 pb-0">
                        <h2>The seats will be removed. You won't get charged next month.</h2>
                    </TabPanel>
                </TabContext>
            </DialogContent>
            <DialogActions className="mb-10 mr-20">
                <RbRoundButton className="mr-10" variant="text" onClick={() => {
                    props.onClose();
                    resetComponent();
                }}>Cancel</RbRoundButton>
                <RbRoundButton variant="contained" disabled={seats === 0}
                               onClick={handleNext}>{activeStep === 0 ? "Next" : "Done"}</RbRoundButton>
            </DialogActions>
        </RbDialog>
    );
}
