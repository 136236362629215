import {IProjectOverview} from "../../../types/project-overview-types";
import ProjectOverviewCard from "./ProjectOverviewCard";
import ProjectOverviewByUsers from "./ProjectOverviewByUsers";
import ProjectOverviewByBoards from "./ProjectOverviewByBoards";
import ProjectOverviewUserWorkload from "./ProjectOverviewUserWorkload";

export default function OverviewOfProject(props: { data?: IProjectOverview }) {
    return (
        <div>
            <div className="flex">
                <div className="w-[48.4rem]">
                    <ProjectOverviewCard title="Active users" value={props.data?.activeUsers}
                                         subValue={props.data?.totalUsers}
                                         subTitle="Total users"/>
                    <ProjectOverviewCard title="Active boards" value={props.data?.activeBoards}
                                         subValue={props.data?.byBoard?.length}
                                         subTitle="Total boards"/>
                    <ProjectOverviewCard title="Active tasks" value={props.data?.activeTasks}
                                         subValue={props.data?.doneToday}
                                         subTitle="Done today"/>
                    <ProjectOverviewCard title="Scheduled tasks" value={props.data?.scheduleTasks}
                                         subValue={props.data?.overdueTasks}
                                         subTitle="Overdue tasks"/>
                </div>
                <ProjectOverviewUserWorkload data={props.data?.userWorkload}/>
            </div>
            <ProjectOverviewByUsers data={props.data?.byUser}/>
            <ProjectOverviewByBoards data={props.data?.byBoard}/>
        </div>
    )
}
