import React, {useState} from 'react';
import {
    editBillable,
    editCard,
    editDescription, editDuration,
    editProject, editStartDate,
    getAllTrackerDetails, getAllCardsByProjectId, editCategory,
} from "../../../../services/tracker/trackerService";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {ChatAltIcon, CurrencyDollarIcon} from "@heroicons/react/outline";
import {
    getTimeFromDate, parseTime
} from "../../../../utils/utils";
import {FormControl, Grid, InputAdornment, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import Collapse from "@mui/material/Collapse";
import {DesktopDatePicker} from "@mui/lab";
import {KeyboardArrowRight} from "@mui/icons-material";
import DotsMenu from "../../../../shared-components/dots-menu/DotsMenu";
import {useTheme} from "@mui/material/styles";
import TrackerRowMobile from "./tracker-row-mobile/TrackerRowMobile";
import TrackerRowTablet from "./tracker-row-tablet/TrackerRowTablet";
import clsx from "clsx";
import {ITrackerCategory, ITrackerDetails, ITrackerDuration} from "../../../../types/tracker-types";
import moment from "moment";
import {UserOrganization} from "../../../../types/common-types";
import {connect} from 'react-redux';
import withRouter from "../../../../../@fuse/core/withRouter";

function TrackerRow(props: { organization: UserOrganization, categories: ITrackerCategory[], projects: any[], tracker: ITrackerDetails, refreshTrackers: () => void }) {
    const [selectedTracker, setSelectedTracker] = useState<ITrackerDetails | null>(null);
    const [cards, setCards] = useState([]);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const theme = useTheme();
    const {projects, tracker, refreshTrackers, categories} = props;
    const [open, setOpen] = useState(false);

    const pallete: any = theme.palette;
    const tableCellTextColor: any =
        pallete.name === 'default' ? "#94A4B8" :
            pallete.name === 'midnightBlue' ? "#64748B" :
                pallete.name === 'darkNight' ? "#71717A" : "#94A4B8";

    const tableRowBorderBottom: any =
        pallete.name === 'default' ? "1px solid #F1F5F9" :
            pallete.name === 'midnightBlue' ? "1px solid #18181B" :
                pallete.name === 'darkNight' ? "1px solid #111827" : "2px solid #F1F5F9";

    const _editStartDate = (sDate: Date) => {
        if (!startDate) return;
        let time = new Date(tracker.startDate).toLocaleTimeString("de-DE").split(":").map(t => +t);
        sDate.setHours(time[0], time[1], time[2]);
        setStartDate(sDate);
        editStartDate(tracker.id, moment(sDate).toISOString(true)).then(res => {
            if (res?.data?.error) return;
            refreshTrackers();
        });
    }


    const _getAllCardByProject = (projectId: any) => {
        if (!projectId) return;
        getAllCardsByProjectId(projectId).then(res => {
            if (!res?.data?.error) {
                setCards(res.data.data);
            }
        })
    }

    const getTrackerDuration = () => {
        return parseTime(tracker.durationHour, tracker.durationMinute, tracker.durationSecond);
    };

    const _editDescription = (desc: string) => {
        editDescription(tracker.id, desc).then(() => {
            // getTrackerDetails();
            refreshTrackers();
        });
    }

    const _editBillable = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, billable: boolean) => {
        event?.stopPropagation();
        editBillable(tracker.id, billable).then((res) => {
            if (res?.data?.error) return;
            // getTrackerDetails();
            refreshTrackers();
        });
    }

    const _editProject = (projectId?: string) => {
        editProject(tracker.id, projectId).then(res => {
            if (!res?.data?.error) {
                // getTrackerDetails();
                refreshTrackers();
                _getAllCardByProject(projectId);
            }
        });
    }

    const _editCard = (cardId?: string) => {
        editCard(tracker.id, cardId).then((res) => {
            if (res?.data?.error) return;
            // getTrackerDetails();
            refreshTrackers();
        });
    }

    const _editCategory = (categoryId?: string) => {
        editCategory(tracker.id, categoryId).then((res) => {
            if (res?.data?.error) return;
            // getTrackerDetails();
            refreshTrackers();
        });
    }

    const editStartD = (startTime: string) => {
        let date = moment(tracker.startDate);
        let time = startTime.split(":").map(t => +t);
        // date.setHours(time[0], time[1], time[2]);
        date.set({hours: time[0], minutes: time[1], seconds: time[2]});
        editStartDate(tracker.id, date.toISOString(true)).then(res => {
            if (res?.data?.error) return;
            // getTrackerDetails();
            refreshTrackers();
        });
    }

    function calculateManualDuration(startTime: string, endTime: string): { duration: string, hours: number, minutes: number, seconds: number } {
        const startTimeParts = startTime.split(":");
        const endTimeParts = endTime.split(":");
        const dur = moment.duration(moment(`${endTimeParts[0]}:${endTimeParts[1]}:${endTimeParts[2]}`, "HH:mm:ss").diff(moment(`${startTimeParts[0]}:${startTimeParts[1]}:${startTimeParts[2]}`, "HH:mm:ss")));
        const hours = Math.floor(dur.asHours());
        const minutes = Math.floor(dur.asMinutes() - 60 * hours);
        const seconds = Math.floor(dur.asSeconds() - (hours * 3600 + minutes * 60));
        return {
            duration: `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`,
            hours, minutes, seconds
        }
    }

    const editEndD = (endTime: string) => {
        const dur = calculateManualDuration(new Date(tracker.startDate).toLocaleTimeString("de-DE"), endTime);
        if (!dur) return;
        _editDuration(dur.duration);
    }

    const _editDuration = (duration: string) => {
        const splitDuration = duration.split(':');
        const trackerDuration: ITrackerDuration = {
            trackerId: tracker.id,
            hour: +splitDuration[0],
            minute: +splitDuration[1],
            second: +splitDuration[2],
        };

        editDuration(trackerDuration).then(res => {
            if (res?.data?.error) return;
            getTrackerDetails();
            refreshTrackers();
        });
    }

    const closeDetails = () => {
        setSelectedTracker(null);
        setStartDate(undefined);
    }

    const getTrackerDetails = () => {
        if (!props?.organization?.id) return;
        getAllTrackerDetails(props.organization.id, tracker.id).then(res => {
            if (!res?.data?.error) {
                setSelectedTracker(null);
                setSelectedTracker(res.data.data);
                setStartDate(new Date(res.data.data.startDate));
            }
        });
    }

    const toggleDetails = (tracker: ITrackerDetails) => {
        setSelectedTracker(tracker);
        setStartDate(new Date(tracker.startDate));

        if (selectedTracker && selectedTracker?.id === tracker?.id) {
            closeDetails();
        } else {
            if (cards.length === 0) {
                _getAllCardByProject(tracker.projectId);
            }
        }
        // getTrackerDetails();
    }

    return (
        <React.Fragment>
            <TableBody>
                <TableRow
                    className={"hidden sm:table-row"}
                    onClick={() => toggleDetails(tracker)}
                >
                    <TableCell
                        className={"font-bold"}
                        style={{color: tableCellTextColor}}
                        colSpan={4}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                        >
                            {selectedTracker?.id === tracker.id ? <KeyboardArrowDownIcon/> :
                                <KeyboardArrowRight/>}
                        </IconButton>
                        Description
                    </TableCell>
                    <TableCell className={'hidden sm:table-cell'}>
                        <div onClick={(event) => event.stopPropagation()}>
                            <DotsMenu tracker={tracker} refreshTrackers={props.refreshTrackers}/>
                        </div>
                    </TableCell>
                </TableRow>

                <TableRow
                    className={"cursor-pointer hidden sm:table-row"}
                    sx={{'& > *': {borderBottom: 'unset'}}}>
                    <TableCell colSpan={5} className={"text-sm min-w-200 max-w-200"}>
                        {tracker?.description}
                    </TableCell>
                </TableRow>
                <TableRow
                    className={"cursor-pointer sm:hidden"}
                    sx={{'& > *': {borderBottom: 'unset'}}}
                    onClick={() => toggleDetails(tracker)}>
                    <TableCell className={'table-cell text-sm'}>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                        >
                            {selectedTracker?.id === tracker.id ? <KeyboardArrowDownIcon/> :
                                <KeyboardArrowRight/>}
                        </IconButton>
                    </TableCell>
                    <TableCell
                        colSpan={3}
                        className={'text-sm  min-w-200 max-w-200 lg:min-w-128 lg:max-w-128 md:min-w-full md:max-w-full truncate'}
                    >
                        {tracker?.description}
                    </TableCell>
                    <TableCell colSpan={2}
                               className={'table-cell truncate text-sm  min-w-200 max-w-200 lg:min-w-128 lg:max-w-128 ' +
                                   'md:min-w-full md:max-w-full'}>{tracker?.projectName}{tracker?.cardName && `/${tracker?.cardName}`}</TableCell>
                    <TableCell
                        className={'table-cell md:hidden text-sm  min-w-128 max-w-128'}>{tracker?.labelName}</TableCell>
                    <TableCell className={'table-cell text-center md:hidden text-sm  min-w-64 max-w-64'}>
                        <FormControl>
                            <CurrencyDollarIcon height={24}
                                                onClick={(event) => {
                                                    _editBillable(event, !tracker.billable);
                                                }}
                                                className={clsx("border-hidden cursor-pointer", tracker?.billable ? "opacity-100" : "opacity-30")}/>
                        </FormControl>
                    </TableCell>
                    <TableCell
                        className={'table-cell md:hidden text-sm'}>{getTimeFromDate(tracker?.startDate)}</TableCell>
                    <TableCell
                        className={'table-cell md:hidden text-sm'}>{getTimeFromDate(tracker?.endDate)}
                    </TableCell>

                    <TableCell className={'table-cell md:hidden text-sm'}>{getTrackerDuration()}</TableCell>

                    <TableCell className={'table-cell text-sm'}>
                        <div onClick={(event) => event.stopPropagation()}>
                            <DotsMenu tracker={tracker} refreshTrackers={props.refreshTrackers}/>
                        </div>
                    </TableCell>
                </TableRow>


                <TrackerRowTablet tracker={tracker} tableCellTextColor={tableCellTextColor}
                                  tableRowBorderBottom={tableRowBorderBottom}/>

                <TrackerRowMobile tracker={tracker} tableCellTextColor={tableCellTextColor}
                                  tableRowBorderBottom={tableRowBorderBottom}/>

            </TableBody>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0, width: "100%"}} colSpan={12}>
                    {selectedTracker?.id === tracker.id}
                    <Collapse
                        in={!!selectedTracker?.id}
                        timeout="auto"
                        unmountOnExit>
                        <Grid sx={{mb: 4, mt: 4}}>
                            <form>
                                <Grid mb={4} item>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Description"
                                            id="description"
                                            defaultValue={tracker.description}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position='end'>
                                                        <ChatAltIcon className={"min-w-24 max-w-24 stroke-1"}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            variant="outlined"
                                            onBlur={(e) => _editDescription(e.target.value)}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid
                                    container
                                    spacing={4}
                                    columns={3}
                                    mb={4}
                                    className={"items-center md:block sm:block"}>
                                    <Grid item xs={1} className={"max-w-full"}>
                                        <FormControl fullWidth>
                                            <InputLabel id="project">Project</InputLabel>
                                            <Select
                                                labelId="project"
                                                label="Project"
                                                variant="outlined"
                                                value={tracker.projectId}
                                            >
                                                <MenuItem value={undefined}
                                                          onClick={() => _editProject(undefined)}>Clear</MenuItem>
                                                {projects.map((project: any, i) => (
                                                    <MenuItem key={i}
                                                              onClick={() => {
                                                                  _editProject(project.id);
                                                              }}
                                                              value={project?.id}>{project?.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        className={"flex md:max-w-full gap-36 sm:block sm:max-w-full"}
                                    >
                                        <FormControl fullWidth>
                                            <InputLabel id="card">Card</InputLabel>
                                            <Select
                                                labelId="card"
                                                label="Card"
                                                variant="outlined"
                                                value={tracker.cardId}
                                            >
                                                <MenuItem value={undefined}
                                                          onClick={() => _editCard(undefined)}>Clear</MenuItem>
                                                {cards.map((card: any, i) => (
                                                    <MenuItem key={i}
                                                              onClick={() => {
                                                                  _editCard(card?.cardId)
                                                              }}
                                                              value={card?.cardId}>{card?.cardName}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl fullWidth className={"sm:mt-36"}>
                                            <InputLabel id="label">Category</InputLabel>
                                            <Select
                                                labelId="label"
                                                label="Category"
                                                variant="outlined"
                                                value={tracker.labelId}
                                            >
                                                <MenuItem value={undefined}
                                                          onClick={() => _editCategory(undefined)}>Clear</MenuItem>
                                                {categories.map((category: any, i) => (
                                                    <MenuItem key={i}
                                                              onClick={() => _editCategory(category?.id)}
                                                              value={category?.id}>{category?.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    columns={4}
                                    spacing={4}
                                    className={"flex items-center sm:block"}>
                                    <Grid item xs={1} className={"sm:max-w-full"}>
                                        <DesktopDatePicker
                                            open={open}
                                            label="Date"
                                            inputFormat="dd.MM.yyyy."
                                            value={startDate}
                                            onOpen={() => setOpen(true)}
                                            onClose={() => setOpen(false)}
                                            onChange={(e) => _editStartDate(e as Date)}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                variant="outlined"
                                                fullWidth
                                                onClick={() => setOpen(true)}
                                                InputProps={{endAdornment: null}}
                                            />}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={3}
                                        className={"flex md:max-w-full sm:max-w-full md:gap-36 sm:mt-12 gap-36"}
                                    >
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Start"
                                                id="start"
                                                defaultValue={new Date(tracker.startDate).toLocaleTimeString("de-DE")}
                                                onBlur={(e) => editStartD(e.target.value)}
                                                variant="outlined"
                                            />
                                        </FormControl>
                                        <FormControl fullWidth disabled={true}>
                                            <TextField
                                                label="End"
                                                disabled={true}
                                                id="end"
                                                value={new Date(tracker.endDate).toLocaleTimeString("de-DE")}
                                                onBlur={(e) => editEndD(e.target.value)}
                                                variant={"outlined"}
                                            />
                                            {/*<span*/}
                                            {/*    className="h-full flex items-center pl-[14px] cursor-default text-inherit">{new Date(tracker.endDate).toLocaleTimeString("de-DE")}</span>*/}
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Duration"
                                                id="duration"
                                                defaultValue={getTrackerDuration()}
                                                onBlur={(e) => _editDuration(e.target.value)}
                                                variant="outlined"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </form>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(TrackerRow));

