export function formatLastTabName(name: string): string {
    return `${name}-last`;
}

export function reorderListItem<T>(
    list: T[],
    oldIndex: number,
    newIndex: number
): T[] {
    const result = Array.from(list);
    const [removed] = result.splice(oldIndex, 1);
    result.splice(newIndex, 0, removed);

    return result;
}


export function getUserInitials(name: string | undefined) {
    if (!name) return "";
    return name.split(' ').map(part => part[0]?.toUpperCase()).join("");
}

const isPropValuesEqual = (subject: any, target: any, propNames: string[]) =>
    propNames?.every(propName => subject[propName] === target[propName]);

export const getUniqueItemsByProperties = <T>(items: T[] | undefined, path: string[]) => {
    if (!items || items?.length <= 0) return [];
    return items.filter((item, index, array) =>
        index === array.findIndex(foundItem => isPropValuesEqual(foundItem, item, path ?? []))
    );
}
export const getUniqueItemsByProperty = <T>(items: T[] | undefined, path: string) => {
    if (!items || items.length <= 0) return [];
    return items.filter((item, index, array) =>
        index === array.findIndex(foundItem => isPropValuesEqual(foundItem, item, [path]))
    );
}
