import React, {useEffect, useState} from "react";
import {
    deleteBoardCard, getBoardCardStatuses, getBoardCardTrackerHistory, getBoardMembers, getCardActivity,
    getCardComments,
    getCardDetails,
    getCardTasks, updateCardDueDate
} from "../../../services/boards/boardsService";
import {
    IBoardCard, IBoardCardActivity,
    IBoardCardComment,
    IBoardCardDetails,
    IBoardCardTasks,
    IBoardLabel,
    IBoardMember, IBoardTrackerHistory, ICardStatus
} from "../../../types/board-types";
import {Dialog, DialogContent, IconButton, Menu, MenuItem, Tab, TextField, Tooltip} from "@mui/material";
import {DesktopDatePicker, TabContext, TabPanel} from "@mui/lab";
import {RbTabs} from "app/shared-components/rb-components/RbTabs/RbTabs";
import {
    CalendarIcon,
    ClipboardIcon, DotsHorizontalIcon,
    MinusCircleIcon, PaperClipIcon,
    TagIcon, TrashIcon,
    UserCircleIcon,
    UsersIcon,
    XIcon
} from "@heroicons/react/outline";
import RbBox from "../../../shared-components/rb-components/RbBox";
import HeroIcon from "../../../../@fuse/core/HeroIcon/HeroIcon";
import BoardCardName from "../board-details-compoments/BoardCardName";
import {RbSubSection, RbSubSectionItem} from "app/shared-components/rb-components/RbSections";
import BoardCardDescription from "../board-details-compoments/BoardCardDescription";
import BoardCardMembers from "../board-details-compoments/BoardCardMembers";
import BoardCardReminder from "../board-details-compoments/BoardCardReminder";
import BoardCardETC from "../board-details-compoments/BoardCardETC";
import BoardCardParent from "../board-details-compoments/BoardCardParent";
import BoardCardBlockedBy from "../board-details-compoments/BoardCardBlockedBy";
import BoardCardLabels from "../board-details-compoments/BoardCardLabels";
import BoardCardAttachments from "../board-details-compoments/BoardCardAttachments";
import BoardCardTasks from "../board-details-compoments/BoardCardTasks";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import BoardCardComments from "../board-details-compoments/BoardCardComments";
import BoardCardHeadControls from "../board-details-compoments/BoardCardHeadControls";
import {useParams} from "react-router-dom";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import BoardTrackerHistory from "../board-tracker-history/BoardTrackerHistory";
import {styled} from "@mui/system";
import BoardCardActivities from "../board-details-compoments/BoardCardActivities";

interface IBoardControls {
    members: boolean;
    labels: boolean;
    reminder: boolean;
    etc: boolean;
    parent: boolean;
    blockedBy: boolean;
    attachments: boolean;
    checkList: boolean;
    dueDate: boolean;
}

export default function BoardCardDetailsDialog(props: { refreshBoard: () => void, onClose: () => void, cards: IBoardCard[], cardId: string, open: boolean, labels: IBoardLabel[] }) {
    const {id} = useParams();
    const defControls = {
        members: false,
        labels: false,
        reminder: false,
        etc: false,
        parent: false,
        blockedBy: false,
        attachments: false,
        checkList: false,
        dueDate: false
    }
    const [card, setCard] = useState<IBoardCardDetails | undefined>(undefined);
    const [value, setValue] = React.useState<"general" | "activity" | "history">("general");
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [openDueDate, setOpenDueDate] = React.useState<boolean>(false);
    const [anchorElDelete, setAnchorElDelete] = React.useState<HTMLElement | null>(null);
    const [tasks, setTasks] = useState<IBoardCardTasks[]>([]);
    const [comments, setComments] = useState<IBoardCardComment[]>([]);
    const [boardMembers, setBoardMembers] = useState<IBoardMember[]>([]);
    const [trackerHistory, setTrackerHistory] = useState<IBoardTrackerHistory[]>([]);
    const [cardActivities, setCardActivities] = useState<IBoardCardActivity[]>([])
    const [cardStatuses, setCardStatuses] = useState<ICardStatus[]>([]);
    const [controls, setControls] = useState<IBoardControls>(defControls);
    const theme: RbTheme = useTheme();

    useEffect(() => {
        _getBoardMembers();
        _getBoardCardTrackerHistory();
        _getCardActivity();
    }, [props.cardId]);

    useEffect(() => {
        resetCardDetails();
        _getCardDetails();
        _getCardTasks();
        _getCardComments();
        _getBoardCardStatuses();
    }, [props]);

    useEffect(() => {
        if (!props.open) {
            setTimeout(() => {
                setValue("general");
            }, 1000);
        }
    }, [props.open]);

    function _getCardActivity() {
        if (!props.cardId) return;
        getCardActivity(props.cardId).then(res => {
            if (res?.data?.error) return;
            setCardActivities(res.data.data);
        });
    }

    function resetCardDetails() {
        setCard(undefined);
        setComments([]);
        setTrackerHistory([]);
        setControls(defControls);
        setTasks([]);
    }

    function _getBoardCardStatuses() {
        if (!props.cardId) return;
        getBoardCardStatuses().then(res => {
            if (res?.data?.error) return;
            setCardStatuses(res.data.data);
        });
    }

    function _getBoardCardTrackerHistory() {
        if (!props.cardId) return;
        getBoardCardTrackerHistory(props.cardId).then(res => {
            if (res?.data?.error) return;
            setTrackerHistory(res.data.data);
        })
    }

    function _getCardTasks() {
        if (!props.cardId) return;
        getCardTasks(props.cardId).then(res => {
            if (res?.data?.error) return;
            setTasks(res.data.data);
        });
    }

    function _getCardComments() {
        if (!props?.cardId) return;
        getCardComments(props.cardId).then(res => {
            if (res?.data?.error) return;
            setComments(res.data.data.sort(sortByDate));
        });
    }

    function _getCardDetails() {
        if (!props.cardId) return;
        getCardDetails(props.cardId).then(res => {
            if (res?.data?.error) return;
            setCard(res.data.data);
        });
    }

    function _getBoardMembers() {
        if (!id) return;
        getBoardMembers(id).then(res => {
            if (res?.data?.error) return;
            setBoardMembers(res.data.data);
        });
    }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue as any);
    };

    function sortByDate(a: IBoardCardComment, b: IBoardCardComment) {
        if (new Date(a.date) > new Date(b.date)) return -1;
        if (new Date(a.date) < new Date(b.date)) return 1;
        return 0;
    }

    function handleDeleteCard() {
        if (!card?.id) return;
        deleteBoardCard(card.id).then(res => {
            if (!res?.data?.error) return;
            props.onClose();
        });
    }

    function handleChangeDueDate(date: Date | null, force: boolean = false) {
        setOpenDueDate(false);
        setAnchorEl(null);
        // @ts-ignore
        setCard({...card, dueDate: date?.toISOString() ?? undefined});
        if (!force) {
            if (!date || !card?.id) {
                return;
            }
        } else if (!card?.id) return;
        updateCardDueDate(card.id, date?.toISOString() ?? null).then(res => {
            if (res?.data?.error) return;
            _getCardDetails();
        });
    }

    return (
        <Dialog
            open={props.open}
            onClose={() => {
                props.refreshBoard();
                props.onClose();
            }}
            maxWidth={false}
        >
            <DialogContent className="flex"
                           style={{backgroundColor: theme.palette.primary.main}}
            >
                <div className="w-[80rem] m-0 p-0">
                    <RbTabs variant="fullWidth" value={value} onChange={handleChange}>
                        <Tab value="general" label="General"/>
                        <Tab value="activity" label="Card activity"/>
                        <Tab value="history" label="Tracker history"/>
                    </RbTabs>

                    <TabContext value={value}>
                        <TabPanel value={"general"} className="overflow-auto h-[90%] pl-0 pr-10">
                            <BoardCardHeadControls card={card} statuses={cardStatuses} refreshCard={_getCardDetails}/>

                            <RbSubSectionItem>
                                <div className="flex">
                                    <BoardCardName card={card} refreshCard={_getCardDetails}/>
                                    {
                                        (!!card?.dueDate || controls.dueDate) ?
                                            <DesktopDatePicker
                                                open={openDueDate}
                                                label="Due date"
                                                inputFormat="dd.MM.yyyy"
                                                value={card?.dueDate}
                                                onChange={val => handleChangeDueDate(val as any)}
                                                renderInput={(params) => <TextField {...params}
                                                                                    size="small"
                                                                                    className="w-208 ml-10"
                                                                                    color="primary"
                                                                                    onClick={e => setAnchorEl(e.currentTarget)}/>}/> : <></>
                                    }
                                </div>
                                <Menu
                                    id="due-menu"
                                    anchorEl={anchorEl}
                                    open={!!anchorEl}
                                    onClose={e => {
                                        // @ts-ignore
                                        e?.preventDefault();
                                        // @ts-ignore
                                        e?.stopPropagation();
                                        setAnchorEl(null);
                                    }}
                                >
                                    <MenuItem className="flex items-center"
                                              onClick={() => setOpenDueDate(true)}>
                                        <CalendarIcon className="w-20 mr-10"/>
                                        <span>Set due date</span>
                                    </MenuItem>
                                    <MenuItem className="flex items-center"
                                              onClick={() => handleChangeDueDate(null, true)}
                                              style={{color: theme.palette.warning.main}}>
                                        <MinusCircleIcon className="w-20 mr-10"/>
                                        <span>Clear</span>
                                    </MenuItem>
                                </Menu>
                            </RbSubSectionItem>

                            <RbSubSectionItem>
                                <BoardCardDescription card={card} refreshCard={_getCardDetails}/>
                            </RbSubSectionItem>

                            {
                                ((card?.members ?? []).length > 0 || (card?.labels ?? []).length > 0 || controls.members || controls.labels) ?
                                    <RbSubSection className="flex">
                                        {
                                            (card?.members ?? []).length > 0 || controls.members ?
                                                <div className="w-1/2">
                                                    <BoardCardMembers card={card} members={boardMembers}
                                                                      refreshCard={_getCardDetails}/>
                                                </div> : <></>
                                        }
                                        {
                                            (card?.labels ?? []).length > 0 || controls.labels ?
                                                <div className="w-1/2">
                                                    <BoardCardLabels card={card} labels={props.labels}
                                                                     refreshCard={_getCardDetails}/>
                                                </div> : <></>
                                        }
                                    </RbSubSection> : <></>
                            }

                            {
                                controls.reminder || controls.etc || card?.estimatedTime ?
                                    <RbSubSection className="flex">
                                        {
                                            controls.reminder ?
                                                <div className="w-1/2">
                                                    <BoardCardReminder card={card}/>
                                                </div> : <></>
                                        }
                                        {
                                            card?.estimatedTime || controls.etc ?
                                                <div className="w-1/2">
                                                    <BoardCardETC card={card} refreshCard={_getCardDetails}/>
                                                </div> : <></>
                                        }
                                    </RbSubSection> : <></>
                            }

                            {
                                card?.blocker || card?.parent || controls.parent || controls.blockedBy ?
                                    <RbSubSection className="flex">
                                        {
                                            card?.parent || controls.parent ?
                                                <div className="w-1/2">
                                                    <BoardCardParent card={card} refreshCard={_getCardDetails}
                                                                     cards={props.cards}/>
                                                </div> : <></>
                                        }
                                        {
                                            card?.blocker || controls.blockedBy ?
                                                <div className="w-1/2">
                                                    <BoardCardBlockedBy card={card} refreshCard={_getCardDetails}
                                                                        cards={props.cards}/>
                                                </div> : <></>
                                        }
                                    </RbSubSection> : <></>
                            }


                            {
                                controls.attachments ?
                                    <RbSubSection>
                                        <BoardCardAttachments card={card}/>
                                    </RbSubSection> : <></>
                            }

                            {
                                tasks.length > 0 || controls.checkList ?
                                    <RbSubSection>
                                        <BoardCardTasks card={card} tasks={tasks} refreshTasks={_getCardTasks}/>
                                    </RbSubSection> : <></>
                            }

                            <RbSubSection>
                                <BoardCardComments card={card} comments={comments} refreshComments={_getCardComments}/>
                            </RbSubSection>
                        </TabPanel>
                        <TabPanel value={"activity"}>
                            <BoardCardActivities activities={cardActivities}></BoardCardActivities>
                        </TabPanel>
                        <TabPanel value={"history"} className="p-0">
                            <BoardTrackerHistory trackerHistory={trackerHistory}/>
                        </TabPanel>
                    </TabContext>
                </div>
                <RbBox theme="secondary"
                       className="w-48 px-5 py-10 rounded-2xl ml-20 flex flex-col justify-between max-h-[45rem]">
                    <IconButton onClick={() => props.onClose()}>
                        <Tooltip title="Close" placement="right">
                            <XIcon className="w-20"/>
                        </Tooltip>
                    </IconButton>
                    <IconButton disabled={!!card?.dueDate}
                                onClick={() => setControls({...controls, dueDate: !controls.dueDate})}>
                        <Tooltip title="Due date" placement="right">
                            <CalendarIcon className="w-20"/>
                        </Tooltip>
                    </IconButton>
                    <IconButton disabled={(card?.members ?? []).length > 0}
                                onClick={() => setControls({...controls, members: !controls.members})}>
                        <Tooltip title="Members" placement="right">
                            <UserCircleIcon className="w-20"/>
                        </Tooltip>
                    </IconButton>
                    <IconButton disabled={(card?.labels ?? []).length > 0}
                                onClick={() => setControls({...controls, labels: !controls.labels})}>
                        <Tooltip title="Labels" placement="right">
                            <TagIcon className="w-20"/>
                        </Tooltip>
                    </IconButton>
                    <IconButton disabled={!!card?.estimatedTime}
                                onClick={() => setControls({...controls, etc: !controls.etc})}>
                        <Tooltip title="ETC" placement="right">
                        <span className="w-20">
                            <HeroIcon icon="ClockIcon" outline={true}/>
                        </span>
                        </Tooltip>
                    </IconButton>
                    <IconButton disabled={false}
                                onClick={() => setControls({...controls, reminder: !controls.reminder})}>
                        <Tooltip title="Reminder" placement="right">
                        <span className="w-20">
                            <HeroIcon icon="ClockIcon" outline={false}/>
                        </span>
                        </Tooltip>
                    </IconButton>
                    <IconButton disabled={tasks.length > 0}
                                onClick={() => setControls({...controls, checkList: !controls.checkList})}>
                        <Tooltip title="Tasks" placement="right">
                            <ClipboardIcon className="w-20"/>
                        </Tooltip>
                    </IconButton>
                    <IconButton disabled={!!card?.parent}
                                onClick={() => setControls({...controls, parent: !controls.parent})}>
                        <Tooltip title="Parent card" placement="right">
                            <UsersIcon className="w-20"/>
                        </Tooltip>
                    </IconButton>
                    <IconButton disabled={!!card?.blocker}
                                onClick={() => setControls({...controls, blockedBy: !controls.blockedBy})}>
                        <Tooltip title="Blocked by card" placement="right">
                            <MinusCircleIcon className="w-20"/>
                        </Tooltip>
                    </IconButton>
                    <IconButton disabled={false}
                                onClick={() => setControls({...controls, attachments: !controls.attachments})}>
                        <Tooltip title="Attachments" placement="right">
                            <PaperClipIcon className="w-20"/>
                        </Tooltip>
                    </IconButton>
                    <IconButton onClick={e => setAnchorElDelete(e.currentTarget)}>
                        <DotsHorizontalIcon className="w-20"/>
                    </IconButton>
                </RbBox>

                <Menu
                    id="delete-menu"
                    anchorEl={anchorElDelete}
                    open={!!anchorElDelete}
                    onClose={e => {
                        // @ts-ignore
                        e.stopPropagation();
                        setAnchorElDelete(null);
                    }}>
                    <div className="flex mx-5">
                        <RbRoundButton className="flex rounded-6 w-full" onClick={handleDeleteCard}
                                       style={{color: theme.priority.high}}><TrashIcon className="w-20 mr-10"/>
                            <span>Delete card</span>
                        </RbRoundButton>
                    </div>
                </Menu>
            </DialogContent>
        </Dialog>
    )
}
