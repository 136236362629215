import {IconButton, Menu, MenuItem} from "@mui/material";
import {CheckCircleIcon, DotsVerticalIcon, EyeOffIcon, TrashIcon} from "@heroicons/react/outline";
import React, {useState} from "react";
import RbColorMenu from "../../../shared-components/rb-components/RbColorMenu/RbColorMenu";
import {
    deleteBoardList,
    markBoardListAsDoneUnDone,
    showBoardCardsInTracker
} from "../../../services/boards/boardsService";

export default function BoardListActions(props: { list: { id: string } | any, refreshBoards: () => void }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


    function handleColorChange(color: string) {
        console.log(color);
        setAnchorEl(null);
    }

    function handleMarkBoardAsDone() {
        if (!props?.list?.id) return;
        markBoardListAsDoneUnDone(props.list.id).then();
    }

    function handleShowBoardCardsInTracker() {
        if (!props?.list?.id) return;
        showBoardCardsInTracker(props.list.id).then();
    }

    function handleDeleteBoardList() {
        if (!props?.list?.id) return;
        deleteBoardList(props.list.id).then(res => {
            if (res?.data?.error) return;
            props.refreshBoards();
        });
    }

    return (
        <>
            <IconButton onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setAnchorEl(e.currentTarget);
            }} className="p-5">
                <DotsVerticalIcon className="w-16"/>
            </IconButton>
            <Menu
                id="board-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={e => {
                    // @ts-ignore
                    e?.preventDefault();
                    // @ts-ignore
                    e?.stopPropagation();
                    setAnchorEl(null);
                }}
            >
                <div style={{backgroundColor: ""}}>
                    <RbColorMenu className="m-auto p-5" colorClassName="m-4"
                                 onSelect={color => handleColorChange(color)}/>
                    <hr className="m-5 ml-10 mr-10"/>
                    <MenuItem className="flex justify-between" onClick={handleMarkBoardAsDone}>
                        <span className="inline-block align-middle">Mark all cards as done</span>
                        <span className="inline-block align-middle"><CheckCircleIcon className="w-20 ml-10"/></span>
                    </MenuItem>
                    <MenuItem className="flex justify-between" onClick={handleShowBoardCardsInTracker}>
                        <span className="inline-block align-middle">Show cards in tracker</span>
                        <span className="inline-block align-middle"><EyeOffIcon className="w-20 ml-10"/></span>
                    </MenuItem>
                    <MenuItem style={{color: "firebrick"}} className="flex justify-between"
                              onClick={handleDeleteBoardList}>
                        <span className="inline-block align-middle">Delete list</span>
                        <span className="inline-block align-middle"><TrashIcon className="w-20 ml-10"/></span>
                    </MenuItem>
                </div>
            </Menu>
        </>
    )
}
