import {IOrganizationBoard} from "../../types/board-types";
import {RbAccordion} from "../todo/category/CategorySection";
import BoardTile from "./board-components/BoardTile";
import {styled} from "@mui/material/styles";
import {AccordionDetails, AccordionSummary, AccordionSummaryProps, IconButton} from "@mui/material";
import BoardGrouping, {BoardGroup} from "./board-components/BoardGrouping";
import {useState} from "react";
import BoardBar from "./board-components/BoardBar";
import {Link} from "react-router-dom";

const RbAccordionDetails = styled(AccordionDetails)(() => ({
    padding: "0",
}));

const RbAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary
        {...props}
    />
))(() => ({
    '& .MuiAccordionSummary-content': {
        alignItems: "center",
    },
}));

export default function BoardCard(props: { boards: IOrganizationBoard[], title: string, hideGrouping?: boolean, boardType?: "tile" | "bar", refreshBoards: () => void }) {
    const [groupType, setGroupType] = useState<BoardGroup>("tile");

    function boardGroupingChange(type: BoardGroup) {
        if (type === groupType) return;
        setGroupType(type);
    }

    return (
        <RbAccordion defaultExpanded={true}>
            <RbAccordionSummary className="flex items-center justify-between">
                <h3 className="whitespace-nowrap w-fit mr-20">{props.title}</h3>
                <hr className="w-full"/>
                {
                    !props.hideGrouping && <BoardGrouping type={groupType} onChange={boardGroupingChange}/>
                }
            </RbAccordionSummary>
            <RbAccordionDetails>
                {
                    props.boards.map(board => {
                        return (
                            <Link style={{color: "initial", textDecoration: "none"}}
                                  className={board?.archived ? "opacity-70" : ""} key={board.id}
                                  to={`/board-view/${board.id}`}>
                                {
                                    (props?.boardType ?? groupType) === "tile" ?
                                        <BoardTile board={board} refreshBoards={props.refreshBoards}/>
                                        :
                                        <BoardBar board={board} refreshBoards={props.refreshBoards}/>
                                }
                            </Link>)
                    })
                }
            </RbAccordionDetails>
        </RbAccordion>
    )
}
BoardCard.defaultProps = {
    hideGrouping: false
}
