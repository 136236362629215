import {RbDialog} from "../../../shared-components/dialogs/RbDialog";
import {DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {RbSubSection, RbSubSectionItem} from "../../../shared-components/rb-components/RbSections";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import React, {useState} from "react";
import {createNewWorkspace} from "../../../services/organizationSettingsService";

export default function OrganizationWorkspaceCreateDialog(props: { open: boolean, onClose: () => void, organizationId?: string }) {
    const [workspaceFullName, setWorkspaceFullName] = useState<string>("");
    const [workspaceName, setWorkspaceName] = useState<string>("");

    function _createNewWorkspace() {
        if (!props?.organizationId) return;
        createNewWorkspace(props.organizationId, workspaceFullName).then(res => {
            if (res?.data?.error) return;
            closeAndReset();
        });
    }

    function closeAndReset() {
        props.onClose();
        setTimeout(() => {
            setWorkspaceName("");
            setWorkspaceFullName("");
        }, 1000);
    }

    return (
        <RbDialog
            open={props.open}
            onClose={() => props.onClose()}
        >
            <DialogTitle>Create workspace</DialogTitle>
            <DialogContent>
                <RbSubSectionItem>
                    <FormControl fullWidth>
                        <RbTextField className="w-512"
                                     variant="outlined"
                                     label="Workspace full name"
                                     autoFocus={true}
                                     value={workspaceFullName}
                                     onChange={e => setWorkspaceFullName(e.target.value)}
                                     color="primary"/>
                    </FormControl>
                </RbSubSectionItem>

                {/*<RbSubSection>*/}
                {/*    <FormControl fullWidth>*/}
                {/*        <RbTextField className="w-512"*/}
                {/*                     variant="outlined"*/}
                {/*                     label="Workspace short name"*/}
                {/*                     value={workspaceName}*/}
                {/*                     onChange={e => setWorkspaceName(e.target.value)}*/}
                {/*                     color="primary"/>*/}
                {/*    </FormControl>*/}
                {/*</RbSubSection>*/}
            </DialogContent>
            <DialogActions className="mr-20 mb-20">
                <RbRoundButton className="mr-10" variant="text" onClick={closeAndReset}>Create</RbRoundButton>
                <RbRoundButton disabled={!props?.organizationId || !workspaceFullName}
                               variant="contained" onClick={_createNewWorkspace}>Create</RbRoundButton>
            </DialogActions>
        </RbDialog>
    )
}
