import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import React, {useEffect, useState} from "react";
import {SearchIcon} from "@heroicons/react/outline";
import {styled} from "@mui/system";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";
import {useNavigate, useParams} from "react-router-dom";
import {RbLink} from "app/shared-components/rb-components/RbLink";
import {checkSystemValidity, getTicketDetails} from "../../../services/ticketsService";
import jwtService from "../../../services/jwtService/jwtService";

export default function TicketsManagePreview() {
    const theme: RbTheme = useTheme();
    const {id} = useParams();
    const navigate = useNavigate();
    const [ticketID, setTicketID] = useState<string>("");
    const [validSystem, setValidSystem] = useState<boolean | null>(null);

    useEffect(() => {
        _checkSystemValidity();
    }, []);

    function _checkSystemValidity() {
        if (!id || validSystem !== null) return;
        checkSystemValidity(id).then(res => {
            if (res?.data?.error) return;
            setValidSystem(res.data);
            if (res.data === false) {
                jwtService.emit("info", "Invalid ticket system");
            }
        });
    }

    const RbSpan = styled('span')(() => ({
        '&:before': {
            content: "''",
            width: "100%",
            height: "1px",
            backgroundColor: theme.palette.text.secondary,
            position: "absolute",
            left: "0",
            top: "50%",
            zIndex: "-100"
        },
    }));

    return (
        <div className="h-full w-full flex items-center text-center min-w-400">
            <div className="m-auto">
                <h1 className="mb-20 cursor-default">Want to check the status of exiting ticket?</h1>
                <RbTextField autoFocus={true}
                             className="mb-20 rounded-[8.4rem] overflow-hidden"
                             fullWidth
                             placeholder="Enter ticket ID"
                             variant="filled"
                             hiddenLabel={true}
                             value={ticketID}
                             InputProps={{endAdornment: <SearchIcon className="w-20"/>, className: "pl-10"}}
                             onChange={e => setTicketID(e.target.value)}
                             onKeyDown={e => {
                                 if (e.key === 'Enter') {
                                     getTicketDetails(ticketID).then(res => {
                                         if (!res?.data?.error) {
                                             navigate(`/ticket/details/${ticketID}`);
                                         }
                                     })
                                 }
                             }}
                             color="primary"/>
                <RbSpan className="block relative mb-20 cursor-default">
                    <span style={{backgroundColor: theme.palette.background.paper}} className="px-10 text-11">or</span>
                </RbSpan>
                <RbLink to={`/ticket/create/${id}`}>
                    <RbRoundButton color="primary" variant="contained" disabled={validSystem !== true}>
                        Submit a ticket
                    </RbRoundButton>
                </RbLink>
            </div>
        </div>
    )
}
