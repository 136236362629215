import React, {useState} from "react";
import {createNewProject} from "../../../services/organizationSettingsService";
import {RbDialog} from "../../../shared-components/dialogs/RbDialog";
import {DialogActions, DialogContent, DialogTitle, FormControl} from "@mui/material";
import {RbSubSection, RbSubSectionItem} from "../../../shared-components/rb-components/RbSections";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";

export default function OrganizationProjectCreateDialog(props: { open: boolean, onClose: () => void, workspaceId?: string }) {
    const [projectFullName, setProjectFullName] = useState<string>("");
    const [projectName, setProjectName] = useState<string>("");

    function _createNewProject() {
        if (!props?.workspaceId) return;
        createNewProject(props.workspaceId, projectFullName).then(res => {
            if (res?.data?.error) return;
            closeAndReset();
        });
    }

    function closeAndReset() {
        props.onClose();
        setTimeout(() => {
            setProjectName("");
            setProjectFullName("");
        }, 1000);
    }

    return (
        <RbDialog
            open={props.open}
            onClose={() => props.onClose()}
        >
            <DialogTitle>Create project</DialogTitle>
            <DialogContent>
                <RbSubSectionItem>
                    <FormControl fullWidth>
                        <RbTextField className="w-512"
                                     variant="outlined"
                                     label="Project full name"
                                     autoFocus={true}
                                     value={projectFullName}
                                     onChange={e => setProjectFullName(e.target.value)}
                                     color="primary"/>
                    </FormControl>
                </RbSubSectionItem>

                {/*<RbSubSection>*/}
                {/*    <FormControl fullWidth>*/}
                {/*        <RbTextField className="w-512"*/}
                {/*                     variant="outlined"*/}
                {/*                     label="Project short name"*/}
                {/*                     value={projectName}*/}
                {/*                     onChange={e => setProjectName(e.target.value)}*/}
                {/*                     color="primary"/>*/}
                {/*    </FormControl>*/}
                {/*</RbSubSection>*/}
            </DialogContent>
            <DialogActions className="mr-20 mb-20">
                <RbRoundButton className="mr-10" variant="text" onClick={closeAndReset}>Create</RbRoundButton>
                <RbRoundButton disabled={!props.workspaceId || !projectFullName}
                               variant="contained" onClick={_createNewProject}>Create</RbRoundButton>
            </DialogActions>
        </RbDialog>
    )
}
