import {ICustomTodoList, ITodo} from "../../../types/todo-types";
import React from "react";
import {RbSubSection, RbSubSectionItem} from "app/shared-components/rb-components/RbSections";
import TodoHeader from "./task-components/TodoHeader";
import TodoMyDay from "./task-components/TodoMyDay";
import TodoName from "./task-components/TodoName";
import TodoDueDate from "./task-components/TodoDueDate";
import TodoAssigned from "./task-components/TodoAssigned";
import TodoReminder from "./task-components/TodoReminder";
import TodoRepeat from "./task-components/TodoRepeat";
import TodoAttachments from "./task-components/TodoAttachments";
import TodoNote from "./task-components/TodoNote";
import TodoParentList from "./task-components/TodoParentList";

export default function TaskSideDetails(props: { todo: ITodo | undefined, refreshTodos: (all?: boolean) => void, closeSidebar: () => void, setTodo: React.Dispatch<ITodo | undefined>, customLists: ICustomTodoList[] }) {
    const {todo} = props;

    return (
        <div className="p-20 w-400">
            <TodoHeader todo={todo} refreshTodos={props.refreshTodos} closeSidebar={props.closeSidebar}/>

            <hr/>

            <RbSubSectionItem className="flex justify-between items-center mb-20 mt-16">
                <TodoMyDay todo={todo} refreshTodos={props.refreshTodos}/>
            </RbSubSectionItem>

            <RbSubSectionItem>
                <TodoName todo={todo} refreshTodos={props.refreshTodos} setTodo={props.setTodo}/>
            </RbSubSectionItem>

            <RbSubSection>
                <TodoParentList lists={props.customLists} todo={todo} setTodo={props.setTodo}
                                refreshTodos={props.refreshTodos}/>
            </RbSubSection>

            <RbSubSection className="mt-24">
                <div className="inline-block w-1/2">
                    <TodoDueDate todo={todo} refreshTodos={props.refreshTodos} setTodo={props.setTodo}/>
                </div>

                <div className="inline-block w-1/2">
                    <TodoAssigned todo={todo} refreshTodos={props.refreshTodos} setTodo={props.setTodo}/>
                </div>
            </RbSubSection>

            <RbSubSection className="mt-24">
                <div className="inline-block w-1/2">
                    <TodoReminder todo={todo}/>
                </div>

                <div className="inline-block w-1/2">
                    <TodoRepeat todo={todo}/>
                </div>
            </RbSubSection>

            <RbSubSection className="mt-24">
                <TodoAttachments todo={todo}/>
            </RbSubSection>

            <RbSubSection className="mb-52">
                <TodoNote todo={todo} refreshTodos={props.refreshTodos}/>
            </RbSubSection>
        </div>
    )
}
