import {styled} from "@mui/system";
import Tabs from "@mui/material/Tabs";

export const RbTabs = styled(Tabs)(({theme}) => ({
    '& button.MuiTab-textColorPrimary.Mui-selected': {
        color: "inherit"
    },
    '& button': {
        padding: 0,
        textTransform: "capitalize"
    },
    '& .MuiTabs-indicator': {
        backgroundColor: theme.palette.mode === "light" ? "#18171B" : "#ffffff"
    }
}));
