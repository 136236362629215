import {RbRoundButton} from "../../shared-components/rb-components/RbRoundButton";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import withRouter from "../../../@fuse/core/withRouter";
import {IOrganizationUser} from "../../types/organization-settings-types";
import {getOrganizationTicketSystems} from "../../services/ticketsService";
import {IOrganizationTicketSystem} from "../../types/tickets-types";
import RbText from "../../shared-components/rb-components/RbText";
import {formatToEUDate} from "../../utils/utils";
import RbBox from "../../shared-components/rb-components/RbBox";
import TicketsSystemDialog from "./dialogs/TicketsSystemDialog";
import {RbLink} from "app/shared-components/rb-components/RbLink";

function TicketSystems(props: { organization?: IOrganizationUser }) {
    const [ticketSystems, setTicketSystems] = useState<IOrganizationTicketSystem[]>([]);
    const [openDialog, setOpenDialog] = useState<boolean>(false)

    useEffect(() => {
        _getOrganizationTickets();
    }, [props.organization?.id]);

    function _getOrganizationTickets() {
        if (!props.organization?.id) return;
        getOrganizationTicketSystems(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setTicketSystems(res.data.data);
        });
    }

    return (
        <div className="px-24 py-24">
            <div className="flex justify-end mb-24">
                <RbRoundButton color="primary" variant="contained"
                               onClick={() => setOpenDialog(true)}>+ Create ticket system</RbRoundButton>
            </div>
            <div>
                {
                    ticketSystems.map((t, i) => {
                        return (
                            <RbLink key={i} to={`/ticket-systems/tickets/${t.id}`}
                                    className={!t.active ? "opacity-50" : ""}>
                                <RbBox className="mb-16 p-12 w-full">
                                    <div className="flex justify-between mb-5">
                                        <span>#{t.id}</span>
                                        <span><RbText theme="secondary">Created by: </RbText>{t.user.name}</span>
                                    </div>
                                    <div className="flex justify-between">
                                        <span className="text-base font-semibold">{t.name}</span>
                                        <span>
                                        <RbText theme="secondary">Date created: </RbText>
                                            {new Date(t.createdAt).toLocaleDateString("de-DE")}</span>
                                    </div>
                                </RbBox>
                            </RbLink>
                        )
                    })
                }
            </div>
            <TicketsSystemDialog open={openDialog} onClose={() => setOpenDialog(false)}
                                 organizationId={props.organization?.id} newCreated={_getOrganizationTickets}/>
        </div>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value
    }
}

export default withRouter(connect(mapStateToProps)(TicketSystems));
