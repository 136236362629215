import {IOrganizationBoard} from "../../../types/board-types";
import HeroIcon from "../../../../@fuse/core/HeroIcon/HeroIcon";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {RbTheme, UserOrganization} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {Avatar, IconButton, Tooltip} from "@mui/material";
import {getUserInitials} from "../../../utils/RbUtils";
import {RbAvatarGroup} from "app/shared-components/rb-components/RbAvatarGroup";
import {toggleBoardFavorite} from "../../../services/boards/boardsService";
import moment from "moment";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";

function BoardTile(props: { board: IOrganizationBoard, className?: string, refreshBoards: () => void, organization: UserOrganization }) {
    const theme: RbTheme = useTheme();

    function handleFavoriteBoardChange(e: any) {
        e.preventDefault();
        e.stopPropagation();
        if (!props?.board?.id || !props?.organization?.id) return;
        toggleBoardFavorite(props.organization.id, props.board.id).then(res => {
            if (res?.data?.error) return;
            props.refreshBoards();
        })
    }

    return (
        <RbBox
            className="p-20 cursor-pointer rounded-16 m-14 overflow-hidden hover:shadow-2 shadow-sm transition-shadow shadow-sm sm:w-[calc(100%-2.8rem)] md:w-[calc(33%-2.8rem)] lg:w-[calc(20%-2.8rem)] xl:w-[calc(16.6%-2.8rem)] h-224 inline-block align-top">
            <div className="tile-content flex flex-col justify-between h-full">
                <div>
                    <IconButton className="p-6" onClick={handleFavoriteBoardChange}>
                        <span className="w-20" style={{color: props?.board?.isFavorite ? "gold" : "inherit"}}>
                            <HeroIcon icon="StarIcon" outline={!props?.board?.isFavorite}/>
                        </span>
                    </IconButton>
                    <h3 className="mt-10 overflow-hidden"
                        style={{color: theme.palette.text.primary}}>{props?.board?.boardName ?? "Board name"}</h3>
                    <p className="font-bold" style={{color: theme.palette.special}}>{props?.board?.projectName ?? "Project name"}</p>
                </div>
                <div>
                    <hr className="mb-10 mt-10"/>
                    <RbAvatarGroup className="mb-10">
                        {
                            (props?.board?.members ?? []).map((user, i) => {
                                return (
                                    <Tooltip title={user?.name ?? ""} key={i}>
                                        <Avatar key={i} src={user?.avatar}
                                                alt={user.name}>{getUserInitials(user.name)}</Avatar>
                                    </Tooltip>
                                )
                            })
                        }
                    </RbAvatarGroup>
                    <span className="text-xs">
                        <span className="font-bold" style={{color: theme.palette.special}}>Edited: </span>
                        <span
                            style={{color: theme.palette.text.primary}}>{moment(props.board.lastModified).fromNow()}</span>
                    </span>
                </div>
            </div>
        </RbBox>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(BoardTile));
