import {IProjectOverviewTrackers} from "../../../types/project-overview-types";
import ProjectOverviewTrackerTable from "./ProjectOverviewTrackerTable";
import ProjectOverviewTrackersByUserAndCategory from "./ProjectOverviewTrackersByUserAndCategory";
import ProjectOverviewTrackerTasks from "./ProjectOverviewTrackerTasks";

export default function ProjectOverviewTrackers(props: { data: IProjectOverviewTrackers | undefined }) {
    return (
        <div>
            <ProjectOverviewTrackerTable data={props.data?.trackers ?? []}/>
            <ProjectOverviewTrackerTasks data={props.data?.categoryDistribution ?? []}/>
            <ProjectOverviewTrackersByUserAndCategory data={props.data?.userAndCategoryDistribution ?? []}/>
        </div>
    )
}
