import {_delete, _get, _patch, _post} from "../apiService";
import {NotificationType} from "../../types/user-settings-types";

export const getUserInfo = () => {
    return _get(`user/user-info`);
}

export const getWorkingDays = (organizationId: string) => {
    return _get(`user/${organizationId}/working-days`);
}

export const toggleWorkDaySetting = (orgId: string, dayId: number) => {
    return _patch(`user/${orgId}/update-working-day/${dayId}`, null);
}

export const getUserNotificationSettings = () => {
    return _get(`user/notification-settings`);
}

export const toggleUserNotificationSetting = (notifId: number, channelType: NotificationType) => {
    return _post(`user/update-notification/${notifId}/${channelType}`, null);
}

export const getAllUserOrganizationInvites = () => {
    return _get(`user/invitations`);
}

export const acceptUserInvitation = (organizationId: string) => {
    return _patch(`user/${organizationId}/accept-invitation`, null);
}

export const rejectUserInvitation = (organizationId: string) => {
    return _patch(`user/${organizationId}/reject-invitation`, null);
}

export const getAllUserOrganizationsInfo = () => {
    return _get(`user/organizations`);
}

export const leaveUserOrganization = (organizationId: string) => {
    return _patch(`user/${organizationId}/leave-organization`, null);
}
