import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    AccordionSummaryProps, Avatar, AvatarGroup,
    IconButton, Tooltip,
    Typography
} from "@mui/material";
import {IBoard, IBoardCard, IBoardLabel, IBoardMember} from "../../../types/board-types";
import {styled, useTheme} from "@mui/material/styles";
import {DotsVerticalIcon, PlusCircleIcon} from "@heroicons/react/outline";
import RbText from "../../../shared-components/rb-components/RbText";
import moment from "moment";
import {getUserInitials} from "../../../utils/RbUtils";
import React from "react";
import {formatToEUDate} from "../../../utils/utils";
import RbContentEditable from "../../../shared-components/rb-components/RbContentEditable";
import RbBox from "../../../shared-components/rb-components/RbBox";
import BoardListActions from "./BoardListActions";
import {Draggable, Droppable} from "react-beautiful-dnd";
import clsx from "clsx";

export default function BoardsListView(props: { filterUserId: string, board: IBoard, getBoardDetails: () => void, setCardIdDialog: React.Dispatch<string>, setCardIdDetail: (id: string) => void, setAnchorEl: React.Dispatch<SVGSVGElement | HTMLElement | null>, createBoardCard: (listId: string, name: string) => void, getCardFooterIcons: (card: IBoardCard, full: boolean) => JSX.Element, getBoardMember: (user: string) => IBoardMember | undefined, getBoardLabel: (lbl: string) => IBoardLabel | undefined }) {
    const {board} = props;
    const theme = useTheme();

    const RbAccordionSummary = styled((props: AccordionSummaryProps) => (
        <AccordionSummary
            {...props}
        />
    ))(() => ({
        '&': {
            minHeight: "32px !important",
            overflow: "hidden"
        },
        '& .MuiAccordionSummary-content': {
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
            margin: "1rem 0",
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: "0",
        }
    }));

    function getDisplayLabels(labels: string[]): JSX.Element[] {
        const lbls: IBoardLabel[] = [];
        labels.forEach(lbl => {
            const tmp = props.getBoardLabel(lbl);
            if (tmp) {
                lbls.push(tmp);
            }
        });

        let tooltip = "";
        let ret: JSX.Element[] = [];
        for (let i = 0; i < lbls.length; i++) {
            if (i > 1) {
                tooltip += `\n${lbls[i]?.name}`;
            } else {
                ret.push(
                    <span
                        key={lbls[i].id}
                        style={{backgroundColor: lbls[i]?.color ?? ""}}
                        className="inline-block align-middle mx-5 rounded-full p-5 pl-16 pr-16">{lbls[i]?.name}</span>
                )
            }
        }
        if (labels.length > 2) {
            ret.push(
                <Tooltip title={tooltip}>
                <span key="extra-lbl"
                      className="inline-block bg-black text-white align-middle rounded-full p-5 pl-16 pr-16">
                    +{labels.length - 2}</span>
                </Tooltip>
            )
        }
        return ret;
    }

    function getDueDate(dueDate?: string): JSX.Element {
        if (!dueDate) return <></>;
        return <span key="extra-lbl"
                     style={{backgroundColor: "#FCA5A5", color: "#991B1B"}}
                     className="inline-block align-middle rounded-full p-5 pl-16 pr-16">
                    Due: {formatToEUDate(dueDate)}</span>
    }

    return (
        <>
            <Droppable droppableId="all-columns" key="all-columns" type="column" direction="vertical">
                {
                    (providedRowDrop) => (
                        <div {...providedRowDrop.droppableProps} ref={providedRowDrop.innerRef}>
                            {
                                (board?.lists ?? []).map((list, index) => {
                                    return (
                                        <Draggable draggableId={list.id} index={index} key={list.id}>
                                            {
                                                (providedRowDrag) => (
                                                    <div key={list.id}
                                                         className="h-fit mb-32"
                                                         {...providedRowDrag.draggableProps}
                                                         ref={providedRowDrag.innerRef}>
                                                        <Accordion defaultExpanded={true}
                                                                   style={{
                                                                       borderRadius: "1rem 1rem 0 0",
                                                                       overflow: "hidden"
                                                                   }} className="mb-10">
                                                            <RbAccordionSummary
                                                                style={{backgroundColor: list.color}} {...providedRowDrag.dragHandleProps}>
                                                                <RbContentEditable
                                                                    onConfirm={value => console.log(value)}
                                                                    defaultValue={list?.name}>
                                                                <span
                                                                    className="text-black font-bold">{`${list?.name} (${list?.cards?.length ?? 0})`}</span>
                                                                </RbContentEditable>
                                                                <BoardListActions list={list}
                                                                                  refreshBoards={props.getBoardDetails}/>
                                                            </RbAccordionSummary>
                                                            <AccordionDetails
                                                                style={{backgroundColor: theme.palette.secondary.main}}>
                                                                <div>
                                                                    <RbContentEditable className="h-[4.1rem] pl-10"
                                                                                       childClassName="w-full"
                                                                                       style={{backgroundColor: theme.palette.primary.main}}
                                                                                       onConfirm={value => props.createBoardCard(list.id, value)}>
                                                                        <button
                                                                            style={{backgroundColor: theme.palette.primary.main}}
                                                                            className="rounded-6 p-10 w-full">
                                                                            <PlusCircleIcon
                                                                                className="mr-10 inline-block align-middle w-20"/>
                                                                            <span className="inline-block align-middle">Add a card</span>
                                                                        </button>
                                                                    </RbContentEditable>
                                                                </div>
                                                                <Droppable droppableId={list.id} key={list.id}
                                                                           type="card">
                                                                    {
                                                                        (providedDrop) => (
                                                                            <div
                                                                                key={list.id}
                                                                                {...providedDrop.droppableProps}
                                                                                ref={providedDrop.innerRef}
                                                                            >
                                                                                {
                                                                                    ((props.filterUserId ? list.cards.filter(c => c.members.some(u => u === props.filterUserId)) : list?.cards ?? [])).map((card, i) => {
                                                                                        return (
                                                                                            <Draggable
                                                                                                draggableId={card.id}
                                                                                                index={i} key={card.id}>
                                                                                                {
                                                                                                    (providedDrag) => (
                                                                                                        <div
                                                                                                            key={card.id}
                                                                                                            {...providedDrag.draggableProps}
                                                                                                            {...providedDrag.dragHandleProps}
                                                                                                            ref={providedDrag.innerRef}>
                                                                                                            <RbBox
                                                                                                                className={clsx("flex cursor-pointer justify-between mt-8 py-10", card.statusId === 1 ? "task-done" : "")}
                                                                                                                onClick={() => props.setCardIdDialog(card.id)}>
                                                                                                                <div
                                                                                                                    className="flex items-center">
                                                                                                                <span
                                                                                                                    className="w-32 mr-16 flex items-center">{`#${card.number}`}</span>
                                                                                                                    <span
                                                                                                                        className="w-84 mr-16 flex items-center">{moment(card.dateInsert).fromNow()}</span>
                                                                                                                </div>
                                                                                                                <span
                                                                                                                    className="w-full font-bold overflow-ellipsis flex items-center">{card.title}</span>
                                                                                                                <div
                                                                                                                    className="flex justify-center items-center">
                                                                                                                    <AvatarGroup
                                                                                                                        max={2}
                                                                                                                        className="w-64 items-end">
                                                                                                                        {
                                                                                                                            card.members.map(user => {
                                                                                                                                return <Avatar
                                                                                                                                    sx={{
                                                                                                                                        width: 24,
                                                                                                                                        height: 24
                                                                                                                                    }}
                                                                                                                                    key={user}
                                                                                                                                    className="text-12"
                                                                                                                                    src={props.getBoardMember(user)?.avatar ?? ""}
                                                                                                                                    alt={user}>{getUserInitials(props.getBoardMember(user)?.name)}</Avatar>
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </AvatarGroup>
                                                                                                                    <span
                                                                                                                        className="w-128 mr-10">{getDisplayLabels(card.labels)}</span>
                                                                                                                    <span
                                                                                                                        className="w-128 mr-10">{getDueDate(card.dueDate)}</span>
                                                                                                                    <span
                                                                                                                        className="flex items-center justify-end w-136">
                                                                                                                    {props.getCardFooterIcons(card, true)}
                                                                                                                        <IconButton
                                                                                                                            className="p-5"
                                                                                                                            onClick={e => {
                                                                                                                                e.preventDefault();
                                                                                                                                e.stopPropagation();
                                                                                                                                props.setCardIdDetail(card.id);
                                                                                                                                props.setAnchorEl(e.currentTarget);
                                                                                                                            }}>
                                                                                                                        <DotsVerticalIcon
                                                                                                                            className="w-16"/>
                                                                                                                    </IconButton>
                                                                                                                </span>
                                                                                                                </div>
                                                                                                                {providedDrop.placeholder}
                                                                                                            </RbBox>
                                                                                                        </div>
                                                                                                    )
                                                                                                }
                                                                                            </Draggable>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                </Droppable>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                )
                                            }
                                        </Draggable>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </Droppable>
        </>
    )
}
