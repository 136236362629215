import ProjectOverview from "./ProjectOverview";

const ProjectOverviewConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'project-overview',
            element: <ProjectOverview/>,
        },
    ],
};

export default ProjectOverviewConfig;
