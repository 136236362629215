import List from '@mui/material/List';
import {styled} from '@mui/material/styles';
import clsx from 'clsx';
import FuseNavItem from '../FuseNavItem';
import HelpMenu from "../../../../app/shared-components/help-menu/HelpMenu";
import SettingsPanel from "../../../../app/fuse-layouts/shared-components/SettingsPanel";
import {useRef} from "react";
import {useDispatch} from "react-redux";
import {logoutUser} from "../../../../app/auth/store/userSlice";
import {useNavigate} from "react-router-dom";

const StyledList = styled(List)(({theme}) => ({
    '& .fuse-list-item': {
        '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.04)',
        }, '&:focus:not(.active)': {
            backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0,0,0,.05)',
        },
    }, '&.active-square-list': {
        '& .fuse-list-item, & .active.fuse-list-item': {
            width: '100%', borderRadius: '0',
        },
    }, '&.dense': {
        '& .fuse-list-item': {
            paddingTop: 0, paddingBottom: 0, height: 32,
        },
    },
}));

function FuseNavVerticalLayout1(props) {
    const {navigation, active, dense, className, onItemClick} = props;
    const themePickerRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logoutItem = {
        id: 'logout', title: 'Logout', translate: 'Logout', type: 'button', icon: 'ArrowCircleLeftIcon',
    };

    function handleItemClick(item) {
        switch (item.id) {
            case 'themes':
                themePickerRef.current.openThemesPanel();
                // themePickerRef.current.openSettingsPanel();
                break;
            case 'logout':
                // dispatch(logoutUser());
                navigate("signout-oidc");
                break;
            default:
                onItemClick?.(item);
                break;
        }
    }

    return (<>
        <div className="flex justify-between flex-1 flex-col">
            <StyledList
                className={clsx('navigation whitespace-nowrap px-12', `active-${active}-list`, dense && 'dense', className)}
                color={'secondary'}
            >
                {navigation.map((_item) => (<FuseNavItem
                    key={_item.id}
                    type={`vertical-${_item.type}`}
                    item={_item}
                    nestedLevel={0}
                    onItemClick={handleItemClick}
                />))}
            </StyledList>
            <StyledList
                className={clsx('navigation whitespace-nowrap px-12', `active-${active}-list`, dense && 'dense', className)}
                color={'secondary'}
            >
                <hr className="w-11/12 m-auto"/>
                <FuseNavItem
                    key={logoutItem.id}
                    type={`vertical-${logoutItem.type}`}
                    item={logoutItem}
                    nestedLevel={0}
                    onItemClick={handleItemClick}
                />
                <HelpMenu className="help-menu-btn absolute top-[1.6rem] right-[2.5rem]"/>
            </StyledList>
        </div>
        <SettingsPanel ref={themePickerRef}/>
    </>);
}

export default FuseNavVerticalLayout1;
