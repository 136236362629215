import React from 'react';
import {FormControl, Grid, InputAdornment, MenuItem, Select, TextField} from "@mui/material";
import {ChatAltIcon} from "@heroicons/react/outline";
import {createTheme} from "@mui/material/styles";
import {ThemeProvider} from "@mui/styles";
import clsx from "clsx";

const rtlTheme = createTheme({direction: "rtl"});
const TrackerAddLg = (props: any) => {
    const {manual, description, card, label, project, projects, categories, formValues, cards} = props;

    const handleFormValueChange = (name: string) => (event: { target: { value: any; }; }) => {
        if (name === "description") {
            description(event.target.value);
        }
        if (name === "projectId") {
            project(event.target.value);
        }
        if (name === "cardId") {
            card(event.target.value);
        }
        if (name === "labelId") {
            label(event.target.value);
        }
    };
    return (
        <Grid container spacing={2}
              columns={manual ? 5 : 1}
              className={"hidden lg:flex lg:mb-12"}
        >
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <TextField
                        label="Describe what are you doing"
                        id="description"
                        onBlur={handleFormValueChange('description')}
                        className={clsx("subvariant-hovered", !!formValues.description ? "force-fieldset-border" : "")}
                        defaultValue={formValues.description}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <ChatAltIcon className={"min-w-24 max-w-24  stroke-1"}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </FormControl>
            </Grid>
            {manual && (
                <ThemeProvider theme={rtlTheme}>
                    <Grid item xs={1}>
                        <TextField
                            className={clsx("subvariant-hovered w-full", !!formValues.projectId ? "force-fieldset-border" : "")}
                            label="Project"
                            onChange={handleFormValueChange('projectId')}
                            select
                            value={formValues.projectId}
                            inputProps={{IconComponent: () => null}}
                        >
                            <MenuItem value={undefined}>Clear</MenuItem>
                            {
                                projects && projects.map((project: any, i: number) => {
                                    return <MenuItem key={i}
                                                     value={project?.id}>{project?.name}</MenuItem>
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            onChange={handleFormValueChange('cardId')}
                            className={clsx("subvariant-hovered w-full", formValues.cardId ? "force-fieldset-border" : "")}
                            label="Card"
                            select
                            value={formValues.cardId}
                            inputProps={{IconComponent: () => null}}
                        >
                            <MenuItem value={undefined}>Clear</MenuItem>
                            {
                                cards && cards.map((card: any, i: number) => {
                                    return <MenuItem key={i}
                                                     value={card?.cardId}>{card?.cardName}</MenuItem>
                                })
                            }
                        </TextField>
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            onChange={handleFormValueChange('labelId')}
                            className={clsx("subvariant-hovered w-full", formValues.labelId ? "force-fieldset-border" : "")}
                            label="Category"
                            value={formValues.labelId}
                            select
                            inputProps={{IconComponent: () => null}}
                        >
                            <MenuItem value={undefined}>Clear</MenuItem>
                            {
                                categories && categories.map((cat: any, i: number) => {
                                    return <MenuItem key={i}
                                                     value={cat?.id}>{cat?.name}</MenuItem>
                                })
                            }
                        </TextField>
                    </Grid>
                </ThemeProvider>
            )}
        </Grid>
    );
};

export default TrackerAddLg;
