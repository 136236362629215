import React, {useEffect, useState} from "react";
import {IBoardDropDown, IProjectDropDown, RbTheme, UserOrganization} from "../../types/common-types";
import {connect} from "react-redux";
import withRouter from "../../../@fuse/core/withRouter";
import {RbRoundButton} from "../../shared-components/rb-components/RbRoundButton";
import RbBox from "../../shared-components/rb-components/RbBox";
import {ChevronDownIcon} from "@heroicons/react/outline";
import {RbTextField} from "app/shared-components/rb-components/RbTextField";
import {formatToEUDate} from "../../utils/utils";
import moment from "moment";
import RbSingleSelect from "../../shared-components/rb-components/RbSingleSelect";
import {DesktopDatePicker, TabContext, TabPanel} from "@mui/lab";
import {getAllOrganizationProjects, getAllProjectBoards} from "../../services/common/common";
import {useTheme} from "@mui/material/styles";
import {
    getProjectOverview,
    getProjectOverviewBoards, getProjectOverviewMembers,
    getProjectOverviewTrackers
} from "../../services/projectOverviewService";
import {
    IProjectOverview,
    IProjectOverviewBoard,
    IProjectOverviewMember,
    IProjectOverviewTrackers
} from "../../types/project-overview-types";
import OverviewOfProject from "./overview/OverviewOfProject";
import ProjectOverviewTrackers from "./trackers/ProjectOverviewTrackers";
import ProjectOverviewByBoards from "./overview/ProjectOverviewByBoards";
import ProjectOverviewBoards from "./boards/ProjectOverviewBoards";
import ProjectOverviewMembers from "./members/ProjectOverviewMembers";
import {getLSUserData, saveLSUserData} from "../../services/storageBus";
import {formatLastTabName} from "../../utils/RbUtils";
import {TextField} from "@mui/material";

function ProjectOverview(props: { organization?: UserOrganization }) {
    const theme: RbTheme = useTheme();
    const [tab, setTab] = useState<string>("overview");
    const tabs = ["overview", "trackers", "board", "members"];
    const [dateAnchor, setDateAnchor] = useState<HTMLElement | null>(null);
    const [boardAnchor, setBoardAnchor] = useState<HTMLElement | null>(null);
    const [projectAnchor, setProjectAnchor] = useState<HTMLElement | null>(null);
    const [project, setProject] = useState<IProjectDropDown>();
    const [projects, setProjects] = useState<IProjectDropDown[]>([]);
    const dateValues = [{id: 0, name: "Today"}, {id: 1, name: "This week"}, {id: 2, name: "This month"},
        {id: 3, name: "Last month"}, {id: 4, name: "Last 2 months"}, {id: 5, name: "Last 3 months"},
        {id: 6, name: "Custom"}];
    const [projectOverview, setProjectOverview] = useState<IProjectOverview>();
    const [dateDisplay, setDateDisplay] = useState<string>("This week");
    const [dateFrom, setDateFrom] = useState<moment.Moment>(moment().startOf("week"));
    const [dateTo, setDateTo] = useState<moment.Moment>(moment().endOf("week"));
    const [boardsData, setBoardsData] = useState<IProjectOverviewBoard>();
    const [boards, setBoards] = useState<IBoardDropDown[]>([]);
    const [board, setBoard] = useState<IBoardDropDown>();
    const [membersData, setMembersData] = useState<IProjectOverviewMember[]>([]);
    const [trackersData, setTrackersData] = useState<IProjectOverviewTrackers>();
    const [openFrom, setOpenFrom] = useState<boolean>(false);
    const [openTo, setOpenTo] = useState<boolean>(false);
    const [dateFromReadonly, setDateFromReadonly] = useState<boolean>(true);
    const [dateToReadonly, setDateToReadonly] = useState<boolean>(true);

    useEffect(() => {
        setTab(getLSUserData(formatLastTabName(ProjectOverview.name)) ?? "overview");
        setProject(getLSUserData("projectOverviewProject-dd"))
    }, []);

    useEffect(() => {
        _getOrganizationProjects();
    }, [props.organization?.id]);

    useEffect(() => {
        switch (tab) {
            case "trackers":
                _getProjectOverviewTrackers();
                break;
            case "board":
                _getAllProjectBoards();
                _getProjectOverviewBoards();
                break;
            case "members":
                _getProjectOverviewMembers();
                break;
            default:
                _getProjectOverview();
                break;
        }
    }, [project?.id, dateFrom, dateTo, tab]);

    useEffect(() => {
        saveLSUserData(formatLastTabName(ProjectOverview.name), tab);
    }, [tab]);

    useEffect(() => {
        saveLSUserData("projectOverviewProject-dd", project);
    }, [project?.id]);

    useEffect(() => {
        _getProjectOverviewBoards();
    }, [board?.id]);

    function _getProjectOverviewBoards() {
        if (!project?.id || !board?.id) return;
        getProjectOverviewBoards(project.id, board.id).then(res => {
            if (res?.data?.error) return;
        });
    }

    function _getProjectOverviewMembers() {
        if (!project?.id) return;
        getProjectOverviewMembers(project?.id).then(res => {
            if (res?.data?.error) return;
            setMembersData(res.data.data);
        });
    }

    function _getAllProjectBoards() {
        if (!project?.id) return;
        getAllProjectBoards(project.id).then(res => {
            if (res?.data?.error) return;
            setBoards(res.data.data);
        });
    }

    function _getOrganizationProjects() {
        if (!props.organization?.id) return;
        getAllOrganizationProjects(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setProjects(res.data.data);
        });
    }

    function _getProjectOverviewTrackers() {
        if (!project?.id) return;
        getProjectOverviewTrackers(project.id, dateFrom.toISOString(true), dateTo.toISOString(true)).then(res => {
            if (res?.data?.error) return;
            setTrackersData(res.data.data);
        });
    }

    function _getProjectOverview() {
        if (!project?.id) return;
        getProjectOverview(project?.id, dateFrom.toISOString(true), dateTo.toISOString(true)).then(res => {
            if (res?.data?.error) return;
            setProjectOverview(res.data.data);
        });
    }

    function handleDateRange(val: { id: number, name: string }) {
        setDateDisplay(val.name);
        switch (val.id) {
            case 0:
                setDateFrom(moment());
                setDateTo(moment());
                break;
            case 1:
                setDateFrom(moment().startOf("week"));
                setDateTo(moment().endOf("week"));
                break;
            case 2:
                setDateFrom(moment().startOf("month"));
                setDateTo(moment().endOf("month"));
                break;
            case 3:
                setDateFrom(moment().subtract(1, "month").startOf("month"));
                setDateTo(moment().subtract(1, "month").endOf("month"));
                break;
            case 4:

                setDateFrom(moment().subtract(2, "month").startOf("day"));
                setDateTo(moment().endOf("day"));
                break;
            case 5:
                setDateFrom(moment().subtract(3, "month").startOf("day"));
                setDateTo(moment().endOf("day"));
                break;
            case 6:
                setDateToReadonly(false);
                setDateFromReadonly(false);
                return;
        }
        setDateToReadonly(true);
        setDateFromReadonly(true);
    }

    function showIllustration(): boolean {
        return !props?.organization?.id || !dateFrom || !dateTo || !project?.id;
    }

    function getIllustrationPath(): string {
        switch (theme.palette.name) {
            case "midnightBlue":
                return "/assets/images/illustrations/reports/reports-midnightblue.svg";
            case "darkNight":
                return "/assets/images/illustrations/reports/reports-darknight.svg";
            default:
                return "/assets/images/illustrations/reports/reports-light.svg";
        }
    }

    return (
        <div className="px-32">
            <div className="flex mt-20">
                <RbBox onClick={e => setProjectAnchor(e.currentTarget)}
                       className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-28 whitespace-nowrap overflow-hidden">
                    <span>{project?.name ?? "Project name"}</span>
                    <ChevronDownIcon className="w-20"/>
                </RbBox>
                {
                    tab === "board" &&
                    <RbBox onClick={e => setBoardAnchor(e.currentTarget)}
                           className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-28 ml-10 whitespace-nowrap overflow-hidden">
                        <span>{board?.name ?? "Board name"}</span>
                        <ChevronDownIcon className="w-20"/>
                    </RbBox>
                }
            </div>
            <div className="flex my-12">
                {
                    tabs.map(t => {
                        return <RbRoundButton key={t} className="pl-16 pr-16 mr-10 capitalize" onClick={() => setTab(t)}
                                              variant={t === tab ? "contained" : "text"}>{t}</RbRoundButton>
                    })
                }
            </div>
            <div className="flex items-center">
                <RbBox onClick={e => setDateAnchor(e.currentTarget)}
                       className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-36">
                    <span className="capitalize">{dateDisplay}</span>
                    <ChevronDownIcon className="w-20"/>
                </RbBox>
                {/*<RbTextField*/}
                {/*    fullWidth*/}
                {/*    className="w-128 ml-10"*/}
                {/*    variant="outlined"*/}
                {/*    size="small"*/}
                {/*    disabled={true}*/}
                {/*    label="From"*/}
                {/*    value={formatToEUDate(dateFrom)}*/}
                {/*    color="primary"/>*/}
                {/*<RbTextField*/}
                {/*    fullWidth*/}
                {/*    className="w-128 ml-10"*/}
                {/*    variant="outlined"*/}
                {/*    size="small"*/}
                {/*    disabled={true}*/}
                {/*    label="To"*/}
                {/*    value={formatToEUDate(dateTo)}*/}
                {/*    color="primary"/>*/}


                <DesktopDatePicker
                    open={openFrom}
                    label="Date from"
                    value={dateFrom}
                    onOpen={() => {
                        if (!dateFromReadonly) {
                            setOpenFrom(true);
                        }
                    }}
                    onClose={() => {
                        setOpenFrom(false);
                    }}
                    ignoreInvalidInputs={true}
                    disabled={dateFromReadonly}
                    inputFormat="dd.MM.yyyy"
                    onChange={(e) => setDateFrom(moment(e?.toISOString() ?? ""))}
                    renderInput={(params) => <TextField {...params}
                                                        InputProps={
                                                            {
                                                                endAdornment: null,
                                                                readOnly: true
                                                            }
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                        className="w-128 ml-10"
                                                        onClick={() => {
                                                            if (!dateFromReadonly) {
                                                                setOpenFrom(true);
                                                            }
                                                        }}
                    />}/>
                <DesktopDatePicker
                    open={openTo}
                    label="Date to"
                    value={dateTo}
                    onOpen={() => {
                        if (!dateToReadonly) {
                            setOpenTo(true);
                        }
                    }}
                    onClose={() => {
                        setOpenTo(false);
                    }}
                    ignoreInvalidInputs={true}
                    disabled={dateToReadonly}
                    inputFormat="dd.MM.yyyy"
                    onChange={(e) => setDateTo(moment(e?.toISOString() ?? ""))}
                    renderInput={(params) => <TextField {...params}
                                                        InputProps={
                                                            {
                                                                endAdornment: null,
                                                                readOnly: true
                                                            }
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                        className="w-128 ml-10"
                                                        onClick={() => {
                                                            if (!dateToReadonly) {
                                                                setOpenTo(true);
                                                            }
                                                        }}
                    />}/>

            </div>
            <RbSingleSelect items={projects} anchorEl={projectAnchor} onClose={() => setProjectAnchor(null)}
                            onSelect={p => setProject(p)} renderItem={item => item.name}/>
            <RbSingleSelect items={boards} anchorEl={boardAnchor} onClose={() => setBoardAnchor(null)}
                            onSelect={b => setBoard(b)} renderItem={item => item.name}/>
            <RbSingleSelect items={dateValues} search={false} anchorEl={dateAnchor} onClose={() => setDateAnchor(null)}
                            onSelect={handleDateRange} hasClear={false} renderItem={item => item.name}/>
            <div>
                <TabContext value={tab}>
                    <TabPanel value={"overview"} className="px-0">
                        {
                            showIllustration() ?
                                <img className="m-auto mt-[15vh]" src={getIllustrationPath()}
                                     alt="Project overview"/> :
                                <OverviewOfProject data={projectOverview}/>
                        }
                    </TabPanel>
                    <TabPanel value={"trackers"} className="px-0">
                        {
                            showIllustration() ?
                                <img className="m-auto mt-[15vh]" src={getIllustrationPath()}
                                     alt="Trackers"/> :
                                <ProjectOverviewTrackers data={trackersData}/>
                        }
                    </TabPanel>
                    <TabPanel value={"board"} className="px-0">
                        {
                            showIllustration() ?
                                <img className="m-auto mt-[15vh]" src={getIllustrationPath()}
                                     alt="Board"/> :
                                <ProjectOverviewBoards data={boardsData}/>
                        }
                    </TabPanel>
                    <TabPanel value={"members"} className="px-0">
                        {
                            showIllustration() ?
                                <img className="m-auto mt-[15vh]" src={getIllustrationPath()}
                                     alt="Members"/> :
                                <ProjectOverviewMembers data={membersData}/>
                        }
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value
    }
}

export default withRouter(connect(mapStateToProps)(ProjectOverview));

