import {
    IProjectOverviewTrackerCategory,
    IProjectOverviewTrackerUserAndCategory
} from "../../../types/project-overview-types";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {IRbDisplayHeader, RbTheme} from "../../../types/common-types";
import {styled} from "@mui/system";
import Chart from "react-apexcharts";
import {useEffect, useState} from "react";
import {RbColors} from "../../../shared-components/rb-components/RbColorMenu/RbColorMenu";
import {useTheme} from "@mui/material/styles";

const RbChart = styled(Chart)(() => ({
    '& svg': {
        backgroundColor: "transparent !important"
    }
}));

export default function ProjectOverviewBoardCategories(props: { data: IProjectOverviewTrackerUserAndCategory[] | undefined }) {
    const theme: RbTheme = useTheme();
    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "",
            objAttribute: "color",
            className: "w-32",
            handleRendering: handleColorRender,
        },
        {
            name: "Category",
            objAttribute: "categoryName",
        },
        {
            name: "Cards",
            objAttribute: "cards"
        },
        {
            name: "Duration",
            objAttribute: "duration"
        },
    ];
    const [series, setSeries] = useState<any>([]);
    const options: any = {
        chart: {
            id: "project-donut",
            pattern: {
                strokeWidth: 4
            }
        },
        labels: [],
        legend: {
            show: false
        },
        colors: RbColors,
        dataLabels: {
            enabled: false
        },
        theme: {mode: theme.palette.mode}
    };

    useEffect(() => {
        setSeries(props.data?.map(t => t.trackers) ?? []);
    }, [props.data]);

    function handleColorRender(item: IProjectOverviewTrackerCategory): JSX.Element {
        return <span className="block w-20 h-20 rounded-full"
                     style={{backgroundColor: item.color}}></span>
    }

    return (
        <div className="mt-10">
            <h2 className="mb-20">Categories</h2>
            <div className="flex justify-center">
                <RbChart options={options} series={series ?? []} type="donut" height="260" width="300"/>
                <RbDataTable dataSource={props.data ?? []} hasBackground={false} headerColumns={displayHeaders}/>
            </div>
        </div>
    )
}
