import {ITrackerByCategory} from "../../../types/report-types";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {IRbDisplayHeader} from "../../../types/common-types";
import ReportWidgetBase from "./ReportWidgetBase";

export default function ReportTrackerByCategory(props: { data?: ITrackerByCategory[] }) {

    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "",
            objAttribute: "color",
            className: "w-32",
            handleRendering: handleColorRender,
        },
        {
            name: "Category",
            objAttribute: "category"
        },
        {
            name: "Billable",
            objAttribute: "billable"
        },
        {
            name: "Billable time",
            objAttribute: "billableTime"
        },
        {
            name: "Unbillable",
            objAttribute: "unbillable"
        },
        {
            name: "Unbillable time",
            objAttribute: "unbillableTime"
        },
        {
            name: "Total tasks",
            objAttribute: "totalTrackers"
        },
        {
            name: "Total time",
            objAttribute: "totalTime"
        },
        {
            name: "Total price",
            objAttribute: "totalPrice",
            handleRendering: handleRenderTotalPrice
        },
    ];

    function sortNoName(a: ITrackerByCategory, b: ITrackerByCategory): number {
        if (a.category === "No category") return 1;
        if (b.category === "No category") return -1;
        return a.category > b.category ? 1 : -1;
    }

    function handleRenderTotalPrice(item: ITrackerByCategory): JSX.Element {
        return <span>{item.totalPrice} EUR</span>;
    }

    function handleColorRender(item: ITrackerByCategory): JSX.Element {
        return <span className="block w-20 h-20 rounded-full" style={{backgroundColor: item.color}}></span>
    }

    return (
        <ReportWidgetBase title="Tracker report by category">
            <RbDataTable hasBackground={false} dataSource={props?.data?.sort(sortNoName) ?? []} search={false}
                         headerColumns={displayHeaders}/>
        </ReportWidgetBase>
    )
}
