import React from "react";
import HeroIcon from "../../../@fuse/core/HeroIcon/HeroIcon";
import clsx from "clsx";

export default function RbIconTitle(props: { container?: string, icon: string, outline?: boolean, title: string, bold?: boolean, iconClassName?: string, className?: string }) {
    return (
        <>
            {
                React.createElement(props?.container ?? "h2", null,
                    <div className={props.className}>
                        <span className={clsx("inline-block align-middle w-20 mr-10", props?.iconClassName ?? "")}>
                            <HeroIcon icon={props.icon} outline={props?.outline ?? true}/>
                        </span>
                        <span
                            className={clsx("inline-block align-middle cursor-default", props?.bold === true && "font-bold")}>{props.title}</span>
                    </div>
                )
            }
        </>
    )
}
