import {RbCheckBoxIcon} from "../../../../shared-components/rb-components/RbCheckBoxIcon";
import HeroIcon from "../../../../../@fuse/core/HeroIcon/HeroIcon";
import {IconButton, Menu} from "@mui/material";
import {DotsVerticalIcon, TrashIcon} from "@heroicons/react/outline";
import React, {useState} from "react";
import {ITodo} from "../../../../types/todo-types";
import {deleteTodo, toggleDoneTask} from "../../../../services/todo/todoService";
import MenuItem from "@mui/material/MenuItem";
import {RbTheme} from "../../../../types/common-types";
import {useTheme} from "@mui/material/styles";

export default function TodoHeader(props: { todo: ITodo | undefined, refreshTodos: () => void, closeSidebar: () => void }) {
    const {todo} = props;
    const theme: RbTheme = useTheme();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    function _toggleDoneTask(id: string | undefined) {
        if (!id) return;
        toggleDoneTask(id).then(res => {
            if (res?.data?.error) return;
            props.refreshTodos();
        });
    }

    function _deleteTodo() {
        setAnchorEl(null);
        if (!todo?.id) return;
        deleteTodo(todo?.id).then(res => {
            if (res?.data?.error) return;
            props.closeSidebar();
            props.refreshTodos();
        });
    }

    return (
        <div className="flex justify-between items-center mb-10">
            <h3 className="mr-10">
                <RbCheckBoxIcon defaultChecked={!!todo?.isDone} onClick={() => _toggleDoneTask(todo?.id)}
                                className="w-20 mr-10 inline-block align-middle"
                                icon={<HeroIcon icon="CheckCircleIcon" outline={true}/>}
                                checkedIcon={<HeroIcon icon="CheckCircleIcon" outline={false}/>}/>
                <span className="inline-block align-middle">{todo?.isDone ? "COMPLETED" : "COMPLETE"}</span>
            </h3>
            <IconButton onClick={e => setAnchorEl(e.currentTarget)}>
                <DotsVerticalIcon className="w-20"/>
            </IconButton>
            <Menu
                id="widget-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={_deleteTodo} style={{color: theme.palette.error.dark}}>Delete
                    list <span
                        className="w-20 ml-5"><TrashIcon/></span></MenuItem>
            </Menu>
        </div>
    )
}
