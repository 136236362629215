import {IProjectOverviewByUser} from "../../../types/project-overview-types";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {IRbDisplayHeader} from "../../../types/common-types";
import RbBox from "../../../shared-components/rb-components/RbBox";

export default function ProjectOverviewByUsers(props: { data: IProjectOverviewByUser[] | undefined }) {
    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "Name",
            objAttribute: "user.name"
        },
        {
            name: "Boards",
            objAttribute: "boards"
        },
        {
            name: "Assigned tasks",
            objAttribute: "assignTasks"
        },
        {
            name: "Marked as done",
            objAttribute: "markAsDone"
        },
        {
            name: "Overdue",
            objAttribute: "overdue"
        },
        {
            name: "Total time",
            objAttribute: "totalTime"
        },
    ];
    return (
        <RbBox className="px-0 mb-10">
            <h2 className="px-16">Users</h2>
            <RbDataTable dataSource={props.data ?? []} headerColumns={displayHeaders}/>
        </RbBox>
    )
}
