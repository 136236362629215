import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useTheme} from "@mui/material/styles";


const Root = styled('div')(({theme}) => ({
    '& > .logo-icon': {
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    '& > .badge, & > .logo-text': {
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
}));

function Logo() {
    const theme = useTheme();

    return (
        <Root className="flex items-center">
            <img className="logo-icon"
                 src={theme.palette.mode === "light" ? "assets/images/logos/RubiconTrackLogoLight.svg" : "assets/images/logos/RubiconTrackLogo.svg"}
                 alt="logo"/>
        </Root>
    );
}

export default Logo;
