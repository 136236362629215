import Reports from "./Reports";

const ReportsConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'reports',
            element: <Reports/>,
        },
    ],
};

export default ReportsConfig;
