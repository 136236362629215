import RbBox from "../../../shared-components/rb-components/RbBox";
import {IRbDisplayHeader, RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {IProjectOverviewOfTracker, IProjectOverviewTrackerCategory} from "../../../types/project-overview-types";
import {useEffect, useState} from "react";
import {RbColors} from "../../../shared-components/rb-components/RbColorMenu/RbColorMenu";
import {styled} from "@mui/system";
import Chart from "react-apexcharts";
import {ITrackerByCategory} from "../../../types/report-types";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";

const RbChart = styled(Chart)(({theme}) => ({
    '& svg': {
        backgroundColor: "transparent !important",
    },
    '& #apexchartsproject-donut': {
        margin: "1rem auto"
    }
}));

export default function ProjectOverviewTrackerTasks(props: { data: IProjectOverviewTrackerCategory[] }) {
    const theme: RbTheme = useTheme();
    const [series, setSeries] = useState<any>([1, 2, 3, 4]);
    const [options, setOptions] = useState<any>({
        chart: {
            id: "project-donut",
            pattern: {
                strokeWidth: 4
            }
        },
        labels: [],
        legend: {
            show: false
        },
        colors: RbColors,
        dataLabels: {
            enabled: false
        },
        theme: {mode: theme.palette.mode}
    });

    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "",
            objAttribute: "color",
            className: "w-32",
            handleRendering: handleColorRender,
        },
        {
            name: "Category",
            objAttribute: "categoryName",
        },
        {
            name: "Cards",
            objAttribute: "cards"
        },
        {
            name: "Records",
            objAttribute: "trackers"
        },
        {
            name: "Duration",
            objAttribute: "duration"
        },
    ];

    useEffect(() => {
        setOptions({...options, colors: props.data.map(t => t.color)});
        setSeries(props.data.map(t => t.trackers))
    }, [props?.data]);

    function handleColorRender(item: IProjectOverviewTrackerCategory): JSX.Element {
        return <span className="block w-20 h-20 rounded-full"
                     style={{backgroundColor: item.color}}></span>
    }

    return (
        <div className="flex items-center">
            <div className="mr-32">
                <h2 className="mb-10">Tasks distribution</h2>
                <RbBox className="w-400 px-0 pb-0">
                <span className="my-20">
                    <RbChart options={options} series={series} type="donut" width="300"/>
                </span>
                    <div className="flex flex-col">
                        <div className="flex">
                            <div className="w-1/2 flex flex-1 pt-10 pb-16 flex-col items-center border-r-4 border-t-8"
                                 style={{borderColor: theme.palette.background.paper}}>
                                <span className="text-2xl">{props?.data?.length ?? 0}</span>
                                <span className="text-md">Records</span>
                            </div>

                            <div className="w-1/2 flex flex-1 pt-10 pb-16 flex-col items-center  border-l-4 border-t-8"
                                 style={{borderColor: theme.palette.background.paper}}>
                                <span className="text-2xl">{props.data.filter(t => !t.categoryId).length}</span>
                                <span className="text-md">Uncategorized</span>
                            </div>
                        </div>
                    </div>
                </RbBox>
            </div>
            <RbDataTable dataSource={props.data} hasBackground={false} headerColumns={displayHeaders}/>
        </div>
    )
}
