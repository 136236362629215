export enum StorageDataType {
    Organization = "organization",
    ReturnUrl = "return_url"
}

// Local storage

export const saveLSUserData = (type: StorageDataType | string, data: any): any | undefined => {
    if (!data) return;
    const tmp = JSON.stringify(data);
    localStorage.setItem(type, tmp);
}
export const getLSUserData = (type: StorageDataType | string) => {
    if (!type) return;
    const tmp = localStorage.getItem(type);
    return tmp ? JSON.parse(tmp) : undefined;
}
export const clearLSUserData = (type: StorageDataType | string) => {
    localStorage.removeItem(type);
}

// Session storage

export const saveSSUserData = (type: StorageDataType | string, data: any) => {
    const tmp = JSON.stringify(data);
    sessionStorage.setItem(type, tmp);
}
export const getSSUserData = (type: StorageDataType | string) => {
    if (!type) return;
    const tmp = sessionStorage.getItem(type);
    if (!tmp) return;
    return JSON.parse(tmp);
}
export const clearSSUserData = (type: StorageDataType | string) => {
    sessionStorage.removeItem(type);
}
