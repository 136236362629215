import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import {SnackbarProvider} from 'notistack';
import {Provider, useSelector} from 'react-redux';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';
import {selectCurrLangDir} from 'app/store/i18nSlice';
import withAppProviders from './withAppProviders';
import userManager, {loadUserFromStorage} from "./services/authService";
import AuthProvider from 'app/auth/utils/authProvider';
import {useEffect} from "react";
import store from "./store";
import SnackbarHandler from "./shared-components/rb-components/SnackbarHandler";

const emotionCacheOptions = {
    rtl: {
        key: 'muirtl',
        stylisPlugins: [rtlPlugin],
        insertionPoint: document.getElementById('emotion-insertion-point'),
    },
    ltr: {
        key: 'muiltr',
        stylisPlugins: [],
        insertionPoint: document.getElementById('emotion-insertion-point'),
    },
};


const App = () => {
    const langDirection = useSelector(selectCurrLangDir);
    // @ts-ignore

    useEffect(() => {
        // fetch current user from cookies
        loadUserFromStorage(store).then(r => r)
    }, []);

    return (
        // @ts-ignore
        <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
            <Provider store={store}>
                <AuthProvider userManager={userManager} store={store}>
                    <BrowserRouter>
                        {/*<ProtectedRoute/>*/}
                        <FuseTheme>
                            <SnackbarProvider
                                maxSnack={5}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                classes={{
                                    containerRoot: 'bottom-0 right-0 mr-12 lg:mr-48 xl:mr-48 z-99',
                                }}
                            >
                                <FuseLayout/>
                                <SnackbarHandler/>
                            </SnackbarProvider>
                        </FuseTheme>
                    </BrowserRouter>
                </AuthProvider>
            </Provider>
        </CacheProvider>
    );
};

export default withAppProviders(App)();
