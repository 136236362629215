import {IBoardCardActivity} from "../../../types/board-types";
import moment from "moment";
import React from "react";
import Avatar from "@mui/material/Avatar";
import {getUserInitials} from "../../../utils/RbUtils";

export default function BoardCardActivities(props: { activities: IBoardCardActivity[] }) {
    return (
        <div className="flex flex-col mt-14">
            {
                props.activities.map((act, i) => {
                    return (
                        <div key={i} className="flex justify-between mb-16 items-center">
                            <div className="flex items-center">
                                <Avatar src={act?.avatar ?? ""} sx={{width: 36, height: 36}} className="text-sm mr-10">
                                    {getUserInitials(act.createdBy)}
                                </Avatar>
                                <div className="flex flex-col">
                                    <h4 className="font-bold">{act?.createdBy}</h4>
                                    <span className="text-xs">{act?.description}</span>
                                </div>
                            </div>
                            <span>{moment(act.createdAt).fromNow()}</span>
                        </div>
                    )

                })
            }
        </div>
    )
}
