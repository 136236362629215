import React, {useEffect, useState} from "react";
import BoardsViewControls, {RbViewType} from "./BoardsViewControls";
import {
    getOrganizationBoardCards,
    getOrganizationBoards,
    getOrganizationUserFavoriteBoards,
    getOrganizationUserTimelineBoards
} from "../../services/boards/boardsService";
import {IBoardFullCard, IOrganizationBoard} from "../../types/board-types";
import BoardCard from "./BoardCard";
import {RbSubSection, RbSubSectionItem} from "app/shared-components/rb-components/RbSections";
import _ from "lodash";
import CreateBoard from "./create-board/CreateBoard";
import {useRbDispatch} from "../../rb-hooks/hooks";
import {updateRbSearchCallBack} from "../../store/rubicon/searchSlice";
import {UserOrganization} from "../../types/common-types";
import {connect} from "react-redux";
import withRouter from "../../../@fuse/core/withRouter";
import BoardCardsBar from "./board-components/BoardCardsBar";
import BoardCardsBarControls, {IBoardCardsOptionChange} from "./board-components/BoardCardsBarControls";
import BoardGrouping, {BoardGroup} from "./board-components/BoardGrouping";
import {getSSUserData, saveSSUserData} from "../../services/storageBus";

function Boards(props: { organization: UserOrganization }) {
    const [view, setView] = useState<RbViewType>("cards");
    const [timelineBoards, setTimelineBoards] = useState<IOrganizationBoard[]>([]);
    const [boardCards, setBoardCards] = useState<IBoardFullCard[]>([]);
    const [favBoards, setFavBoards] = useState<IOrganizationBoard[]>([]);
    const [boards, setBoards] = useState<IOrganizationBoard[]>([]);
    const [boardOptions, setBoardOptions] = useState<IBoardCardsOptionChange>();
    const [search, setSearch] = useState<string>("");
    const [groupType, setGroupType] = useState<BoardGroup>("tile");
    const dispatch = useRbDispatch();

    useEffect(() => {
        // @ts-ignore
        dispatch(updateRbSearchCallBack(onSearchChange));

        const data = getSSUserData("boards_options");
        if (data) {
            setView(data.view ?? view);
            setBoardOptions(data?.boardOptions);
        }

        return () => {
            // @ts-ignore
            dispatch(updateRbSearchCallBack(undefined));
        }
    }, []);

    useEffect(() => {
        getUserFavoriteBoards();
    }, [props?.organization?.id]);

    useEffect(() => {
        updateBoardsData();
    }, [view, props?.organization?.id]);

    useEffect(() => {
        saveSSUserData("boards_options", {view, boardOptions});
    }, [view, boardOptions]);

    function updateBoardsData() {
        switch (view) {
            // case "fav":
            //     getUserFavoriteBoards();
            //     break;
            case "activity":
                _getOrganizationUserTimelineBoards();
                break;
            case "list":
                getAllOrganizationBoards();
                break;
            case "cards":
                _getOrganizationBoardCards();
                break;
            default:
                break;
        }
        getUserFavoriteBoards();
    }

    function boardGroupingChange(type: BoardGroup) {
        if (type === groupType) return;
        setGroupType(type);
    }

    function onSearchChange(val: string) {
        setSearch(val.toLowerCase());
    }

    function _getOrganizationBoardCards(options?: IBoardCardsOptionChange) {
        if (!props?.organization?.id || !options?.DateFrom || !options?.DateTo) return;
        setBoardOptions(options);
        getOrganizationBoardCards(props.organization.id, options).then(res => {
            if (res?.data?.error) return;
            setBoardCards(res.data.data);
        });
    }

    function getAllOrganizationBoards() {
        if (!props?.organization?.id) return;
        getOrganizationBoards(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setBoards(res.data.data);
        });
    }

    function _getOrganizationUserTimelineBoards() {
        if (!props?.organization?.id) return;
        getOrganizationUserTimelineBoards(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setTimelineBoards(res.data.data);
        });
    }

    function filteredBoards(): IOrganizationBoard[] {
        if (!search) return boards;
        return boards.filter(b => b.boardName.toLowerCase().startsWith(search));
    }

    function getUserFavoriteBoards() {
        if (!props?.organization?.id) return;
        getOrganizationUserFavoriteBoards(props?.organization.id).then(res => {
            if (res?.data?.error) return;
            setFavBoards(res.data.data);
        });
    }

    function getRenderedView(): JSX.Element {
        switch (view) {
            case "fav":
                return <BoardCard
                    boards={favBoards}
                    title="Favorites"
                    refreshBoards={getUserFavoriteBoards}/>
            case "cards":
                return (
                    <RbSubSectionItem>
                        <BoardCardsBarControls options={boardOptions} onChange={_getOrganizationBoardCards}
                                               organization={props.organization}/>
                        <BoardCardsBar cards={boardCards}/>
                    </RbSubSectionItem>
                );
            case "activity":
                return <BoardCard boards={timelineBoards} title="Latest activity"
                                  refreshBoards={updateBoardsData}/>
            case "list":
                const tmp = _(filteredBoards()).groupBy(board => board.projectName).map((bords, projectName) => ({
                    boards: bords,
                    title: projectName
                })).value();

                return (
                    <>
                        <div className="mt-20 flex justify-end">
                            <BoardGrouping type={groupType} onChange={boardGroupingChange}/>
                        </div>
                        {
                            tmp.map((t, i) => {
                                return (
                                    <RbSubSection key={`${i}${t.title}`}>
                                        <BoardCard boards={t.boards} title={t.title} refreshBoards={updateBoardsData}
                                                   hideGrouping={true} boardType={groupType}/>
                                    </RbSubSection>
                                )
                            })
                        }
                    </>
                )
            default:
                return <></>;
        }
    }

    return (
        <div className="p-20">
            <div className="flex justify-between">
                <BoardsViewControls view={view} setView={setView} hasFav={favBoards.length > 0}/>
                <CreateBoard updateBoards={getAllOrganizationBoards}/>
            </div>

            {getRenderedView()}

        </div>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(Boards));
