import React from "react";
import {IBoardCardDetails, IBoardLabel} from "../../../types/board-types";
import RbIconTitle from "../../../shared-components/rb-components/RbIconTitle";
import {addLabelToBoardCard, removeLabelFromBoardCard} from "../../../services/boards/boardsService";
import RbMultiSelect from "../../../shared-components/rb-components/RbMultiSelect";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";

export default function BoardCardLabels(props: { card: IBoardCardDetails | undefined, labels: IBoardLabel[], refreshCard: () => void }) {
    const {card} = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    function addLabel(label: IBoardLabel) {
        if (!card?.id) return;
        addLabelToBoardCard(card.id, label.id).then(res => {
            if (res?.data?.error) return;
            props.refreshCard();
        });
    }

    function removeLabel(label: IBoardLabel) {
        if (!card?.id) return;
        removeLabelFromBoardCard(card.id, label.id).then(res => {
            if (res?.data?.error) return;
            props.refreshCard();
        });
    }

    return (
        <div className="cursor-pointer" onClick={e => setAnchorEl(e.currentTarget)}>
            <RbIconTitle icon="TagIcon" title="Labels" container="h4" className="mb-10"/>
            {
                (card?.labels ?? []).length > 0 ?
                    <div>
                        {
                            (card?.labels ?? []).map(lbl => {
                                return (
                                    <span key={lbl.id}
                                          className="mb-5 py-5 px-10 mr-5 rounded-full inline-block align-middle"
                                          style={{backgroundColor: lbl?.color ?? ""}}>{lbl?.name}</span>
                                )
                            })
                        }
                    </div> :
                    <RbRoundButton color="secondary"
                                   variant="contained">Not set</RbRoundButton>
            }
            <RbMultiSelect onClose={() => setAnchorEl(null)} anchorEl={anchorEl} items={props.labels}
                           renderItem={item => {
                               return (
                                   <span className="flex items-center">
                                       <span className="p-5 rounded-full mr-7 ml-3"
                                             style={{backgroundColor: item?.color}}></span>
                                       <span>{item?.name}</span>
                                   </span>
                               )
                           }}
                           searchPlaceholder="Search for labels"
                           onItemAdd={addLabel}
                           checkedItems={card?.labels}
                           onItemRemove={removeLabel}
            />
        </div>
    )
}
