import React, {useState} from "react";
import {IBoardCardDetails, ICardStatus} from "../../../types/board-types";
import {Menu, MenuItem} from "@mui/material";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {ChevronDownIcon} from "@heroicons/react/outline";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import moment from "moment";
import {updateBoardCardStatus} from "../../../services/boards/boardsService";
import {getRunningTracker, startTracker} from "../../../services/tracker/trackerService";
import {ITracker, ITrackerResponse} from "../../../types/tracker-types";
import {useRbDispatch} from "../../../rb-hooks/hooks";
import {updateGlobalTracker} from "../../../store/rubicon/globalTrackerSlice";

export default function BoardCardHeadControls(props: { card: IBoardCardDetails | undefined, statuses: ICardStatus[], refreshCard: () => void }) {
    const {card} = props;
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const dispatch = useRbDispatch();

    function handleChangeStatus(statusId: number) {
        setAnchorEl(null);
        if (!card?.id) return;
        updateBoardCardStatus(card.id, statusId).then(res => {
            if (res?.data?.error) return;
            props.refreshCard();
        });
    }

    function _startTracker() {
        if (!card?.id) return;
        const tmp: ITrackerResponse = {
            cardId: card.id,
            billable: true,
            startDate: moment().toISOString(true),
            description: card?.description,
            projectId: card?.projectId,

        }
        startTracker(tmp).then(res => {
                if (res?.data?.error) return;
                getRunningTracker().then((res) => {
                    if (res?.data?.data?.id) {
                        dispatch(updateGlobalTracker(res.data.data as ITracker));
                    }
                })
            }
        )
    }

    return (
        <>
            <div className="flex justify-between overflow-auto">
                <span>#{card?.number}</span>
                <span>{card?.createdBy}</span>
                <span>{moment(card?.dateInsert).fromNow()}</span>
            </div>
            <div className="flex justify-between items-center mt-10">
                <div>
                    <span className="mr-8">Status:</span>

                    <RbRoundButton color="secondary"
                                   variant="contained"
                                   className="py-3"
                                   onClick={e => setAnchorEl(e.currentTarget)}
                    >
                        <span
                            className="inline-block align-middle">{props.statuses.find(s => s.id === card?.statusId)?.name ?? "None"}</span>
                        <ChevronDownIcon
                            className="w-12 ml-5 inline-block align-middle"/>
                    </RbRoundButton>
                </div>
                <RbRoundButton variant="contained" onClick={() => _startTracker()}>Start tracker</RbRoundButton>
            </div>

            <Menu
                id="status-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={e => {
                    // @ts-ignore
                    e?.preventDefault();
                    // @ts-ignore
                    e?.stopPropagation();
                    setAnchorEl(null);
                }}
            >
                {
                    props.statuses.map(s => {
                        return (
                            <MenuItem key={s.id} onClick={() => handleChangeStatus(s.id)}>
                                <span>{s.name}</span>
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </>
    )
}
