import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {PencilIcon, PlusIcon, RefreshIcon} from "@heroicons/react/outline";
import * as React from "react";
import {FormControl, MenuItem} from "@mui/material";
import {useEffect, useState} from "react";
import {getAllCurrencies} from "../../../services/common/common";
import {ICurrency, IMemberType, IRbDisplayHeader, UserOrganization,} from "../../../types/common-types";
import {
    getAllAdminOrganizationProjects,
    getMemberTypes, getProjectBoards, getProjectPrices, getProjectUsers,
    removeUserFromProject, updateProjectName,
    updateUserMemberTypeInProject,
    updateWorkspaceCurrency
} from "../../../services/organizationSettingsService";
import {RbFilledSelect} from "app/shared-components/rb-components/RbDataTable/RbFilledSelect";
import {RbSection, RbSubSection, RbSubSectionItem} from "app/shared-components/rb-components/RbSections";
import {
    IAdminProject,
    IProjectBoard, IProjectPrice, IProjectUser,
} from "../../../types/organization-settings-types";
import {RbTableSelect} from "../../../shared-components/rb-components/RbDataTable/RbTableSelect";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {useTheme} from "@mui/material/styles";
import RbTableActionButton from "../../../shared-components/rb-components/RbDataTable/RbTableActionButton";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";
import OrganizationBoardCreateDialog from "./OrganizationBoardCreateDialog";
import RbProtectedTextField from "../../../shared-components/rb-components/RbProtectedTextField";

function ProjectOrganizationSettings(props: { organization: UserOrganization }) {
    const [currencies, setCurrencies] = useState<ICurrency[]>([]);
    const [projects, setProjects] = useState<IAdminProject[]>([]);
    const [project, setProject] = useState<IAdminProject | undefined>();
    const [boards, setBoards] = useState<IProjectBoard[]>([]);
    const [projectUsers, setProjectUsers] = useState<IProjectUser[]>([]);
    const [projectPrices, setProjectPrices] = useState<IProjectPrice[]>([]);
    const [memberTypes, setMemberTypes] = useState<IMemberType[]>([]);
    const [boardOpen, setBoardOpen] = useState<boolean>(false);

    const theme = useTheme();

    const displayProjectUsersHeaders: IRbDisplayHeader[] = [
        {
            name: "User",
            objAttribute: "name"
        },
        {
            name: "Email",
            objAttribute: "email"
        },
        {
            name: "Type",
            objAttribute: "typeId",
            handleRendering: renderTableMemberType
        },
        {
            name: "Added",
            objAttribute: "dateInsert"
        },
        {
            name: "",
            objAttribute: "",
            handleRendering: renderMemberTableActions,
            className: "text-right"
        }
    ];
    const displayBoardsHeaders: IRbDisplayHeader[] = [
        {
            name: "Board",
            objAttribute: "name"
        },
        {
            name: "Members",
            objAttribute: "members"
        },
        {
            name: "Tasks",
            objAttribute: "tasks"
        },
        {
            name: "Inserted at",
            objAttribute: "dateInsert"
        },
        {
            name: "Inserted by",
            objAttribute: "userInsert"
        },
    ];
    const displayPricesHeaders: IRbDisplayHeader[] = [
        {
            name: "Valid from",
            objAttribute: "dateFrom"
        },
        {
            name: "Inserted at",
            objAttribute: "dateInsert"
        },
        {
            name: "Price",
            objAttribute: "price"
        },
        {
            name: "Inserted by",
            objAttribute: "name"
        },
    ];

    useEffect(() => {
        _getAllCurrencies();
        _getMemberTypes();
        _getAllOrganizationProjects();
    }, [props?.organization?.id]);

    useEffect(() => {
        if (!project) return;
        _getProjectBoards();
        _getProjectUsers();
        _getProjectPrices();
    }, [project]);

    function renderMemberTableActions(obj: IProjectUser) {
        return <RbTableActionButton icon="TrashIcon" onClick={() => {
            if (!project?.id) return;
            removeUserFromProject(project.id, obj.id).then();
        }
        }/>;
    }

    function renderTableMemberType(data: IProjectUser) {
        return (<FormControl key={data.id} size="small">
            <RbTableSelect
                value={data.typeId}
                placeholder="Member type"
                variant="filled"
                disableUnderline={true}
                onChange={e => handleChangeUserMemberType(e, data.id)}
            >
                {
                    memberTypes.map(type => {
                        return (
                            <MenuItem key={type?.id} value={type?.id}>{type?.name}</MenuItem>
                        )
                    })
                }
            </RbTableSelect>
        </FormControl>)
    }

    function _getMemberTypes() {
        getMemberTypes().then(res => {
            if (!res?.data?.error) {
                setMemberTypes(res.data.data);
            }
        })
    }

    function handleChangeUserMemberType(e: any, userId: string) {
        if (!project?.id) return;
        updateUserMemberTypeInProject(project.id, userId, e.target.value).then(res => {
            if (!res?.data?.error) {
                _getProjectUsers();
            }
        });
    }

    function _getProjectBoards() {
        if (!project) return;
        getProjectBoards(project.id).then(res => {
            if (!res?.data?.error) {
                setBoards(res.data.data);
            }
        })
    }

    function _getProjectUsers() {
        if (!project) return;
        getProjectUsers(project.id).then(res => {
            if (!res?.data?.error) {
                setProjectUsers(res.data.data);
            }
        });
    }

    function _getProjectPrices() {
        if (!project) return;
        getProjectPrices(project.id).then(res => {
            if (!res?.data?.error) {
                setProjectPrices(res.data.data);
            }
        });
    }


    function _getAllCurrencies() {
        getAllCurrencies().then(res => {
            if (!res?.data?.error) {
                setCurrencies(res.data.data);
            }
        });
    }

    function _updateProjectName(name: string) {
        if (!name || !project?.id) return;
        updateProjectName(project.id, name).then(res => {
            if (res?.data?.error) return;
            _getAllOrganizationProjects();
        });
    }

    function _updateWorkspaceCurrency(curr: ICurrency) {
        if (!curr?.id || !project?.id) return;

        updateWorkspaceCurrency(project.id, curr.id).then(res => {
            if (!res?.data?.error) {
                _getAllOrganizationProjects();
            }
        });
    }

    function _getAllOrganizationProjects() {
        if (!props?.organization?.id) return;
        getAllAdminOrganizationProjects(props.organization.id).then(res => {
            if (!res?.data?.error) {
                setProjects(res.data.data);
                if (!project?.id && res.data.data.length > 0) {
                    setProject(res.data.data[0]);
                }
            }
        });
    }

    function handleSetProject(e: any) {
        const proj = projects.find(el => el.id === e.target.value);
        if (!proj) return;
        setProject(proj);
    }

    return (
        <div className="mt-48 md:ml-28 sm:ml-28 sm:mr-28 ml-64 mr-64 mb-48">
            <div className="project-controls">
                <RbSubSection>
                    <div className="w-full">
                        <FormControl size="medium">
                            <RbFilledSelect
                                variant="filled"
                                label="age"
                                className="w-256"
                                value={project?.id ?? ""}
                                onChange={(e) => handleSetProject(e)}
                            >
                                <MenuItem key={-1} value={""}
                                          style={{display: `${!project?.id ? "block" : "none"}`}}><b>Project
                                    name</b></MenuItem>
                                {
                                    projects.map(proj => {
                                        return <MenuItem key={proj?.id} value={proj.id}>{proj?.name}</MenuItem>
                                    })
                                }
                            </RbFilledSelect>
                        </FormControl>
                    </div>
                </RbSubSection>
                <RbSubSection>
                    <RbProtectedTextField size="small" slim={true} value={project?.name ?? ""}
                                          onSave={_updateProjectName}/>
                </RbSubSection>
                <RbSubSection>
                    <FormControl size="medium">
                        <RbFilledSelect
                            className="w-256"
                            variant="filled"
                            value={project?.currencyId ?? -1}>
                            <MenuItem key={-1} value={-1}
                                      style={{display: `${!project?.currencyId ? "block" : "none"}`}}><b>Currency</b></MenuItem>
                            {
                                currencies.map(curr => {
                                    return <MenuItem key={curr?.id} value={curr.id}
                                                     onClick={() => _updateWorkspaceCurrency(curr)}>{curr?.name}</MenuItem>
                                })
                            }
                        </RbFilledSelect>
                    </FormControl>
                </RbSubSection>
                <RbSection>
                    <h2 className="font-semibold">Project members</h2>
                    <RbSubSectionItem>
                        <RbDataTable search={true} dataSource={projectUsers}
                                     headerColumns={displayProjectUsersHeaders}/>
                    </RbSubSectionItem>
                </RbSection>
                <RbSection>
                    <div className="w-full flex justify-between">
                        <h2 className="font-semibold">Boards</h2>
                        <RbRoundButton variant="contained" color="primary" onClick={() => setBoardOpen(true)}>
                            + Create board
                        </RbRoundButton>
                    </div>
                    <RbSubSectionItem>
                        <RbDataTable search={true} dataSource={boards} headerColumns={displayBoardsHeaders}/>
                    </RbSubSectionItem>
                    <OrganizationBoardCreateDialog open={boardOpen} projectId={project?.id} onClose={() => {
                        setBoardOpen(false);
                        _getProjectBoards();
                    }}/>
                </RbSection>
                <RbSection>
                    <div className="w-full flex justify-between">
                        <h2 className="font-semibold">Price per hour: {project?.currentPrice ?? 0}$</h2>
                        <RbRoundButton style={{color: theme.palette.text.primary}}
                                       variant="contained"><RefreshIcon
                            className="w-20 inline-block mr-5 align-middle"/> <span
                            className="inline-block align-middle">Change price</span></RbRoundButton>
                    </div>
                    <RbSubSectionItem>
                        <RbDataTable search={true} dataSource={projectPrices} headerColumns={displayPricesHeaders}/>
                    </RbSubSectionItem>
                </RbSection>
            </div>
        </div>
    )

}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(ProjectOrganizationSettings));
