import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {ITodoFriend} from "../../../types/todo-types";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {acceptFriendRequest, removeFriend} from "../../../services/todo/todoService";
import {RbSubSection} from "app/shared-components/rb-components/RbSections";

export default function TodoFriendInvites(props: { friendRequests: ITodoFriend[], refreshFriendRequests: () => void, refreshFriends: () => void }) {
    const theme: RbTheme = useTheme();

    function _acceptFriendRequest(id: string) {
        acceptFriendRequest(id).then(res => {
            if (res?.data?.error) return;
            props.refreshFriendRequests();
        });
    }

    function _rejectFriendRequest(id: string) {
        removeFriend(id).then(res => {
            if (res?.data?.error) return;
            props.refreshFriendRequests();
        });
    }

    return (
        <RbSubSection>
            {
                props.friendRequests.length === 0 ?
                    <h3 className="w-full font-bold text-center">No pending invites</h3> :
                    <table className="text-left w-full">
                        <thead>
                        <tr className="border-b-solid border-b-1">
                            <th className="">Invited by</th>
                            <th className="">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            props.friendRequests?.map(fr => {
                                return (
                                    <tr key={fr.id}>
                                        <td className="pt-5 pb-5">{fr.name}</td>
                                        <td className="pt-5 pb-5">
                                            <RbRoundButton color="primary" variant="contained"
                                                           style={{color: theme.palette.text.primary}}
                                                           className="md:block"
                                                           onClick={() => _acceptFriendRequest(fr.id)}>Accept</RbRoundButton>
                                            <RbRoundButton color="warning" variant="contained"
                                                           className="sm:ml-10 md:ml-0 md:block"
                                                           style={{color: theme.palette.text.primary}}
                                                           onClick={() => _rejectFriendRequest(fr.id)}>Decline</RbRoundButton>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
            }
        </RbSubSection>
    )
}
