import {styled} from "@mui/system";
import {ToggleButton} from "@mui/material";

export const RbToggleButton = styled(ToggleButton)(() => ({
    '&.Mui-selected': {
        borderColor: "#06B6D4",
        borderWidth: 3
    },
    '& + .MuiToggleButton-root': {
        marginLeft: "7px"
    }
}));

