import {_delete, _get, _patch, _post} from "../apiService";

export const triggerBackendError = () => {
    return _get(`test-error`);
}

export const getAllOrganizationProjects = (organizationId: string) => {
    return _get(`common/organization/${organizationId}/projects-drop-down`);
}

export const getAllProjectBoards = (projectId: string) => {
    return _get(`common/project/${projectId}/boards-drop-down`);
}

export const getWorkspaceProjects = (workspaceId: string) => {
    return _get(`common/workspace/${workspaceId}/projects-drop-down`);
}

export const getAllCurrencies = () => {
    return _get(`common/currencies`);
}

export const getOrganizationWorkspaces = (orgId: string) => {
    return _get(`common/organization/${orgId}/workspaces-drop-down`);
}

export const searchOrganizationUsers = (orgId: string, searchTerm: string) => {
    return _get(`common/${orgId}/search-for-user/${searchTerm}`)
}
