import React, {useEffect, useState} from "react";
import clsx from "clsx";

export default function RbContentEditable(props: { children?: any | JSX.Element, manualToggle?: boolean, multiple?: boolean, defaultValue?: string, className?: string, style?: {}, childClassName?: string, placeholder?: string, onConfirm?: (value: string) => void }) {
    const [isEditor, setIsEditor] = useState<boolean>(false);

    useEffect(() => {
        if (props.manualToggle !== undefined) {
            setIsEditor(props.manualToggle);
        }
    }, [props.manualToggle]);

    function handleKeyPress(e: any) {
        if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            setIsEditor(false);
            e.target.value = "";
        }
        if (e.key === 'Enter' && props.onConfirm) {
            setIsEditor(false);
            if (props.onConfirm) {
                props?.onConfirm(e?.target?.value ?? "");
            }
            e.target.value = "";
        }
    }

    function handleClick(e: React.MouseEvent<HTMLElement>) {
        if (props.manualToggle !== undefined) return;
        e.stopPropagation();
        e.preventDefault();
        setIsEditor(true);
    }

    function handleBlur(e: any) {
        setIsEditor(false);
        e.target.value = "";
    }

    return (
        <>
            {
                isEditor ?
                    <input defaultValue={props?.defaultValue ?? ""} onBlur={handleBlur} placeholder={props.placeholder}
                           autoFocus={true}
                           onKeyDown={handleKeyPress}
                           multiple={props?.multiple ?? false}
                           className={clsx("bg-transparent outline-none box-border border-b-1 w-full", props.className)}
                           style={props?.style}/> :
                    <span className={clsx("inline-block h-fit cursor-pointer", props.childClassName)}
                          onClick={e => handleClick(e)}>{props?.children}</span>
            }

        </>
    )
}
