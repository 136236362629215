import i18next from 'i18next';
import en from './i18n/en';
import tr from './i18n/tr';
import ar from './i18n/ar';
import AccountUserSettings from "./account/AccountUserSettings";
import NotificationUserSettings from "./notification/NotificationUserSettings";
import OrganizationInvitesUserSettings from "./organization-invites/OrganizationInvitesUserSettings";
import UserOrganizationsUserSettings from "./user-organizations/UserOrganizationsUserSettings";
import {INavigationBlock} from "../../store/rubicon/subNavigationSlice";

i18next.addResourceBundle('en', 'userSettingsPage', en);
i18next.addResourceBundle('tr', 'userSettingsPage', tr);
i18next.addResourceBundle('ar', 'userSettingsPage', ar);

const SettingsConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'user-settings/account',
            element: <AccountUserSettings/>,
        },
        {
            path: 'user-settings/notification',
            element: <NotificationUserSettings/>,
        },
        {
            path: 'user-settings/organization-invites',
            element: <OrganizationInvitesUserSettings/>,
        },
        {
            path: 'user-settings/organizations',
            element: <UserOrganizationsUserSettings/>,
        },
    ],
};

export const userSettingsSubNavigation: INavigationBlock[] = [
    {
        id: 'user-settings',
        title: 'User Settings',
        translate: 'UserSettings',
        type: 'group',
        icon: 'apps',
        children: [
            {
                id: 'user-settings-account',
                title: 'Account settings',
                translate: 'AccountSettings',
                type: 'item',
                icon: 'UserCircleIcon',
                url: 'user-settings/account',
            },
            {
                id: 'user-settings-notification',
                title: 'Notification settings',
                translate: 'NotificationSettings',
                type: 'item',
                icon: 'BellIcon',
                url: 'user-settings/notification',
            },
            {
                id: 'user-settings-organization-invites',
                title: 'Organization invites',
                translate: 'OrganizationInvites',
                type: 'item',
                icon: 'ClockIcon',
                url: 'user-settings/organization-invites',
            },
            {
                id: 'user-settings-user-invites',
                title: 'User invites',
                translate: 'UserInvites',
                type: 'item',
                icon: 'UserGroupIcon',
                url: 'user-settings/organizations',
            },
        ]
    }];

export default SettingsConfig;
