import {useEffect, useState} from "react";
import Popover from "@mui/material/Popover";
import * as React from "react";
import {RbTextField} from "../RbTextField";
import {MenuItem} from "@mui/material";
import RbText from "../RbText";

export default function RbAutoComplete(props: { variant?: "outlined" | "standard" | "filled", displayString?: string, className?: string, handleItemPreview?: (obj: any) => string | JSX.Element, onSelect: (obj: any) => {}, staticDataSource?: any[], onSearch?: (searchString: string) => {}, autoDataAPIFetching?: { method: (...args: any[]) => {}, searchPosition?: number, args?: any[] } }) {
    const [filteredDataSource, setFilteredDataSource] = useState<any[]>([]);
    const [searchString, setSearchString] = useState<string>("");
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (searchString.length > 2) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (props?.staticDataSource) {
            setFilteredDataSource(props.staticDataSource);
        }

        setSearchString(props?.displayString ?? "");
    }, [props]);

    function handleDataFiltering(e: any) {
        setSearchString(e?.target?.value ?? "");
        if ((e?.target?.value?.length ?? 0) < 3) {
            setAnchorEl(null);
            return;
        }
        setAnchorEl(e.currentTarget);
        if (props.staticDataSource) {
            setFilteredDataSource(props.staticDataSource.filter(el => Object.values(el).some((attr) => e?.target?.value.toLowerCase().startsWith((attr as string)?.toLowerCase()))));
        } else {
            const args = [...props.autoDataAPIFetching?.args ?? []];
            args?.splice(props.autoDataAPIFetching?.searchPosition ?? 0, 0, e.target.value);

            (props.autoDataAPIFetching?.method(...args) as Promise<any>).then(res => {
                if (!res?.data?.error) {
                    setFilteredDataSource(res?.data?.data ?? []);
                }
            });
        }
    }

    return (
        <>
            <RbTextField
                fullWidth
                name="emailInv"
                placeholder="Full e-mail or username"
                variant={props?.variant ?? "standard"}
                value={searchString}
                onChange={handleDataFiltering}
                className={props.className}
                onClick={handleClick}
                onBlur={() => {
                    setSearchString("");
                    setAnchorEl(null);
                }}
                color="primary"/>
            <Popover
                id="rb-autocomplete"
                hideBackdrop={true}
                disableAutoFocus={true}
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}>
                <div className="content min-w-256 min-h-128 p-10 overflow-auto max-h-320">
                    {
                        filteredDataSource.length > 0 ?
                            filteredDataSource.map((el, i) => {
                                return (
                                    <MenuItem key={i} onClick={() => {
                                        if (props?.onSelect) {
                                            setSearchString(props?.handleItemPreview ? props.handleItemPreview(el) : el);
                                            setAnchorEl(null);
                                            props.onSelect(el);
                                        }
                                    }}>
                                        {
                                            props?.handleItemPreview ? props.handleItemPreview(el) :
                                                <span className="capitalize">{el}</span>
                                        }
                                    </MenuItem>
                                )
                            }) : <RbText theme="secondary" className="italic block mt-10 text-center">No users
                                found...</RbText>
                    }
                </div>
            </Popover>
        </>
    )
}
