import {_delete, _get, _patch, _post} from "../apiService";


export const getAllTodos = () => {
    return _get(`to-do/all`);
}

export const getAllPrioritisedTodos = () => {
    return _get(`to-do/lists/prioritized-tasks`);
}

export const getAllMyDayTodos = () => {
    return _get(`to-do/lists/my-day`);
}

export const getCustomLists = () => {
    return _get(`to-do/lists/user-lists`);
}

export const getCustomListTodos = (listId: string) => {
    return _get(`to-do/lists/${listId}`);
}

export const toggleDoneTask = (id: string) => {
    return _patch(`to-do/${id}/toggle-done`, null);
}

export const deleteCustomList = (listId: string) => {
    return _delete(`to-do/lists/${listId}`);
}

export const createTodoTask = (listId: string, value: string) => {
    return _post(`to-do/tasks/create`, {
        orderNo: 0,
        listId: listId,
        name: value
    });
}
export const createTodoList = (name: string) => {
    return _post(`to-do/lists/create`, {name, orderNo: 0});
}

export const toggleMyDay = (taskId: string) => {
    return _patch(`to-do/${taskId}/toggle-my-day`, null);
}
export const updateTaskPriority = (taskId: string, priority: number) => {
    return _patch(`to-do/${taskId}/add-priority/${priority}`, {});
}

export const updateTaskName = (taskId: string, name: string) => {
    return _patch(`to-do/tasks/edit-name`, {taskId, name});
}
export const updateTaskNote = (taskId: string, note: string) => {
    return _patch(`to-do/tasks/update-note`, {taskId, note});
}
export const moveTaskToList = (taskId: string, listId: string) => {
    return _patch(`to-do/${taskId}/update-list/${listId}`, null);
}
export const getTaskSubTasks = (taskId: string) => {
    return _get(`to-do/${taskId}/steps`);
}

export const removeSubTask = (taskId: string, stepId: string) => {
    return _delete(`to-do/${taskId}/remove-step?stepId=${stepId}`);
}
export const createSubTask = (taskId: string, name: string) => {
    return _post(`to-do/tasks/add-step`, {taskId, name});
}
export const deleteTask = (taskId: string) => {
    return _delete(`to-do/${taskId}/delete`);
}
export const toggleSubTask = (stepId: string) => {
    return _post(`to-do/tasks/${stepId}/toggle-step`, null);
}
export const getFriendList = () => {
    return _get(`to-do/friend-list`);
}
export const assignUserToTask = (taskId: string, userId: string) => {
    return _patch(`to-do/tasks/${taskId}/assign-user?userId=${userId}`, null);
}
export const updateTaskDueDate = (taskId: string, dueDate: string) => {
    return _patch(`to-do/add-due-date`, {taskId, dueDate});
}
export const deleteTodo = (taskId: string) => {
    return _delete(`to-do/${taskId}/delete`)
}
export const sendUserFriendRequest = (userId: string) => {
    return _post(`to-do/${userId}/invite-friend`, null);
}
export const acceptFriendRequest = (userId: string) => {
    return _post(`to-do/${userId}/accept-friend`, null);
}
export const removeFriend = (userId: string) => {
    return _delete(`to-do/${userId}/remove-friend`, null);
}
export const getFriendRequests = () => {
    return _get(`to-do/pending-invitations`);
}
export const searchFriendsUser = (search: string) => {
    return _get(`to-do/get-user?searchTerm=${search}`);
}
