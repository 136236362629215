import React, {useState} from "react";
import {IBoardCardDetails, IBoardCardTask, IBoardCardTasks} from "../../../types/board-types";
import RbIconTitle from "../../../shared-components/rb-components/RbIconTitle";
import {FormControlLabel, IconButton, LinearProgress} from "@mui/material";
import {ChatIcon, TrashIcon, XIcon} from "@heroicons/react/outline";
import {RbCheckBox} from "app/shared-components/rb-components/RbCheckBoxIcon";
import RbContentEditable from "../../../shared-components/rb-components/RbContentEditable";
import {
    createBoardCardList,
    createBoardCardTask,
    deleteBoardCardTask, deleteBoardList,
    toggleBoardCardTask, updateCheckListItemName, updateCheckListName
} from "../../../services/boards/boardsService";
import RbConfirmationDialog from "../../../shared-components/dialogs/RbConfirmationDialog";
import {RbProgressBar} from "../../../shared-components/rb-components/RbProgressBar";

export default function BoardCardTasks(props: { card: IBoardCardDetails | undefined, refreshTasks: () => void, tasks: IBoardCardTasks[] }) {
    const {card, tasks} = props;
    const [taskListId, setTaskListId] = useState<string | undefined>();

    function _toggleBoardCardTask(task: IBoardCardTask) {
        if (!task?.id) return;
        toggleBoardCardTask(task.id, !task.checked).then(res => {
            if (res?.data?.error) return;
            props.refreshTasks();
        });
    }

    function _deleteBoardCardTask(id: string) {
        deleteBoardCardTask(id).then(res => {
            if (res?.data?.error) return;
            props.refreshTasks();
        })
    }

    function getCheckedTasks(task: IBoardCardTasks): number {
        return (task?.items ?? []).filter(t => t?.checked)?.length ?? 0;
    }

    function getCheckedTasksPercentage(task: IBoardCardTasks): number {
        const checked = getCheckedTasks(task);
        return (100 * checked) / task.items.length;
    }

    function _createBoardCardTask(listId: string, name: string) {
        if (!listId || !name) return;
        createBoardCardTask(listId, name).then(res => {
            if (res?.data?.error) return;
            props.refreshTasks();
        });
    }

    function handleNewCheckList(name: string) {
        if (!card?.id) return;
        createBoardCardList(card?.id, name).then(res => {
            if (res?.data?.error) return;
            props.refreshTasks();
        })
    }

    function handleTaskListDelete() {
        if (!taskListId) return;
        deleteBoardList(taskListId).then(res => {
            if (res?.data?.error) return;
            props.refreshTasks();
        });
    }

    function renameTaskItem(id: string, name: string) {
        if (!id || !name) return;
        updateCheckListItemName(id, name).then(res => {
            if (res?.data?.error) return;
            props.refreshTasks();
        });
    }

    function renameTask(id: string, name: string) {
        if (!id || !name) return;
        updateCheckListName(id, name).then(res => {
            if (res?.data?.error) return;
            props.refreshTasks();
        });
    }

    return (
        <>
            <div className="flex py-10 gap-10">
                <ChatIcon className="w-20"/>
                <RbContentEditable onConfirm={handleNewCheckList}>
                    <span>Add checklist</span>
                </RbContentEditable>
                <RbConfirmationDialog isDelete={true} onClose={() => setTaskListId(undefined)}
                                      onConfirm={handleTaskListDelete} open={!!taskListId}>
                    Are you sure you want to delete this list?
                </RbConfirmationDialog>
            </div>
            {
                tasks.length > 0 ? tasks.map(taskList => {
                        return (
                            <div key={taskList.id} className="mb-10">
                                <h4 className="w-full flex justify-between items-center mb-10">
                                    <RbContentEditable onConfirm={(name) => renameTask(taskList.id, name)}
                                                       defaultValue={taskList.name}>
                                        <RbIconTitle icon="ClipboardIcon" title={taskList?.name} container="span"/>
                                    </RbContentEditable>
                                    <IconButton onClick={() => setTaskListId(taskList.id)}>
                                        <TrashIcon className="w-20"/>
                                    </IconButton>
                                </h4>
                                <div className="flex items-center cursor-default">
                                    <span>{getCheckedTasks(taskList)}/{(taskList?.items ?? []).length ?? 0}</span>
                                    <RbProgressBar value={getCheckedTasksPercentage(taskList)} className="w-full ml-10"
                                                   color="primary"
                                                   variant="determinate"/>
                                </div>
                                <div className="mt-10 pl-24">
                                    {
                                        (taskList?.items ?? []).map(task => {
                                            return (
                                                <div key={task.id} className="flex justify-between">
                                                    <div className="flex items-center">
                                                        <RbCheckBox defaultChecked={task.checked}
                                                                    onChange={() => _toggleBoardCardTask(task)}
                                                                    className="py-0 pr-6"/>
                                                        <RbContentEditable
                                                            onConfirm={(name) => renameTaskItem(task.id, name)}
                                                            defaultValue={task.name}>
                                                            <span>{task.name}</span>
                                                        </RbContentEditable>
                                                    </div>
                                                    <IconButton className="p-5"
                                                                onClick={() => _deleteBoardCardTask(task.id)}>
                                                        <XIcon className="w-20"/>
                                                    </IconButton>
                                                </div>
                                            )
                                        })
                                    }

                                    <RbContentEditable multiple={false}
                                                       onConfirm={value => _createBoardCardTask(taskList.id, value)}>
                                        <p className="my-5">+ Add checklist item</p>
                                    </RbContentEditable>
                                </div>
                            </div>
                        )
                    }) :
                    <img src="/assets/images/illustrations/boards/no_lists.svg"
                         className="m-auto max-w-128 pt-32 w-full"
                         alt="No lists"/>
            }
        </>
    )
}
