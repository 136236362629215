import {DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {RbDialog} from "app/shared-components/dialogs/RbDialog";
import React, {useEffect, useState} from "react";
import {getTicketsCloseStatuses, updateTicketCloseStatus, updateTicketStatus} from "../../../services/ticketsService";
import {RbSubSectionItem} from "../../../shared-components/rb-components/RbSections";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {ITicketStatus} from "../../../types/tickets-types";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";

export default function TicketCloseDialog(props: { ticketId: string | undefined, open: boolean, onClose: (update?: boolean) => void }) {
    const [statuses, setStatuses] = useState<ITicketStatus[]>([]);
    const [closeStatusId, setCloseStatusId] = useState<number>(-1);
    const [description, setDescription] = useState<string>("");

    useEffect(() => {
        getTicketsCloseStatuses().then(res => {
            if (res?.data?.error) return;
            setStatuses(res.data.data);
        })
    }, []);

    function _closeTicket() {
        if (!props.ticketId) return;
        // id = 3 is closed status
        updateTicketCloseStatus(props.ticketId, 3, closeStatusId, description).then(res => {
            if (res?.data?.error) return;
            props.onClose(true);
            resetData();
        })
    }

    function resetData() {
        setCloseStatusId(-1);
        setDescription("");
    }

    return (
        <RbDialog
            open={props.open}
            onClose={() => props.onClose()}
        >
            <DialogTitle>Close ticket</DialogTitle>
            <DialogContent>
                <RbSubSectionItem>
                    <FormControl fullWidth>
                        <InputLabel id="status">Status</InputLabel>
                        <Select
                            variant="outlined"
                            id="status"
                            label="Status"
                            size="small"
                            value={closeStatusId}
                            onChange={e => setCloseStatusId(+e.target.value)}
                        >
                            <MenuItem key={-1} value={-1}>Status name</MenuItem>;
                            {
                                statuses.map((s, i) => {
                                    return <MenuItem key={i} value={s.id}>{s?.name ?? ""}</MenuItem>;
                                })
                            }

                        </Select>
                    </FormControl>
                </RbSubSectionItem>
                <RbSubSectionItem>
                    <FormControl fullWidth>
                        <RbTextField className="w-320"
                                     variant="outlined"
                                     label="Description"
                                     multiline={true}
                                     rows={5}
                                     value={description}
                                     onChange={e => setDescription(e.target.value)}
                                     color="primary"/>
                    </FormControl>
                </RbSubSectionItem>
            </DialogContent>
            <DialogActions className="mr-20 mb-20">
                <RbRoundButton className="mr-10" variant="text" onClick={() => {
                    props.onClose();
                    resetData();
                }}>Close</RbRoundButton>
                <RbRoundButton disabled={closeStatusId < 0 || !description || !props?.ticketId}
                               variant="contained" onClick={_closeTicket}>Create</RbRoundButton>
            </DialogActions>
        </RbDialog>
    )
}
