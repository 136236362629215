import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import * as React from "react";
import {WheelEventHandler} from "react";
import {styled} from "@mui/system";

const Boxy = styled(Box)(() => ({
    "&.task-done:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        backgroundColor: "rgba(5, 150, 105, 0.2);",
        zIndex: 99,
        pointerEvents: "none",
        borderRadius: 6
    }
}));

export default function RbBox(props: { onWheel?: (e: WheelEventHandler<HTMLDivElement>) => void, innerRef?: any, className?: string, onClick?: (e?: any) => void, style?: React.CSSProperties, theme?: "primary" | "secondary", children: any }) {
    const theme = useTheme();
    return (
        <Boxy className={`rounded-6 p-10 min-h-10 relative ${props.className}`}
              onWheel={props.onWheel as any}
              ref={props.innerRef}
              onClick={props?.onClick && props.onClick}
              style={{
                  backgroundColor: props.theme === "secondary" ? theme.palette.secondary.main : theme.palette.primary.main,
                  ...props.style
              }}>
            {props.children}
        </Boxy>
    )
}

RbBox.defaultProps = {
    theme: "primary"
}
