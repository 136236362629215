import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {signinRedirect} from "../../services/authService";
import {useSelector} from "react-redux";

function Login() {
    const user = useSelector((state:any) => state.auth.user.data);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate("");
        } else {
            signinRedirect();
        }
    }, [navigate, user]);

    return (
        <>
        </>
    );
}

export default Login;
