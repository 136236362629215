import {useTheme} from "@mui/material/styles";
import {RbTheme} from "../../types/common-types";

export default function RbText(props: { className?: string, theme: "primary" | "secondary" | "special", children?: any }) {
    const theme: RbTheme = useTheme();

    function getThemeColor(): string {
        switch (props.theme) {
            case "primary":
                return theme.palette.text.primary;
            case "secondary":
                return theme.palette.text.secondary;
            case "special":
                return theme.palette.special;
            default:
                return "black";
        }
    }

    return (
        <span className={props?.className ?? ""}
              style={{color: getThemeColor()}}
        >{props.children}</span>
    )
}

RbText.defaultProps = {
    theme: "primary"
}
