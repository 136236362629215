import {DialogContent, DialogTitle, IconButton, Tooltip} from "@mui/material";
import {RbDialog} from "app/shared-components/dialogs/RbDialog";
import React, {useEffect, useState} from "react";
import RbText from "../../../shared-components/rb-components/RbText";
import moment from "moment";
import {DownloadIcon} from "@heroicons/react/outline";
import {ITrackerTimeline, ITrackerTimelineTracker} from "../../../types/report-types";
import {getTrackerTimeline} from "../../../services/resportsService";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";
import {RbTheme, UserOrganization} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";

function ReportTrackerTimelineDialog(props: { organization?: UserOrganization, date: string, onClose: () => void, projectId?: string, workspaceId?: string }) {
    const [timelineData, setTimelineData] = useState<ITrackerTimeline>();
    const theme: RbTheme = useTheme();

    useEffect(() => {
        _getTrackerTimeline();
    }, [props]);

    function _getTrackerTimeline() {
        if (!props.organization?.id || !props.date) return;
        getTrackerTimeline(props.organization.id, props.date, props.workspaceId, props.projectId).then(res => {
            if (res?.data?.error) return;
            setTimelineData(res.data.data);
        })
    }

    function generateHourHeaders(): JSX.Element | JSX.Element[] {
        if (!timelineData?.startHour || !timelineData?.endHour) return <></>;
        const ths: any[] = [];
        const start = new Date(timelineData.startHour).getHours();
        let end = new Date(timelineData.endHour).getHours();
        if (end < start) {
            end += 24;
        }
        for (let i = start; i <= (end > 23 ? 24 : end); i++) {
            ths.push(<th className="w-96 border-r-1" key={i}>{String(i % 24).padStart(2, "0")}:00</th>)
        }
        return ths;
    }

    function generateTrackerBlocks(trackers: ITrackerTimelineTracker[]): JSX.Element | JSX.Element[] {
        return (
            trackers.map((tracker, i) => {
                return (
                    <Tooltip title={getTrackerHover(tracker)} key={i}>
                        <div className={`absolute block h-24 py-2 px-10 m-0 rounded-8 border-1 box-border`} key={i}
                             style={{
                                 width: getTrackerBlockWidth(tracker),
                                 left: getTrackerBlockLeft(tracker),
                                 top: getTrackerBlockTop(trackers, i),
                                 backgroundColor: tracker?.color ?? theme.palette.secondary.main
                             }}>
                        </div>
                    </Tooltip>
                )
            })
        )
    }

    function getTrackerBlockWidth(tracker: ITrackerTimelineTracker): string {
        const to = moment(tracker.dateTo);
        const from = moment(tracker.dateFrom);
        if (to.hours() < from.hours()) {
            to.set({hours: 0, minutes: 60});
        }
        const diff = moment.duration(to.diff(from));
        return `${diff.hours() * 9.6 + (diff.minutes() * 9.6) / 60}rem`;
    }

    function getTrackerBlockLeft(tracker: ITrackerTimelineTracker): string {
        const dateFrom = new Date(tracker.dateFrom);
        const start = new Date(timelineData?.startHour ?? "").getHours();
        return `${(dateFrom.getHours() - (start)) * 9.6 + (dateFrom.getMinutes() * 9.6) / 60}rem`;
    }

    function getTrackerBlockTop(trackers: ITrackerTimelineTracker[], ind: number): string {
        if (ind === 0) return "0.4rem";
        let tmp = 0.4;
        for (let i = ind - 1; i >= 0; i--) {
            if (moment(trackers[ind].dateFrom).isSameOrBefore(moment(trackers[i].dateTo))) tmp += 0.4;
        }
        return `${tmp}rem`;
    }

    function getTrackerHover(tracker: ITrackerTimelineTracker) {
        return (
            <div className="text-13 p-10 flex flex-col">
                <span className="flex items-center flex-nowrap whitespace-nowrap">
                    <span className="w-64 basis-[64px] text-right mr-6">Start time:</span>
                    {new Date(tracker?.dateFrom ?? "").toLocaleTimeString("de-DE")}</span>

                <span className="flex items-center flex-nowrap whitespace-nowrap">
                    <span className="w-64 basis-[64px] text-right mr-6">End time:</span>
                    {new Date(tracker?.dateTo ?? "").toLocaleTimeString("de-DE")}</span>

                <span className="flex items-center flex-nowrap whitespace-nowrap">
                    <span className="w-64 basis-[64px] text-right mr-6">Duration:</span>
                    {tracker?.duration}</span>

                <span className="flex items-center flex-nowrap whitespace-nowrap">
                    <span className="w-64 basis-[64px] text-right mr-6">Project:</span>
                    {tracker?.project}</span>

                <span className="flex items-center flex-nowrap whitespace-nowrap">
                    <span className="w-64 basis-[64px] text-right mr-6">Board:</span>
                    {tracker?.board}</span>

                <span className="flex items-center flex-nowrap whitespace-nowrap">
                    <span className="w-64 basis-[64px] text-right mr-6">Card:</span>
                    {tracker?.card}</span>

                <span className="flex items-center flex-nowrap whitespace-nowrap">
                    <span className="w-64 basis-[64px] text-right mr-6">Automatic:</span>
                    {tracker?.automatic ? "Yes" : "No"}</span>

                <span className="flex items-center flex-nowrap whitespace-nowrap">
                    <span className="w-64 basis-[64px] text-right mr-6">Edited:</span>
                    {tracker?.edited ? "Yes" : "No"}</span>
            </div>
        )
    }

    return (
        <RbDialog
            open={!!props.date}
            onClose={() => props.onClose()}>
            <DialogTitle className="flex justify-between align-middle">
                <RbText>{moment(props.date).format("ddd, DD MMM YY")}</RbText>
                <IconButton className="p-5">
                    <DownloadIcon className="w-20"/>
                </IconButton>
            </DialogTitle>

            <DialogContent className="max-w-none">
                <div>
                    <table className="table-fixed w-full">
                        <thead>
                        <tr className="border-b-1">
                            <th className="w-fit px-10 w-128 border-r-1 text-right">Users</th>
                            <th className="w-fit px-10 w-80 border-r-1 text-center whitespace-nowrap">Total time</th>
                            {generateHourHeaders()}
                        </tr>
                        </thead>

                        <tbody>
                        {
                            (timelineData?.users ?? []).map((user, i) => {
                                return (
                                    <tr className="h-32" key={i}>
                                        <td className="border-r-1 text-right px-10">{user.user.name}</td>
                                        <td className="border-r-1 text-center px-10 whitespace-nowrap">{user.totalDuration}</td>
                                        <td colSpan={(new Date(timelineData?.startHour ?? "").getHours()) - (new Date(timelineData?.endHour ?? "").getHours())}
                                            className="relative border-r-1">
                                            {generateTrackerBlocks(user.trackers)}
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </DialogContent>
        </RbDialog>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(ReportTrackerTimelineDialog));
