import {Navigate} from 'react-router-dom';
import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import Error404Page from 'app/main/404/Error404Page';
import LoginConfig from "../main/login/LoginConfig";
import SignOidcConfig from "../main/signin-oidc/SignOidcConfig";
import SilentRenewConfig from "../main/silent-renew/SilentRenewConfig";
import TrackerConfig from "../main/tracker/TrackerConfig";
import DashboardConfig from "../main/dashboard/DashboardConfig";
import SignoutOidcConfig from "../main/signout-oidc/SignoutOidcConfig";
import UserSettingsConfig from "../main/user-settings/UserSettingsConfig";
import OrganizationSettingsConfig from "../main/organization-settings/OrganizationSettingsConfig";
import TodoConfig from "../main/todo/TodoConfig";
import BoardsConfig from "../main/boards/BoardsConfig";
import BoardsCardDetailsConfig from "../main/boards/BoardsCardDetailsConfig";
import ReportsConfig from "../main/reports/ReportsConfig";
import ProjectOverviewConfig from "../main/project-overview/ProjectOverviewConfig";
import TicketsConfig from "../main/tickets/TicketsConfig";
import TicketDetailsConfig from "../main/tickets/TicketDetialsConfig";
import LandingConfig from "../main/landing-page/LandingPageConfig";

const routeConfigs = [DashboardConfig, TicketDetailsConfig, LandingConfig, TicketsConfig, ProjectOverviewConfig, ReportsConfig, TrackerConfig, TodoConfig, BoardsConfig, BoardsCardDetailsConfig, LoginConfig, SignOidcConfig, SignoutOidcConfig, SilentRenewConfig, UserSettingsConfig, OrganizationSettingsConfig, TodoConfig];

const routes = [// if you want to make whole app auth protected by default change defaultAuth for dashboard:
    // ...FuseUtils.generateRoutesFromConfigs(routeConfigs, ['admin','staff','user']),
    // The individual route configs which has auth option won't be overridden.
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs, null), {
        path: '/', element: <Navigate to="dashboard"/>,
    }, {
        path: '/tracker', element: <Navigate to="tracker"/>,
    }, {
        path: 'loading', element: <FuseLoading/>,
    }, {
        path: '404', element: <Error404Page/>,
    }, {
        path: '*', element: <Navigate to="404"/>,
    },];

export default routes;
