import {_delete, _get, _patch, _post} from "./apiService";

export const getOrganizationTicketSystems = (organizationId: string) => {
    return _get(`ticket/${organizationId}/get-systems`);
}

export const addTicketSystemCategory = (systemId: string, name: string) => {
    return _post(`ticket/add-category`, {systemId, name});
}

export const addTicketSystemSubCategory = (categoryId: string, name: string) => {
    return _post(`ticket/add-subcategory`, {categoryId, name});
}

export const createTicketSystem = (organizationId: string, name: string) => {
    return _post(`ticket/create-system`, {organizationId, name});
}

export const getTicketsForSystem = (systemId: string) => {
    return _get(`ticket/${systemId}/tickets`);
}

export const editTicketSystemName = (hashId: string, name: string) => {
    return _patch(`ticket/edit-system-name`, {hashId, name});
}

export const archiveTicketSystem = (id: string, active: boolean) => {
    return _patch(`ticket/toggle-ticket-system`, {id, active});
}

export const getTicketSystemCategories = (id: string) => {
    return _get(`ticket/${id}/categories`);
}

export const getTicketSystemSubcategories = (id: string) => {
    return _get(`ticket/${id}/subcategories`);
}

export const archiveTicketSystemCategory = (id: string, active: boolean) => {
    return _patch(`ticket/toggle-category`, {id, active})
}

export const archiveTicketSystemSubCategory = (id: string, active: boolean) => {
    return _patch(`ticket/toggle-subcategory`, {id, active})
}

export const getTicketDetails = (id: string) => {
    return _get(`ticket/${id}`);
}

export const getTicketStatuses = () => {
    return _get(`ticket/get-statuses`);
}

export const getTicketComments = (ticketId: string) => {
    return _get(`ticket/${ticketId}/comments`);
}

export const addCommentToTicket = (ticketId: string, comment: string) => {
    return _post(`ticket/add-comment`, {ticketId, comment})
}

export const getTicketActivities = (ticketId: String) => {
    return _get(`ticket/${ticketId}/activity`);
}

export const updateTicketStatus = (ticketId: string, statusId: number) => {
    return _patch(`ticket/update-status`, {ticketId, statusId});
}

export const updateTicketCloseStatus = (ticketId: string, statusId: number, closeStatusId: number, closeDescription: string) => {
    return _patch(`ticket/update-status`, {ticketId, statusId, closeStatusId, closeDescription});
}

export const checkSystemValidity = (systemId: string) => {
    return _get(`ticket/${systemId}/check`);
}

export const getTicketsCloseStatuses = () => {
    return _get(`ticket/get-close-statuses`);
}

export const createTicket = (title: string, subcategoryId: string, description: string = "") => {
    return _post(`ticket/create-ticket`, {title, subcategoryId, description})
}
