import {useEffect} from "react";
import {signinSilentCallback} from "../../services/authService";
import {useNavigate} from "react-router-dom";

function SilentRenew() {
    const navigate = useNavigate()
    useEffect(() => {
        async function signinAsync() {
            await signinSilentCallback()
            navigate('/')
        }
        signinAsync()
    }, [navigate])

    return (
        <>
            Loading...
        </>
    );
}

export default SilentRenew;
