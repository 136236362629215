import {UserManager, WebStorageStateStore} from 'oidc-client';
import {storeUserError} from '../actions/authActions'
import {getFromLS, setUserDataRubicon} from "../auth/store/userSlice";
import jwtService from "./jwtService/jwtService";

const config = {
    authority: "https://login.rubicontrack.com/",
    client_id: "rbc-cli",
    redirect_uri: `${process.env.NODE_ENV !== 'production' ? 'http://localhost:3000' : 'https://rubicontrack.com'}/signin-oidc`,
    response_type: "code",
    scope: "openid profile rbc roles",
    post_logout_redirect_uri: `${process.env.NODE_ENV !== 'production' ? 'http://localhost:3000' : 'https://rubicontrack.com'}`,
    silent_redirect_uri: `${process.env.NODE_ENV !== 'production' ? 'http://localhost:3000' : 'https://rubicontrack.com'}/silent-renew`,
    response_mode: "query",
    automaticSilentRenew: true,
    store: new WebStorageStateStore({store: sessionStorage})
};

const userManager = new UserManager(config)

export async function loadUserFromStorage(store: any) {
    try {
        let user = await userManager.getUser();
        const key = user?.profile?.email + "-theme";
        console.log(key, "Key")
        const data = getFromLS(key);

        console.log(user, "Userrr")
        if (!user) {
            return store.dispatch(storeUserError())
        }
        store.dispatch(setUserDataRubicon(user, data?.settings))
    } catch (e) {
        console.error(`User not found: ${e}`)
        store.dispatch(storeUserError())
    }
}

export async function signinRedirect() {
    await userManager.getUser().then(user => {
        if (user != null && !user.expired) {
            return
        } else {
            return userManager.signinRedirect()
        }
    })
}

export function signinRedirectCallback() {
    return userManager.signinRedirectCallback()
}

export function signinSilentCallback() {
    return userManager.signinSilentCallback();
}

export function signoutRedirect() {
    userManager.clearStaleState().then(r => r)
    userManager.removeUser().then(r => r)
    return userManager.signoutRedirect();
}

export function signoutRedirectCallback() {
    userManager.clearStaleState().then(r => r);
    userManager.removeUser().then(r => r);
    return userManager.signoutRedirectCallback();
}

export function updateUser(userMetadata: any) {
    console.log(userMetadata)
    userManager.getUser().then(user => {

        console.log(user)
    })
};

// getAccessToken = () => {
//     return localStorage.getItem('access_token');
// };
//
// getIdToken = () => {
//     return window.localStorage.getItem('id_token');
// };
//
// getTokenData = () => {
//     const token = this.getIdToken();
//     const decoded = jwtDecode(token);
//     if (!decoded) {
//         return null;
//     }
//     return decoded;
// };

export function getProfile() {
    return userManager.getUser().then((user) => {
        if (user) return user.profile;
    });
}

export function getToken() {
    return userManager.getUser().then((user) => {
        if (user) return user.access_token;
    });
}

export default userManager
