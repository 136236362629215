import {ICustomTodoList, ITodo} from "../../../../types/todo-types";
import {HomeIcon} from "@heroicons/react/outline";
import {RbRoundButton} from "../../../../shared-components/rb-components/RbRoundButton";
import React, {useState} from "react";
import {Menu, MenuItem} from "@mui/material";
import {moveTaskToList} from "../../../../services/todo/todoService";

export default function TodoParentList(props: { lists: ICustomTodoList[], todo: ITodo | undefined, refreshTodos: () => void, setTodo: React.Dispatch<ITodo> }) {
    const {todo} = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleAnchor = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    function _moveTaskToList(list: ICustomTodoList) {
        setAnchorEl(null);
        if (!todo?.id || !list.listId) return;
        moveTaskToList(todo.id, list.listId).then(res => {
            if (res?.data?.error) return;
            props.setTodo({...todo, listName: list.name});
            props.refreshTodos();
        });
    }

    return (
        <>
            <h4 className="mb-10"><HomeIcon className="w-20 mr-10 inline-block align-middle"/><span
                className="inline-block align-middle">List</span></h4>
            <RbRoundButton color="secondary"
                           onClick={handleAnchor}
                           variant="contained">{todo?.listName ?? "Not set"}</RbRoundButton>
            <Menu
                id="list-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                {
                    props.lists.map(list => {
                        return (
                            <MenuItem key={list.listId} onClick={() => _moveTaskToList(list)}>
                                <span
                                    className={todo?.listName === list.name ? "font-bold" : ""}>{list.name}</span></MenuItem>
                        )
                    })
                }
            </Menu>
        </>
    )
}
