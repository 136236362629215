import {styled} from "@mui/system";
import {InputAdornment, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import clsx from "clsx";
import {SearchIcon} from "@heroicons/react/outline";
import {useTheme} from "@mui/material/styles";
import {formatToEUDate} from "../../../utils/utils";
import {IRbPaginatorChange, RbDataTablePaginator} from "./RbDataTablePaginator";
import RbText from "../RbText";

export const RbStyledDataTable = styled("table")(({theme}) => ({
    "&": {
        borderRadius: "6px",
        borderTopLeftRadius: "6px",
        borderTopLeftLeft: "6px",
        textAlign: "left"
    },
    "& tr": {
        borderBottom: "1px solid transparent"
    },
    "& td,th": {
        padding: "1rem",
    },
    "& th": {
        paddingTop: "1.5rem",
        paddingBottom: "1.5rem",
    },
    "& td:first-of-type, th:first-of-type": {
        paddingLeft: "24px",
    },
    "& td:last-of-type, th:last-of-type": {
        paddingRight: "24px",
    },
    "& tfoot > tr": {
        minHeight: "1rem"
    }
}));


export const RbSearchField = styled(TextField)(({theme}) => ({
    "& input": {
        paddingTop: "8px",
        paddingBottom: "8px",
    }
}));

// TODO: add paginator & paging
export default function RbDataTable<T>(props: { defaultPageSize?: number, pageSizes?: number[], onRowClick?: (e: React.MouseEvent<HTMLTableRowElement>, item: T) => void, paginator: boolean, hasBackground?: boolean, dataSource: T[], search: boolean, headerColumns: { name: string, objAttribute: string, search?: boolean, handleRendering?: (data: T, index: number) => JSX.Element | string, className?: string }[], colClassName?: string, rowClassName: ((obj: T) => string) | string }) {
    const [tableData, setTableData] = useState<any[]>([]);
    const theme = useTheme();
    const [paginator, setPaginator] = useState<IRbPaginatorChange>({pageIndex: 0, pageSize: 5});

    useEffect(() => {
        setTableData(props.dataSource);
    }, [props.dataSource]);

    function handleFilterTable(e: any) {
        if (!e?.target?.value) setTableData(props.dataSource);
        if ((e?.target?.value.length ?? 0) < 3 || tableData.length === 0) return;
        // if (props.paginator) return;
        setTableData(props.dataSource.filter(data => {
            return props.headerColumns.some(el => {
                if (!el?.objAttribute || el?.search === false || !(typeof ((data as any)[el?.objAttribute]) === "string")) return false;
                return (data as any)[el?.objAttribute].toLowerCase()?.startsWith(e?.target?.value.toLowerCase());
            });
        }));
    }

    function parseForDate(val: any) {
        if (val === null || val === undefined) return undefined;

        if (typeof val === "string") {
            return formatToEUDate(val);
        } else {
            return String(val);
        }
    }

    function getObjectData(obj: any, path: string | undefined): any {
        if (!path || !obj) return obj;
        if (!path.includes(".")) return obj[path];
        const parts: string[] = path.split(".");
        let data: any = obj;
        for (let i = 0; i < parts.length; i++) {
            if (!data[parts[i]]) return undefined;
            data = data[parts[i]];
        }
        return data;
    }

    function paginateTable(): any[] {
        return (props.paginator && tableData?.length > 0) ? (tableData.slice(paginator.pageIndex * paginator.pageSize, paginator.pageIndex * paginator.pageSize + paginator.pageSize)) : tableData;
    }

    return (
        <RbStyledDataTable className="w-full"
                           style={{backgroundColor: props.hasBackground === false ? "" : theme.palette.primary.light}}>
            <thead>
            {
                props.search ?
                    <tr style={{borderBottomColor: theme.palette.mode === "dark" ? "#52525B" : "#DBEAFE"}}>
                        <td className="search-td" colSpan={props.headerColumns.length}>
                            <RbSearchField
                                id="search-text-field"
                                placeholder="Search"
                                className="mb-10 mt-10 rounded-6"
                                style={{backgroundColor: theme.palette.secondary.main}}
                                hiddenLabel={true}
                                onChange={handleFilterTable}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon className="w-20"/>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="filled"
                            /></td>
                    </tr> : <></>
            }
            <tr key="rb-table-head" style={{borderBottomColor: theme.palette.mode === "dark" ? "#52525B" : "#DBEAFE"}}>
                {
                    props?.headerColumns.map(el => {
                        return <th style={{color: theme.palette.text.secondary}} key={el?.name}
                                   className={clsx(props.colClassName, el.className, "capitalize")}>{el?.name}</th>;
                    })
                }
            </tr>
            </thead>
            <tbody>
            {
                paginateTable().map((obj, index) => {
                    return <tr
                        onClick={e => {
                            if (!props?.onRowClick) return;
                            props.onRowClick(e, obj);
                        }}
                        key={`rb-row-${index}`}
                        className={(typeof props.rowClassName === "string") ? props.rowClassName : props.rowClassName(obj)}
                        style={{borderBottomColor: theme.palette.mode === "dark" ? "#52525B" : "#DBEAFE"}}>{
                        props.headerColumns.map((path, ind) => {
                            return <td className={`${props?.colClassName ?? ""} ${path?.className ?? ""}`}
                                       key={`rb-td-${ind}`}>
                                {(path.handleRendering && path?.handleRendering(obj, index)) ?? parseForDate(getObjectData(obj, path?.objAttribute)) ??
                                    <RbText theme="secondary" className="italic whitespace-nowrap">No
                                        data</RbText>}</td>
                        })
                    }
                    </tr>
                })
            }
            </tbody>
            <tfoot>
            <tr>
                <td colSpan={props.headerColumns.length}>
                    {
                        tableData.length === 0 ?
                            <h4 className="italic text-center" style={{color: theme.palette.text.secondary}}>No data
                                available</h4> : props.paginator &&
                            <RbDataTablePaginator recordsSize={tableData.length} onChange={p => setPaginator(p)}
                                                  pageSizes={props.pageSizes} defaultPageSize={props?.defaultPageSize}/>
                    }
                </td>
            </tr>
            </tfoot>
        </RbStyledDataTable>
    )
}

RbDataTable.defaultProps = {
    search: false,
    paginator: false,
    rowClassName: ""
}
