import {ISubTask, ITodo} from "../../../../types/todo-types";
import {RbTextField} from "../../../../shared-components/rb-components/RbTextField";
import {FlagIcon} from "@heroicons/react/solid";
import {IconButton, Menu, MenuItem} from "@mui/material";
import React, {useEffect, useState} from "react";
import {
    createSubTask,
    getTaskSubTasks,
    removeSubTask, toggleSubTask,
    updateTaskName,
    updateTaskPriority
} from "../../../../services/todo/todoService";
import {RbTheme} from "../../../../types/common-types";
import {useTheme} from "@mui/styles";
import {RbSubSectionItem} from "app/shared-components/rb-components/RbSections";
import {PlusIcon, XIcon} from "@heroicons/react/outline";
import {RbCheckBoxIcon} from "../../../../shared-components/rb-components/RbCheckBoxIcon";
import HeroIcon from "../../../../../@fuse/core/HeroIcon/HeroIcon";
import RbContentEditable from "../../../../shared-components/rb-components/RbContentEditable";

export default function TodoName(props: { todo: ITodo | undefined, refreshTodos: (all?: boolean) => void, setTodo: React.Dispatch<ITodo | undefined> }) {
    const theme: RbTheme = useTheme();
    const {todo} = props;
    const prioritiesString: string[] = ["None", "Low", "Medium", "High"];
    const [anchorElFlag, setAnchorElFlag] = useState<null | SVGSVGElement>(null);
    const [subTasks, setSubTasks] = useState<ISubTask[]>([]);

    useEffect(() => {
        _getTaskSubTasks();
    }, []);

    function _getTaskSubTasks() {
        if (!todo?.id) return;
        getTaskSubTasks(todo.id).then(res => {
            if (res?.data?.error) return;
            setSubTasks(res.data.data);
        });
    }

    function getPriorityColor(id: number | undefined = undefined): string {
        switch (id ?? todo?.priorityId ?? -1) {
            case 1:
                return theme.priority.low;
            case 2:
                return theme.priority.medium;
            case 3:
                return theme.priority.high;
            default:
                return theme.palette.text.primary;
        }
    }

    function handleTaskNameUpdate(e: any) {
        if (e.target.value === todo?.name || !todo?.id) return;
        updateTaskName(todo.id, e.target.value).then(res => {
            if (res?.data?.error) return;
            props.refreshTodos();
        });
    }

    function handlePriority(i: number) {
        if (!todo?.id) return;
        updateTaskPriority(todo.id, i).then(res => {
            if (res?.data?.error) return;
            props.refreshTodos(true);
            props.setTodo({...todo, priorityId: i});
        });
    }

    function handleSaveSubTask(value: string) {
        createSubTask(todo!.id, value).then(res => {
            if (res?.data?.error) return;
            _getTaskSubTasks();
        });
    }

    function _removeSubTask(subTaskId: string) {
        removeSubTask(todo!.id, subTaskId).then(res => {
            if (res?.data?.error) return;
            _getTaskSubTasks();
        });
    }

    function toggleSubTaskChecked(stepId: string) {
        toggleSubTask(stepId).then();
    }

    return (
        <>
            <RbTextField className="w-full block"
                         variant="outlined"
                         label="Task"
                         defaultValue={todo?.name}
                         InputProps={{
                             endAdornment:
                                 <FlagIcon color={getPriorityColor()}
                                           onClick={e => setAnchorElFlag(e.currentTarget)}/>
                         }}
                         onBlur={handleTaskNameUpdate}
                         color="primary"/>
            <RbSubSectionItem>
                {
                    subTasks.map(task => {
                        return (
                            <div className="flex justify-between items-center">
                           <span>
                               <RbCheckBoxIcon
                                   className="inline-block align-middle mr-5 w-20"
                                   onChange={() => toggleSubTaskChecked(task.id)}
                                   defaultChecked={task.isChecked}
                                   icon={<HeroIcon icon="CheckCircleIcon" outline={true}/>}
                                   checkedIcon={<HeroIcon icon="CheckCircleIcon" outline={false}/>}/>
                               <span className="inline-block align-middle">{task.name}</span>
                           </span>
                                <IconButton className="ml-10 inline-block align-sub"
                                            onClick={() => _removeSubTask(task.id)}>
                                    <XIcon className="w-20"/>
                                </IconButton>
                            </div>
                        )
                    })
                }
                <RbSubSectionItem>
                    <PlusIcon className="w-20 inline-block align-middle mr-5"/>
                    <RbContentEditable placeholder="Add subtask" className="w-[initial]" childClassName="pt-3 mb-3"
                                       onConfirm={handleSaveSubTask}>
                        <span>Add subtask</span>
                    </RbContentEditable>
                </RbSubSectionItem>
            </RbSubSectionItem>
            <Menu
                id="priority-menu"
                anchorEl={anchorElFlag}
                open={!!anchorElFlag}
                onClose={() => setAnchorElFlag(null)}
            >
                {
                    prioritiesString.map((p, i) => {
                        return (
                            <MenuItem key={p} onClick={() => handlePriority(i)}>
                                <FlagIcon className="w-20 mr-10" color={getPriorityColor(i)}/>
                                {p}</MenuItem>
                        )
                    })
                }
            </Menu>
        </>
    )
}
