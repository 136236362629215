import {useSnackbar} from "notistack";
import {useEffect} from "react";
import jwtService from "../../services/jwtService/jwtService";

export default function SnackbarHandler() {
    const snackbar = useSnackbar();

    // snackbar.enqueueSnackbar("Test: Default", {variant: "default"});
    // snackbar.enqueueSnackbar("Test: Info", {variant: "info"});
    // snackbar.enqueueSnackbar("Test: Success", {variant: "success"});
    // snackbar.enqueueSnackbar("Test: Warning", {variant: "warning"});
    // snackbar.enqueueSnackbar("Test: Error", {variant: "error"});

    useEffect(() => {
        jwtService.on("error", (message: string) => {
            snackbar.enqueueSnackbar(message, {variant: "error"});
            // dispatch(showMessage({message: "test", variant: "error"}));
        })

        jwtService.on("api_error", (err: { status: number, statusText: string }) => {
            snackbar.enqueueSnackbar(`${err?.status ?? "Error"}: ${err?.statusText ?? "Unexpected or unknown error has occurred"}`, {variant: "error"});
            // dispatch(showMessage({message: "test", variant: "error"}));
        })

        jwtService.on("info", (message: string) => {
            snackbar.enqueueSnackbar(message, {variant: "info"});
            // dispatch(showMessage({message: "test", variant: "error"}));
        })

        jwtService.on("success", (message: string) => {
            snackbar.enqueueSnackbar(message, {variant: "success"});
            // dispatch(showMessage({message: "test", variant: "error"}));
        })
    }, []);

    return (<></>)
}
