import {IProjectOverviewMember} from "../../../types/project-overview-types";
import {IRbDisplayHeader} from "../../../types/common-types";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import * as React from "react";
import RbProtectedTextField from "../../../shared-components/rb-components/RbProtectedTextField";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {ChevronDownIcon} from "@heroicons/react/outline";
import {useState} from "react";

export default function ProjectOverviewMembers(props: { data: IProjectOverviewMember[] | undefined }) {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "Name",
            objAttribute: "user.name",
        },
        {
            name: "Position",
            objAttribute: "position"
        },
        {
            name: "Member type",
            objAttribute: "memberType"
        },
        {
            name: "Is automatic",
            objAttribute: "isAutomatic"
        },
        {
            name: "Price per hour",
            objAttribute: "pricePerHour",
        },
        {
            name: "Price per month",
            objAttribute: "pricePerMonth",
        },
        {
            name: "Expected monthly hours",
            objAttribute: "expectedHoursPerMonth"
        },
        {
            name: "Price set on",
            objAttribute: "priceFrom"
        },
    ];

    function handleUserProjectPrice(item: IProjectOverviewMember) {
        if (item?.pricePerMonth === 0 || item?.pricePerHour === 0) {
            return (
                <RbBox onClick={e => setAnchor(e.currentTarget)}
                       theme="secondary"
                       className="flex items-center cursor-pointer justify-between px-10 min-w-[12rem] w-fit h-28 ml-10 whitespace-nowrap overflow-hidden">
                    <span>{item?.pricePerMonth ?? "Not set"}</span>
                    <ChevronDownIcon className="w-20"/>
                </RbBox>
            );
        } else {
            return <></>;
        }
    }

    function handleMonthlyHours(item: IProjectOverviewMember) {
        return <RbProtectedTextField variant="outlined" size="small" slim={true}
                                     value={String(item.expectedHoursPerMonth)}/>
    }

    return (
        <div className="my-10">
            <RbDataTable
                search={true}
                dataSource={props?.data ?? []}
                headerColumns={displayHeaders}/>
        </div>
    )
}
