import clsx from "clsx";
import Popover from "@mui/material/Popover";
import {useState} from "react";
import {styled} from "@mui/system";
import {useTheme} from "@mui/material/styles";
import RbBox from "../RbBox";
import RbColorMenu from "./RbColorMenu";


export const RbColorButton = styled("button")(() => ({
    "&": {
        marginLeft: "0.6rem"
    },
}));

export default function RbColorPickerMenu(props: { anchorEl: any, handleMenuClose: () => void, onSelect: (color: string) => void, className?: string }) {
    const theme = useTheme();

    return (
        <Popover
            open={Boolean(props.anchorEl)}
            anchorEl={props.anchorEl}
            onClose={props.handleMenuClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div style={{backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "initial"}}>
                <RbColorMenu onSelect={props.onSelect}/>
            </div>
        </Popover>
    )
}
