import {IProjectOverviewByBoard, IProjectOverviewByUser} from "../../../types/project-overview-types";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {IRbDisplayHeader} from "../../../types/common-types";
import RbBox from "../../../shared-components/rb-components/RbBox";

export default function ProjectOverviewByBoards(props: { data: IProjectOverviewByBoard[] | undefined }) {
    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "Name",
            objAttribute: "boardName"
        },
        {
            name: "Members",
            objAttribute: "totalMembers"
        },
        {
            name: "Active members",
            objAttribute: "activeMembers"
        },
        {
            name: "Tasks",
            objAttribute: "totalTasks"
        },
        {
            name: "Active tasks",
            objAttribute: "activeTasks"
        },
        {
            name: "Mark as done",
            objAttribute: "markAsDone"
        },
        {
            name: "Overdue",
            objAttribute: "overdue"
        },
        {
            name: "Total time",
            objAttribute: "totalTime"
        },
        {
            name: "Project price",
            objAttribute: "boards"
        },
        {
            name: "User price",
            objAttribute: "boards"
        },
        {
            name: "Last activity",
            objAttribute: "lastActivity"
        },
    ];
    return (
        <RbBox className="px-0 mb-10">
            <h2 className="px-16">Boards</h2>
            <RbDataTable dataSource={props.data ?? []} headerColumns={displayHeaders}/>
        </RbBox>
    )
}
