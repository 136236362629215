import {useEffect, useState} from "react";
import {IOrganizationTeam} from "../../../types/organization-settings-types";
import {getOrganizationTeams, deleteTeam} from "../../../services/organizationSettingsService";
import {IRbDisplayHeader, UserOrganization} from "../../../types/common-types";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import {RbSubSectionItem} from "app/shared-components/rb-components/RbSections";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {AvatarGroup, Tooltip} from "@mui/material";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {PlusIcon} from "@heroicons/react/outline";
import {useTheme} from "@mui/material/styles";
import RbTableActionButton from "../../../shared-components/rb-components/RbDataTable/RbTableActionButton";
import {getUserInitials} from "../../../utils/RbUtils";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";
import RbConfirmationDialog from "../../../shared-components/dialogs/RbConfirmationDialog";
import TeamsCreateDialog from "./TeamsCreateDialog";

function TeamsOrganizationSettings(props: { organization: UserOrganization }) {
    const [teamCreateOpen, setTeamCreateOpen] = useState<boolean>(false);
    const [teamForDelete, setTeamForDelete] = useState<IOrganizationTeam>();
    const [teams, setTeams] = useState<IOrganizationTeam[]>([]);
    const theme = useTheme();

    const displayTeamsHeaders: IRbDisplayHeader[] = [
        {
            name: "Name",
            objAttribute: "name",

        },
        {
            name: "Description",
            objAttribute: "description",
        },
        {
            name: "Members",
            objAttribute: "members",
            className: "text-right max-w-72",
            handleRendering: renderMembers
        },
        {
            name: "",
            objAttribute: "",
            handleRendering: renderTableActions,
            className: "text-right max-w-32"
        }
    ];

    useEffect(() => {
        _getOrganizationTeams();
    }, []);

    function renderTableActions(data: IOrganizationTeam) {
        return <RbTableActionButton icon="TrashIcon" onClick={() => {
            setTeamForDelete(data);
        }}/>;
    }

    function renderMembers(data: IOrganizationTeam) {
        return (
            <>
                <AvatarGroup max={4}>
                    {
                        data.members.map((user, i) => {
                            return (
                                <Tooltip title={user.name} key={i} placement="bottom">
                                    <Avatar sizes="small" className="w-24 h-24 text-13"
                                            src={user?.avatar}>{!user?.avatar ? getUserInitials(user.name) : ""}</Avatar>
                                </Tooltip>
                            )
                        })
                    }
                </AvatarGroup>
            </>
        )
    }

    function _getOrganizationTeams() {
        if (!props?.organization?.id) return;
        getOrganizationTeams(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setTeams(res.data.data);

        });
    }

    function handleDeleteTeam() {
        if (!teamForDelete?.id) return;
        deleteTeam(teamForDelete.id).then(res => {
            if (res?.data?.error) return;
            setTeamForDelete(undefined);
            _getOrganizationTeams();
        });
    }

    return (
        <div className="mt-48 md:ml-28 sm:ml-28 sm:mr-28 ml-64 mr-64 mb-48">
            <div className="w-full flex justify-between">
                <h2 className="font-semibold">Teams</h2>
                <RbRoundButton variant="contained" onClick={() => setTeamCreateOpen(true)}><PlusIcon
                    className="w-16 inline-block mr-5 align-middle"/>
                    <span className="inline-block align-middle">Create team</span></RbRoundButton>
            </div>
            <TeamsCreateDialog organizationId={props?.organization?.id} open={teamCreateOpen}
                               onClose={(update) => {
                                   if (update) {
                                       _getOrganizationTeams();
                                   }
                                   setTeamCreateOpen(false);
                               }}/>
            <RbConfirmationDialog onClose={() => setTeamForDelete(undefined)} onConfirm={handleDeleteTeam}
                                  open={!!teamForDelete} isDelete={true}>
                Are you sure you want to remove <span className="font-bold italic">{teamForDelete?.name ?? ""}</span>?
            </RbConfirmationDialog>
            <RbSubSectionItem>
                <RbDataTable search={true} dataSource={teams} headerColumns={displayTeamsHeaders}/>
            </RbSubSectionItem>
        </div>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(TeamsOrganizationSettings));
