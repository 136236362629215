import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {editTicketSystemName, getTicketsForSystem} from "../../../services/ticketsService";
import {ISystemTickets} from "../../../types/tickets-types";
import {TabContext, TabPanel} from "@mui/lab";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {ClipboardCopyIcon, TicketIcon} from "@heroicons/react/outline";
import {RbLink} from "app/shared-components/rb-components/RbLink";
import TicketsCardsView from "./tickets-view/TicketsCardsView";
import TicketsListView from "./tickets-view/TicketsListView";
import TicketSystemSettings from "../settings/TicketSystemSettings";
import TicketSystemCategories from "../settings/TicketSystemCategories";
import RbContentEditable from "../../../shared-components/rb-components/RbContentEditable";
import copy from "clipboard-copy";

export default function TicketsOfSystem() {
    const {id} = useParams();
    const [categoriesOpen, setCategoriesOpen] = useState<boolean>(false);
    const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
    const [systemTickets, setSystemTickets] = useState<ISystemTickets>()
    const [tab, setTab] = useState<string>("tile view");
    const tabs = ["tile view", "list view"];

    useEffect(() => {
        _getTicketsForSystem();
    }, [id]);

    function _getTicketsForSystem() {
        if (!id) return;
        getTicketsForSystem(id).then(res => {
            if (res?.data?.error) return;
            setSystemTickets(res.data.data);
        });
    }

    function handleSystemRename(name: string) {
        if (!name || name === systemTickets?.name || !systemTickets?.id) return;
        editTicketSystemName(systemTickets.id, name).then(res => {
            if (res?.data?.error) return;
            setSystemTickets({...systemTickets, name: name});
        })
    }

    function handleRename(name: string) {
        setSystemTickets({...systemTickets, name: name} as ISystemTickets);
    }

    return (
        <div>
            <div className="flex my-12 mx-24 justify-between">
                <div>
                    {
                        tabs.map(t => {
                            return <RbRoundButton key={t} className="pl-16 pr-16 mr-10 capitalize"
                                                  onClick={() => setTab(t)}
                                                  variant={t === tab ? "contained" : "text"}>{t}</RbRoundButton>
                        })
                    }
                </div>
                <RbRoundButton color="primary" className="py-0 flex items-center" variant="contained"
                               onClick={() => copy(`${window.location.origin}/ticket/manage/${systemTickets?.id ?? ""}`)}>
                    <ClipboardCopyIcon className="w-16 mr-8"/>
                    <span>Copy public link</span>
                </RbRoundButton>
            </div>
            <RbBox className="px-24 py-20 flex justify-between">
                <RbContentEditable onConfirm={handleSystemRename}>
                    <h2>{systemTickets?.name ?? "Ticket system name"}</h2>
                </RbContentEditable>
                <div className="flex items-center">
                    <RbLink to="/ticket-systems">
                        <RbRoundButton color="inherit" className="py-0 flex items-center h-28" variant="outlined"
                                       onClick={() => setSettingsOpen(true)}>
                            <TicketIcon className="w-16 mr-8"/>
                            <span>Systems</span>
                        </RbRoundButton>
                    </RbLink>
                    <RbRoundButton className="ml-10 py-2 px-12 h-28" variant="text"
                                   onClick={() => setSettingsOpen(true)}>Settings</RbRoundButton>
                </div>
            </RbBox>
            <TabContext value={tab}>
                <TabPanel value={"tile view"} className="px-24">
                    <TicketsCardsView title="Open tickets"
                                      data={(systemTickets?.tickets ?? []).filter(t => !t.closeDate)}/>
                    <TicketsCardsView title="Closed tickets" className="opacity-50"
                                      data={(systemTickets?.tickets ?? []).filter(t => !!t.closeDate)}/>
                </TabPanel>
                <TabPanel value={"list view"} className="px-24">
                    <TicketsListView title="Open tickets"
                                     data={(systemTickets?.tickets ?? []).filter(t => !t.closeDate)}/>
                    <TicketsListView title="Closed tickets" className="opacity-50"
                                     data={(systemTickets?.tickets ?? []).filter(t => !!t.closeDate)}/>
                </TabPanel>
            </TabContext>
            <TicketSystemSettings ticketSystem={systemTickets} open={settingsOpen} updateTicketSettings={_getTicketsForSystem}
                                  openCategories={() => setCategoriesOpen(true)}
                                  onClose={() => setSettingsOpen(false)} systemRename={handleRename}/>
            <TicketSystemCategories ticketSystem={systemTickets} open={categoriesOpen}
                                    onClose={() => setCategoriesOpen(false)}/>
        </div>
    )
}
