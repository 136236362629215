import React, {useEffect, useState} from "react";
import {ITodoFriend} from "../../../types/todo-types";
import {getFriendList, getFriendRequests} from "../../../services/todo/todoService";
import TodoFriends from "./TodoFriends";
import TodoFriendInvites from "./TodoFriendInvites";
import {ChevronLeftIcon} from "@heroicons/react/outline";
import {IconButton} from "@mui/material";

export default function TodoFriendList() {
    const [friendRequests, setFriendRequests] = useState<ITodoFriend[]>([]);
    const [isInvites, setIsInvites] = useState<boolean>(false);
    const [friendList, setFriendList] = useState<ITodoFriend[]>([]);

    useEffect(() => {
        _getFriendList();
        _getFriendRequests();
    }, []);

    function _getFriendList() {
        getFriendList().then(res => {
            if (res?.data?.error) return;
            setFriendList(res.data.data);
        });
    }

    function _getFriendRequests() {
        getFriendRequests().then(res => {
            if (res?.data?.error) return;
            setFriendRequests(res.data.data);
        });
    }

    return (
        <div className="p-20 w-400">
            <h2 className="mb-5">{isInvites ?
                <span>
                    <IconButton className="inline-block align-middle"
                                onClick={() => setIsInvites(false)}>
                    <ChevronLeftIcon className="w-20"/>
                    </IconButton>
                    <span className="ml-10 inline-block align-middle">Pending invites</span>
                </span>
                : "To do friends"}</h2>
            <hr/>
            {
                !isInvites ?
                    <TodoFriends friendList={friendList} refreshFriendList={_getFriendList}
                                 friendResults={friendRequests.length} setIsInvites={setIsInvites}/> :
                    <TodoFriendInvites refreshFriends={_getFriendList} friendRequests={friendRequests}
                                       refreshFriendRequests={_getFriendRequests}/>
            }
        </div>
    )
}
