import {RbDialog} from "../../../shared-components/dialogs/RbDialog";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import React, {useState} from "react";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";
import {
    addTicketSystemCategory,
    addTicketSystemSubCategory,
    createTicketSystem
} from "../../../services/ticketsService";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import Popover from "@mui/material/Popover";
import RbContentEditable from "../../../shared-components/rb-components/RbContentEditable";
import {ChevronDownIcon} from "@heroicons/react/outline";

export default function TicketsSystemDialog(props: { open: boolean, onClose: () => void, organizationId?: string, newCreated?: () => void }) {
    const [systemName, setSystemName] = useState<string>("");
    const [systemId, setSystemId] = useState<string>();
    const [categories, setCategories] = useState<{ id: string, name: string }[]>([]);
    const [subcategories, setSubcategories] = useState<{ category: { id: string, name: string }, subCategories: string[] }[]>([]);

    const [subcategoryAnchorEl, setSubcategoryAnchorEl] = useState<HTMLElement | null>(null)
    const [categoryAnchorEl, setCategoryAnchorEl] = useState<HTMLElement | null>(null)

    function _addTicketSystemCategory(name: string) {
        if (!systemId || !name) return;
        addTicketSystemCategory(systemId, name).then(res => {
            if (res?.data?.error) return;
            setCategories([...categories, {id: res.data.data, name}]);
            setSubcategories([...subcategories, {category: {id: res.data.data, name}, subCategories: []}]);
        });
    }

    function _addTicketSystemSubCategory(category: any, name: string) {
        if (!systemId || !name || !category?.id) return;
        addTicketSystemSubCategory(category.id, name).then(res => {
            if (res?.data?.error) return;
            const i = subcategories.findIndex(s => s?.category?.id === category?.id);
            if (i === -1) return;
            const tmp = [...subcategories];
            tmp[i].subCategories.push(name);
            setSubcategories(tmp);
        });
    }

    function _createTicketSystem() {
        if (!props.organizationId || !systemName) return;
        createTicketSystem(props.organizationId, systemName).then(res => {
            if (res?.data?.error) {
                setSystemId("");
            } else {
                if (props?.newCreated) props.newCreated();
                setSystemId(res.data.data);
            }
        });
    }

    return (
        <RbDialog
            open={props.open}
            onClose={() => {
                props.onClose();
                setCategories([]);
                setSubcategories([]);
                setSystemId(undefined);
                setSystemName("");
            }}
            aria-labelledby="ticket-system-dialog">
            <DialogTitle id="ticket-system-dialog">Create ticket system</DialogTitle>
            <DialogContent>
                <div className="pt-10">
                    <RbTextField className="pb-10 w-full"
                                 label="Title"
                                 placeholder="System name"
                                 variant="outlined"
                                 size="small"
                                 value={systemName}
                                 onChange={e => setSystemName(e.target.value)}
                                 onBlur={_createTicketSystem}
                                 disabled={!!systemId}
                                 color="primary"/>
                    <RbTextField className="pb-10 w-full mt-20"
                                 onClick={e => {
                                     if (!systemId) return;
                                     setCategoryAnchorEl(e.currentTarget)
                                 }}
                                 label="Category"
                                 placeholder="Category name"
                                 variant="outlined"
                                 value={categories.length > 0 ? categories.map(c => c.name).join(", ") : "Create"}
                                 size="small"
                                 InputLabelProps={{
                                     shrink: true,
                                 }}
                                 disabled={!systemId}
                                 color="primary"/>
                    <RbTextField className="pb-10 w-full mt-20"
                                 onClick={e => {
                                     if (!systemId) return;
                                     setSubcategoryAnchorEl(e.currentTarget)
                                 }}
                                 label="Subcategory"
                                 placeholder="Subcategory name"
                                 variant="outlined"
                                 value={subcategories.length > 0 ? subcategories.map(s => `${s.category.name}: ${s.subCategories.join(", ")}`).join(" | ") : "Create"}
                                 size="small"
                                 InputLabelProps={{
                                     shrink: true,
                                 }}
                                 disabled={!systemId || categories.length === 0}
                                 color="primary"/>
                </div>
            </DialogContent>
            <DialogActions className="px-20 pb-10 pt-0">
                <RbRoundButton variant="contained" onClick={props.onClose} disabled={!systemId}>Done</RbRoundButton>
            </DialogActions>


            <Popover
                open={Boolean(categoryAnchorEl)}
                anchorEl={categoryAnchorEl}
                onClose={() => setCategoryAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className="p-10 min-w-256">
                    {
                        categories.map((c, i) => {
                            return (
                                <span className="py-5 block" key={i}>{c.name}</span>
                            )
                        })
                    }
                    <RbContentEditable onConfirm={name => _addTicketSystemCategory(name)}>
                        <span className="cursor-pointer">+ Create new category</span>
                    </RbContentEditable>
                </div>
            </Popover>

            <Popover
                open={Boolean(subcategoryAnchorEl)}
                anchorEl={subcategoryAnchorEl}
                onClose={() => setSubcategoryAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className="min-w-320">
                    {
                        subcategories.map((c, i) => {
                            return (
                                <Accordion key={i} disableGutters={true}>
                                    <AccordionSummary
                                        expandIcon={<ChevronDownIcon className="w-20"/>}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <span>{c.category.name}</span>
                                    </AccordionSummary>
                                    <AccordionDetails className="ml-10 pt-0">
                                        {
                                            c.subCategories.map((s, i) => {
                                                return (
                                                    <span className="py-5 block" key={i}>{s}</span>
                                                )
                                            })
                                        }
                                        <RbContentEditable
                                            onConfirm={name => _addTicketSystemSubCategory(c.category, name)}>
                                            <span className="cursor-pointer">+ Create new subcategory</span>
                                        </RbContentEditable>
                                    </AccordionDetails>
                                </Accordion>
                            )
                        })
                    }
                </div>
            </Popover>

        </RbDialog>
    )
}
