import {IReports, IReportTrackerActivity} from "../../../types/report-types";
import ReportTrackerByCategory from "../widgets/ReportTrackerByCategory";
import ReportTrackerByCategoryAndUser from "../widgets/ReportTrackerByCategoryAndUser";
import ReportTrackerReport from "../widgets/ReportTrackerReport";
import ReportTrackerProject from "../widgets/ReportTrackerByProject";
import ReportBoardWorkload from "../widgets/ReportBoardWorkload";
import ReportTrackerActivity from "../widgets/ReportTrackerActivity";

export default function TrackerReports(props: { refreshActivity?: () => void, data?: IReports, projectId?: string, workspaceId?: string, trackerActivity: IReportTrackerActivity[] | undefined }) {
    return (
        <div>
            <ReportTrackerReport data={props.data} projectId={props.projectId} workspaceId={props.workspaceId}/>
            <ReportTrackerProject data={props.data?.byProjects}/>
            <ReportTrackerByCategory data={props.data?.byCategories}/>
            <ReportTrackerByCategoryAndUser data={props.data?.byUserAndCategories}/>
            <ReportTrackerActivity data={props.trackerActivity} refreshActivity={props.refreshActivity}/>
        </div>
    )
}
