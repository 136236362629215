import RbBox from "../../../shared-components/rb-components/RbBox";
import {ITrackerByCategory, ITrackerByProject} from "../../../types/report-types";
import Chart from 'react-apexcharts'
import {useEffect, useState} from "react";
import {IRbDisplayHeader, RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {styled} from "@mui/system";
import {RbColors} from "../../../shared-components/rb-components/RbColorMenu/RbColorMenu";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";


const RbChart = styled(Chart)(() => ({
    '& svg': {
        backgroundColor: "transparent !important",
    },
}));

export default function ReportTrackerProject(props: { title?: string, data?: ITrackerByProject[] }) {
    const theme: RbTheme = useTheme();
    const [series, setSeries] = useState<any>([1, 2, 3, 4]);
    const [options, setOptions] = useState<any>({
        chart: {
            id: "project-donut",
            events: {
                dataPointSelection: handleChartClick
            },
            pattern: {
                strokeWidth: 4
            }
        },
        legend: {
            show: false
        },
        tooltip: {
            cssClass: "rbTooltip"
        },
        dataLabels: {
            enabled: false
        },
        colors: RbColors,
        labels: (props.data ?? []).map(p => p.project),
        theme: {mode: theme.palette.mode}
    });


    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "",
            objAttribute: "color",
            className: "w-32",
            handleRendering: handleColorRender,
        },
        {
            name: "Project",
            objAttribute: "project",
        },
        {
            name: "Billable",
            objAttribute: "billable"
        },
        {
            name: "Billable time",
            objAttribute: "billableTime"
        },
        {
            name: "Unbillable",
            objAttribute: "unbillable"
        },
        {
            name: "Unbillable time",
            objAttribute: "unbillableTime"
        },
        {
            name: "Total trackers",
            objAttribute: "totalTrackers"
        },
        {
            name: "Total time",
            objAttribute: "totalTime"
        },
        {
            name: "Total price",
            objAttribute: "totalPrice"
        },
    ];

    function handleColorRender(item: ITrackerByCategory, index?: number): JSX.Element {
        return <span className="block w-20 h-20 rounded-full"
                     style={{backgroundColor: RbColors[index ?? 0]}}></span>
    }

    function getNumberedValue(val: string): number {
        const tmp = val.split(":");
        return Number(tmp[0]) * 60 + Number(tmp[1]);
    }

    useEffect(() => {
        if ((props.data ?? []).length > 0) {
            setOptions({...options, labels: (props.data ?? []).map(p => p.project)})
            setSeries((props.data ?? []).map(p => getNumberedValue(p.totalTime)));
        } else {
            setOptions({...options, labels: []})
            setSeries([]);
        }
    }, [props.data]);

    function handleChartClick(e: any, chart?: any, options?: { dataPointIndex: number, seriesIndex: number }) {
        console.log(options);
    }

    return (
        <RbBox className="px-32 py-24 my-20">
            <h2 className="font-bold">
                Tracker report by project
            </h2>
            <div className="flex">
                <span className="flex items-center lg:hidden sm:hidden md:hidden">
                    <RbChart options={options} series={series} type="donut" width="380"/>
                </span>
                <RbDataTable dataSource={props.data ?? []} headerColumns={displayHeaders}/>
            </div>
        </RbBox>
    )
}
