import {_get, _patch} from "../apiService";

export const getAllUserOrganizations = () => {
    return _get(`common/user-organizations`);
}

export const updateUserStatus = (statusId: number) => {
    return _patch(`user/${statusId}/update-status`, null);
}

export const getAllUserStatuses = () => {
    return _get(`user/statuses`);
}

export const getUserOrganizationRoles = (organizationId: string) => {
    return _get(`user/${organizationId}/user-roles`);
}
