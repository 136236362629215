import {IBoardTrackerHistory} from "../../../types/board-types";
import moment from "moment";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";

export default function BoardTrackerHistory(props: { trackerHistory: IBoardTrackerHistory[] }) {
    const theme: RbTheme = useTheme();
    return (
        <>
            {
                props.trackerHistory.length > 0 ?
                    <table className="w-full text-left mt-24">
                        <thead>
                        <tr style={{color: theme.palette.text.secondary}}>
                            <th className="pb-10">User</th>
                            <th className="pb-10">Category</th>
                            <th className="pb-10">Start time</th>
                            <th className="pb-10">Duration</th>
                            <th className="pb-10">Inserted at</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            props.trackerHistory.map((t, i) => {
                                return (
                                    <tr key={i}>
                                        <td className="pb-10">{t.user}</td>
                                        <td className="pb-10">
                                            {
                                                !!t.label && <span className="py-5 px-10 rounded-12"
                                                                   style={{backgroundColor: t?.labelColor ?? theme.palette.secondary.main}}>{t.label}</span>
                                            }
                                        </td>
                                        <td className="pb-10">{new Date(t.startDate).toLocaleString("de-DE")}</td>
                                        <td className="pb-10">{t.duration}</td>
                                        <td className="pb-10">{moment(t.createdAt).fromNow()}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table> :
                    <img src="/assets/images/illustrations/boards/no_trackers.svg"
                         className="m-auto max-w-128 pt-32 w-full"
                         alt="No trackers"/>
            }
        </>
    )
}
