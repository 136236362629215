import React from 'react';
import {Button, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import {RbDialog} from './RbDialog';
import {RbTheme} from "../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {XIcon} from "@heroicons/react/outline";

type DeleteType = "YesNo" | "CancelDelete";

const RbConfirmationDialog = (props: { type?: DeleteType, isDelete: boolean, title?: string, children?: any, open: boolean, onClose: () => void, onConfirm: () => void }) => {
    const theme: RbTheme = useTheme();

    return (
        <RbDialog
            open={props.open}
            onClose={props?.onClose}
            aria-labelledby="confirm-dialog">
            <DialogTitle id="confirm-dialog"
                         className={`py-8 whitespace-nowrap ${props.isDelete ? "delete" : ""}`}
                         style={{
                             color: theme.palette.text.primary
                         }}>
                <span className="flex justify-between">
                    <span className="whitespace-nowrap mr-20 min-w-320">{props.title}</span>
                    <IconButton className="p-5" onClick={props.onClose}>
                        <XIcon className="w-20"/>
                    </IconButton>
                </span>
            </DialogTitle>
            <DialogContent>{props?.children}</DialogContent>
            <DialogActions>
                <Button
                    style={{color: theme.palette.text.primary}}
                    variant="text"
                    onClick={props.onClose}
                >
                    {props.type === "YesNo" ? "No" : "Cancel"}
                </Button>
                <Button
                    style={{color: theme.palette.text.primary}}
                    variant="contained"
                    onClick={() => {
                        props.onConfirm();
                        props.onClose();
                    }}
                    color={props.isDelete ? "warning" : "primary"}
                >
                    {props.type === "YesNo" ? "Yes" : "Delete"}
                </Button>
            </DialogActions>
        </RbDialog>
    );
}
export default RbConfirmationDialog;
RbConfirmationDialog.defaultProps = {
    isDelete: false,
    title: "Confirmation"
}
