import React, {useEffect, useState} from 'react';
import {
    AppBar,
    Button,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    Toolbar,
    Tooltip
} from "@mui/material";
import {DesktopDatePicker} from "@mui/lab";
import {ChatAltIcon, CurrencyDollarIcon, HandIcon, ShieldCheckIcon} from "@heroicons/react/outline";
import clsx from "clsx";
import TrackerAddLg from "./tracker-add-lg/TrackerAddLg";
import {
    getAllCardsByProjectId,
    manualTracker,
    getRunningTracker,
    startTracker,
    stopTracker,
    getTrackerCategories, editBillable,
} from "app/services/tracker/trackerService";
import {ITracker, ITrackerCard, ITrackerCategory} from "../../../types/tracker-types";
import {
    getHoursFromMilliseconds,
    getMinutesFromMilliseconds,
    getSecondsFromMillisecond,
    parseTime
} from "../../../utils/utils";
import useMediaQuery from "@mui/material/useMediaQuery";
import moment from "moment";
import {getAllOrganizationProjects} from 'app/services/common/common';
import {IProjectDropDown} from "../../../types/common-types";
import withRouter from '@fuse/core/withRouter';
import {connect} from 'react-redux';
import {useRbDispatch} from "../../../rb-hooks/hooks";
import {updateGlobalTracker} from "../../../store/rubicon/globalTrackerSlice";

const TrackerAdd = (props: any) => {
    const {refreshTrackers, organization, tracker} = props;
    const [inter, setInter]: any = useState("");
    const [duration, setDuration] = useState("00:00:00");
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const matches = useMediaQuery("(min-width:1421px)");
    const matches1 = useMediaQuery("(min-width:1646px)");
    let [manual, setManual] = useState(false);
    let [numCol, setNumCol] = useState(9);
    const [projects, setProjects] = useState<IProjectDropDown[]>([]);
    const [cards, setCards] = useState<ITrackerCard[]>([]);
    const [categories, setCategories] = useState<ITrackerCategory[]>([]);
    const [previewStartDate, setPreviewStartDate] = useState<Date>(new Date());
    const dispatch = useRbDispatch();

    // @ts-ignore
    // let trackerTimer: TrackerTimer = {};
    const defaultValues = {
        id: "",
        description: "",
        projectId: undefined,
        cardId: undefined,
        labelId: undefined,
        durationHour: 0,
        durationMinute: 0,
        durationSecond: 0,
        duration: "00:00:00",
        billable: true,
        startDate: new Date().toLocaleString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        }),
        startTime: new Date().toLocaleTimeString("de-DE") || undefined,
        endTime: new Date().toLocaleTimeString("de-DE") || undefined,
    }

    // Postavlja vrednost input-a unutar jedne vrednosti, kao forma
    const [formValues, setFormValues] = useState(defaultValues);

    const handleFormValueChange = (name: string) => (event: { target: { value: string; }; }) => {
        // @ts-ignore
        switch (name) {
            case "duration": {
                if (!event.target.value.includes(":") || !formValues?.startTime) return;
                const endDate = moment(formValues.startDate, "dd.MM.yyyy").toDate();
                const endTimeParts = event.target.value.split(":");
                const startTimeParts = formValues.startTime.split(":");
                endDate.setHours((+startTimeParts[0]) + (+endTimeParts[0]));
                endDate.setMinutes((+startTimeParts[1]) + (+endTimeParts[1]));
                endDate.setSeconds((+startTimeParts[2]) + (+endTimeParts[2]));
                formValues.endTime = endDate.toLocaleTimeString("de-DE");
                formValues.duration = event.target.value;
            }
                break;
            case "startTime":
                if (!event.target.value.includes(":")) return;
                formValues.startTime = event.target.value;
            {
                const dRes = calculateManualDuration();
                if (!dRes) return;
                formValues.duration = dRes.duration;
                formValues.durationHour = dRes.hours;
                formValues.durationMinute = dRes.minutes;
                formValues.durationSecond = dRes.seconds;
            }
                break;
            case "endTime":
                if (!event.target.value.includes(":")) return;
                formValues.endTime = event.target.value;
            {
                const dRes = calculateManualDuration();
                if (!dRes) return;
                formValues.duration = dRes.duration;
                formValues.durationHour = dRes.hours;
                formValues.durationMinute = dRes.minutes;
                formValues.durationSecond = dRes.seconds;
            }
                break;
            case "projectId":
                // @ts-ignore
                formValues.projectId = event.target.value;
                _getAllCardByProject(event.target.value);
                break;
            default:
                // @ts-ignore
                formValues[name] = event.target.value;
                break;
        }
        setFormValues({...formValues});
    };

    useEffect(() => {
        _getRunningTracker();
    }, []);

    useEffect(() => {
        _getAllProjects();
        _getTrackerCategories();
    }, [organization?.id]);

    useEffect(() => {
        if (!tracker) {
            resetTracker();
        }
    }, [tracker]);

    function calculateManualDuration(): { duration: string, hours: number, minutes: number, seconds: number } | undefined {
        if (!formValues?.startTime || !formValues?.endTime) return undefined;
        const startTimeParts = formValues.startTime.split(":");
        const endTimeParts = formValues.endTime.split(":");
        const dur = moment.duration(moment(`${endTimeParts[0]}:${endTimeParts[1]}:${endTimeParts[2]}`, "HH:mm:ss").diff(moment(`${startTimeParts[0]}:${startTimeParts[1]}:${startTimeParts[2]}`, "HH:mm:ss")));
        const hours = Math.floor(dur.asHours());
        const minutes = Math.floor(dur.asMinutes() - 60 * hours);
        const seconds = Math.floor(dur.asSeconds() - (hours * 3600 + minutes * 60));
        return {
            duration: `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`,
            hours, minutes, seconds
        }
    }

    function _getTrackerCategories() {
        if (!organization?.id) return;
        getTrackerCategories(organization.id).then(res => {
            if (!res?.data?.error) {
                setCategories(res.data.data);
            }
        });
    }

    const handleDateChange = (date: Date | null) => {
        if (!date) return;
        setPreviewStartDate(date);
        formValues.startDate = date.toLocaleString("de-DE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        setFormValues({...formValues});
    };
    const toggleBillable = () => {
        if (!formValues?.id) {
            setFormValues({...formValues, billable: !formValues.billable});
        } else {
            const tmp = !formValues.billable;
            editBillable(formValues.id, tmp).then(res => {
                if (res?.data?.error) return;
                setFormValues({...formValues, billable: tmp});
            });
        }
    }

    const _getAllProjects = () => {
        if (!organization?.id) return;
        getAllOrganizationProjects(organization.id).then(res => {
            setProjects(res?.data?.data);
        });
    }

    const _getAllCardByProject = (projectId: any) => {
        if (!projectId) return;
        getAllCardsByProjectId(projectId).then(res => {
            if (!res?.data?.error) {
                setCards(res.data.data);
            }
        })
    }

    const startTrack = () => {
        setFormValues({...formValues, startTime: new Date().toISOString(), startDate: moment().toISOString(true)});
        let tmp = {...formValues, startDate: moment().toISOString(true)};
        delete tmp?.startTime;
        delete tmp?.endTime;
        startTracker(tmp).then(res => {
            if (!res?.data?.error) {
                _getRunningTracker();
            }
        });
    }

    const changeFormValueUpdate = (name: string, value: string) => {
        // @ts-ignore
        formValues[name] = value;
        setFormValues({...formValues});
    }

    const stopTrack = () => {
        stopTracker(formValues.id, moment().toISOString(true)).then(() => {
            dispatch(updateGlobalTracker(null));
            resetTracker();
        });
    }

    const resetTracker = () => {
        setFormValues({...defaultValues});
        setDuration("00:00:00");
        clearInterval(inter);
        refreshTrackers();
    }

    const switchTrackerMode = () => {
        setFormValues({...defaultValues});
        setManual(manual = !manual);
        if (manual) {
            setNumCol(15);
        } else {
            setNumCol(9);
        }
    };

    const _getRunningTracker = () => {
        getRunningTracker().then((res) => {
            if (res?.data?.data?.id) {
                dispatch(updateGlobalTracker(res.data.data as ITracker));
                formValues.id = res.data.data.id;
                formValues.projectId = res.data.data.projectId;
                formValues.cardId = res.data.data.cardId;
                formValues.labelId = res.data.data.labelId;
                formValues.billable = res.data.data.billable;
                formValues.description = res.data.data.description;
                formValues.description = res.data.data.description;
                const date = new Date(res.data.data.startDate);
                formValues.startDate = date.toLocaleString("de-DE", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                });
                formValues.startTime = date.toLocaleTimeString("de-DE");
                setFormValues({...formValues});

                if (inter) {
                    clearInterval(inter);
                }
                startTimer();
            }
        });
    }

    const startTimer = () => {
        const startDate: any = moment(formValues.startTime, "HH:mm:ss").toDate();
        setInter(setInterval(() => {
            // @ts-ignore
            const counter = Math.floor((new Date() - startDate) / 1000);
            formValues.durationHour = getHoursFromMilliseconds(counter);
            formValues.durationMinute = getMinutesFromMilliseconds(counter);
            formValues.durationSecond = getSecondsFromMillisecond(counter);
            setFormValues({...formValues});
            setDuration(parseTime(formValues.durationHour, formValues.durationMinute, formValues.durationSecond));
        }, 1000));
    }


    const addManualTracker = () => {
        const tmp = {...formValues};
        tmp.startDate = moment().toISOString(true);
        manualTracker(tmp as any).then(res => {
            if (!res?.data?.error) {
                setFormValues({...defaultValues});
                refreshTrackers();
            }
        });
    }

    const setDescription = (value: string) => {
        setFormValues({...formValues, description: value});
    }
    const setLabel = (value: any) => {
        setFormValues({...formValues, labelId: value});
    }
    const setProject = (value: string) => {
        _getAllCardByProject(value);
        // @ts-ignore
        setFormValues({...formValues, projectId: value});
    }
    const setCard = (value: string) => {
        // @ts-ignore
        formValues.cardId = value;
        setFormValues({...formValues});
    }

    return (
        <form className={"p-12"}>
            <AppBar position={"static"} className={"rounded-2xl p-10 mb-10"}>
                <Toolbar sx={{flexGrow: 1}} className={"lg:block"}>
                    <TrackerAddLg manual={manual} description={setDescription}
                                  project={setProject} card={setCard} label={setLabel} categories={categories}
                                  projects={projects} cards={cards} formValues={formValues}/>
                    <Grid
                        container
                        spacing={1}
                        columns={numCol}
                        className={"" +
                            "flex-row items-center justify-between " +
                            "md:flex-col md:items-baseline md:block md:items-baseline " +
                            "sm:flex-col sm:items-baseline sm:block sm:items-baseline"}
                    >
                        <Grid item xs={3} className={"lg:hidden md:pl-0 sm:pl-0 max-w-full"}>
                            <FormControl fullWidth>
                                <TextField
                                    style={matches && manual ? {width: '30ch'} : {}}
                                    label="Describe what are you doing"
                                    id="description"
                                    onBlur={handleFormValueChange('description')}
                                    className={clsx("subvariant-hovered md:pr-0", !!formValues.description ? "force-fieldset-border" : "")}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position='end'>
                                                <ChatAltIcon className={"min-w-24 max-w-24 stroke-1"}/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={matches ? 1 : 2}
                              className={clsx("max-w-full md:pl-0 sm:pl-0", manual ? "lg:hidden" : "")}>
                            <FormControl fullWidth>
                                <TextField
                                    className={clsx("subvariant-hovered", !!formValues.projectId ? "force-fieldset-border" : "")}
                                    onChange={handleFormValueChange('projectId')}
                                    label="Project"
                                    select
                                    inputProps={{IconComponent: () => null}}
                                >
                                    <MenuItem value={undefined}>Clear</MenuItem>
                                    {projects && projects.map((project: any, i) => (
                                        <MenuItem key={i}
                                                  value={project?.id}>{project?.name}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid>

                        <Grid
                            item
                            xs={matches ? 1 : 2}
                            className={"hidden md:flex md:items-center md:pl-0 md:mb-8 sm:pl-0 md:max-w-full sm:max-w-full"}
                        >
                            <FormControl fullWidth className={"md:pl-0"}>
                                <TextField
                                    className={clsx("subvariant-hovered", !!formValues.cardId ? "force-fieldset-border" : "")}
                                    label="Card"
                                    onChange={handleFormValueChange('cardId')}
                                    select
                                    value={formValues.cardId}
                                    inputProps={{IconComponent: () => null}}
                                >
                                    <MenuItem value={undefined}>Clear</MenuItem>
                                    {cards && cards.map((card: any, i) => (
                                        <MenuItem key={i}
                                                  value={card?.cardId}>{card?.cardName}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>

                            <FormControl fullWidth>
                                <TextField
                                    className={clsx("subvariant-hovered", !!formValues.labelId ? "force-fieldset-border" : "")}
                                    label="Category"
                                    onChange={handleFormValueChange('labelId')}
                                    select
                                    value={formValues.labelId ?? ""}
                                    inputProps={{IconComponent: () => null}}
                                >
                                    <MenuItem value={undefined}>Clear</MenuItem>
                                    {
                                        categories && categories.map((cat, i) => {
                                            return <MenuItem key={i}
                                                             value={cat?.id}>{cat?.name}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>

                        <Grid item xs={1}
                              className={clsx("md:hidden  sm:pl-0 sm:max-w-full", manual ? "lg:hidden" : "")}>
                            <FormControl fullWidth className={"md:p-0"}>
                                <TextField
                                    className={clsx("subvariant-hovered", !!formValues.cardId ? "force-fieldset-border" : "")}
                                    label="Card"
                                    value={formValues.labelId}
                                    onChange={handleFormValueChange('cardId')}
                                    select
                                    inputProps={{IconComponent: () => null}}
                                >
                                    <MenuItem value={undefined}>Clear</MenuItem>
                                    {cards && cards.map((card: any, i) => (
                                        <MenuItem key={i}
                                                  value={card?.cardId}>{card?.cardName}</MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid>

                        <Grid item xs={1}
                              className={clsx("md:hidden  sm:pl-0 max-w-full", manual ? "lg:hidden" : "")}>
                            <FormControl fullWidth>
                                <TextField
                                    className={clsx("subvariant-hovered", !!formValues.labelId ? "force-fieldset-border" : "")}
                                    label="Category"
                                    onChange={handleFormValueChange('labelId')}
                                    select
                                    inputProps={{IconComponent: () => null}}
                                >
                                    <MenuItem value={undefined}>Clear</MenuItem>
                                    {
                                        categories && categories.map((cat, i) => {
                                            return <MenuItem key={i}
                                                             value={cat?.id}>{cat?.name}</MenuItem>
                                        })
                                    }
                                </TextField>
                            </FormControl>
                        </Grid>

                        {manual && (
                            <>
                                <Grid
                                    item
                                    xs={6}
                                    className={clsx("flex gap-8 items-center lg:max-w-full md:max-w-full sm:block sm:max-w-full md:pl-0 sm:pl-0", manual ? "lg:hidden" : "")}
                                >
                                    <div className={'flex gap-8 md:pl-0 sm:pl-0 sm:p-8'}>
                                        <FormControl fullWidth>
                                            <DesktopDatePicker
                                                open={open}
                                                label="Date"
                                                value={previewStartDate}
                                                onOpen={() => {
                                                    setOpen(true)
                                                }}
                                                onClose={() => {
                                                    setOpen(false)
                                                }}
                                                inputFormat="dd.MM.yyyy"
                                                ignoreInvalidInputs={true}
                                                onChange={(e) => handleDateChange(e as Date | null)}
                                                renderInput={(params) => <TextField {...params}
                                                                                    InputProps={
                                                                                        {
                                                                                            endAdornment: null,
                                                                                            readOnly: true
                                                                                        }
                                                                                    }
                                                                                    onClick={() => setOpen(true)}
                                                                                    className={clsx("subvariant-hovered", !!formValues.startDate ? "force-fieldset-border" : "")}
                                                />}/>
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Start"
                                                value={formValues.startTime}
                                                id="start"
                                                onChange={(e) => changeFormValueUpdate("startTime", e.target.value)}
                                                onBlur={handleFormValueChange('startTime')}
                                                className={clsx("subvariant-hovered", !!formValues.startTime ? "force-fieldset-border" : "")}
                                            />
                                        </FormControl>
                                    </div>
                                    <div className={'flex gap-8 sm:p-8 sm:pl-0'}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="End"
                                                value={formValues.endTime}
                                                id="end"
                                                onChange={(e) => changeFormValueUpdate("endTime", e.target.value)}
                                                onBlur={handleFormValueChange('endTime')}
                                                className={clsx("subvariant-hovered", !!formValues.endTime ? "force-fieldset-border" : "")}
                                            />
                                        </FormControl>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Duration"
                                                id="duration"
                                                value={formValues?.duration}
                                                onChange={(e) => changeFormValueUpdate("duration", e.target.value)}
                                                onBlur={handleFormValueChange('duration')}
                                                className={clsx("subvariant-hovered", !!formValues.duration ? "force-fieldset-border" : "")}
                                            />
                                        </FormControl>

                                        <FormControl
                                            className={"hidden cursor-pointer justify-center md:inline-flex md:pr-28"}>
                                            <CurrencyDollarIcon onClick={toggleBillable}
                                                                className={clsx("w-24 h-24 opacity-30", formValues.billable ? "opacity-100" : "opacity-30")}/>
                                        </FormControl>
                                    </div>
                                </Grid>

                                <Grid item xs={matches ? 1 : 2}
                                      className={"hidden lg:max-w-full lg:flex"}>
                                    <FormControl fullWidth>
                                        <DesktopDatePicker
                                            open={open1}
                                            label="Date"
                                            value={previewStartDate}
                                            onOpen={() => {
                                                setOpen1(true)
                                            }}
                                            onClose={() => {
                                                setOpen1(false)
                                            }}
                                            ignoreInvalidInputs={true}
                                            inputFormat="dd.MM.yyyy"
                                            onChange={(e) => handleDateChange(e as Date | null)}
                                            renderInput={(params) => <TextField {...params}
                                                                                InputProps={
                                                                                    {
                                                                                        endAdornment: null,
                                                                                        readOnly: true
                                                                                    }
                                                                                }
                                                                                onClick={() => setOpen1(true)}
                                                                                className={clsx("subvariant-hovered", !!formValues.startDate ? "force-fieldset-border" : "")}
                                            />}/>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={2} className={"hidden lg:flex"}>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Start"
                                            id="start"
                                            value={formValues.startTime}
                                            onChange={(e) => changeFormValueUpdate("startTime", e.target.value)}
                                            onBlur={handleFormValueChange('startTime')}
                                            className={clsx("subvariant-hovered", !!formValues.startTime ? "force-fieldset-border" : "")}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={2} className={"hidden lg:flex"}>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="End"
                                            id="end"
                                            value={formValues.endTime}
                                            onChange={(e) => changeFormValueUpdate("endTime", e.target.value)}
                                            onBlur={handleFormValueChange('endTime')}
                                            className={clsx("subvariant-hovered", !!formValues.endTime ? "force-fieldset-border" : "")}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={2} className={"hidden lg:flex"}>
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Duration"
                                            id="duration"
                                            value={formValues.duration}
                                            onChange={(e) => changeFormValueUpdate("duration", e.target.value)}
                                            onBlur={handleFormValueChange('duration')}
                                            className={clsx("subvariant-hovered", !!formValues.duration ? "force-fieldset-border" : "")}
                                        />
                                    </FormControl>
                                    {/*<span>{manual ? formValues.duration : duration}</span>*/}
                                </Grid>
                            </>
                        )}
                        <Grid item xs={1}
                              className={clsx("hidden  justify-center sm:hidden", manual ? "lg:hidden" : "")}>
                            <FormControl>
                                <CurrencyDollarIcon onClick={toggleBillable}
                                                    className={clsx("w-24 h-24 opacity-30", formValues.billable ? "opacity-100" : "opacity-30")}/>
                            </FormControl>
                        </Grid>

                        {!manual && (
                            <>
                                <Grid
                                    item
                                    xs={matches1 ? 2 : 2.5}
                                    className={"flex gap-32  items-center justify-between lg:hidden md:max-w-full " +
                                        "sm:max-w-full"}
                                >
                                    <FormControl
                                        className={"cursor-pointer md:justify-center sm:justify-center"}>
                                        <CurrencyDollarIcon onClick={toggleBillable}
                                                            className={clsx("w-24 h-24 opacity-30", formValues.billable ? "opacity-100" : "opacity-30")}/>
                                    </FormControl>
                                    {/*<FormControl>*/}
                                    {/*    <TextField*/}
                                    {/*        label="Duration"*/}
                                    {/*        id="duration"*/}
                                    {/*        defaultValue={formValues?.duration}*/}
                                    {/*        onBlur={handleFormValueChange('duration')}*/}
                                    {/*        className={clsx("subvariant-hovered", !!formValues.duration ? "force-fieldset-border" : "")}*/}
                                    {/*    />*/}
                                    {/*</FormControl>*/}
                                    <span>{duration}</span>

                                    {(!formValues?.id) && (
                                        <Button variant="contained" onClick={() => {
                                            startTrack();
                                        }}>
                                            Start
                                        </Button>
                                    )}


                                    {(formValues?.id) && (
                                        <Button variant="contained" color={"warning"}
                                                onClick={stopTrack}>Stop</Button>
                                    )}
                                    <Tooltip title="Switch to manual mood">
                                        {/*<FormControl className="pr-14">*/}
                                        {/*    <HandIcon onClick={switchTrackerMode} cursor={"pointer"}*/}
                                        {/*              className={"min-w-24 max-w-24"}/>*/}
                                        {/*</FormControl>*/}
                                        <IconButton disabled={!!formValues?.id} color="inherit" component="span"
                                                    onClick={switchTrackerMode}>
                                            <HandIcon className="w-20"/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                                {/*  LG */}
                                <Grid
                                    item
                                    xs={3.5}
                                    className={"hidden gap-32  items-center justify-between lg:flex"}
                                >
                                    <FormControl
                                        className={"cursor-pointer md:justify-center sm:justify-center"}>
                                        <CurrencyDollarIcon onClick={toggleBillable}
                                                            className={clsx("w-24 h-24 opacity-30", formValues.billable ? "opacity-100" : "opacity-30")}/>
                                    </FormControl>
                                    {/*<FormControl>*/}
                                    {/*    <TextField*/}
                                    {/*        label="Duration"*/}
                                    {/*        id="duration"*/}
                                    {/*        defaultValue={formValues?.duration}*/}
                                    {/*        onBlur={handleFormValueChange('duration')}*/}
                                    {/*        className={clsx("subvariant-hovered", !!formValues.duration ? "force-fieldset-border" : "")}*/}
                                    {/*    />*/}
                                    {/*</FormControl>*/}
                                    <span>{duration}</span>


                                    {(!formValues?.id) && (
                                        <Button variant="contained" onClick={() => {
                                            startTrack();
                                        }}>
                                            Start
                                        </Button>
                                    )}


                                    {(formValues?.id) && (
                                        <Button variant="contained" color="warning" onClick={stopTrack}>Stop</Button>
                                    )}
                                    <Tooltip title={"Switch to manual mode"}>
                                        <FormControl className={"pr-20"}>
                                            <HandIcon onClick={switchTrackerMode} cursor={"pointer"}
                                                      className={"min-w-24 max-w-24"}/>
                                        </FormControl>
                                    </Tooltip>
                                </Grid>
                            </>
                        )}


                        {manual && (
                            <Grid
                                item
                                xs={matches ? 2 : 3}
                                display={"flex"}
                                alignItems={'center'}
                                className={"md:flex gap-44 lg:gap-72 sm:flex justify-end " +
                                    "md:max-w-full sm:max-w-full md:float-right sm:justify-between md:p-0"}
                            >
                                <FormControl className={"cursor-pointer sm:inline-flex md:hidden"}>
                                    <CurrencyDollarIcon onClick={toggleBillable}
                                                        className={clsx("w-24 h-24 opacity-30", formValues.billable ? "opacity-100" : "opacity-30")}/>
                                </FormControl>

                                <Button variant="contained" className={"sm:hidden"}
                                        onClick={addManualTracker}>Add</Button>
                                <Tooltip title={"Switch to automatic mood"}>
                                    <FormControl className={"pr-14"}>
                                        <HandIcon onClick={switchTrackerMode} cursor={"pointer"}
                                                  className={"min-w-24 max-w-24 sm:hidden"}/>
                                    </FormControl>
                                </Tooltip>
                                <div className={"hidden sm:flex sm:items-center sm:gap-32"}>
                                    <Button variant="contained" onClick={addManualTracker}>Add</Button>
                                    <Tooltip title={"Switch to automatic mode"}>
                                        <FormControl className={"pr-14"}>
                                            <HandIcon onClick={switchTrackerMode} cursor={"pointer"}
                                                      className={"min-w-24 max-w-24"}/>
                                        </FormControl>
                                    </Tooltip>
                                </div>
                            </Grid>
                        )}

                    </Grid>
                </Toolbar>
            </AppBar>
        </form>
    );
};

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
        tracker: fuse.runningTracker.value,
    }
}

export default withRouter(connect(mapStateToProps)(TrackerAdd));

