import {useTheme} from "@mui/material/styles";
import {RbTheme} from "../../../types/common-types";
import RbBox from "../../../shared-components/rb-components/RbBox";

export default function ReportWidgetBase(props: { widgetControl?: () => JSX.Element, title?: string, children?: any, className?: string }) {
    const theme: RbTheme = useTheme();

    return (
        <div className={`my-20 ${props.className ?? ""}`}>
            <div className="flex justify-between mb-20 font-bold">
                <h2>{props?.title ?? ""}</h2>
                <span>
                    {props?.widgetControl && props.widgetControl()}
                </span>
            </div>
            {props.children}
        </div>
    )
}
