import {red} from '@mui/material/colors';
import {useMediaQuery} from "@mui/material";
import * as darkNight from "@mui/system";


const lightText = {
    primary: '#18181B',
    secondary: '#94A4B8',
    disabled: 'rgb(149, 156, 169)',
};

const mbDarkText = {
    primary: 'rgb(255,255,255)',
    secondary: '#64748B',
    disabled: 'rgb(156, 163, 175)',
};
const dkDarkText = {
    primary: 'rgb(255,255,255)',
    secondary: '#71717a',
    disabled: 'rgb(156, 163, 175)',
};

const themesConfig = {
    midnightBlue: {
        palette: {
            name: "midnightBlue",
            mode: 'dark',
            text: mbDarkText,
            common: {
                black: 'rgb(24, 24, 27)',
                white: 'rgb(255, 255, 255)',
            },
            primary: {
                light: '#1E293B',
                main: '#1E293B',
                dark: '#1E293B',
            },
            secondary: {
                light: '#374151',
                main: '#374151',
                dark: '#374151',
            },
            success: {
                contrastText: 'white',
                dark: '#059669',
                main: '#059669',
                light: '#059669'
            },
            warning: {
                contrastText: 'white',
                dark: '#b91c1c',
                main: '#EF4444',
                light: '#DC2626'
            },
            background: {
                paper: '#111827',
                default: '#1E293B',
            },
            special: "#06B6D4",
            error: red,
        },
        status: {
            danger: 'orange',

        },
        priority: {
            low: '#059669',
            medium: '#FCD34D',
            high: '#EF4444',
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: "inherit"
                    },
                    containedPrimary: {
                        backgroundColor: "#075985",
                        "&:hover": {
                            backgroundColor: '#0c4a6e'
                        },
                        "&:active": {
                            backgroundColor: '#0284C7'
                        }
                    },
                    outlinedPrimary: {
                        borderColor: '#075985',
                        color: '#0c4a6e',
                        "&:hover": {
                            borderColor: '#075985',
                            backgroundColor: 'rgba(12,74,110,0.1)',
                        },
                        "&:active": {
                            borderColor: '#075985',
                            backgroundColor: 'rgba(2,132,199,0.1)',
                        }
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#075985",
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#1E293B",
                        color: "#64748B"
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: "2px solid #111827",
                        '@media (min-width: 300px) and (max-width: 768px) ': {
                            borderBottom: "none"
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: "#FFFFFF",
                        "&.Mui-focused": {
                            color: "#959ca9"
                        }
                    }
                }
            },
            MuiTextField: {
                defaultProps: {
                    variant: "outlined",
                    fullWidth: true,
                },
                styleOverrides: {
                    root: {
                        "& label": {
                            // set some styles for the label if need it
                        },
                        "& legend": {
                            // set some styles for the legend if need it
                        },


                        // this is styles for the new variants
                        "&.subvariant-hovered": {
                            "& fieldset": {
                                border: "none"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                                color: "#959ca9",
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: "#959ca9",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: `1px solid #959ca9`
                            },
                            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                border: `1px solid #959ca9`
                            },
                            "& .MuiFormLabel-filled": {
                                color: "#959ca9"
                            }
                        },

                    }
                }
            },
        }
    },
    darkNight: {
        palette: {
            name: "darkNight",
            mode: 'dark',
            text: dkDarkText,
            common: {
                black: 'rgb(24, 24, 27)',
                white: 'rgb(255, 255, 255)',
            },
            primary: {
                light: '#27272A',
                main: '#27272A',
                dark: '#27272A',
            },
            secondary: {
                light: '#52525B',
                main: '#52525B',
                dark: '#52525B',
            },
            success: {
                contrastText: 'white',
                dark: '#059669',
                main: '#059669',
                light: '#059669'
            },
            warning: {
                contrastText: 'white',
                dark: '#b91c1c',
                main: '#EF4444',
                light: '#DC2626'
            },
            background: {
                paper: '#18181B',
                default: '#27272A',
            },
            special: "#075985",
            error: red,
        },
        status: {
            danger: 'orange',
        },
        priority: {
            low: '#059669',
            medium: '#FCD34D',
            high: '#EF4444',
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: "inherit"
                    },
                    containedPrimary: {
                        backgroundColor: "#06B6D4",
                        "&:hover": {
                            backgroundColor: '#0891B2'
                        },
                        "&:active": {
                            backgroundColor: '#67E8F9'
                        }
                    },
                    outlinedPrimary: {
                        borderColor: '#06B6D4',
                        color: '#0891B2',
                        "&:hover": {
                            borderColor: '#06B6D4',
                            backgroundColor: 'rgba(8,145,178,0.1)',
                        },
                        "&:active": {
                            borderColor: '#06B6D4',
                            backgroundColor: 'rgba(8,145,178,0.1)',
                        }
                    },
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#06B6D4",
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#27272A",
                        color: "#71717A"
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: "2px solid #18181B",
                        '@media (min-width: 300px) and (max-width: 768px) ': {
                            borderBottom: "none"
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: "#FFFFFF",
                        "&.Mui-focused": {
                            color: "#959ca9"
                        }
                    }
                }
            },
            MuiTextField: {
                defaultProps: {
                    variant: "outlined",
                    fullWidth: true,
                },
                styleOverrides: {
                    root: {
                        "& label": {
                            // set some styles for the label if need it
                        },
                        "& legend": {
                            // set some styles for the legend if need it
                        },


                        // this is styles for the new variants
                        "&.subvariant-hovered": {
                            "& fieldset": {
                                border: "none"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                                color: "#959ca9",
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: "#959ca9",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: `1px solid #959ca9`
                            },
                            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                border: `1px solid #959ca9`
                            },
                            "& .MuiFormLabel-filled": {
                                color: "#959ca9"
                            }
                        },

                    }
                }
            },
        }
    },
    default: {
        palette: {
            name: "default",
            mode: 'light',
            text: lightText,
            primary: {
                light: "#FFFFFF",
                main: "#FFFFFF",
                dark: "#FFFFFF",
            },
            secondary: {
                light: "#94A4B8",
                main: "#DBEAFE",
                dark: "#DBEAFE",
            },
            success: {
                contrastText: 'white',
                dark: '#059669',
                main: '#059669',
                light: '#059669'
            },
            warning: {
                contrastText: 'white',
                dark: '#b91c1c',
                main: '#EF4444',
                light: '#DC2626'
            },
            background: {
                paper: '#F1F5F9',
                default: '#FFFFFF',
            },
            special: "#7DD3FC",
            error: red,
        },
        status: {
            danger: 'orange',
        },
        priority: {
            low: '#059669',
            medium: '#FCD34D',
            high: '#EF4444',
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        color: "inherit"
                    },
                    containedPrimary: {
                        backgroundColor: "#7dd3fc",
                        color: 'white',
                        "&:hover": {
                            backgroundColor: '#0EA5E9'
                        },
                        "&:active": {
                            backgroundColor: '#BAE6FD'
                        }
                    },
                    outlinedPrimary: {
                        borderColor: '#7dd3fc',
                        color: '#0EA5E9',
                        "&:hover": {
                            borderColor: '#7dd3fc',
                            backgroundColor: 'rgba(14,165,233,0.1)',
                        },
                        "&:active": {
                            borderColor: '#7dd3fc',
                            backgroundColor: 'rgba(14,165,233,0.1)',
                        }
                    }
                },
            },
            MuiTableHead: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#7dd3fc",
                    }
                }
            },
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        backgroundColor: "#FFFFFF",
                        color: "#94A4B8"
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: "2px solid #F1F5F9",
                        '@media (min-width: 300px) and (max-width: 768px) ': {
                            borderBottom: "none"
                        }
                    }
                }
            },
            MuiInputLabel: {
                styleOverrides: {
                    root: {
                        color: "#18181B",
                        "&.Mui-focused": {
                            color: "#959ca9"
                        }
                    }
                }
            },
            MuiTextField: {
                defaultProps: {
                    variant: "outlined",
                    fullWidth: true,
                },
                styleOverrides: {
                    root: {
                        "& label": {
                            // set some styles for the label if need it
                        },
                        "& legend": {
                            // set some styles for the legend if need it
                        },


                        // this is styles for the new variants
                        "&.subvariant-hovered": {
                            "& fieldset": {
                                border: "none"
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
                                color: "#959ca9",
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: "#959ca9",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                border: `1px solid #959ca9`
                            },
                            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                                border: `1px solid #959ca9`
                            },
                            "& .MuiFormLabel-filled": {
                                color: "#959ca9"
                            }
                        },

                    }
                }
            },

        }
    },
};


export default themesConfig;
