import React, {useState} from "react";
import {IBoardCard, IBoardCardDetails, IBoardShortCard} from "../../../types/board-types";
import RbIconTitle from "../../../shared-components/rb-components/RbIconTitle";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";
import BoardCardListPicker from "../board-components/BoardCardListPicker";
import {updateBlockedByBoardCard} from "../../../services/boards/boardsService";

export default function BoardCardBlockedBy(props: { card: IBoardCardDetails | undefined, cards: IBoardCard[] | IBoardShortCard[], refreshCard: () => void }) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const {card} = props;

    function updateCard(item: string | null) {
        if (!card?.id) return;
        updateBlockedByBoardCard(card?.id, item).then(res => {
            if (res?.data?.error) return;
            props.refreshCard();
        })
    }

    return (
        <>
            <RbIconTitle icon="MinusCircleIcon" title="Blocked by" container="h4" className="mb-10"/>
            <RbRoundButton color="secondary"
                           onClick={e => setAnchorEl(e.currentTarget)}
                           variant="contained">{card?.blocker?.name ?? "Not set"}</RbRoundButton>
            <BoardCardListPicker cards={props.cards} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}
                                 onSelect={item => updateCard(item?.id ?? null)}/>
        </>
    )
}
