import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import {useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {CogIcon} from "@heroicons/react/outline";
import {updateSubNavigation} from "../../store/rubicon/subNavigationSlice";
import {userSettingsSubNavigation} from "../../main/user-settings/UserSettingsConfig";
import withRouter from "../../../@fuse/core/withRouter";

function UserMenu(props) {
    const dispatch = useDispatch();
    const [userMenu, setUserMenu] = useState(null);

    const userMenuClick = (event) => {
        setUserMenu(event.currentTarget);
    };

    const userMenuClose = () => {
        setUserMenu(null);
    };

    return (<>
        <Button
            className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6"
            onClick={userMenuClick}
            color="inherit"
        >
            <div className="hidden md:flex flex-col mx-4 items-end">
                <Typography component="span" className="font-semibold flex">
                    {props?.user?.data?.displayName}
                </Typography>
                <Typography className="text-11 font-medium capitalize" color="textSecondary">
                    {props?.user?.role.toString()}
                    {(!props?.user?.role || (Array.isArray(props?.user?.role) && props?.user?.role.length === 0)) && 'Guest'}
                </Typography>
            </div>
            {props?.user?.data?.photoURL ? (
                <Avatar className="md:mx-4" alt="user photo" src={props?.user?.data?.photoURL}/>) : (
                <Avatar className="md:mx-4">{props?.user?.data?.displayName}</Avatar>)}
        </Button>

        <Popover
            open={Boolean(userMenu)}
            anchorEl={userMenu}
            onClose={userMenuClose}
            anchorOrigin={{
                vertical: 'bottom', horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top', horizontal: 'center',
            }}
            classes={{
                paper: 'py-8',
            }}
        >
            {!props?.user?.data ? (<>
                <MenuItem component={Link} to="/login" role="button">
                    <ListItemIcon className="min-w-40">
                        <Icon>lock</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Login"/>
                </MenuItem>
                <MenuItem component={Link} to="/register" role="button">
                    <ListItemIcon className="min-w-40">
                        <Icon>person_add</Icon>
                    </ListItemIcon>
                    <ListItemText primary="Register"/>
                </MenuItem>
            </>) : (<>
                <MenuItem component={Link} to="/user-settings/account" onClick={() => {
                    dispatch(updateSubNavigation(userSettingsSubNavigation));
                    userMenuClose();
                }} role="button">
                    <ListItemIcon>
                        <CogIcon className="w-20"/>
                    </ListItemIcon>
                    <ListItemText primary="User settings"/>
                </MenuItem>
            </>)}
        </Popover>
    </>);
}

function mapStateToProps({auth}) {
    return {
        user: auth.user,
    };
}

export default withRouter(connect(mapStateToProps)(UserMenu));
