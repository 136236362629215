import {styled} from "@mui/material/styles";
import {
    Accordion,
    AccordionDetails,
    AccordionProps,
    AccordionSummary,
    AccordionSummaryProps, Avatar, Checkbox,
    List, ListItem
} from "@mui/material";
import {ChevronRightIcon, DuplicateIcon, PencilAltIcon, UserIcon} from "@heroicons/react/outline";
import RbBox from "../../../shared-components/rb-components/RbBox";
import ListItemText from "@mui/material/ListItemText";
import {ITodo} from "../../../types/todo-types";
import {CheckCircle, CheckCircleOutline} from "@mui/icons-material";
import React, {useEffect} from "react";
import {RbCheckBoxIcon} from "app/shared-components/rb-components/RbCheckBoxIcon";
import HeroIcon from "../../../../@fuse/core/HeroIcon/HeroIcon";
import {FlagIcon} from "@heroicons/react/solid";
import {useTheme} from "@mui/styles";
import clsx from "clsx";
import {toggleDoneTask} from "../../../services/todo/todoService";
import {RbTheme} from "../../../types/common-types";
import {getUserInitials} from "../../../utils/RbUtils";
import {useSelector} from "react-redux";

export const RbAccordion = styled((props: AccordionProps) => (
    <Accordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `0`,
    '&': {
        border: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

export const RbAccordionSummary = styled((props: AccordionSummaryProps) => (
    <AccordionSummary
        expandIcon={<ChevronRightIcon className="w-16"/>}
        {...props}
    />
))(() => ({
    paddingRight: 0,
    '&:before': {
        content: "''",
        width: "100%",
        borderBottom: "1px solid gray"
    },
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: "1rem",
        marginRight: "2rem",
    },
}));

export const RbAccordionDetails = styled(AccordionDetails)(() => ({
    padding: "2rem",
}));
export const RbTodoDetailItem = styled('span')(() => ({
    '&:after': {
        content: "''",
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        borderRight: "1px solid",
        display: "inline-block",
        height: "1rem"
    },
    '& svg': {
        width: "1.6rem",
        display: "inline-block",
        verticalAlign: "text-bottom"
    }
}));


export default function CategorySection(props: { title: string, todos: ITodo[], refreshTodos: () => void, selectTodo: (todo: ITodo) => void }) {
    const theme: RbTheme = useTheme();
    const user = useSelector((state: any) => state.auth.user).data;

    function getPriorityColor(val: number): string {
        switch (val) {
            case 1:
                return theme.priority.low;
            case 2:
                return theme.priority.medium;
            case 3:
                return theme.priority.high;
            default:
                return "transparent";
        }
    }

    function renderTodoSecondaryDetails(todo: ITodo) {
        return (
            <span className="flex ml-2">
                {todo.listName ? <RbTodoDetailItem>{todo.listName}</RbTodoDetailItem> : <></>}
                {todo.dueDate ?
                    <RbTodoDetailItem>{new Date(todo.dueDate).toLocaleDateString("de-DE")}</RbTodoDetailItem> : <></>}
                {todo.note ? <RbTodoDetailItem><PencilAltIcon/></RbTodoDetailItem> : <></>}
            </span>
        )
    }

    function assignLogicInitials(todo: ITodo): string {
        if (todo?.assignTo?.id === user.uid) return getUserInitials(todo?.assignBy?.name);
        if (todo?.assignBy?.id === user.uid) return getUserInitials(todo?.assignTo?.name);
        return "??";
    }

    function renderSecondaryActions(todo: ITodo) {
        return (
            <span className="flex items-center">
                {
                    todo?.assignTo || todo?.assignBy ?
                        <span className="inline-block align-middle"><Avatar sx={{width: 26, height: 26}}
                                                                            className="text-sm"
                                                                            src={todo?.assignTo?.avatar}>{assignLogicInitials(todo)}</Avatar></span> : <></>
                }
                {
                    todo.priorityId ?
                        <span className="inline-block align-middle w-24 ml-10"><FlagIcon
                            color={getPriorityColor(todo?.priorityId)}/></span> : <></>
                }
            </span>
        )
    }

    function _toggleDoneTask(id: string) {
        toggleDoneTask(id).then(res => {
            if (res?.data?.error) return;
            props.refreshTodos();
        });
    }

    function renderTodoTitle(todo: ITodo) {
        return (
            <span className="flex">
                <RbCheckBoxIcon defaultChecked={todo.isDone} onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    _toggleDoneTask(todo.id);
                }} className="w-20"
                                icon={<HeroIcon icon="CheckCircleIcon" outline={true}/>}
                                checkedIcon={<HeroIcon icon="CheckCircleIcon" outline={false}/>}/>
                <h3 className={clsx("ml-10", todo.isDone ? "line-through" : "")}>{todo.name}</h3>
            </span>
        )
    }

    return (
        <div className="mt-18">
            <RbAccordion defaultExpanded={true}>
                <RbAccordionSummary>
                    <h3 className="whitespace-nowrap">{props?.title ?? ""}</h3>
                </RbAccordionSummary>
                <RbAccordionDetails>
                    <List>
                        {
                            props.todos.map(todo => {
                                return (
                                    <span
                                        key={todo.id}
                                        onClick={() => props.selectTodo(todo)}>
                                        <RbBox
                                            className={clsx("mb-10 cursor-pointer transition-spacing items-center hover:ml-10", todo.isDone ? "opacity-75" : "")}>
                                            <div className="flex justify-between">
                                                <span>
                                                    <ListItemText primary={renderTodoTitle(todo)}/>
                                                    <ListItem className="pl-0 pt-0">
                                                        {renderTodoSecondaryDetails(todo)}
                                                    </ListItem>
                                                </span>
                                                {renderSecondaryActions(todo)}
                                            </div>
                                        </RbBox>
                                    </span>
                                )
                            })
                        }
                    </List>
                </RbAccordionDetails>
            </RbAccordion>
        </div>
    )
}
