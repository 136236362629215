import {ITrackerResponse, ITrackerDuration} from "../../types/tracker-types";
import {_delete, _get, _patch, _post} from "../apiService";


export const getAllTrackerFromTo = (organizationId: string, dateFrom?: string, dateTo?: string) => {
    return _get(`trackers/${organizationId}?dateFrom=${encodeURIComponent(dateFrom ?? "")}&dateTo=${encodeURIComponent(dateTo ?? "")}`)
}

export const editDescription = (trackerId: string, description: string) => {
    return _patch(`trackers/description`, {description, trackerId});
}

export const editBillable = (trackerId: string, billable: boolean) => {
    return _patch(`trackers/billable`, {billable, trackerId});
}

export const editProject = (trackerId: string, projectId?: string) => {
    return _patch(`trackers/project`, {projectId: projectId ?? null, trackerId});
}

export const editCard = (trackerId: string, cardId?: string) => {
    return _patch(`trackers/card`, {cardId, trackerId});
}

export const editCategory = (trackerId: string, categoryId?: string) => {
    return _patch(`trackers/category`, {trackerId, categoryId});
}

export const editStartDate = (trackerId: string, startDate: string) => {
    return _patch(`trackers/start-date`, {startDate, trackerId});
}

export const editDuration = (duration: ITrackerDuration) => {
    return _patch(`trackers/duration`, duration);
}

export const stopTracker = (trackerId: string, currentDate: string) => {
    return _patch(`trackers/${trackerId}/stop?currentDate=${encodeURIComponent(currentDate)}`, {});
}

export const repeatTracker = (trackerId: string, startTime: string) => {
    return _post(`trackers/${trackerId}/repeat?startTime=${encodeURIComponent(startTime)}`, {});
}

export const duplicateTracker = (trackerId: string) => {
    return _post(`trackers/${trackerId}/duplicate`, {});
}

export const deleteTracker = (trackerId: string) => {
    return _delete(`trackers/${trackerId}`);
}

export const startTracker = (tracker: ITrackerResponse) => {
    return _post('trackers/start', tracker);
}

export const manualTracker = (tracker: ITrackerResponse) => {
    return _post('trackers/manual', tracker);
}

export const getRunningTracker = () => {
    return _get('trackers/running');
}

export const getAllTrackerDetails = (organizationId?: string, trackerId?: string) => {
    return _get(`trackers/${organizationId}/details/${trackerId}`)
}

export const getAllCardsByProjectId = (projectId?: string) => {
    return _get(`trackers/${projectId}/cards`)
}

export const getTrackerCategories = (orgId: string) => {
    return _get(`trackers/${orgId}/categories`);
}

