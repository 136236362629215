import {IProjectOverviewTrackerUserAndCategory} from "../../../types/project-overview-types";
import {IRbDisplayHeader} from "../../../types/common-types";
import RbBox from "../../../shared-components/rb-components/RbBox";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import React, {useState} from "react";
import {ChevronDownIcon} from "@heroicons/react/outline";
import RbSingleSelect from "../../../shared-components/rb-components/RbSingleSelect";
import {getUniqueItemsByProperty} from "../../../utils/RbUtils";
import {ITodoFriend} from "../../../types/todo-types";

export default function ProjectOverviewTrackersByUserAndCategory(props: { data: IProjectOverviewTrackerUserAndCategory[] }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [user, setUser] = useState<ITodoFriend>();
    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "Name",
            objAttribute: "user.name"
        },
        {
            name: "Category",
            objAttribute: "categoryName"
        },
        {
            name: "Tasks tracked",
            objAttribute: "trackers"
        },
        {
            name: "Count time",
            objAttribute: "duration"
        },
    ];

    function getFilteredItems(): ITodoFriend[] {
        return getUniqueItemsByProperty(props.data?.map(c => c.user), "id");
    }

    return (
        <RbBox className="px-0 my-12">
            <div className="flex justify-between px-16">
                <h2 className="">Trackers distribution by user and category</h2>
                <RbRoundButton onClick={e => setAnchorEl(e.currentTarget)} variant="contained"
                               className="flex justify-between items-center px-10 w-fit">
                    <span>{user?.name ?? "Select user"}</span>
                    <ChevronDownIcon className="ml-10 w-20"/>
                </RbRoundButton>
            </div>
            <RbSingleSelect anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onSelect={user => setUser(user)}
                            items={getFilteredItems()} renderItem={item => item.name}/>
            <RbDataTable dataSource={props.data ?? []} headerColumns={displayHeaders}/>
        </RbBox>
    )
}
