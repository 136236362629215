import {RbRoundButton} from "../../shared-components/rb-components/RbRoundButton";
import {useTheme} from "@mui/material/styles";
import React, {useEffect, useState} from "react";
import {
    createTodoList,
    createTodoTask,
    deleteCustomList, getAllMyDayTodos, getAllPrioritisedTodos,
    getAllTodos,
    getCustomLists,
    getCustomListTodos
} from "../../services/todo/todoService";
import {ICustomTodoList, ITodo} from "../../types/todo-types";
import CategorySection from "./category/CategorySection";
import {
    Dialog,
    Drawer,
    IconButton,
    Menu,
    MenuItem,
} from "@mui/material";
import {DotsVerticalIcon, TrashIcon, UserAddIcon} from "@heroicons/react/outline";
import _ from "lodash";
import RbConfirmationDialog from "../../shared-components/dialogs/RbConfirmationDialog";
import RbContentEditable from "../../shared-components/rb-components/RbContentEditable";
import AddTodoTask from "./AddTodoTask";
import TaskSideDetails from "./task-details/TaskSideDetails";
import TodoFriendList from "./friend-list/TodoFriendList";
import {useSelector} from "react-redux";

export default function Todo() {
    const theme = useTheme();
    const user = useSelector((state: any) => state.auth.user).data;
    const [viewIndex, setViewIndex] = useState<number>(0);
    const defaultLists: string[] = ["Priority", "My day", "Assigned to me", "All"];
    const [taskLists, setTaskLists] = useState(["Priority", "My day", "Assigned to me", "All"]);
    const prioritiesString: string[] = ["None", "Low", "Medium", "High"];
    const [priorityTodos, setPriorityTodos] = useState<ITodo[]>([]);
    const [myDayTodos, setMyDayTodos] = useState<ITodo[]>([]);
    const [allTodos, setAllTodos] = useState<ITodo[]>([]);
    const [activeCustomTodos, setActiveCustomTodos] = useState<any[]>([]);
    const [customTodoLists, setCustomTodoLists] = useState<ICustomTodoList[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [categorySort, setCategorySort] = useState<boolean>(false);
    const [dialogTaskOpen, setDialogTaskOpen] = useState<boolean>(false);
    const [dialogDelete, setDialogDelete] = useState<boolean>(false);
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
    const [friendListOpen, setFriendListOpen] = useState<boolean>(false);
    const [selectedTodo, setSelectedTodo] = useState<ITodo>();

    useEffect(() => {
        _getCustomLists();
    }, []);

    useEffect(() => {
        renderTodos();
    }, [viewIndex, categorySort, allTodos, customTodoLists, priorityTodos, myDayTodos]);

    useEffect(() => {
        switch (viewIndex) {
            case 0:
                _getAllPrioritisedTodos();
                break;
            case 1:
                _getAllMyDayTodos();
                break;
            case 2:
            case 3:
                _getAllTodos();
                break;
        }
    }, [viewIndex]);

    function _getAllTodos() {
        getAllTodos().then(res => {
            if (res?.data?.error) return;
            setAllTodos(res.data.data);
        });
    }

    function _getAllPrioritisedTodos() {
        getAllPrioritisedTodos().then(res => {
            if (res?.data?.error) return;
            setPriorityTodos(res.data.data);
        });
    }

    function _getAllMyDayTodos() {
        getAllMyDayTodos().then(res => {
            if (res?.data?.error) return;
            setMyDayTodos(res.data.data);
        });
    }

    function toggleFriendList() {
        setFriendListOpen(!friendListOpen);
    }


    function _getCustomLists(deleteCalled: boolean = false) {
        getCustomLists().then(res => {
            if (res?.data?.error) return;
            setCustomTodoLists([...res.data.data]);
            const tmp: string[] = [];
            (res.data.data as ICustomTodoList[]).forEach(list => {
                tmp.push(list.name);
            });
            if (viewIndex >= taskLists.length) {
                setViewIndex(0);
            }
            if (deleteCalled) {
                setViewIndex(viewIndex - 1);
            }
            setTaskLists([...defaultLists, ...tmp]);
        });
    }


    const handleAnchor = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event: any) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    function _deleteCustomList(listId: string) {
        deleteCustomList(listId).then(res => {
            if (res?.data?.error) return;
            _getCustomLists(true);
        });

    }

    function _createTodoTask(listId: string, value: string) {
        createTodoTask(listId, value).then(res => {
            if (res?.data?.error) return;
            renderTodos();
        });
    }

    function _createTodoList(val: string) {
        createTodoList(val).then(res => {
            if (res?.data?.error) return;
            _getCustomLists();
        });
    }

    function handleRefreshTodos(all: boolean = false) {
        if (all) {
            _getAllTodos();
            _getCustomLists();
        } else {
            if (viewIndex >= defaultLists.length) {
                _getCustomLists();
            } else {
                _getAllTodos();
            }
        }
    }

    return (
        <div className="h-full pl-20">
            <div className="todo-controls flex flex-1 justify-between">
                <div className="views p-20 pl-0 overflow-y-auto whitespace-nowrap">
                    {
                        taskLists.map((btn, index) => {
                            return (
                                <RbRoundButton onClick={() => {
                                    setViewIndex(index)
                                }}
                                               key={btn}
                                               className="pl-16 pr-16"
                                               style={{color: theme.palette.text.primary}}
                                               variant={index === viewIndex ? "contained" : "text"}>{btn}</RbRoundButton>
                            )
                        })
                    }

                    <RbContentEditable onConfirm={val => _createTodoList(val)} className="max-w-128">
                        <span className="pl-16 pr-16 pt-6 pb-6 ml-10 cursor-pointer">+ Add list</span>
                    </RbContentEditable>
                </div>
                <div className="min-w-[23rem] text-right">
                    <RbRoundButton className="m-20 mr-10 inline-block align-middle whitespace-nowrap"
                                   style={{color: theme.palette.text.primary}}
                                   onClick={() => setDialogTaskOpen(true)}
                                   variant="contained">+ Add task</RbRoundButton>
                    {
                        viewIndex >= 3 ?
                            <>
                                <IconButton className="mr-5 inline-block align-middle" onClick={toggleFriendList}>
                                    <UserAddIcon className="w-20"/>
                                </IconButton>
                                <IconButton className="mr-20 inline-block align-middle" onClick={handleAnchor}>
                                    <DotsVerticalIcon className="w-20"/>
                                </IconButton>
                            </>
                            : <></>
                    }
                </div>
            </div>

            <div className="todos-content h-[90%]">
                {
                    activeCustomTodos.length > 0 ?
                        activeCustomTodos.map((cat, i) => {
                            return <CategorySection key={i} refreshTodos={handleRefreshTodos}
                                                    selectTodo={todo => {
                                                        setSelectedTodo(todo);
                                                        setSidebarOpen(true);
                                                    }} title={cat.title}
                                                    todos={cat.todos}/>
                        }) : <div className="flex flex-1 h-[90%] justify-center items-center"><img
                            src="/assets/images/illustrations/todo.svg" alt="Todo illustration"/></div>
                }</div>

            <Menu
                id="widget-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={() => handleMenuItem(true)}>Sort by categories</MenuItem>
                <MenuItem onClick={() => handleMenuItem(false)}>Sort by completeness</MenuItem>
                {
                    viewIndex >= defaultLists.length &&
                    <MenuItem onClick={() => {
                        setAnchorEl(null);
                        setDialogDelete(true);
                    }} style={{color: theme.palette.error.dark}}>Delete
                        list <span
                            className="w-20 ml-5"><TrashIcon/></span></MenuItem>
                }
            </Menu>
            <Dialog open={dialogTaskOpen} onClose={() => setDialogTaskOpen(false)}>
                <AddTodoTask customLists={customTodoLists}
                             getCustomLists={_getCustomLists}
                             defaultList={viewIndex >= defaultLists.length ? customTodoLists[viewIndex - defaultLists.length] : undefined}
                             onSave={(listId, value) => {
                                 setDialogTaskOpen(false);
                                 _createTodoTask(listId, value);
                             }}/>
            </Dialog>
            <RbConfirmationDialog open={dialogDelete} onClose={() => setDialogDelete(false)}
                                  onConfirm={() => _deleteCustomList(customTodoLists[viewIndex - defaultLists.length].listId)}
                                  title="Delete confirmation">
                <p>Are you sure you want to delete
                    <b> {viewIndex >= defaultLists.length ? customTodoLists[viewIndex - defaultLists.length]?.name : ""}</b> list?
                </p>
            </RbConfirmationDialog>
            <Drawer
                anchor="right"
                open={sidebarOpen}
                onClose={() => setSidebarOpen(false)}>
                <TaskSideDetails todo={selectedTodo} refreshTodos={handleRefreshTodos} setTodo={setSelectedTodo}
                                 customLists={customTodoLists} closeSidebar={() => setSidebarOpen(false)}/>
            </Drawer>
            <Drawer
                anchor="right"
                open={friendListOpen}
                onClose={() => setFriendListOpen(false)}>
                <TodoFriendList/>
            </Drawer>
        </div>
    );

    function handleMenuItem(sortByCat: boolean) {
        setCategorySort(sortByCat);
        setAnchorEl(null);
    }

    function sortByOrderNo(a: ITodo, b: ITodo): number {
        if (a.orderNo > b.orderNo) return 1;
        if (a.orderNo < b.orderNo) return -1;
        return 0;
    }

    function sortByPriority(a: ITodo, b: ITodo): number {
        if (a.priorityId > b.priorityId) return 1;
        if (a.priorityId < b.priorityId) return -1;
        return 0;
    }

    function specialCustomFilter(todos: ITodo[]): { todos: ITodo[], title: string }[] {
        let tmp: { todos: ITodo[], title: string }[] = [];

        if (categorySort) {
            tmp.push({
                title: "My day",
                todos: todos.filter(t => t.isMyDay && !t.isDone).sort((a, b) => sortByOrderNo(a, b))
            });

            tmp.push({
                title: "Priorities",
                todos: todos.filter(t => t.priorityId && !t.isDone).sort((a, b) => sortByPriority(a, b))
            });

            tmp.push({
                title: "Scheduled",
                todos: todos.filter(t => t.dueDate && !t.isDone).sort((a, b) => sortByOrderNo(a, b))
            });

            tmp.push({
                title: "Other tasks",
                todos: todos.filter(t => !t.isMyDay && !t.isDone && !t.dueDate && !t.priorityId).sort((a, b) => sortByOrderNo(a, b))
            });

            tmp.push({
                title: "Completed",
                todos: todos.filter(t => t.isDone).sort((a, b) => sortByOrderNo(a, b))
            });
        } else {
            tmp.push({
                title: "Tasks",
                todos: todos.filter(t => !t.isDone).sort((a, b) => sortByOrderNo(a, b))
            });
            tmp.push({
                title: "Completed",
                todos: todos.filter(t => t.isDone).sort((a, b) => sortByOrderNo(a, b))
            });
        }
        return tmp;
    }

    function renderTodos() {
        let tmp: { todos: ITodo[], title: string }[] = [];
        switch (viewIndex) {
            case 0:
                if (priorityTodos.length <= 0) break;
                tmp = _(priorityTodos).groupBy(todo => todo.priorityId).map((todos, priorityId) => ({
                    todos: todos.filter(t => !t.isDone).sort((a, b) => sortByOrderNo(a, b)),
                    title: prioritiesString[Number(priorityId)]
                })).value();
                tmp.push({
                    todos: allTodos.filter(t => t.isDone && t.priorityId).sort((a, b) => sortByOrderNo(a, b)),
                    title: "Completed"
                });
                break;
            case 1:
                if (myDayTodos.length <= 0) break;
                tmp = _(myDayTodos).groupBy(todo => categorySort ? todo.listName : todo.isDone).map((todos, doneOrName) => ({
                    todos: todos.sort((a, b) => sortByOrderNo(a, b)),
                    title: categorySort ? doneOrName : (doneOrName === "false" ? "Today" : "Completed")
                })).value();
                break;
            case 2:
                if (allTodos.length <= 0) break;
                const myTasks = allTodos.filter(todo => (todo?.assignTo?.id ?? "") === user.uid)
                tmp = _(myTasks).groupBy(todo => todo.isDone).map((todos, isDone) => ({
                    todos,
                    title: isDone === "false" ? "Today" : "Completed"
                })).value();
                break;
            case 3:
                if (allTodos.length <= 0) break;
                tmp = specialCustomFilter(allTodos);
                break;
            default:
                getCustomListTodos(customTodoLists[viewIndex - defaultLists.length].listId).then(res => {
                    if (res?.data?.error) return;
                    tmp = specialCustomFilter(res.data.data);
                    setActiveCustomTodos(tmp);
                });
        }
        tmp = tmp.filter(cat => cat.todos.length > 0);
        setActiveCustomTodos(tmp);
    }
}
