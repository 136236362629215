import Todo from "./Todo";

const TodoConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'todo',
            element: <Todo/>,
        },
    ],
};

export default TodoConfig;
