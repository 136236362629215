import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import userManager, {signinRedirectCallback} from "../../services/authService";
import {setAccessToken} from "../../auth/store/userSlice";
import FuseSplashScreen from "../../../@fuse/core/FuseSplashScreen";

function SigninOidc() {
    const navigate = useNavigate();
    useEffect(() => {
        signinRedirectCallback().then(r => {
            userManager.getUser().then(user => {
                if (user && user.access_token && !user.expired) {
                    setAccessToken(user.access_token);
                }
            });
            window.history.replaceState({},
                window.document.title,
                window.location.origin + window.location.pathname);
            // @ts-ignore
            window.location = "/";
            navigate('/');
        });
    }, []);

    return (
        <FuseSplashScreen/>
    );
}

export default SigninOidc;
