import {useTheme} from "@mui/material/styles";
import {IRbDisplayHeader, UserOrganization} from "../../../types/common-types";
import * as React from "react";
import {
    IOrganizationBilling,
    IOrganizationBillingDetails,
} from "../../../types/organization-settings-types";
import {useEffect, useState} from "react";
import {RbSubSection} from "app/shared-components/rb-components/RbSections";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import RbTableActionButton from "../../../shared-components/rb-components/RbDataTable/RbTableActionButton";
import {
    getOrganizationBilling,
    getOrganizationBillingDetails, renewLicence,
} from "../../../services/organizationSettingsService";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";
import {CreditCardIcon} from "@heroicons/react/outline";

function BillingOrganizationSettings(props: { organization?: UserOrganization }) {
    const [billings, setBillings] = useState<IOrganizationBilling[]>([]);
    const [billingDetails, setBillingDetails] = useState<IOrganizationBillingDetails>();

    const theme = useTheme();

    const displayColumns: IRbDisplayHeader[] = [
        {
            name: "#Invoice",
            objAttribute: "invoiceNo",
            className: "font-bold",
        },
        {
            name: "Licence type",
            objAttribute: "licence"
        },
        {
            name: "Description",
            objAttribute: "description"
        },
        {
            name: "Seats",
            objAttribute: "seats"
        },
        {
            name: "Total",
            objAttribute: "price",
            handleRendering: renderPrice
        },
        {
            name: "Invoice date",
            objAttribute: "invoiceDate"
        },
        {
            name: "Due date",
            objAttribute: "dueDate"
        },
        {
            name: "Status",
            objAttribute: "status",
            handleRendering: renderStatus
        },
        {
            name: "",
            objAttribute: "",
            handleRendering: renderTableActions
        }
    ];

    useEffect(() => {
        getOrganizationBillingsInfo();
    }, [props?.organization?.id]);

    function getOrganizationBillingsInfo() {
        _getOrganizationBilling();
        _getOrganizationBillingDetails();
    }


    function _getOrganizationBillingDetails() {
        if (!props?.organization?.id) return;
        getOrganizationBillingDetails(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setBillingDetails(res.data.data);
        });
    }

    function _getOrganizationBilling() {
        if (!props.organization?.id) return;
        getOrganizationBilling(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setBillings(res.data.data);
        });
    }

    function renderPrice(obj: IOrganizationBilling) {
        return (+(obj?.price ?? 0)).toLocaleString("en-EN", {
            style: 'currency',
            currency: obj?.currency ?? "EUR",
        });
    }

    function renderStatus(obj: IOrganizationBilling) {
        return (
            <span className="capitalize font-bold"
                // @ts-ignore
                  style={{color: obj.status === "Paid" ? theme.priority.low : theme.priority.high}}>{obj.status}</span>
        );
    }

    function renderTableActions(obj: IOrganizationBilling) {
        return (
            <div className="flex justify-start">
                <RbTableActionButton icon="EyeIcon"/>
                <RbTableActionButton icon="SaveIcon"/>
                {
                    obj.status !== "Paid" &&
                    <RbRoundButton className="ml-5 flex items-center justify-center" variant="outlined" onClick={() => {
                        renewLicence(obj.id).then(res => {
                            if (res?.data?.error) return;
                            getOrganizationBillingsInfo();
                        })
                    }}><CreditCardIcon className="w-20 mr-5"/> <span>Renew</span></RbRoundButton>
                }
            </div>
        );
    }

    return (
        <div className="mt-48 md:ml-28 sm:ml-28 sm:mr-28 ml-64 mr-64 mb-48">
            <h2 className="font-bold">Billing</h2>
            <RbSubSection>
                <div className="flex gap-5 w-full">
                    <RbBox className="flex flex-col w-full justify-between gap-10">
                        <h4 className="font-bold">Next invoice issue date</h4>
                        <span
                            className="xl block font-bold text-xl">
                            {new Date(billingDetails?.nextInvoiceDate ?? "").toLocaleDateString("de-DE")}
                        </span>
                    </RbBox>

                    <RbBox className="flex flex-col w-full justify-between gap-10">
                        <h4 className="font-bold">Total amount</h4>
                        <span className="xl block font-bold text-xl">{billingDetails?.totalAmount}</span>
                    </RbBox>

                    <RbBox className="flex flex-col w-full justify-between gap-10">
                        <h4 className="font-bold">New seats</h4>
                        <span className="xl block font-bold text-xl">{billingDetails?.seats ?? 0}</span>
                    </RbBox>

                    <RbBox className="flex flex-col w-full justify-between gap-10">
                        <h4 className="font-bold">Total seats</h4>
                        <span className="xl block font-bold text-xl">{billingDetails?.seats ?? 0}</span>
                    </RbBox>

                    <RbBox className="flex flex-col w-full justify-between gap-10">
                        <h4 className="font-bold">Licence</h4>
                        <span className="xl block font-bold text-xl">{billingDetails?.licence}</span>
                    </RbBox>
                </div>
            </RbSubSection>

            <RbSubSection>
                <div className="flex gap-5 w-full">
                    <RbBox className="flex flex-col w-full justify-between gap-10">
                        <h4 className="font-bold">Organization annual renual</h4>
                        <span
                            className="xl block font-bold text-xl">
                            {new Date(billingDetails?.nextInvoiceDate ?? "").toLocaleDateString("de-DE")}
                        </span>
                        <span className="block text-xs cursor-pointer hover:underline"
                              style={{color: "#0284C7"}}>Change payment method</span>
                    </RbBox>

                    <RbBox className="flex flex-col w-full justify-between gap-10">
                        <h4 className="font-bold">Licence</h4>
                        <span className="xl block font-bold text-xl">{billingDetails?.licence}</span>
                        <span className="block text-xs cursor-pointer hover:underline"
                              style={{color: "#0284C7"}}>Upgrade your plan</span>
                    </RbBox>

                    <RbBox className="flex flex-col w-full justify-between gap-10">
                        <h4 className="font-bold">Total seats today</h4>
                        <span className="xl block font-bold text-xl">{billingDetails?.occupied ?? 0}</span>
                    </RbBox>

                    <RbBox className="flex flex-col w-full justify-between gap-10">
                        <h4 className="font-bold">Billing contact</h4>
                        <span className="xl block font-bold text-xl"></span>
                        <span className="block text-xs cursor-pointer hover:underline"
                              style={{color: "#0284C7"}}>Change billing contact</span>
                    </RbBox>
                </div>
            </RbSubSection>
            <RbSubSection>
                <RbDataTable dataSource={billings} headerColumns={displayColumns}/>
            </RbSubSection>
        </div>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(BillingOrganizationSettings));
