import {ToggleButton, ToggleButtonGroup, Tooltip} from "@mui/material";
import {ChartBarIcon, ViewBoardsIcon} from "@heroicons/react/outline";
import React from "react";
import HeroIcon from "../../../@fuse/core/HeroIcon/HeroIcon";
import {RbTheme} from "../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {RbToggleButtonGroup} from "app/shared-components/rb-components/RbToggleButtonGroup";

export type RbViewType = "fav" | "activity" | "list" | "cards";
export default function BoardsViewControls(props: { view?: RbViewType, setView: React.Dispatch<RbViewType>, hasFav: boolean }) {
    const theme: RbTheme = useTheme();
    return (
        <>
            <RbToggleButtonGroup
                size="small"
                className="w-fit"
                style={{backgroundColor: theme.palette.primary.main}}
                value={props.view}
                exclusive
                onChange={(e, val) => {
                    if (val) {
                        props.setView(val);
                    } else {
                        e.preventDefault();
                    }
                }}
                aria-label="text alignment">

                {
                    props.hasFav &&
                    <Tooltip title="Favorites">
                        <ToggleButton value="fav">
                    <span
                        style={{color: props.view === "fav" ? "gold" : ""}}
                        className="w-20">
                    <HeroIcon icon="StarIcon" outline={props.view !== "fav"}/>
                    </span>
                        </ToggleButton>
                    </Tooltip>
                }

                <Tooltip title="Card view">
                    <ToggleButton value="cards">
                    <span
                        style={{color: props.view === "cards" ? theme.palette.special : ""}}
                        className="w-20">
                    <HeroIcon icon="ViewGridIcon" outline={props.view !== "cards"}/>
                    </span>
                    </ToggleButton>
                </Tooltip>

                <Tooltip title="Timeline">
                    <ToggleButton value="activity">
                    <span
                        style={{color: props.view === "activity" ? theme.palette.special : ""}}
                        className="w-20">
                    <HeroIcon icon="ClockIcon" outline={props.view !== "activity"}/>
                    </span>
                    </ToggleButton>
                </Tooltip>

                <Tooltip title="By project">
                    <ToggleButton value="list">
                    <span
                        style={{color: props.view === "list" ? theme.palette.special : ""}}
                        className="w-20">
                    <HeroIcon icon="ViewBoardsIcon" outline={props.view !== "list"}/>
                    </span>
                    </ToggleButton>
                </Tooltip>
            </RbToggleButtonGroup>
        </>
    )
}
