import * as React from 'react';
import {useEffect, useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import {CheckIcon, ChevronDownIcon, SearchIcon} from "@heroicons/react/outline";
import {getAllUserOrganizations, getUserOrganizationRoles} from "../../services/sidebar/sidebarService";
import {UserOrganization} from "../../types/common-types";
import {useTheme} from "@mui/material/styles";
import FuseUtils from "../../../@fuse/utils";
import {clearLSUserData, getLSUserData, StorageDataType, saveLSUserData} from "../../services/storageBus";
import {RbTextField} from "../rb-components/RbTextField";
import clsx from "clsx";
import {useRbDispatch} from "../../rb-hooks/hooks";
import {updateUserOrganization} from "../../store/rubicon/organizationSlice";
import {connect} from "react-redux";
import withRouter from "../../../@fuse/core/withRouter";
import {useNavigate} from "react-router-dom";
import OrganizationCreateDialog from "../../main/organization-settings/create-organization/OrganizationCreateDialog";
import {getOrganizationInfo, getOrganizationUserRoles} from "../../services/organizationSettingsService";
import {IUserOrganizationInfo} from "../../types/organization-settings-types";
import moment from "moment";
import {AppInfoSlice, updateAppInfo} from "../../store/rubicon/appInfoSlice";
import {setUserData} from "../../auth/store/userSlice";

const OrganizationMenu = (props: { className?: string, organization?: UserOrganization, user: any }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filteredOrganizations, setFilteredOrganizations] = useState<UserOrganization[]>([]);
    const [createOpen, setCreateOpen] = useState<boolean>(false);
    const [organizations, setOrganizations] = useState<UserOrganization[]>([]);
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useRbDispatch();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuItemSelect = (org: | UserOrganization | null = null) => {
        if (org) {
            dispatch(updateUserOrganization(org));
            saveLSUserData(StorageDataType.Organization, org);
            if (!window.location.pathname.includes("organization-settings") && !window.location.pathname.includes("user-settings")) {
                navigate("/");
            }
        }
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!props?.user?.data) return;
        _getAllUserOrganizations();
    }, [props?.user?.data]);

    useEffect(() => {
        _getOrganizationInfo();
        _getUserRoles();
    }, [props?.organization]);

    function _getUserRoles() {
        if (!props?.organization?.id) return;
        getUserOrganizationRoles(props.organization.id).then(res => {
            if (res?.data?.error) return;
            if (props?.user) {
                // @ts-ignore
                dispatch(setUserData({...props.user, role: res.data.data}));
            }
        });
    }

    function _getOrganizationInfo() {
        if (!props?.organization?.id) return;
        getOrganizationInfo(props.organization.id).then(res => {
            if (res?.data?.error) return;
            const orgData = res.data.data as IUserOrganizationInfo;
            const days = moment.duration(moment(orgData.expirationDate).diff(moment())).days();
            if (days <= 0) {
                const info: AppInfoSlice = {
                    title: "Payment notification",
                    theme: "error",
                    message:
                        <span>Your organization has expired on {new Date(orgData.expirationDate).toLocaleDateString("de-DE")}.
                            Please visit <span onClick={() => navigate("organization-settings/billing")}
                                               className="cursor-pointer underline text-inherit">billing page</span> to take further actions.</span>,
                }
                dispatch(updateAppInfo(info));
            } else if (days <= 7) {
                const info: AppInfoSlice = {
                    title: "Payment notification",
                    theme: "warn",
                    message:
                        <span>Your organization expires on {new Date(orgData.expirationDate).toLocaleDateString("de-DE")}.
                            Please visit <span onClick={() => navigate("organization-settings/billing")}
                                               className="cursor-pointer underline text-inherit">billing page</span> to take further actions.</span>
                }
                dispatch(updateAppInfo(info));
            } else {
                dispatch(updateAppInfo(null));
            }
        })
    }

    function _getAllUserOrganizations() {
        getAllUserOrganizations().then(res => {
            if (res?.data?.data) {
                setFilteredOrganizations(res?.data?.data);
                setOrganizations(res?.data?.data);
                checkSavedOrganization(res.data.data);
            }
        });
    }

    function checkSavedOrganization(orgs: UserOrganization[]) {
        const org = getLSUserData(StorageDataType.Organization);

        if (org?.id && orgs.some(val => val.id === org.id)) {
            dispatch(updateUserOrganization(org));
        } else {
            clearLSUserData(StorageDataType.Organization);
            if (orgs.length > 0 && !props.organization?.id) {
                handleMenuItemSelect(org ?? orgs[0]);
            }
        }
    }

    function handleOrganizationSearch(el: any) {
        if (!el.target?.value) {
            setFilteredOrganizations(organizations);
        } else {
            setFilteredOrganizations(organizations.filter(org => FuseUtils.searchInString(org.name, el.target.value)));
        }
    }

    return (
        <>
            <div onClick={handleClick}
                 className={clsx(props.className, `organisation-container cursor-pointer transition-colors w-full flex justify-between align-middle border-b-2 border-b-[${theme.palette.text.secondary}]`)}>
                    <span className="mr-10">
                        {props.organization?.name ?? "Organization"}
                    </span>
                <ChevronDownIcon className="w-20"/>
            </div>
            <OrganizationCreateDialog organization={props?.organization} open={createOpen} onClose={(update) => {
                if (update) {
                    _getAllUserOrganizations();
                }
                setCreateOpen(false);
            }}/>
            <Popover
                id="organization-menu"
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => handleMenuItemSelect()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <div className="flex flex-col">
                    <RbTextField className="w-10/12 pt-5 mb-5 m-auto"
                                 fullWidth
                                 variant="standard"
                                 InputProps={{endAdornment: <SearchIcon/>}}
                                 onChange={handleOrganizationSearch}
                                 color="primary"/>
                    {filteredOrganizations?.map((org) => {
                        return (
                            <MenuItem onClick={() => handleMenuItemSelect(org)} style={{
                                color: theme?.palette?.text?.primary,
                                opacity: org.id === props.organization?.id ? 1 : 0.7
                            }} key={`orgItem-${org.id.toString()}`}>
                                {props.organization?.id === org.id ?
                                    <CheckIcon className="mr-10 w-16 w-max-16" style={{flexBasis: 18}}/> :
                                    <span style={{flexBasis: 18}}></span>}
                                {org?.name}</MenuItem>
                        )
                    })}
                    <Divider key='divider' className="w-10/12 m-auto mt-8 mb-0"/>
                    <MenuItem key="orgItem-logout" className=""
                              onClick={() => {
                                  setAnchorEl(null);
                                  setCreateOpen(true);
                              }}><span
                        className="w-full text-center">Create new organization</span></MenuItem>
                </div>
            </Popover>
        </>
    );
}

// @ts-ignore
function mapStateToProps({fuse, auth}) {
    return {
        organization: fuse.organization.value,
        user: auth?.user
    }
}

export default withRouter(connect(mapStateToProps)(OrganizationMenu));
