import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserOrganization} from "../../types/common-types";

export interface OrganizationState {
    value: UserOrganization | null;
}

const initialState: OrganizationState = {
    value: null
}

const organizationSlice = createSlice({
    name: "organization", initialState, reducers: {
        updateUserOrganization(state, action: PayloadAction<UserOrganization | null>) {
            state.value = action.payload;
        }
    }
});


export const {updateUserOrganization} = organizationSlice.actions;
export default organizationSlice.reducer;
