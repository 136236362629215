import {RbSubSection, RbSubSectionItem} from "../../../shared-components/rb-components/RbSections";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {IconButton, InputAdornment, List, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import {TrashIcon, UserCircleIcon} from "@heroicons/react/outline";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import React, {useState} from "react";
import RbIconTitle from "../../../shared-components/rb-components/RbIconTitle";
import {ITodoFriend} from "../../../types/todo-types";
import {removeFriend, searchFriendsUser, sendUserFriendRequest} from "../../../services/todo/todoService";
import {RbCheckBoxIcon} from "../../../shared-components/rb-components/RbCheckBoxIcon";
import HeroIcon from "../../../../@fuse/core/HeroIcon/HeroIcon";
import Avatar from "@mui/material/Avatar";
import {getUserInitials} from "../../../utils/RbUtils";

export default function TodoFriends(props: { friendList: ITodoFriend[], refreshFriendList: () => void, friendResults: number, setIsInvites: React.Dispatch<boolean> }) {
    const theme: RbTheme = useTheme();
    const [friend, setFriend] = useState<ITodoFriend>();
    const [search, setSearch] = useState<string>("");

    function handleUserSearch(value: string) {
        setSearch(value);
        if (!value || value.length < 6) return;
        searchFriendsUser(value).then(res => {
            if (res?.data?.error) return;
            setFriend(res.data.data);
        });
    }

    function _sendUserFriendRequest() {
        if (!friend?.id) return;
        sendUserFriendRequest(friend.id).then(res => {
            if (res?.data?.error) return;
            setSearch("");
            props.refreshFriendList();
            setFriend(undefined);
        });
    }

    function _removeFriend(id?: string) {
        if (!id) return;
        removeFriend(id).then(res => {
            if (res?.data?.error) return;
            props.refreshFriendList();
        });
    }

    function handleKeyPress(e: any) {
        if (e.key === 'Enter') {
            e.stopPropagation();
            _sendUserFriendRequest();
        }
    }

    return (
        <>
            <RbSubSection>
                <RbBox className="p-0 rounded-6 overflow-hidden" theme="secondary">
                    <RbTextField
                        placeholder="Search"
                        hiddenLabel={true}
                        className="slim"
                        value={search}
                        onChange={(e) => handleUserSearch(e.target.value)}
                        onKeyDown={handleKeyPress}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <RbCheckBoxIcon checked={!!friend?.id} disabled={!friend?.id}
                                                    onClick={_sendUserFriendRequest}
                                                    icon={<HeroIcon icon="CheckCircleIcon" outline={true}/>}
                                                    checkedIcon={<HeroIcon icon="CheckCircleIcon" outline={false}/>}/>
                                </InputAdornment>
                            ),
                        }}
                        variant="filled"
                    />
                </RbBox>

                <RbSubSectionItem>
                    <ListItem
                        className="cursor-default select-none mb-10"
                        secondaryAction={
                            <span className="font-bold">{props.friendResults ?? 0}</span>
                        }
                        disablePadding
                    >
                        <ListItemAvatar className="min-w-fit">
                            <UserCircleIcon className="w-32 mr-10" strokeWidth={1.5}/>
                        </ListItemAvatar>
                        <ListItemText primary="Pending invites" secondary={
                            <span onClick={() => props.setIsInvites(true)} style={{color: theme.palette.text.primary}}
                                  className="underline cursor-pointer">See all</span>
                        }/>
                    </ListItem>
                </RbSubSectionItem>
            </RbSubSection>
            <hr/>
            <RbSubSectionItem>
                <RbIconTitle container="h4" icon="UsersIcon" iconClassName="w-16" bold={true} title="Friend on the list"/>
            </RbSubSectionItem>

            <RbSubSectionItem>
                <List>
                    {
                        props.friendList.map(fr => {
                            return (
                                <ListItem
                                    key={fr.id}
                                    className="mb-10"
                                    secondaryAction={
                                        <IconButton className="inline-block align-middle"
                                                    onClick={() => _removeFriend(fr.id)}>
                                            <TrashIcon className="w-20"/>
                                        </IconButton>
                                    }
                                    disablePadding
                                >
                                    <ListItemAvatar className="min-w-fit mr-10">
                                        <Avatar src={fr?.avatar ?? ""}
                                                alt={fr?.name}>{getUserInitials(fr?.name)}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={fr?.name}/>
                                </ListItem>
                            )
                        })
                    }
                </List>
            </RbSubSectionItem>
        </>
    )
}
