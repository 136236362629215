import {RbSubSection} from "app/shared-components/rb-components/RbSections";
import {IWidget} from "../../../types/dashboard-types";
import {IconButton, List, ListItem, ListItemText} from "@mui/material";
import {CheckCircleIcon} from "@heroicons/react/outline";
import {useTheme} from "@mui/material/styles";

export default function DashboardWidgetSettings(props: { toggleWidget: (widget: IWidget) => void, widgets: IWidget[] }) {
    const theme = useTheme();

    function renderWidgetCategory(widgets: IWidget[], title: string) {
        return (
            <>
                <h2 className="mb-10 font-bold">{title} widgets</h2>
                <hr/>
                <List dense={true}>
                    {
                        widgets.map(w => {
                            return (
                                <ListItem
                                    key={w.id}
                                    secondaryAction={
                                        <IconButton style={{opacity: w.active ? "1" : "0.3"}}
                                                    className="transition-opacity duration-200" edge="end"
                                                    aria-label="Toggle active" onClick={() => props.toggleWidget(w)}>
                                            <CheckCircleIcon color="#06B6D4" className="w-20"/>
                                        </IconButton>
                                    }
                                >
                                    <ListItemText
                                        primary={w.name}
                                        secondary={w.description}
                                    />
                                </ListItem>
                            )
                        })
                    }
                </List>
            </>
        )
    }

    return (
        <div className="p-20 w-512 mb-28">
            <RbSubSection>
                {renderWidgetCategory(props.widgets.filter(w => w.type === "member"), "Member")}
            </RbSubSection>

            <RbSubSection>
                {renderWidgetCategory(props.widgets.filter(w => w.type === "admin"), "Admin")}
            </RbSubSection>
        </div>
    );
}
