import RbBox from "../../../shared-components/rb-components/RbBox";
import HeroIcon from "../../../../@fuse/core/HeroIcon/HeroIcon";
import clsx from "clsx";
import {DotsVerticalIcon} from "@heroicons/react/outline";
import React, {useState} from "react";
import {IconButton, Menu, MenuItem} from "@mui/material";
import {updateWidgetWidth} from "../../../services/dashboard/dashboardService";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";
import {UserOrganization} from "../../../types/common-types";

export type WidgetWidth = "small" | "medium" | "large" | "full";

function WidgetBase<T>(props: { organization: UserOrganization, id: number, className?: string, title: string, defaultWidth: WidgetWidth, titleIcon?: string, headerControl?: () => JSX.Element, children?: any }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [sizes] = useState([
        {id: 0, name: "Small  (33%)", value: "small"},
        {id: 1, name: "Medium (50%)", value: "medium"},
        {id: 2, name: "Large  (66%)", value: "large"},
        {id: 3, name: "Full   (100%)", value: "full"},
    ]);

    const parseSize = (value: string | undefined) => {
        // if (value === "full") return "w-[calc(100%-16px)]";
        switch (value) {
            case "small":
                return "w-[calc(33%-20px)]";
                break;
            case "medium":
                return "w-[calc(50%-20px)]";
                break;
            case "large":
                return "w-[calc(66%-20px)]";
                break;
            default:
                return "w-[calc(100%-16px)]";
                break;

        }
    }
    const [size, setSize] = useState<string>(parseSize(props?.defaultWidth));

    const handleAnchor = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event: any) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    function handleResize(e: React.MouseEvent<HTMLElement>, value: WidgetWidth) {
        if (!props?.organization?.id) return;
        const tmp = size.toString();
        setSize(parseSize(value));
        updateWidgetWidth(props.organization.id, props.id, value).then(res => {
            if (res?.data?.error) {
                setSize(tmp);
                return;
            }
        });
        setAnchorEl(null);
    }

    return (
        <RbBox
            className={clsx("m-10 mb-28 rounded-16 cursor-default transition-[width] duration-200 inline-block align-top", props.className, size)}>
            <div className="flex pl-10 pr-10 justify-between items-center">
                <h2 className="font-bold">
                    {props.titleIcon &&
                        <span className="w-24 mr-5 inline-block align-middle">
                            <HeroIcon icon={props.titleIcon} outline={true}/>
                        </span>}<span className="inline-block align-middle">{props.title}</span></h2>
                <span className="w-fit">
                {
                    props?.headerControl && props.headerControl()
                }
                    <IconButton className="ml-10 inline-block align-sub" onClick={handleAnchor}>
                    <DotsVerticalIcon className="w-20"/>
                </IconButton>
                </span>
            </div>
            <hr className="mt-10"/>
            {props.children}
            <Menu
                id="resize-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleMenuClose}
            >
                {
                    sizes.map((size, i) => {
                        return <MenuItem
                            key={i}
                            onClick={e => handleResize(e, size.value as WidgetWidth)}
                        >
                            {size.name}
                        </MenuItem>
                    })
                }
            </Menu>
        </RbBox>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(WidgetBase));

