import {QuestionMarkCircleIcon, SpeakerphoneIcon} from "@heroicons/react/outline";
import {ChatAlt2Icon} from "@heroicons/react/outline";
import * as React from "react";
import {useTheme} from "@mui/material/styles";

const HelpMenuDialog = () => {
    const theme = useTheme();
    return (<div className="pb-20 pt-20 pl-10 pr-10 flex" style={{backgroundColor: theme.palette.background.paper}}>
        <div
            className="inline-block cursor-pointer select-none items-center w-80 border-r-1 border-solid leading-[36px] flex flex-col text-center" style={{borderRightColor: theme.palette.text.secondary}}>
            <QuestionMarkCircleIcon strokeWidth={1}
                                    className="w-36 pb-5"/><span>FAQ</span></div>
        <div
            className="inline-block cursor-pointer select-none items-center w-80 border-r-1 border-solid flex flex-col justify-center text-center" style={{borderRightColor: theme.palette.text.secondary}}>
            <SpeakerphoneIcon strokeWidth={1}
                              className="w-36 pb-5"/><span>Report a bug</span></div>
        <div
            className="inline-block cursor-pointer select-none items-center w-80 flex flex-col justify-center text-center">
            <ChatAlt2Icon strokeWidth={1}
                          className="w-36 pb-5"/><span>Give us feedback</span></div>
    </div>);
}

export default HelpMenuDialog;
