import RbBox from "../../../shared-components/rb-components/RbBox";
import {styled} from "@mui/system";
import Chart from "react-apexcharts";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {useState} from "react";
import {RbColors} from "../../../shared-components/rb-components/RbColorMenu/RbColorMenu";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";

const RbChart = styled(Chart)(({theme}) => ({
    '& svg': {
        backgroundColor: "transparent !important",
    },
    '& .apexcharts-pie-label': {
        fill: theme.palette.mode === "light" ? "inherit !important" : ""
    },
}));

export default function ReportBoardWorkload(props: { data: any, className?: string }) {
    const theme: RbTheme = useTheme();
    const [series, setSeries] = useState<any>([1, 2, 3, 4]);
    const [options, setOptions] = useState<any>({
        chart: {
            id: "project-donut",
            pattern: {
                strokeWidth: 4
            }
        },
        tooltip: {
            cssClass: "rbTooltip"
        },
        colors: RbColors,
        labels: [],
        legend: {
            show: false
        },
        dataLabels: {
            enabled: false
        },
        theme: {mode: theme.palette.mode}
    });

    return (
        <RbBox className={`p-0 mb-20 h-320 ${props.className ?? ""}`}>
            <div className="px-20 py-12"
                 style={{borderBottom: `3px solid ${theme.palette.background.paper}`}}>
                <h2 className="flex items-center justify-between">
                    <span>Workload</span>
                    <RbRoundButton className="" variant="contained">Select board</RbRoundButton>
                </h2>
            </div>
            <div className="flex justify-evenly p-20">
                <RbChart options={options} series={series} type="donut" height="200" width="200"/>
                <div className="">
                    <span className="block">test test test</span>
                    <span className="block">test test test</span>
                    <span className="block">test test test</span>
                    <span className="block">test test test</span>
                    <span className="block">test test test</span>
                </div>
            </div>
        </RbBox>
    )
}
