import {styled} from "@mui/system";
import {LinearProgress} from "@mui/material";

export const RbProgressBar = styled(LinearProgress)(({theme}) => ({
    '&.MuiLinearProgress-colorPrimary': {
        backgroundColor: `${theme.palette.mode === "dark" ? "#27272a" : "#c3e7fa"}`
    },
    "& .MuiLinearProgress-bar1Determinate": {
        backgroundColor: `${theme.palette.mode === "dark" ? "#075985" : "#7DD3FC"}`
    }
}));
