import FuseScrollbars from '@fuse/core/FuseScrollbars';
import {styled, useTheme} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import Navigation from 'app/fuse-layouts/shared-components/Navigation';
import UserNavbarHeader from 'app/fuse-layouts/shared-components/UserNavbarHeader';
import clsx from 'clsx';
import {memo, useEffect, useState} from 'react';
import {TabContext, TabPanel} from "@mui/lab";
import {RbRoundButton} from "../../../../../shared-components/rb-components/RbRoundButton";
import {useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/outline";
import OrganizationMenu from "../../../../../shared-components/organization/OrganizationMenu";
import {connect, useDispatch} from "react-redux";
import {updateSubNavigation} from "../../../../../store/rubicon/subNavigationSlice";
import withRouter from "../../../../../../@fuse/core/withRouter";

const Root = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '& ::-webkit-scrollbar-thumb': {
        boxShadow: `inset 0 0 0 20px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'}`,
    },
    '& ::-webkit-scrollbar-thumb:active': {
        boxShadow: `inset 0 0 0 20px ${theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'}`,
    },
}));

const StyledContent = styled(FuseScrollbars)(({theme}) => ({
    overscrollBehavior: 'contain',
    overflowX: 'hidden',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    background: 'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 40px, 100% 10px',
    backgroundAttachment: 'local, scroll',
}));

function NavbarStyle2Content(props) {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    function handleBackSidebar() {
        dispatch(updateSubNavigation(null));
        navigate("/");
    }

    return (<Root className={clsx('flex flex-auto flex-col overflow-auto h-[100vh]', props.className)}>
        {!props?.subNavigation ? <>
            <AppBar
                color="primary"
                position="static"
                className="flex flex-row items-center shrink h-48 md:h-64 min-h-48 md:min-h-64 px-12 shadow-0"
            >
                <div className="flex flex-1 mx-4">
                    <Logo/>
                </div>
            </AppBar>

            <StyledContent option={{suppressScrollX: true, wheelPropagation: true}}>
                <UserNavbarHeader/>

                <Navigation layout="vertical"/>
            </StyledContent>
        </> : <StyledContent option={{suppressScrollX: true, wheelPropagation: true}}>
            <RbRoundButton className="submenu-back-btn mt-10 ml-12 w-full max-w-[10rem]"
                           style={{color: theme.palette.text.primary}}
                           onClick={handleBackSidebar}>
                <ChevronLeftIcon
                    className="submenu-back-icon transition-transform align-middle inline-block max-w-20"
                    strokeWidth="2"/><span
                className="submenu-back-text transition-opacity align-middle inline-block">Go back</span></RbRoundButton>
            <OrganizationMenu
                className={`m-auto w-3/4 pt-20 pb-5 font-bold w-full block`}/>
            <Navigation layout="vertical"/>
        </StyledContent>}
    </Root>);
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        subNavigation: fuse.subNavigation.value
    }
}

export default withRouter(connect(mapStateToProps)(memo(NavbarStyle2Content)));
