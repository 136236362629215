import {RbTableSelect} from "../RbDataTable/RbTableSelect";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import moment from "moment";
import {useEffect} from "react";

export default function RbWidgetDatePicker(props: { onChange: (dateFrom: string, dateTo: string) => void, triggerOnLoad?: boolean }) {
    useEffect(() => {
        if (props?.triggerOnLoad === false) return;
        handleOnChange(0);
    }, [])

    function handleOnChange(value: 0 | 1 | 2 | 3) {
        switch (value) {
            default:
                // today
                const today = moment().startOf('day').toISOString();
                props.onChange(today, today);
                break;
            case 1:
                // yesterday
                const yesterday = moment().startOf('day').subtract(1, "day").toISOString();
                props.onChange(yesterday, yesterday);
                break;
            case 2:
                // this week
                const this_from = moment().startOf('week').toISOString();
                const this_to = moment().endOf('week').toISOString();
                props.onChange(this_from, this_to);
                break;
            case 3:
                // last week
                const last_from = moment().startOf('week').subtract(1, "week").toISOString();
                const last_to = moment().endOf('week').subtract(1, "week").toISOString();
                props.onChange(last_from, last_to);
                break;
        }
    }

    return (
        <RbTableSelect
            defaultValue={0}
            variant="filled"
            disableUnderline={true}
            onChange={e => handleOnChange(e.target.value as any)}>
            <MenuItem value={0}>Today</MenuItem>
            <MenuItem value={1}>Yesterday</MenuItem>
            <MenuItem value={2}>This week</MenuItem>
            <MenuItem value={3}>Last week</MenuItem>
        </RbTableSelect>
    )
}
