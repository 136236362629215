import React, {useEffect, useState} from "react";
import RbSingleSelect from "../../shared-components/rb-components/RbSingleSelect";
import {getAllProjectBoards, getOrganizationWorkspaces, getWorkspaceProjects} from "../../services/common/common";
import {RbRoundButton} from "../../shared-components/rb-components/RbRoundButton";
import {IBoardDropDown, IProjectDropDown, IWorkspace, RbTheme, UserOrganization} from "../../types/common-types";
import {
    getOrganizationEstimatedTime,
    getOrganizationTrackerActivity,
    getReportsTeams,
    getReportsUser,
    getReportsUserActivity, getReportTeamUsersWorkload,
    getTrackerReports
} from "../../services/resportsService";
import {DesktopDatePicker, TabContext, TabPanel} from "@mui/lab";
import {useTheme} from "@mui/material/styles";
import RbBox from "../../shared-components/rb-components/RbBox";
import {ChevronDownIcon} from "@heroicons/react/outline";
import moment from "moment";
import {
    IReportEstimatedTime,
    IReports,
    IReportTeamUserWorkload,
    IReportTrackerActivity,
    IReportUserActivity
} from "../../types/report-types";
import TrackerReports from "./pages/TrackerReports";
import {connect} from "react-redux";
import withRouter from "../../../@fuse/core/withRouter";
import {IOrganizationTeam, IOrganizationUser} from "../../types/organization-settings-types";
import {getOrganizationTeams, getOrganizationUsers} from "../../services/organizationSettingsService";
import ReportUsers from "./pages/ReportUsers";
import ReportTeams from "./pages/ReportTeams";
import {TextField} from "@mui/material";
import ReportEstimatedTime from "./pages/ReportEstimatedTime";
import {getBoardCardStatuses} from "../../services/boards/boardsService";
import {ICardStatus} from "../../types/board-types";
import {clearSSUserData, getSSUserData, saveSSUserData} from "../../services/storageBus";

interface IReportsSettings {
    workspace: IWorkspace | undefined;
    project: IProjectDropDown | undefined;
    board: IBoardDropDown | undefined;
    cardStatus: ICardStatus | undefined;
    dateFrom: moment.Moment;
    dateTo: moment.Moment;
    dateDisplay: string;
}

function Reports(props: { search?: string, organization?: UserOrganization }) {
    const report_estimated = "reports_estimated";
    const theme: RbTheme = useTheme();
    const [tab, setTab] = useState<string>("tracker");
    const [project, setProject] = useState<IProjectDropDown>();
    const [projects, setProjects] = useState<IProjectDropDown[]>([]);
    const [workspaces, setWorkspaces] = useState<IWorkspace[]>([]);
    const [workspace, setWorkspace] = useState<IWorkspace>();
    const [projectAnchor, setProjectAnchor] = useState<HTMLElement | null>(null);
    const [dateAnchor, setDateAnchor] = useState<HTMLElement | null>(null);
    const [teamsAnchor, setTeamsAnchor] = useState<HTMLElement | null>(null);
    const [usersAnchor, setUsersAnchor] = useState<HTMLElement | null>(null);
    const [boardsAnchor, setBoardsAnchor] = useState<HTMLElement | null>(null);
    const [statusAnchor, setStatusAnchor] = useState<HTMLElement | null>(null);
    const [workspaceAnchor, setWorkspaceAnchor] = useState<HTMLElement | null>(null);
    const tabs = ["tracker", "users", "teams", "budget", "invoice", "estimated time"];
    const [usersData, setUsersData] = useState<IReports>();
    const [teamsData, setTeamsData] = useState<IReports>();
    const [trackerData, setTrackerData] = useState<IReports>();
    const [dateFrom, setDateFrom] = useState<moment.Moment>(moment().startOf("week"));
    const [dateTo, setDateTo] = useState<moment.Moment>(moment().endOf("week"));
    const [dateFromReadonly, setDateFromReadonly] = useState<boolean>(true);
    const [dateToReadonly, setDateToReadonly] = useState<boolean>(true);
    const [openFrom, setOpenFrom] = useState<boolean>(false);
    const [openTo, setOpenTo] = useState<boolean>(false);
    const [dateDisplay, setDateDisplay] = useState<string>("This week");
    const dateValues = [{id: 0, name: "Today"}, {id: 1, name: "This week"}, {id: 2, name: "Last week"},
        {id: 3, name: "This month"}, {id: 4, name: "Last month"}, {id: 5, name: "Last 2 months"},
        {id: 6, name: "Last 3 months"}, {id: 7, name: "Custom"}];
    const [organizationUsers, setOrganizationUsers] = useState<IOrganizationUser[]>([]);
    const [userActivities, setUserActivities] = useState<IReportUserActivity[]>([]);
    const [selectedUser, setSelectedUser] = useState<IOrganizationUser>();
    const [teams, setTeams] = useState<IOrganizationTeam[]>([]);
    const [selectedTeam, setSelectedTeam] = useState<IOrganizationTeam>();
    const [teamWorkload, setTeamWorkload] = useState<IReportTeamUserWorkload[]>([])
    const [estimatedData, setEstimatedData] = useState<IReportEstimatedTime[]>([])
    const [trackerActivity, setTrackerActivity] = useState<IReportTrackerActivity[]>([])
    const [boards, setBoards] = useState<IBoardDropDown[]>([]);
    const [board, setBoard] = useState<IBoardDropDown>();
    const [cardStatuses, setCardStatuses] = useState<ICardStatus[]>([]);
    const [cardStatus, setCardStatus] = useState<ICardStatus>();

    useEffect(() => {
        _getOrganizationWorkspaces();
        _getOrganizationUsers();
        _getOrganizationTeams();
        _getBoardCardStatuses();
    }, [props.organization?.id]);

    useEffect(() => {
        _getTrackerReport();
        _getReportsUser();
        _getReportsTeams();
        _getReportTeamUsersWorkload();
        _getWorkspaceProjects();
        _getOrganizationTrackerActivity();
    }, [workspace?.id, project?.id, props.organization?.id, dateTo, dateFrom]);

    useEffect(() => {
        _getReportsUserActivity();
        _getReportsUser();
        _getOrganizationTrackerActivity();
    }, [selectedUser?.id]);

    useEffect(() => {
        _getReportsTeams();
        _getReportTeamUsersWorkload();
    }, [selectedTeam?.id]);

    useEffect(() => {
        _getAllProjectBoards();
    }, [project?.id]);

    useEffect(() => {
        _getOrganizationEstimatedTime();
    }, [workspace?.id, project?.id, dateTo, dateFrom, board?.id, cardStatus?.id, props.organization?.id, board?.id]);

    useEffect(() => {
        if (tab === "tracker") {
            const tmp: IReportsSettings = getSSUserData(report_estimated);
            if (tmp) {
                clearSSUserData(report_estimated);
                setDateTo(moment(tmp.dateTo));
                setDateFrom(moment(tmp.dateFrom));
                setWorkspace(tmp.workspace);
                setCardStatus(tmp.cardStatus);
                setDateDisplay(tmp.dateDisplay);
                setProject(tmp.project);
                setBoard(tmp.board);
                setTab("estimated time");
            }
        }
    }, [tab]);


    function _getAllProjectBoards() {
        if (!project?.id) return;
        getAllProjectBoards(project.id).then(res => {
            if (res?.data?.error) return;
            setBoards(res.data.data);
        })
    }

    function saveEstimatedSettings() {
        const tmp: IReportsSettings = {
            dateTo,
            dateFrom,
            board,
            project,
            dateDisplay,
            cardStatus,
            workspace
        }
        saveSSUserData(report_estimated, tmp);
    }

    function _getOrganizationTrackerActivity() {
        if (!props.organization?.id) return;
        getOrganizationTrackerActivity(props.organization.id, dateFrom.toISOString(true), dateTo.toISOString(true), workspace?.id, project?.id, selectedUser?.id).then(res => {
            if (res?.data?.error) return;
            setTrackerActivity(res.data.data);
        })
    }

    function _getReportTeamUsersWorkload() {
        if (!props.organization?.id || !selectedTeam?.id) return;
        getReportTeamUsersWorkload(props.organization.id, selectedTeam.id, dateFrom.toISOString(true), dateTo.toISOString(true)).then(res => {
            if (res?.data?.error) return;
            setTeamWorkload(res.data.data);
        });
    }

    function _getReportsTeams() {
        if (!props.organization?.id || !selectedTeam?.id) return;
        getReportsTeams(props.organization.id, selectedTeam.id, dateFrom.toISOString(true), dateTo.toISOString(true), workspace?.id, project?.id).then(res => {
            if (res?.data?.error) return;
            setTeamsData(res.data.data);
        });
    }

    function _getOrganizationTeams() {
        if (!props.organization?.id) return;
        getOrganizationTeams(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setTeams(res.data.data);
        });
    }

    function _getReportsUserActivity() {
        if (!props.organization?.id || !selectedUser?.id) return;
        getReportsUserActivity(props.organization.id, selectedUser.id, dateFrom.toISOString(true), dateTo.toISOString(true), workspace?.id, project?.id).then(res => {
            if (res?.data?.error) return;
            setUserActivities(res.data.data);
        });
    }

    function _getReportsUser() {
        if (!props.organization?.id || !selectedUser?.id) return;
        getReportsUser(props.organization.id, selectedUser?.id, dateFrom.toISOString(true), dateTo.toISOString(true), workspace?.id, project?.id).then(res => {
            if (res?.data?.error) return;
            setUsersData(res.data.data);
        })
    }

    function _getOrganizationUsers() {
        if (!props.organization?.id) return;
        getOrganizationUsers(props.organization?.id).then(res => {
            if (res?.data?.error) return;
            setOrganizationUsers(res.data.data);
        });
    }

    function _getOrganizationWorkspaces() {
        if (!props.organization?.id) return;
        getOrganizationWorkspaces(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setWorkspaces(res.data.data);
        });
    }

    function _getWorkspaceProjects() {
        if (!workspace?.id) return;
        getWorkspaceProjects(workspace.id).then(res => {
            if (res?.data?.error) return;
            setProjects(res.data.data);
        })
    }

    function _getTrackerReport() {
        if (!props.organization?.id) return;
        getTrackerReports(props.organization.id, dateFrom.toISOString(true), dateTo.toISOString(true), workspace?.id, project?.id).then(res => {
            if (res?.data?.error) return;
            setTrackerData(res.data.data);
        })
    }

    function _getOrganizationEstimatedTime() {
        if (!props.organization?.id) return;
        getOrganizationEstimatedTime(props.organization.id, dateFrom.toISOString(true), dateTo.toISOString(true), workspace?.id, project?.id, board?.id, cardStatus?.id).then(res => {
            if (res?.data?.error) return;
            setEstimatedData(res.data.data);
        })
    }

    function _getBoardCardStatuses() {
        getBoardCardStatuses().then(res => {
            if (res?.data?.error) return;
            setCardStatuses(res.data.data);
        });
    }


    function getIllustrationPath(): string {
        switch (theme.palette.name) {
            case "midnightBlue":
                return "/assets/images/illustrations/reports/reports-midnightblue.svg";
            case "darkNight":
                return "/assets/images/illustrations/reports/reports-darknight.svg";
            default:
                return "/assets/images/illustrations/reports/reports-light.svg";
        }

    }

    function handleDateRange(val: { id: number, name: string }) {
        setDateDisplay(val.name);
        switch (val.id) {
            case 0:
                setDateFrom(moment().startOf("day"));
                setDateTo(moment().endOf("day"));
                break;
            case 1:
                setDateFrom(moment().startOf("week"));
                setDateTo(moment().endOf("week"));
                break;
            case 2:
                setDateFrom(moment().startOf('week').subtract(1, "week"));
                setDateTo(moment().endOf('week').subtract(1, "week"));
                break;
            case 3:
                setDateFrom(moment().startOf("month"));
                setDateTo(moment().endOf("month"));
                break;
            case 4:
                setDateFrom(moment().subtract(1, "month").startOf('month'));
                setDateTo(moment().subtract(1, "month").endOf('month'));
                break;
            case 5:
                setDateFrom(moment().subtract(2, "month").startOf('day'));
                setDateTo(moment().endOf('day'));
                break;
            case 6:
                setDateFrom(moment().subtract(3, "month").startOf('day'));
                setDateTo(moment().endOf('day'));
                break;
            case 7:
                setDateToReadonly(false);
                setDateFromReadonly(false);
                return;
        }
        setDateToReadonly(true);
        setDateFromReadonly(true);
    }

    function showIllustration(): boolean {
        switch (tab) {
            case "tracker":
                return !props?.organization?.id || !dateFrom || !dateTo;
            case "users":
                return !props?.organization?.id || !dateFrom || !dateTo || !selectedUser?.id;
            case "teams":
                return !props?.organization?.id || !dateFrom || !dateTo || !selectedTeam?.id;
            default:
                return false;
        }
    }

    return (
        <div className="px-32 flex-1">
            <div className="flex mt-20">
                {
                    tab === 'users' &&
                    <RbBox onClick={e => setUsersAnchor(e.currentTarget)}
                           className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-28 mr-28 whitespace-nowrap overflow-hidden">
                        <span>{selectedUser?.name ?? "Users"}</span>
                        <ChevronDownIcon className="w-20"/>
                    </RbBox>
                }
                {
                    tab === 'teams' &&
                    <RbBox onClick={e => setTeamsAnchor(e.currentTarget)}
                           className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-28 mr-28 whitespace-nowrap overflow-hidden">
                        <span>{selectedTeam?.name ?? "Teams"}</span>
                        <ChevronDownIcon className="w-20"/>
                    </RbBox>
                }
                <RbBox onClick={e => setWorkspaceAnchor(e.currentTarget)}
                       className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-28 mr-28 whitespace-nowrap overflow-hidden">
                    <span>{workspace?.name ?? "Workspace"}</span>
                    <ChevronDownIcon className="w-20"/>
                </RbBox>
                <RbBox onClick={e => setProjectAnchor(e.currentTarget)}
                       className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-28 whitespace-nowrap overflow-hidden">
                    <span>{project?.name ?? "Project"}</span>
                    <ChevronDownIcon className="w-20"/>
                </RbBox>

                {
                    tab === "estimated time" &&
                    <>
                        <RbBox onClick={e => setBoardsAnchor(e.currentTarget)}
                               className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-28 mr-28 ml-28 whitespace-nowrap overflow-hidden">
                            <span>{board?.name ?? "Board"}</span>
                            <ChevronDownIcon className="w-20"/>
                        </RbBox>
                        <RbBox onClick={e => setStatusAnchor(e.currentTarget)}
                               className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-28 whitespace-nowrap overflow-hidden">
                            <span>{cardStatus?.name ?? "Status"}</span>
                            <ChevronDownIcon className="w-20"/>
                        </RbBox>
                    </>
                }

            </div>
            <div className="flex my-12">
                {
                    tabs.map(t => {
                        return <RbRoundButton key={t} className="pl-16 pr-16 mr-10 capitalize" onClick={() => setTab(t)}
                                              variant={t === tab ? "contained" : "text"}>{t}</RbRoundButton>
                    })
                }
            </div>
            <div className="flex items-center">
                <RbBox onClick={e => setDateAnchor(e.currentTarget)}
                       className="flex items-center cursor-pointer justify-between px-10 w-[17rem] h-36">
                    <span className="capitalize">{dateDisplay}</span>
                    <ChevronDownIcon className="w-20"/>
                </RbBox>
                <DesktopDatePicker
                    open={openFrom}
                    label="Date from"
                    value={dateFrom}
                    onOpen={() => {
                        if (!dateFromReadonly) {
                            setOpenFrom(true);
                        }
                    }}
                    onClose={() => {
                        setOpenFrom(false);
                    }}
                    ignoreInvalidInputs={true}
                    disabled={dateFromReadonly}
                    inputFormat="dd.MM.yyyy"
                    onChange={(e) => setDateFrom(moment(e?.toISOString() ?? ""))}
                    renderInput={(params) => <TextField {...params}
                                                        InputProps={
                                                            {
                                                                endAdornment: null,
                                                                readOnly: true
                                                            }
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                        className="w-128 ml-10"
                                                        onClick={() => {
                                                            if (!dateFromReadonly) {
                                                                setOpenFrom(true);
                                                            }
                                                        }}
                    />}/>
                <DesktopDatePicker
                    open={openTo}
                    label="Date to"
                    value={dateTo}
                    onOpen={() => {
                        if (!dateToReadonly) {
                            setOpenTo(true);
                        }
                    }}
                    onClose={() => {
                        setOpenTo(false);
                    }}
                    ignoreInvalidInputs={true}
                    disabled={dateToReadonly}
                    inputFormat="dd.MM.yyyy"
                    onChange={(e) => setDateTo(moment(e?.toISOString() ?? ""))}
                    renderInput={(params) => <TextField {...params}
                                                        InputProps={
                                                            {
                                                                endAdornment: null,
                                                                readOnly: true
                                                            }
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                        className="w-128 ml-10"
                                                        onClick={() => {
                                                            if (!dateToReadonly) {
                                                                setOpenTo(true);
                                                            }
                                                        }}
                    />}/>
            </div>
            <RbSingleSelect items={organizationUsers} anchorEl={usersAnchor} onClose={() => setUsersAnchor(null)}
                            onSelect={u => setSelectedUser(u)} renderItem={item => item.name}/>
            <RbSingleSelect items={teams} anchorEl={teamsAnchor} onClose={() => setTeamsAnchor(null)}
                            onSelect={t => setSelectedTeam(t)} renderItem={item => item.name}/>
            <RbSingleSelect items={workspaces} anchorEl={workspaceAnchor} onClose={() => setWorkspaceAnchor(null)}
                            onSelect={w => setWorkspace(w)} renderItem={item => item.name}/>
            <RbSingleSelect items={projects} anchorEl={projectAnchor} onClose={() => setProjectAnchor(null)}
                            onSelect={p => setProject(p)} renderItem={item => item.name}/>
            <RbSingleSelect items={dateValues} search={false} anchorEl={dateAnchor} onClose={() => setDateAnchor(null)}
                            onSelect={handleDateRange} hasClear={false} renderItem={item => item.name}/>
            <RbSingleSelect items={boards} anchorEl={boardsAnchor} onClose={() => setBoardsAnchor(null)}
                            onSelect={b => setBoard(b)} renderItem={item => item.name}/>
            <RbSingleSelect items={cardStatuses} anchorEl={statusAnchor} onClose={() => setStatusAnchor(null)}
                            onSelect={s => setCardStatus(s)} renderItem={item => item.name}/>

            <div>
                <TabContext value={tab}>
                    <TabPanel value={"tracker"} className="px-0">
                        {
                            showIllustration() ?
                                <img className="m-auto mt-[15vh]" src={getIllustrationPath()}
                                     alt="Reports illustration"/> :
                                <TrackerReports data={trackerData} trackerActivity={trackerActivity}
                                                refreshActivity={_getOrganizationTrackerActivity}
                                                workspaceId={workspace?.id} projectId={project?.id}/>
                        }
                    </TabPanel>
                    <TabPanel value={"users"} className="px-0">
                        {
                            showIllustration() ?
                                <img className="m-auto mt-[15vh]" src={getIllustrationPath()}
                                     alt="Reports illustration"/> :
                                <ReportUsers data={usersData} activities={userActivities}
                                             trackerActivity={trackerActivity} userId={selectedUser?.id}/>
                        }
                    </TabPanel>
                    <TabPanel value={"teams"} className="px-0">
                        {
                            showIllustration() ?
                                <img className="m-auto mt-[15vh]" src={getIllustrationPath()}
                                     alt="Reports illustration"/> :
                                <ReportTeams data={teamsData} teamWorkload={teamWorkload}
                                             trackerActivity={trackerActivity}/>
                        }
                    </TabPanel>
                    <TabPanel value={"budget"} className="px-0">
                        <h2>Budget</h2>
                    </TabPanel>
                    <TabPanel value={"invoice"} className="px-0">
                        <h2>Invoice</h2>
                    </TabPanel>
                    <TabPanel value={"estimated time"} className="px-0">
                        <ReportEstimatedTime data={estimatedData} saveEstimatedSettings={saveEstimatedSettings}/>
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value
    }
}

export default withRouter(connect(mapStateToProps)(Reports));
