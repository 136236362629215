import React from "react";
// import ReactDOM from "react-dom/client";
import './i18n';
import './app/styles/app-base.css';
import './app/styles/app-components.css';
import './app/styles/app-utilities.css';
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import ReactDOM from "react-dom";

ReactDOM.render(<App />, document.getElementById('root'));

// const root = ReactDOM.createRoot(document.getElementById("root")!);
// root.render(
//     // <React.StrictMode>
//     <App/>
//     // </React.StrictMode>
// );

reportWebVitals();
