import {useEffect, useState} from "react";
import {CheckCircleIcon, PencilIcon} from "@heroicons/react/outline";
import {RbTextField} from "./RbTextField";
import clsx from "clsx";

export default function RbProtectedTextField<T>(props: { placeholder?: string, variant?: "standard" | "outlined" | "filled", size?: "small" | "medium", slim?: boolean, onSave?: (data: string) => void, value: string, className?: string }) {
    const [editable, setEditable] = useState<boolean>(false);
    const [value, setValue] = useState<string>("");

    useEffect(() => {
        console.log(props.value);
        setValue(props.value ?? "");
    }, [props.value]);

    function handleSave() {
        setEditable(false);
        if (props?.onSave) {
            props.onSave(value);
        }
    }

    function handleValueChange(e: any) {
        if (!e?.target?.value) return;
        setValue(e.target.value);
    }

    function handleKeyPress(e: any) {
        if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            setEditable(false);
            setValue(props.value ?? "");
        }
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            handleSave();
        }

    }

    return (
        <div className={clsx(props.className, "w-fit")}>
            <RbTextField className={props.slim ? "slim" : ""}
                         variant={props.variant}
                         disabled={!editable}
                         size={props?.size ?? "medium"}
                         value={value}
                         onChange={handleValueChange}
                         onKeyDown={handleKeyPress}
                         placeholder={props?.placeholder}
                         InputProps={{
                             startAdornment: (
                                 <div className="relative w-32 h-32">
                                     <PencilIcon
                                         className="edit absolute left-0 top-0 transition-transform origin-center"
                                         style={{transform: editable ? "scale(0)" : "scale(1)"}}
                                         onClick={() => setEditable(true)}/>
                                     <CheckCircleIcon
                                         className="save absolute left-0 top-0 transition-transform origin-center"
                                         style={{transform: editable ? "scale(1)" : "scale(0)"}}
                                         onClick={handleSave}/>
                                 </div>
                             )
                         }}/>
        </div>
    )
}
RbProtectedTextField.defaultProps = {
    slim: true,
    variant: "standard"
}

