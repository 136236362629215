import {DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import React, {useState} from "react";
import {RbDialog} from "../dialogs/RbDialog";
import {RbTextField} from "./RbTextField";
import {PlusIcon, XIcon} from "@heroicons/react/outline";
import {RbRoundButton} from "./RbRoundButton";
import HeroIcon from "../../../@fuse/core/HeroIcon/HeroIcon";

export default function RbSelectDialog<T>(props: { open: boolean, title: string, onClose: () => void, data: T[], onSelect: (item: T) => void, renderAttribute: string }) {
    const [filter, setFilter] = useState<string>("");

    return (
        <RbDialog
            open={props.open}
            onClose={() => props.onClose()}
        >
            <DialogTitle>
                <span className="flex justify-between">
                    <span>{props.title}</span>
                    <IconButton className="p-5" onClick={props.onClose}>
                        <XIcon className="w-16"/>
                    </IconButton>
                </span>
            </DialogTitle>
            <DialogContent className="p-20">
                <div className="min-h-128 min-w-360">
                    <RbTextField autoFocus={true}
                                 className="mb-20"
                                 fullWidth
                                 placeholder="Search"
                                 variant="filled"
                                 hiddenLabel={true}
                                 size="small"
                                 value={filter}
                                 onChange={e => setFilter(e.target.value)}
                                 color="primary"/>
                    <div className="max-h-160 overflow-y-auto">
                        {
                            // @ts-ignore
                            (props?.data ? props.data.filter(o => o[props.renderAttribute].toLowerCase().includes(filter.toLowerCase())) : []).map((obj, i) => {
                                return (
                                    <p key={i} className="mb-10 py-5 px-10 flex justify-between items-center">
                                        {/*// @ts-ignore*/}
                                        <span>{obj[props.renderAttribute]}</span>
                                        <IconButton className="p-5" onClick={() => props.onSelect(obj)}>
                                            <span className="w-20">
                                                {/*// @ts-ignore*/}
                                                <HeroIcon icon="CheckCircleIcon" outline={!(obj?.isMember ?? false)}/>
                                            </span>
                                        </IconButton>
                                    </p>
                                )
                            })
                        }
                    </div>
                </div>
            </DialogContent>
            <DialogActions className="pr-20 pb-20">
                <RbRoundButton variant="contained" color="primary" onClick={props.onClose}>Done</RbRoundButton>
            </DialogActions>
        </RbDialog>
    )
}
