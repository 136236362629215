import {useEffect, useState} from "react";
import {
    acceptUserInvitation,
    getAllUserOrganizationInvites,
    rejectUserInvitation
} from "../../../services/user-settings/userSettingsService";
import {IUserInvite} from "../../../types/user-settings-types";
import {IconButton} from "@mui/material";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";
import {useTheme} from "@mui/material/styles";
import {ClockIcon, TrashIcon} from "@heroicons/react/outline";
import {formatToEUDate} from "../../../utils/utils";

function OrganizationInvitesUserSettings() {
    const [userInvites, setUserInvites] = useState<IUserInvite[] | null>([]);
    const theme = useTheme();


    useEffect(() => {
        _getAllUserOrganizationInvites();
    }, []);

    function _getAllUserOrganizationInvites() {
        getAllUserOrganizationInvites().then(res => {
            if (res.data.data) {
                setUserInvites(res.data.data);
            }
        })
    }

    function _acceptUserInvitation(orgId: string) {
        if (!orgId) return;
        acceptUserInvitation(orgId).then(res => {
            if (res?.data?.error) return;
            _getAllUserOrganizationInvites();
        })
    }

    function _rejectUserInvitation(orgId: string) {
        if (!orgId) return;
        rejectUserInvitation(orgId).then(res => {
            if (res?.data?.error) return;
            _getAllUserOrganizationInvites();
        })
    }

    return (
        <div className="mt-48 md:ml-28 sm:ml-28 sm:mr-28 ml-64 mb-48">
            <div className="invitations">
                <h2 className="font-bold mb-32 inline-block align-middle">Invitations</h2>
                <table className="text-left max-w-[1024px] w-full">
                    <thead>
                    <tr className="border-b-solid border-b-1">
                        <th className="w-1/4">Organization</th>
                        <th className="w-1/4 sm:hidden">Invited by</th>
                        <th className="w-1/4">Action</th>
                        <th className="w-1/4 sm:hidden">Invited at</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        userInvites?.map(inv => {
                            return (
                                <tr key={inv.organizationId}>
                                    <td className="pt-5 pb-5">{inv.name}</td>
                                    <td className="pt-5 pb-5 sm:hidden">{inv.invitedBy}</td>
                                    <td className="pt-5 pb-5">
                                        <RbRoundButton color="primary" variant="contained"
                                                       style={{color: theme.palette.text.primary}}
                                                       className="md:block"
                                                       onClick={() => _acceptUserInvitation(inv.organizationId)}>Accept</RbRoundButton>
                                        <RbRoundButton color="warning" variant="contained"
                                                       className="sm:ml-10 md:ml-0 md:block"
                                                       style={{color: theme.palette.text.primary}}
                                                       onClick={() => _rejectUserInvitation(inv.organizationId)}>Decline</RbRoundButton>
                                    </td>
                                    <td className="pt-5 pb-5 sm:hidden">{formatToEUDate(inv.dateCreated)}</td>
                                </tr>
                            )
                        })
                    }
                    </tbody>
                </table>
            </div>

            {/*<div className="invitation-history mt-48">*/}
            {/*    <div className="mb-32 ">*/}
            {/*        <ClockIcon className="w-full inline-block align-middle w-20 mr-5"/> <h3*/}
            {/*        className="font-bold inline-block align-middle">Invitation history</h3>*/}
            {/*    </div>*/}
            {/*    <table className="text-left max-w-[1024px] w-full">*/}
            {/*        <thead>*/}
            {/*        <tr className="border-b-solid border-b-1">*/}
            {/*            <th className="w-1/5">Organization</th>*/}
            {/*            <th className="w-1/5 sm:hidden">Invited by</th>*/}
            {/*            <th className="w-1/5">Status</th>*/}
            {/*            <th className="w-1/5 sm:hidden">Invited at</th>*/}
            {/*            <th className="w-1/5 text-right"></th>*/}
            {/*        </tr>*/}
            {/*        </thead>*/}
            {/*        <tbody>*/}
            {/*        <tr>*/}
            {/*            <td className="">Pera transport</td>*/}
            {/*            <td className="sm:hidden">Brka Palikuća</td>*/}
            {/*            <td>Accepted</td>*/}
            {/*            <td className="sm:hidden">13.06.2022</td>*/}
            {/*            <td className="text-right">*/}
            {/*                <IconButton aria-label="delete button">*/}
            {/*                    <TrashIcon className="w-16 color-inherit"/>*/}
            {/*                </IconButton>*/}
            {/*            </td>*/}
            {/*        </tr>*/}
            {/*        </tbody>*/}
            {/*    </table>*/}
            {/*</div>*/}
        </div>
    );
}

export default OrganizationInvitesUserSettings;
