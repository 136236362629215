import ClickAwayListener from '@mui/material/ClickAwayListener';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import {memo, useEffect, useState} from 'react';
import withRouter from '@fuse/core/withRouter';
import {SearchIcon} from "@heroicons/react/outline";
import {RbTextField} from "../../../app/shared-components/rb-components/RbTextField";
import * as React from "react";
import {useRbDispatch, useRbSelector} from "../../../app/rb-hooks/hooks";
import {updateRbSearch} from "../../../app/store/rubicon/searchSlice";

function FuseSearch(props) {
    const [opened, setOpened] = useState(false);
    const searchRb = useRbSelector(state => state.fuse.search);
    const dispatch = useRbDispatch();

    function showSearch(ev) {
        ev.stopPropagation();
        setOpened(true);
        document.addEventListener('keydown', escFunction, false);
    }

    function hideSearch(ev) {
        ev.preventDefault();
        ev.stopPropagation();
        dispatch(updateRbSearch(""));
        setOpened(false);
        document.removeEventListener('keydown', escFunction, false);
    }

    function escFunction(event) {
        if (event.keyCode === 27) {
            hideSearch();
        }
    }

    function handleSearchChange(event) {
        dispatch(updateRbSearch(event.target.value))
    }

    function handleClickAway() {
        setOpened(false);
    }

    switch (props.variant) {
        case 'basic': {
            return (<div className={clsx('flex items-center w-full', props.className)}>
                <RbTextField className=""
                             placeholder={props.placeholder}
                             variant="standard"
                             value={searchRb.value}
                             onChange={handleSearchChange}
                             InputProps={{endAdornment: <SearchIcon className="w-20"/>}}
                             color="primary"/>
            </div>);
        }
        case 'full': {
            return (<div className={clsx('flex', props.className)}>
                <Tooltip title="Click to search" placement="bottom">
                    <div
                        onClick={showSearch}
                        role="button"
                        tabIndex={0}
                    >
                        {props.trigger}
                    </div>
                </Tooltip>

                {(opened === true) && (<ClickAwayListener onClickAway={handleClickAway}>
                    <Paper className="absolute left-0 right-0 top-0 h-full z-9999 shadow-0" square>
                        <div className="flex items-center w-full h-full">
                            <RbTextField className="pl-32"
                                         placeholder={props.placeholder}
                                         autoFocus={true}
                                         variant="standard"
                                         value={searchRb.value}
                                         onChange={handleSearchChange}
                                         InputProps={{endAdornment: <SearchIcon className="w-20"/>}}
                                         color="primary"/>
                            <IconButton onClick={hideSearch} className="mx-8" size="large">
                                <Icon>close</Icon>
                            </IconButton>
                        </div>
                    </Paper>
                </ClickAwayListener>)}
            </div>);
        }
        default: {
            return null;
        }
    }
}

FuseSearch.defaultProps = {
    trigger: (<IconButton className="w-40 h-40" size="large">
        <Icon>search</Icon>
    </IconButton>), variant: 'full', placeholder: 'Search', noResults: 'No results..',
};

export default withRouter(memo(FuseSearch));
