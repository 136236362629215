import {_delete, _get, _patch, _post} from "../apiService";
import {IBoardCardsOptionChange} from "../../main/boards/board-components/BoardCardsBarControls";

export const getOrganizationBoards = (organizationId: string) => {
    return _get(`boards/${organizationId}/user-boards`);
}

export const getOrganizationUserFavoriteBoards = (organizationId: string) => {
    return _get(`boards/${organizationId}/user-favorited`);
}

export const getOrganizationUserTimelineBoards = (organizationId: string) => {
    return _get(`boards/${organizationId}/timeline`);
}

export const getOrganizationBoardCards = (organizationId: string, options?: IBoardCardsOptionChange) => {
    return _get(`boards/${organizationId}/board-cards?DateFrom=${encodeURIComponent(options?.DateFrom ?? "")}&DateTo=${encodeURIComponent(options?.DateTo ?? "")}&WorkspaceId=${options?.workspace?.id ?? ""}&ProjectId=${options?.project?.id ?? ""}&BoardId=${options?.board?.id ?? ""}&StatusId=${options?.status?.id ?? ""}&UserId=${options?.user?.id ?? ""}`);
}

export const updateCheckListItemName = (id: string, name: string) => {
    return _patch(`board/card/update-check-list-item-name`, {id, name});
}

export const updateCheckListName = (id: string, name: string) => {
    return _patch(`board/card/update-check-list-name`, {id, name});
}

export const createNewBoard = (name: string, projectId: string) => {
    return _post(`boards/create`, {name, projectId});
}

export const deleteBoard = (boardId: string) => {
    return _delete(`boards/${boardId}`)
}

export const createBoardLabel = (boardId: string, name: string, color: string) => {
    return _post(`boards/create-label`, {boardId, name, color});
}

export const updateBoardLabel = (id: string, name: string, color: string) => {
    return _patch(`boards/update-label`, {id, name, color});
}

export const getShortBoardCards = (boardId: string) => {
    return _get(`boards/${boardId}/blocker-cards`);
}

export const deleteBoardLabel = (labelId: string) => {
    return _delete(`boards/${labelId}/delete-label`);
}

export const addMemberToBoard = (boardId: string, userId: string) => {
    return _post(`boards/add-member`, {boardId, userId});
}

export const toggleArchiveBoard = (boardId: string) => {
    return _patch(`boards/${boardId}/archive`, null);
}

export const removeMemberFromBoard = (boardId: string, userId: string) => {
    return _delete(`boards/${boardId}/remove-member/${userId}`);
}

export const membersToInviteOnBoard = (boardId: string) => {
    return _get(`boards/${boardId}/user-to-invite`);
}

export const renameBoard = (boardId: string, name: string) => {
    return _patch(`boards/update-name`, {boardId, name});
}

export const getBoardDetails = (boardId: string) => {
    return _get(`boards/${boardId}`);
}

export const toggleBoardFavorite = (organizationId: string, boardId: string) => {
    return _post(`board/${organizationId}/favorite/${boardId}`, null);
}

export const createBoardCard = (listId: string, name: string, orderNo: number = 0) => {
    return _post(`board/card/create`, {listId, name, orderNo});
}
export const createBoardList = (boardId: string, name: string, orderNo: number = 0) => {
    return _post(`boards/lists/create`, {boardId, name, orderNo});
}
export const getCardDetails = (cardId: string) => {
    return _get(`board/card/${cardId}`);
}

export const getCardComments = (cardId: string) => {
    return _get(`board/card/${cardId}/comments`);
}

export const getCardActivity = (cardId: string) => {
    return _get(`board/card/${cardId}/activity`);
}

export const getBoardLabels = (boardId: string) => {
    return _get(`boards/${boardId}/labels`);
}

export const getCardTasks = (cardId: string) => {
    return _get(`board/card/${cardId}/check-lists`);
}

export const toggleBoardCardTask = (itemId: string, check: boolean) => {
    return _patch(`board/card/check-list-item`, {itemId, check});
}

export const addCommentToCard = (cardId: string, text: string) => {
    return _post(`board/card/add-comment`, {cardId, text});
}

export const updateBoardListName = (listId: string, name: string) => {
    return _patch(`boards/lists/update-name`, {listId, name});
}

export const getBoardCardStatuses = () => {
    return _get(`board/card/statuses`);
}

export const updateBoardCardStatus = (cardId: string, statusId: number) => {
    return _patch(`board/card/${cardId}/${statusId}`, null);
}

export const updateBoardCardName = (cardId: string, title: string) => {
    return _patch(`board/card/title`, {cardId, title});
}

export const updateBoardETC = (cardId: string, estimatedTime: number | null) => {
    return _patch(`board/card/estimated-time`, {cardId, estimatedTime});
}

export const updateBoardCardDescription = (cardId: string, description: string) => {
    return _patch(`board/card/description`, {cardId, description});
}

export const updateBoardCardPriority = (cardId: string, priorityId: number | null) => {
    return _patch(`board/card/update-priority`, {cardId, priorityId});
}

export const deleteComment = (commentId: string) => {
    return _delete(`board/card/${commentId}/delete-comment`);
}

export const deleteBoardCardTask = (itemId: string) => {
    return _delete(`board/card/delete-check-list-item/${itemId}`);
}

export const createBoardCardTask = (checkListId: string, name: string) => {
    return _post(`board/card/create-check-list-item`, {checkListId, name});
}

export const createBoardCardList = (cardId: string, name: string) => {
    return _post(`board/card/create-check-list`, {cardId, name});
}

export const getBoardMembers = (boardId: string) => {
    return _get(`boards/${boardId}/members`);
}

export const assignUserToBoardCard = (cardId: string, userId: string) => {
    return _post(`board/card/${cardId}/assign/${userId}`, null);
}
export const unassignUserToBoardCard = (cardId: string, userId: string) => {
    return _delete(`board/card/${cardId}/unassign/${userId}`);
}
export const addLabelToBoardCard = (cardId: string, labelId: string) => {
    return _post(`board/card/${cardId}/add-label/${labelId}`, null);
}
export const removeLabelFromBoardCard = (cardId: string, labelId: string) => {
    return _delete(`board/card/${cardId}/remove-label/${labelId}`);
}

export const updateCardDueDate = (cardId: string, date: string | null) => {
    return _patch(`board/card/due-date`, {cardId, date});
}

export const updateBoardListOrder = (order: { listId: string, orderNo: number }[]) => {
    return _patch(`boards/lists/order`, {order});
}

export const updateBlockedByBoardCard = (cardId: string, blockById: string | null) => {
    return _patch(`board/card/block-by`, {blockById, cardId});
}
export const updateParentCardBoardCard = (cardId: string, parentCardId: string | null) => {
    return _patch(`board/card/parent`, {parentCardId, cardId});
}

export const deleteBoardCard = (cardId: string) => {
    return _delete(`board/card/${cardId}`);
}

export const getBoardCardTrackerHistory = (cardId: string) => {
    return _get(`board/card/${cardId}/tracker-history`);
}

export const markBoardListAsDoneUnDone = (listId: string, value: boolean = true) => {
    return _patch(`boards/lists/mark-as-done`, {listId, value});
}
export const showBoardCardsInTracker = (listId: string, value: boolean = true) => {
    return _patch(`boards/lists/show-in-tracker`, {listId, value});
}

export const deleteBoardList = (listId: string) => {
    return _delete(`boards/lists/${listId}`);
}

export const updateBoardCardsOrder = (listId: string, order: { cardId: string, orderNo: number }[]) => {
    return _patch(`board/card/order`, {listId, order});
}
