import React from 'react';
import * as SolidIcons from '@heroicons/react/solid';
import * as OutlineIcons from '@heroicons/react/outline';
import {useTheme} from "@mui/material/styles";

interface Props {
    icon: string;
    color?: string;
    size?: number;
    outline?: boolean;
    onClick?: Function;
}

export const HeroIcon = (props: Props): JSX.Element => {
    const {icon, color, size, outline = false, onClick} = props;
    const theme = useTheme();

    const {...icons} = outline ? OutlineIcons : SolidIcons;

    // @ts-ignore
    const Icon: JSX.Element = icons[icon];

    const classes = [
        `${color ? color : theme.palette.text.primary}`,
        `min-h-${size ? size : 6}`,
        `min-w-${size ? size : 6}`,
        `max-h-${size ? size : 6}`,
        `max-w-${size ? size : 6}`,
        `fuse-list-item-icon`
    ];

    const click = onClick?.bind(this);

    return (
        // @ts-ignore
        <Icon onClick={click} className={classes.join(' ')}/>
    );
};

export default HeroIcon;
