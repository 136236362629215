import FuseScrollbars from '@fuse/core/FuseScrollbars';
import {styled, useTheme} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import Navigation from 'app/fuse-layouts/shared-components/Navigation';
import UserNavbarHeader from 'app/fuse-layouts/shared-components/UserNavbarHeader';
import clsx from 'clsx';
import {memo} from 'react';

const Root = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    '& ::-webkit-scrollbar-thumb': {
        boxShadow: `inset 0 0 0 20px ${
            theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
        }`,
    },
    '& ::-webkit-scrollbar-thumb:active': {
        boxShadow: `inset 0 0 0 20px ${
            theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
        }`,
    },
}));

const StyledContent = styled(FuseScrollbars)(({theme}) => ({
    overscrollBehavior: 'contain',
    overflowX: 'hidden',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    background:
        'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 40px, 100% 10px',
    backgroundAttachment: 'local, scroll',
    height: '100%'
}));

function NavbarStyle1Content(props) {
    const theme = useTheme();

    return (
        <Root className={clsx('flex flex-auto flex-col overflow-auto h-full', props.className)}>
            <AppBar
                color="primary"
                position="static"
                className="flex flex-row items-center shrink h-48 md:h-64 min-h-48 md:min-h-64 px-12 shadow-0"
            >
                <div className="flex flex-1 mx-4">
                    <Logo/>
                </div>

                <NavbarToggleButton className="w-40 h-40 p-0"/>
            </AppBar>

            <StyledContent className="flex flex-col" option={{suppressScrollX: true, wheelPropagation: false}}>
                <UserNavbarHeader/>

                <Navigation layout="vertical"/>
            </StyledContent>
        </Root>
    );
}

export default memo(NavbarStyle1Content);
