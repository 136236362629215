import React, {useEffect, useState} from "react";
import {RbCheckBox} from "./RbCheckBoxIcon";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Popover} from "@mui/material";
import {RbTextField} from "./RbTextField";

export default function RbMultiSelect<T, V>(props: { onClose: () => void, anchorEl: HTMLElement | SVGSVGElement | null, checkedItems?: V[], checkCompare?: (source: T, checkedItems: T[] | V[]) => boolean, searchPlaceholder?: string, search?: boolean, items: T[], renderItem?: (item: T) => JSX.Element | string, onSelectChange?: (items: T[]) => void, onItemAdd?: (item: T) => void, onItemRemove?: (item: T) => void }) {
    const [items, setItems] = useState<{ checked: boolean, data: T }[]>([]);
    const [filter, setFilter] = useState<string>("");

    function checkCompare(source: T): boolean {
        if (props?.checkCompare) {
            return props.checkCompare(source, props?.checkedItems ?? []);
        }

        for (let i = 0; i < (props.checkedItems ?? []).length; i++) {
            // @ts-ignore
            if (props?.checkedItems[i]?.id === source.id || props?.checkedItems[i]?.id === source || props?.checkedItems[i] === source.id || props?.checkedItems[i] === source) {
                return true;
            }
        }
        return false;
    }

    useEffect(() => {
        setItems(getFilteredItems().map(item => {
            return ({data: item, checked: checkCompare(item)})
        }));
    }, [props?.items, props?.checkedItems, filter]);

    function getFilteredItems(): T[] {
        if (!props?.items || props?.items.length <= 0) return [];
        const keys = Object.keys(props.items[0]);
        return props?.items.filter(item => {
            for (let i = 0; i < keys.length; i++) {
                // @ts-ignore
                if (typeof item[keys[i]] === "string" && item[keys[i]].toLowerCase().startsWith(filter)) return true;
            }
            return false;
        });
    }

    function handleCheckItemChange(index: number, added: boolean) {
        const tmp = [...items];
        tmp[index].checked = added;
        setItems(tmp);

        if (added && props?.onItemAdd) {
            props.onItemAdd(props.items[index]);
        }

        if (!added && props?.onItemRemove) {
            props.onItemRemove(props.items[index]);
        }

        if (props?.onSelectChange) {
            props.onSelectChange(tmp.filter(x => x.checked).map(x => x.data));
        }

    }

    return (
        <Popover
            open={!!props.anchorEl}
            anchorEl={props.anchorEl}
            onClose={(e) => {
                // @ts-ignore
                e.stopPropagation();
                props.onClose();
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className="p-10">
                {
                    (props?.searchPlaceholder || props?.search !== false) &&
                    <RbTextField autoFocus={true}
                                 fullWidth
                                 placeholder={props?.searchPlaceholder ?? "Search"}
                                 variant="filled"
                                 hiddenLabel={true}
                                 size="small"
                                 value={filter}
                                 onChange={e => setFilter(e.target.value)}
                                 color="primary"/>
                }
                <div className="mt-10 max-h-160 overflow-auto">
                    {
                        items.map((item, index) => {
                            return (
                                <FormControlLabel className="flex justify-between m-0"
                                    // @ts-ignore
                                                  key={index}
                                                  control={<RbCheckBox checked={item.checked}
                                                                       onChange={() => handleCheckItemChange(index, !item.checked)}
                                                                       className="py-0 pr-0"/>}
                                                  labelPlacement="start"
                                                  label={props?.renderItem ? props.renderItem(item.data) : item.data}/>
                            )
                        })

                    }
                </div>
            </div>
        </Popover>
    )
}
