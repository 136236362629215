import {IBoardFullCard} from "../../../types/board-types";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {RbSubSectionItem} from "app/shared-components/rb-components/RbSections";
import RbText from "../../../shared-components/rb-components/RbText";
import moment from "moment";
import clsx from "clsx";
import {RbLink} from "app/shared-components/rb-components/RbLink";

export default function BoardCardsBar(props: { cards: IBoardFullCard[] }) {
    return (
        <RbSubSectionItem className="mt-24">
            {
                props.cards.map((card, i) => {
                    return <RbLink to={`/board/card/details/${card.boardId}/${card.id}`} key={i}>
                        <RbBox
                            className={clsx("px-20 w-full flex items-center justify-between mb-12 hover:shadow-2 transition-shadow", card.statusId === 1 ? "task-done" : "")}>
                            <div className="flex flex-col gap-5 w-1/2 flex-shrink-0 mr-5">
                                <div className="flex items-center whitespace-nowrap">
                                    <span className="mr-10 sm:mr-5">#{card.number ?? ""}</span>
                                    <span className="overflow-hidden text-ellipsis">{card.name}</span>
                                </div>
                                <RbText theme="secondary">Created by: {card?.user?.name ?? ""}</RbText>
                            </div>

                            <div className="flex flex-col items-start w-full">
                                <div className="flex">
                                    <RbText theme="primary" className="mr-5">{card.boardName}</RbText>
                                    <RbText theme="secondary">({card.projectName})</RbText>
                                </div>
                                <RbText theme="secondary">{moment(card.createdAt).fromNow()}</RbText>
                            </div>

                            <div className="flex flex-col items-start">
                                <RbText theme="primary" className="whitespace-nowrap">{card.status}</RbText>
                            </div>
                        </RbBox>
                    </RbLink>
                })
            }
        </RbSubSectionItem>
    )
}
