import {
    IReports,
    IReportTeamUserWorkload,
    IReportTrackerActivity,
} from "../../../types/report-types";
import ReportTrackerReport from "../widgets/ReportTrackerReport";
import ReportTrackerProject from "../widgets/ReportTrackerByProject";
import ReportTrackerByCategory from "../widgets/ReportTrackerByCategory";
import ReportTeamsWorkload from "../widgets/ReportTeamsWorkload";
import ReportTrackerByCategoryAndUser from "../widgets/ReportTrackerByCategoryAndUser";
import ReportTrackerActivity from "../widgets/ReportTrackerActivity";

export default function ReportTeams(props: { data?: IReports, teamWorkload?: IReportTeamUserWorkload[], trackerActivity: IReportTrackerActivity[] }) {
    return (
        <div>
            <ReportTeamsWorkload data={props.teamWorkload}/>
            <ReportTrackerReport data={props.data}/>
            <ReportTrackerProject data={props.data?.byProjects}/>
            <ReportTrackerByCategory data={props.data?.byCategories}/>
            <ReportTrackerByCategoryAndUser data={props.data?.byUserAndCategories}/>
            <ReportTrackerActivity data={props.trackerActivity}/>
        </div>
    )
}
