import FuseSearch from '@fuse/core/FuseSearch';
import {ThemeProvider} from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import NavbarToggleButton from 'app/fuse-layouts/shared-components/NavbarToggleButton';
import QuickPanelToggleButton from 'app/fuse-layouts/shared-components/quickPanel/QuickPanelToggleButton';
import UserMenu from 'app/fuse-layouts/shared-components/UserMenu';
import clsx from 'clsx';
import React, {memo, useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {selectToolbarTheme} from 'app/store/fuse/settingsSlice';
import FullScreenToggle from '../../shared-components/FullScreenToggle';
import {stopTracker} from "../../../services/tracker/trackerService";
import withRouter from "../../../../@fuse/core/withRouter";
import moment from "moment";
import {Button} from "@mui/material";
import {updateGlobalTracker} from "../../../store/rubicon/globalTrackerSlice";
import {useRbDispatch} from "../../../rb-hooks/hooks";
import RbText from "../../../shared-components/rb-components/RbText";
import {useNavigate} from "react-router-dom";

function ToolbarLayout1(props) {
    const config = useSelector(({fuse}) => fuse.settings.current.layout.config);
    const navbar = useSelector(({fuse}) => fuse.navbar);
    const toolbarTheme = useSelector(selectToolbarTheme);
    const [duration, setDuration] = useState("00:00:00");
    const [trackerStart, setTrackerStart] = useState();
    const [inter, setInter] = useState();
    const dispatch = useRbDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!props.tracker?.id || !props.tracker?.startDate) {
            setTrackerStart(undefined);
            stopTrack();
        } else {
            setTrackerStart(moment(props.tracker.startDate));
        }
    }, [props.tracker?.id]);

    useEffect(() => {
        if (!trackerStart) return;
        startTimer();
    }, [trackerStart]);

    const startTimer = () => {
        setInter(setInterval(() => {
            const dur = moment.duration(moment().diff(trackerStart));
            setDuration(`${String(dur.hours()).padStart(2, "0")}:${String(dur.minutes()).padStart(2, "0")}:${String(dur.seconds()).padStart(2, "0")}`)
        }, 1000));
    }

    const stopTrack = () => {
        if (!props.tracker?.id) {
            clearInterval(inter);
            setDuration("");
        } else {
            stopTracker(props.tracker?.id, moment().toISOString(true)).then(() => {
                dispatch(updateGlobalTracker(null));
                clearInterval(inter);
                setDuration("");
            });
        }
    }


    return (<ThemeProvider theme={toolbarTheme}>
        <AppBar
            id="fuse-toolbar"
            className={clsx('flex relative z-20 shadow-none', props.className)}
            color="default"
            sx={{backgroundColor: toolbarTheme.palette.background.paper}}
            position="static"
        >
            <Toolbar className="p-0 min-h-48 md:min-h-64">
                <div className="flex flex-1 px-16">
                    {config.navbar.display && config.navbar.position === 'left' && (<>
                        {/*<Hidden lgDown>*/}
                        {/*    {(config.navbar.style === 'style-3' ||*/}
                        {/*        config.navbar.style === 'style-3-dense') && (*/}
                        {/*        <NavbarToggleButton className="w-40 h-40 p-0 mx-0"/>*/}
                        {/*    )}*/}

                        {/*    {config.navbar.style === 'style-1' && !navbar.open && (*/}
                        {/*        <NavbarToggleButton className="w-40 h-40 p-0 mx-0"/>*/}
                        {/*    )}*/}
                        {/*</Hidden>*/}

                        {/*<Hidden lgUp>*/}
                        <div className="flex items-center">
                            <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8"/>
                            {!!trackerStart && <div className="flex items-center gap-10 text-16 ml-20">
                                <p className="w-72 text-left">{!duration ? "00:00:00" : duration}</p>
                                <RbText theme="secondary">
                                    {props.tracker?.projectName ?? "Project"} / <span onClick={() => {
                                    if (!props?.tracker?.boardId || !props?.tracker?.cardId) return;
                                    navigate(`/board/card/details/${props.tracker.boardId}/${props.tracker.cardId}`);
                                }}
                                                                                      className={props?.tracker?.cardId ? "cursor-pointer hover:underline underline-offset-4" : ""}>{props.tracker?.cardName ?? "Card"}</span>
                                </RbText>
                                <Button variant="contained" color="warning" onClick={stopTrack}>
                                    Stop
                                </Button>
                            </div>}
                        </div>
                    </>)}


                    {/*<Hidden lgDown>*/}
                    {/*    <FuseShortcuts/>*/}
                    {/*</Hidden>*/}
                </div>

                <div className="flex items-center px-8 h-full overflow-x-auto">
                    {/*<LanguageSwitcher/>*/}

                    {/*<AdjustFontSize/>*/}

                    <FullScreenToggle/>

                    <FuseSearch/>

                    <QuickPanelToggleButton/>

                    <UserMenu/>
                </div>

                {config.navbar.display && config.navbar.position === 'right' && (<>
                    <Hidden lgDown>
                        {!navbar.open && <NavbarToggleButton className="w-40 h-40 p-0 mx-0"/>}
                    </Hidden>

                    <Hidden lgUp>
                        <NavbarToggleButton className="w-40 h-40 p-0 mx-0 sm:mx-8"/>
                    </Hidden>
                </>)}
            </Toolbar>
        </AppBar>
    </ThemeProvider>);
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        tracker: fuse.runningTracker.value,
    }
}

export default withRouter(connect(mapStateToProps)(memo(ToolbarLayout1)));
