import {RbTextField} from "app/shared-components/rb-components/RbTextField";
import React, {useEffect, useState} from "react";
import {IBoardCardDetails} from "../../../types/board-types";
import {updateBoardCardDescription} from "../../../services/boards/boardsService";

export default function BoardCardDescription(props: { card: IBoardCardDetails | undefined, refreshCard: () => void }) {
    const {card} = props;
    const [desc, setDesc] = useState<string>("");

    useEffect(() => {
        setDesc(card?.description ?? "");
    }, [card?.description]);

    function handleBoardCardUpdate(e: any) {
        if (e.target.value === card?.description || !card?.id) return;
        updateBoardCardDescription(card.id, desc).then(res => {
            if (res?.data?.error) return;
            props.refreshCard();
        });
    }

    return (
        <RbTextField className="w-full block"
                     variant="outlined"
                     label="Description"
                     maxRows={24}
                     minRows={12}
                     placeholder="Type..."
                     multiline={true}
                     value={desc}
                     onChange={e => setDesc(e.target.value)}
                     onBlur={handleBoardCardUpdate}
                     color="primary"/>
    )
}
