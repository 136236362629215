import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface INavigationBlock {
    id: string;
    title: string;
    translate: string;
    type: string;
    icon: string;
    url?: string;
    roles?: string[];
    children?: INavigationBlock[];
}

interface ISubNavigationSlice {
    value: INavigationBlock[] | null;
}

const initialState: ISubNavigationSlice = {
    value: null
}

const subNavigationSlice = createSlice({
    name: "sub_navigation", initialState, reducers: {
        updateSubNavigation(state, action: PayloadAction<INavigationBlock[] | null>) {
            state.value = action.payload;
        }
    }
});


export const {updateSubNavigation} = subNavigationSlice.actions;
export default subNavigationSlice.reducer;
