import React, {useLayoutEffect, useState} from 'react';
import history from '@history';
import {Navigate, Outlet, Router} from 'react-router-dom';
import {useSelector} from "react-redux";

function BrowserRouter(props) {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return (
        <Router
            basename={props.basename}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        >
            {props.children}
        </Router>
    );
}

export default BrowserRouter;
