import {ITodo} from "../../../../types/todo-types";
import {RbTextField} from "../../../../shared-components/rb-components/RbTextField";
import React from "react";
import {updateTaskNote} from "../../../../services/todo/todoService";

export default function TodoNote(props: { todo: ITodo | undefined, refreshTodos: () => void }) {
    const {todo} = props;

    function handleTaskNoteUpdate(e: any) {
        if (e.target.value === todo?.note || !todo?.id) return;
        updateTaskNote(todo.id, e.target.value).then(res => {
            if (res?.data?.error) return;
            props.refreshTodos();
        });
    }

    return (
        <RbTextField className="w-full block min-h-32"
                     variant="outlined"
                     label="Note"
                     placeholder="Type..."
                     multiline={true}
                     defaultValue={todo?.note}
                     onBlur={handleTaskNoteUpdate}
                     color="primary"/>
    )
}
