import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {
    IBoardCardActivity,
    IBoardCardComment,
    IBoardCardDetails, IBoardCardTasks, IBoardLabel,
    IBoardMember,
    IBoardShortCard, IBoardTrackerHistory,
    ICardStatus
} from "../../../types/board-types";
import {
    getBoardCardStatuses, getBoardCardTrackerHistory, getBoardLabels,
    getBoardMembers, getCardActivity, getCardComments,
    getCardDetails, getCardTasks,
    getShortBoardCards, updateBoardCardName, updateBoardCardPriority, updateCardDueDate
} from "../../../services/boards/boardsService";
import BoardCardHeadControls from "../board-details-compoments/BoardCardHeadControls";
import {IconButton, Menu, MenuItem, Tab, TextField} from "@mui/material";
import BoardCardDescription from "../board-details-compoments/BoardCardDescription";
import {RbSubSection, RbSubSectionItem} from "../../../shared-components/rb-components/RbSections";
import BoardCardMembers from "../board-details-compoments/BoardCardMembers";
import BoardCardLabels from "../board-details-compoments/BoardCardLabels";
import BoardCardReminder from "../board-details-compoments/BoardCardReminder";
import BoardCardETC from "../board-details-compoments/BoardCardETC";
import BoardCardParent from "../board-details-compoments/BoardCardParent";
import BoardCardBlockedBy from "../board-details-compoments/BoardCardBlockedBy";
import BoardCardAttachments from "../board-details-compoments/BoardCardAttachments";
import {RbTabs} from "../../../shared-components/rb-components/RbTabs/RbTabs";
import {DesktopDatePicker, TabContext, TabPanel} from "@mui/lab";
import BoardCardComments from "../board-details-compoments/BoardCardComments";
import BoardCardTasks from "../board-details-compoments/BoardCardTasks";
import BoardTrackerHistory from "../board-tracker-history/BoardTrackerHistory";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {FlagIcon, ViewBoardsIcon} from "@heroicons/react/solid";
import HeroIcon from "../../../../@fuse/core/HeroIcon/HeroIcon";
import RbContentEditable from "../../../shared-components/rb-components/RbContentEditable";
import BoardCardActivities from "../board-details-compoments/BoardCardActivities";
import {CalendarIcon, ClipboardCopyIcon, MinusCircleIcon} from "@heroicons/react/outline";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import jwtService from "../../../services/jwtService/jwtService";

export default function BoardCardDetailsPreview() {
    const {boardId, id} = useParams();
    const [card, setCard] = useState<IBoardCardDetails>();
    const [shortCards, setShortCards] = useState<IBoardShortCard[]>([]);
    const prioritiesString: string[] = ["None", "Low", "Medium", "High"];
    const [anchorElFlag, setAnchorElFlag] = useState<null | HTMLElement>(null);
    const [cardStatuses, setCardStatuses] = useState<ICardStatus[]>([]);
    const [boardMembers, setBoardMembers] = useState<IBoardMember[]>([]);
    const [trackerHistory, setTrackerHistory] = useState<IBoardTrackerHistory[]>([]);
    const [comments, setComments] = useState<IBoardCardComment[]>([]);
    const [tasks, setTasks] = useState<IBoardCardTasks[]>([]);
    const [tab, setTab] = useState<"comments" | "checklists" | "card activity" | "tracker history">("comments")
    const tabs = ["comments", "checklists", "card activity", "tracker history"];
    const [cardActivities, setCardActivities] = useState<IBoardCardActivity[]>([])
    const [openDueDate, setOpenDueDate] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [boardLabels, setBoardLabels] = useState<IBoardLabel[]>([])
    const theme: RbTheme = useTheme();

    useEffect(() => {
        if (!id) return;
        _getCardDetails();
        _getBoardCardStatuses();
        _getBoardMembers();
        _getShortBoardCards();
        _getCardComments();
        _getCardTasks();
        _getBoardCardTrackerHistory();
        _getBoardLabels();
    }, [id]);

    function _getBoardLabels() {
        if (!boardId) return;
        getBoardLabels(boardId).then(res => {
            if (res?.data?.error) return;
            setBoardLabels(res.data.data);
        });
    }

    function _getCardActivity() {
        if (!id) return;
        getCardActivity(id).then(res => {
            if (res?.data?.error) return;
            setCardActivities(res.data.data);
        });
    }

    function _getBoardCardTrackerHistory() {
        if (!id) return;
        getBoardCardTrackerHistory(id).then(res => {
            if (res?.data?.error) return;
            setTrackerHistory(res.data.data);
        });
    }

    function _getBoardCardStatuses() {
        getBoardCardStatuses().then(res => {
            if (res?.data?.error) return;
            setCardStatuses(res.data.data);
        });
    }

    function _getCardComments() {
        if (!id) return;
        getCardComments(id).then(res => {
            if (res?.data?.error) return;
            setComments(res.data.data.sort(sortByDate));
        });
    }

    function _getCardTasks() {
        if (!id) return;
        getCardTasks(id).then(res => {
            if (res?.data?.error) return;
            setTasks(res.data.data);
        });
    }

    function sortByDate(a: IBoardCardComment, b: IBoardCardComment) {
        if (new Date(a.date) > new Date(b.date)) return -1;
        if (new Date(a.date) < new Date(b.date)) return 1;
        return 0;
    }


    function _getShortBoardCards() {
        if (!boardId) return;
        getShortBoardCards(boardId).then(res => {
            if (res?.data?.error) return;
            setShortCards(res.data.data);
        })
    }

    function _getBoardMembers() {
        if (!boardId) return;
        getBoardMembers(boardId).then(res => {
            if (res?.data?.error) return;
            setBoardMembers(res.data.data);
        });
    }

    function _getCardDetails() {
        if (!id) return;
        _getCardActivity();
        getCardDetails(id).then(res => {
            if (res?.data?.error) return;
            setCard(res.data.data);
        });
    }

    function getPriorityColor(i: number | undefined = undefined): string {
        switch (i ?? card?.priorityId) {
            case 1:
                return theme.priority.low;
            case 2:
                return theme.priority.medium;
            case 3:
                return theme.priority.high;
            default:
                return theme.palette.text.primary;
        }
    }

    function handleBoardCardNameUpdate(name: string) {
        if (name === card?.title || !card?.id) return;
        updateBoardCardName(card.id, name).then(res => {
            if (res?.data?.error) return;
            _getCardDetails();
        });
    }

    function handleChangeDueDate(date: Date | null, force: boolean = false) {
        setOpenDueDate(false);
        setAnchorEl(null);
        // @ts-ignore
        setCard({...card, dueDate: date?.toISOString() ?? undefined});
        // if (!force) {
        //     if (!date || !card?.id) {
        //         return;
        //     }
        // } else
        if (!card?.id) return;
        updateCardDueDate(card.id, date?.toISOString() ?? null).then(res => {
            if (res?.data?.error) return;
            _getCardDetails();
        });
    }

    function handlePriority(i: number) {
        if (!card?.id) return;
        setAnchorElFlag(null);
        updateBoardCardPriority(card.id, i === 0 ? null : i).then(res => {
            if (res?.data?.error) return;
            _getCardDetails();
        });
    }

    return (
        <div className="flex h-full">
            <div className="p-24 md:w-1/2 w-3/5 border-r-1 border-solid"
                 style={{borderColor: theme.palette.secondary.main}}>
                <div className="mb-16">
                    <Link to={`/board-view/${boardId}`}>
                        <RbRoundButton className="border-1 border-solid pb-0 pt-0" onClick={() => null}
                                       style={{
                                           color: theme.palette.text.primary,
                                           borderColor: theme.palette.text.primary
                                       }}><ViewBoardsIcon
                            className="inline-block align-middle w-16 mr-5"/>
                            <span className="inline-block align-middle">Board</span></RbRoundButton>
                    </Link>

                    <RbRoundButton className="border-1 border-solid pb-0 pt-0 ml-10" onClick={() => {
                        navigator.clipboard.writeText(`${window.location.origin}/board/card/details/${boardId}/${card?.id}`).then(() => {
                            jwtService.emit("success", "Link copied!");
                        })
                    }} style={{
                        color: theme.palette.text.primary,
                        borderColor: theme.palette.text.primary
                    }}><ClipboardCopyIcon
                        className="inline-block align-middle w-16 mr-5"/>
                        <span className="inline-block align-middle">Copy card link</span></RbRoundButton>
                </div>
                <BoardCardHeadControls card={card} statuses={cardStatuses} refreshCard={_getCardDetails}/>

                <RbSubSection>
                    <div className="flex justify-between mt-20">
                        <h1 className="flex items-center"><span className="font-bold mr-6">Title: </span>
                            <RbContentEditable onConfirm={handleBoardCardNameUpdate} defaultValue={card?.title}>
                                {card?.title}
                            </RbContentEditable>
                        </h1>
                        <IconButton className="p-6" onClick={e => setAnchorElFlag(e.currentTarget)}>
                            <span className="w-20" style={{color: getPriorityColor()}}>
                                <HeroIcon icon="FlagIcon" outline={!card?.priorityId}/>
                            </span>
                        </IconButton>
                    </div>

                    <Menu
                        id="priority-menu"
                        anchorEl={anchorElFlag}
                        open={!!anchorElFlag}
                        onClose={() => setAnchorElFlag(null)}
                    >
                        {
                            prioritiesString.map((p, i) => {
                                return (
                                    <MenuItem key={p} onClick={() => handlePriority(i)}>
                                        <FlagIcon className="w-20 mr-10" color={getPriorityColor(i)}/>
                                        {p}</MenuItem>
                                )
                            })
                        }
                    </Menu>
                </RbSubSection>

                <RbSubSectionItem>
                    <DesktopDatePicker
                        open={openDueDate}
                        label="Due date"
                        inputFormat="dd.MM.yyyy"
                        value={card?.dueDate}
                        onChange={val => handleChangeDueDate(val as any)}
                        renderInput={(params) => <TextField {...params}
                                                            size="small"
                                                            className="w-208"
                                                            color="primary"
                                                            onClick={e => setAnchorEl(e.currentTarget)}/>}/>
                    <Menu
                        id="due-menu"
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={e => {
                            // @ts-ignore
                            e?.preventDefault();
                            // @ts-ignore
                            e?.stopPropagation();
                            setAnchorEl(null);
                        }}
                    >
                        <MenuItem className="flex items-center"
                                  onClick={() => setOpenDueDate(true)}>
                            <CalendarIcon className="w-20 mr-10"/>
                            <span>Set due date</span>
                        </MenuItem>
                        <MenuItem className="flex items-center"
                                  onClick={() => handleChangeDueDate(null, true)}
                                  style={{color: theme.palette.warning.main}}>
                            <MinusCircleIcon className="w-20 mr-10"/>
                            <span>Clear</span>
                        </MenuItem>
                    </Menu>
                </RbSubSectionItem>


                <RbSubSectionItem>
                    <BoardCardDescription card={card} refreshCard={_getCardDetails}/>
                </RbSubSectionItem>

                <RbSubSection className="flex">
                    <div className="w-1/2">
                        <BoardCardMembers card={card} members={boardMembers}
                                          refreshCard={_getCardDetails}/>
                    </div>
                    <div className="w-1/2">
                        <BoardCardLabels card={card} labels={boardLabels}
                                         refreshCard={_getCardDetails}/>
                    </div>
                </RbSubSection>

                <RbSubSection className="flex">
                    <div className="w-1/2">
                        <BoardCardReminder card={card}/>
                    </div>
                    <div className="w-1/2">
                        <BoardCardETC card={card} refreshCard={_getCardDetails}/>
                    </div>
                </RbSubSection>
                <RbSubSection className="flex">
                    <div className="w-1/2">
                        <BoardCardParent card={card} refreshCard={_getCardDetails}
                                         cards={shortCards}/>
                    </div>
                    <div className="w-1/2">
                        <BoardCardBlockedBy card={card} refreshCard={_getCardDetails}
                                            cards={shortCards}/>
                    </div>
                </RbSubSection>


                <RbSubSection>
                    <BoardCardAttachments card={card}/>
                </RbSubSection>
            </div>

            <div className="md:w-1/2 w-2/5">
                <RbTabs variant="fullWidth" value={tab} onChange={(e, val) => setTab(val)} className="pl-5 pr-24">
                    {
                        tabs.map((t, i) => {
                            return (
                                <Tab key={i} value={t} label={t}/>
                            )
                        })
                    }
                </RbTabs>

                <TabContext value={tab}>
                    <TabPanel value="comments" className="overflow-auto p-10 pr-24">
                        <BoardCardComments card={card} comments={comments} refreshComments={_getCardComments}/>
                    </TabPanel>

                    <TabPanel value="checklists" className="overflow-auto p-10 pr-24">
                        <BoardCardTasks card={card} tasks={tasks} refreshTasks={_getCardTasks}/>
                    </TabPanel>

                    <TabPanel value="card activity" className="overflow-auto p-10 pr-24">
                        <BoardCardActivities activities={cardActivities}></BoardCardActivities>
                    </TabPanel>

                    <TabPanel value="tracker history" className="overflow-auto p-10 pr-24">
                        <BoardTrackerHistory trackerHistory={trackerHistory}/>
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    )
}
