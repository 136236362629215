import SystemTicketPreview from "./ticket-preview/SystemTicketPreview";
import TicketDetails from "./ticket-preview/TicketDetails";
import TicketsManagePreview from "./manage/TicketsManagePreview";
import TicketCreate from "./manage/TicketCreate";

const TicketDetailsConfig = {
    settings: {
        layout: {
            config: {
                navbar: {
                    display: false,
                },
                toolbar: {
                    display: false,
                },
                footer: {
                    display: false,
                },
                leftSidePanel: {
                    display: false,
                },
                rightSidePanel: {
                    display: false,
                },
            },
        },
    },
    routes: [
        {
            path: 'ticket/:id',
            element: <SystemTicketPreview/>,
        },
        {
            path: 'ticket/details/:id',
            element: <TicketDetails className="w-11/12 m-auto mt-40" edit={false}/>,
        },
        {
            path: 'ticket/manage/:id',
            element: <TicketsManagePreview/>,
        },
        {
            path: 'ticket/create/:id',
            element: <TicketCreate/>,
        },
    ],
};

export default TicketDetailsConfig;
