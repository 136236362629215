import {IBoardCard, IBoardShortCard} from "../../../types/board-types";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {Popover} from "@mui/material";
import React, {useEffect, useState} from "react";
import {MinusIcon} from "@heroicons/react/outline";

export default function BoardCardListPicker(props: { cards: IBoardCard[] | IBoardShortCard[], anchorEl: HTMLElement | SVGSVGElement | null, onClose: () => void, onSelect?: (card: IBoardCard | IBoardShortCard | null) => void }) {
    const [filter, setFilter] = useState<string>("");

    useEffect(() => {

    }, [filter]);

    return (
        <Popover
            open={!!props.anchorEl}
            anchorEl={props.anchorEl}
            onClose={(e) => {
                // @ts-ignore
                e.stopPropagation();
                props.onClose();
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className="p-10">
                {
                    <RbTextField autoFocus={true}
                                 fullWidth
                                 placeholder="Search for card"
                                 variant="filled"
                                 hiddenLabel={true}
                                 size="small"
                                 value={filter}
                                 onChange={e => setFilter(e.target.value)}
                                 color="primary"/>
                }
                <div className="my-10">
                    <button className="w-full text-left" onClick={() => {
                        if (!props?.onSelect) return;
                        props.onSelect(null);
                        props.onClose();
                    }}>
                        <MinusIcon className="inline-block align-middle w-20 mr-10"/>
                        <span className="inline-block align-middle">Clear</span>
                    </button>
                </div>
                <hr/>
                <div className="mt-10 max-h-160 overflow-auto">
                    {
                        (props.cards as any[]).filter((c: any) => (c?.title ?? c?.name ?? "").toLowerCase().startsWith(filter)).map((card, i) => {
                            return <button className="w-full text-left py-5 my-1"
                                           onClick={() => {
                                               if (!props?.onSelect) return;
                                               props.onSelect(card);
                                               props.onClose();
                                           }}
                                           key={i}>{card?.title ?? card?.name ?? ""}</button>
                        })
                    }
                </div>
            </div>
        </Popover>
    )
}
