import React, {useState} from 'react';
import HeroIcon from "../../../@fuse/core/HeroIcon/HeroIcon";
import {Menu, MenuItem} from "@mui/material";
import {DuplicateIcon, RefreshIcon, TrashIcon} from "@heroicons/react/outline";
import RbConfirmationDialog from "../dialogs/RbConfirmationDialog";
import {deleteTracker, duplicateTracker, getRunningTracker, repeatTracker} from "../../services/tracker/trackerService";
import {ITracker, ITrackerDetails} from "../../types/tracker-types";
import moment from "moment";
import {useDispatch} from "react-redux";
import {updateGlobalTracker} from "../../store/rubicon/globalTrackerSlice";


const DotsMenu = (props: { tracker: ITrackerDetails, refreshTrackers: () => void }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dispatch = useDispatch();
    const op = Boolean(anchorEl);
    const [dialogConfig, setDialogConfig] = useState({
        name: "",
        title: "",
        children: ""
    })

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (event: any) => {
        event.stopPropagation();
        setAnchorEl(null);
    };
    const [open, setOpen] = React.useState(false);


    const remove = (trackerId: string) => {
        setAnchorEl(null);

        deleteTracker(trackerId).then(() => {
            setOpen(false);
            setAnchorEl(null);
            props.refreshTrackers();
        });
    }

    const repeat = (trackerId: string) => {
        setAnchorEl(null);

        repeatTracker(trackerId, moment().toISOString(true)).then(() => {
            getRunningTracker().then((res) => {
                if (!res?.data?.data?.id) return;
                dispatch(updateGlobalTracker(res.data.data as ITracker));
            });
        });
    }

    const duplicate = (trackerId: string) => {
        setAnchorEl(null);

        duplicateTracker(trackerId).then(() => {
            setAnchorEl(null);
            props.refreshTrackers();
        });
    }
    return (
        <div className={'float-right'}>
            <HeroIcon icon={"DotsVerticalIcon"} outline onClick={handleClick} size={24}/>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={op}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    onClick={() => duplicate(props.tracker.id)}
                    className={'justify-between'}
                >
                    Duplicate
                    <DuplicateIcon height={24}/>
                </MenuItem>
                <MenuItem
                    onClick={(event) => {
                        repeat(props.tracker.id)
                    }}
                    className={'justify-between'}
                >
                    Repeat
                    <RefreshIcon height={24}/>
                </MenuItem>
                <MenuItem
                    onClick={(event) => {
                        setOpen(true)
                        setDialogConfig({
                            name: "delete",
                            title: "Delete tracker!",
                            children: "Are you sure you want to delete this tracker? This action cannot be undone!"
                        })
                    }}
                    className={'justify-between text-red-800'}
                >
                    Delete
                    <TrashIcon height={24}/>
                </MenuItem>
            </Menu>
            <RbConfirmationDialog
                title={dialogConfig?.title}
                open={open}
                isDelete={true}
                onClose={() => setOpen(false)}
                onConfirm={() => {
                    remove(props.tracker.id);
                }}
            >
                {dialogConfig?.children}
            </RbConfirmationDialog>
        </div>
    );
};

export default DotsMenu;
