import {ITodo, ITodoFriend} from "../../../../types/todo-types";
import {UserCircleIcon} from "@heroicons/react/outline";
import {RbRoundButton} from "../../../../shared-components/rb-components/RbRoundButton";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Menu, MenuItem} from "@mui/material";
import {FlagIcon} from "@heroicons/react/solid";
import {assignUserToTask, getFriendList} from "../../../../services/todo/todoService";

export default function TodoAssigned(props: { todo: ITodo | undefined, setTodo: React.Dispatch<ITodo | undefined>, refreshTodos: () => void }) {
    const {todo} = props;
    const user = useSelector((state: any) => state.auth.user).data;
    const [friendList, setFriendList] = useState<ITodoFriend[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [assigned, setAssigned] = useState<boolean>(false);

    useEffect(() => {
        assignLogic();
        _getFriendList();
    }, []);

    function _getFriendList() {
        getFriendList().then(res => {
            if (res?.data?.error) return;
            setFriendList(res.data.data);
        });
    }

    function assignLogic() {
        if (todo?.assignTo?.id === user.uid) {
            setAssigned(false);
            return;
        }
        if (todo?.assignBy?.id === user.uid) {
            setAssigned(true);
            return;
        }
        setAssigned(!todo?.assignTo);
    }

    function handleFriendAssign(friend: ITodoFriend) {
        setAnchorEl(null);
        if (!todo?.id || !friend.id) return;
        assignUserToTask(todo?.id, friend.id).then(res => {
            if (res?.data?.error) return;
            props.refreshTodos();
            props.setTodo({...todo, assignTo: todo.assignTo?.id === friend?.id ? null : friend});
        });
    }

    return (
        <>
            <h4 className="mb-10"><UserCircleIcon className="w-20 mr-10 inline-block align-middle"/><span
                className="inline-block align-middle">Assign {assigned ? "to" : "by"}</span></h4>
            <RbRoundButton color="secondary"
                           onClick={e => setAnchorEl(e.currentTarget)}
                           variant="contained">{(assigned ? todo?.assignTo : todo?.assignBy)?.name ?? "Not set"}</RbRoundButton>

            <Menu
                id="freinds-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
            >
                {
                    friendList.map(friend => {
                        return (
                            <MenuItem key={friend.id} onClick={() => handleFriendAssign(friend)}>
                                <span
                                    className={todo?.assignTo?.id === friend.id ? "font-bold" : ""}>{friend.name}</span></MenuItem>
                        )
                    })
                }
            </Menu>
        </>
    )
}
