import {Drawer, IconButton} from "@mui/material";
import {XIcon} from "@heroicons/react/outline";
import React from "react";
import {IBoard} from "../../../types/board-types";

export default function BoardSettingsTemplates(props: { board: IBoard, open: boolean, onClose: () => void }) {
    return (
        <Drawer
            anchor="right"
            open={props.open}
            onClose={props.onClose}
            hideBackdrop={true}>
            <div className="p-20">
                <div className="flex justify-between items-center">
                    <h2>Templates</h2>
                    <IconButton onClick={props.onClose}>
                        <XIcon className="w-20"/>
                    </IconButton>
                </div>
                <hr className="w-320 mt-10 mb-20"/>
            </div>
        </Drawer>
    )
}
