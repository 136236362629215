import ReportWidgetBase from "./ReportWidgetBase";
import {ITrackerByCategory, ITrackerByUserAndCategory} from "../../../types/report-types";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {IRbDisplayHeader} from "../../../types/common-types";
import {useState} from "react";
import {ITodoFriend} from "../../../types/todo-types";
import RbSingleSelect from "../../../shared-components/rb-components/RbSingleSelect";
import * as React from "react";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";
import {ChevronDownIcon} from "@heroicons/react/outline";
import {getUniqueItemsByProperty} from "../../../utils/RbUtils";

export default function ReportTrackerByCategoryAndUser(props: { className?: string, data?: ITrackerByUserAndCategory[] }) {
    const [user, setUser] = useState<ITodoFriend>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "Name",
            objAttribute: "name",
            handleRendering: handleUserRender
        },
        {
            name: "Category",
            objAttribute: "category"
        },
        {
            name: "Billable",
            objAttribute: "billable"
        },
        {
            name: "Billable time",
            objAttribute: "billableTime"
        },
        {
            name: "Unbillable",
            objAttribute: "unbillable"
        },
        {
            name: "Unbillable time",
            objAttribute: "unbillableTime"
        },
        {
            name: "Total tasks",
            objAttribute: "totalTrackers"
        },
        {
            name: "Total time",
            objAttribute: "totalTime"
        },
        {
            name: "Total price",
            objAttribute: "totalPrice",
            handleRendering: handleRenderTotalPrice
        },
    ];

    function handleUserRender(item: ITrackerByUserAndCategory) {
        return <span>{item.user.name}</span>
    }

    function handleRenderTotalPrice(item: ITrackerByCategory): JSX.Element {
        return <span>{item.totalPrice} EUR</span>;
    }

    function renderHeaderControl(): JSX.Element {
        return (
            <RbRoundButton className="h-32 w-fit min-w-128 whitespace-nowrap flex items-center justify-between"
                           variant="contained"
                           onClick={e => setAnchorEl(e.currentTarget)}>
                <span>{user?.name ?? "Select user"}</span>
                <ChevronDownIcon className="w-20"/>
            </RbRoundButton>
        )
    }

    function getFilteredItems(): ITodoFriend[] {
        return getUniqueItemsByProperty(props.data?.map(c => c.user), "id");
    }

    return (
        <ReportWidgetBase className={props.className} title="Tracker report by category and user"
                          widgetControl={renderHeaderControl}>
            <RbDataTable
                dataSource={(!user?.id ? props?.data : props?.data?.filter(d => d?.user?.id === user?.id)) ?? []}
                search={false} paginator={true} pageSizes={[10, 20, 30, 50]} headerColumns={displayHeaders}/>
            <RbSingleSelect items={getFilteredItems()}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)} onSelect={u => setUser(u)}
                            renderItem={item => item.name}/>
        </ReportWidgetBase>
    )
}
