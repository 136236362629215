import {styled} from "@mui/system";
import {Button} from "@mui/material";

export const RbRoundButton = styled(Button)(() => ({
    '&': {
        borderRadius: "5rem",
        display: "inline-block",
        color: "inherit"
    },
    "& + .MuiButton-root": {
        marginLeft: "1rem"
    }
}));

