import {useEffect, useState} from "react";
import {ITrackerCategory} from "../../../types/organization-settings-types";
import {
    addOrganizationCategory, deleteTrackerCategory,
    getOrganizationCategories, updateTrackerCategoryStatus, updateTrackerColor
} from "../../../services/organizationSettingsService";
import {IRbDisplayHeader, UserOrganization} from "../../../types/common-types";
import {ColorSwatchIcon, PlusIcon} from "@heroicons/react/outline";
import * as React from "react";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {RbSubSection} from "app/shared-components/rb-components/RbSections";
import {useTheme} from "@mui/material/styles";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {RbTextField} from "app/shared-components/rb-components/RbTextField";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import Switch from "@mui/material/Switch";
import RbColorPickerMenu from "../../../shared-components/rb-components/RbColorMenu/RbColorPickerMenu";
import RbTableActionButton from "../../../shared-components/rb-components/RbDataTable/RbTableActionButton";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";
import RbConfirmationDialog from "../../../shared-components/dialogs/RbConfirmationDialog";

function TrackerCategoriesOrganizationSettings(props: { organization: UserOrganization }) {
    const [categoryForDelete, setCategoryForDelete] = useState<ITrackerCategory>();
    const [trackerCategories, setTrackerCategories] = useState<ITrackerCategory[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [newCategory, setNewCategory] = useState<ITrackerCategory>({
        active: false,
        color: "#525255",
        id: 0,
        name: ""
    });
    const theme = useTheme();
    const [categoryEdit, setCategoryEdit] = useState<ITrackerCategory>();

    const displayCategoriesHeaders: IRbDisplayHeader[] = [
        {
            name: "Color",
            objAttribute: "color",
            handleRendering: renderColorPickerBtn
        },
        {
            name: "Category name",
            objAttribute: "name"
        },
        {
            name: "Status",
            objAttribute: "status",
            handleRendering: renderActiveStatus
        },
        {
            name: "",
            objAttribute: "",
            handleRendering: renderOrganizationCategoriesActions,
            className: "text-right"
        }
    ];

    useEffect(() => {
        _getOrganizationCategories();
    }, [props?.organization?.id]);

    function resetCategory() {
        setNewCategory({
            active: false,
            color: "#525255",
            id: 0,
            name: ""
        });
    }

    function handleCategoryStatusChange(obj: ITrackerCategory) {
        updateTrackerCategoryStatus(obj.id, !obj.active).then(res => {
            if (res?.data?.error) return;
            _getOrganizationCategories();
        });
    }


    function renderActiveStatus(obj: ITrackerCategory) {
        return (<Switch
            key={`switch-${obj.id}`}
            checked={obj.active}
            color="info"
            onClick={() => handleCategoryStatusChange(obj)}
        />)
    }


    function handleAddOrganizationCategory() {
        if (!props?.organization?.id) return;
        addOrganizationCategory(props.organization.id, newCategory).then(res => {
            if (!res?.data?.error) {
                resetCategory();
                _getOrganizationCategories();
            }
        })
    }

    function renderOrganizationCategoriesActions(obj: ITrackerCategory) {
        return <RbTableActionButton icon="TrashIcon" onClick={() => {
            setCategoryForDelete(obj);
        }}/>;
    }

    function renderColorPickerBtn(obj: ITrackerCategory) {
        return (
            <button key={`color-picker-${obj.id}`} className="rounded-6 w-32 p-5"
                    style={{backgroundColor: obj.color}} onClick={e => {
                setAnchorEl(e.currentTarget);
                setCategoryEdit(obj);
            }}><ColorSwatchIcon/></button>
        )
    }

    function _getOrganizationCategories() {
        if (!props?.organization?.id) return;
        getOrganizationCategories(props.organization.id).then(res => {
            if (!res?.data?.error) {
                setTrackerCategories(res.data.data);
            }
        });
    }

    function handleNewCategoryNameChange(e: any) {
        newCategory.name = e?.target?.value ?? "";
        setNewCategory(newCategory);
    }

    function handleCloseColorPicker() {
        setAnchorEl(null);
        setCategoryEdit(undefined);
    }

    function _updateTrackerColor(color: string) {
        if (categoryEdit?.id) {
            updateTrackerColor(categoryEdit?.id, color).then(res => {
                _getOrganizationCategories();
            });
        } else {
            setNewCategory({...newCategory, color: color});
        }
        setAnchorEl(null);
    }

    function handleCategoryDelete() {
        if (!categoryForDelete?.id) return;
        deleteTrackerCategory(categoryForDelete.id).then(res => {
            setCategoryForDelete(undefined);
            if (res?.data?.error) return;
            _getOrganizationCategories();
        })
    }

    return (
        <div className="mt-48 md:ml-28 sm:ml-28 sm:mr-28 ml-64 mr-64 mb-48">
            <RbBox>
                <>
                    <div className="inline-block align-middle mr-20">
                        {renderColorPickerBtn(newCategory)}
                    </div>
                    <RbTextField className="inline-block align-middle w-200 mr-20" placeholder="+ Category name"
                                 variant="standard" onChange={handleNewCategoryNameChange}/>
                </>
                <RbRoundButton onClick={handleAddOrganizationCategory}
                               disabled={!newCategory?.name || !newCategory?.color}
                               style={{color: theme.palette.text.primary}} variant="contained"><PlusIcon
                    className="w-16 inline-block align-middle"/> <span className="inline-block align-middle">Add</span></RbRoundButton>
            </RbBox>
            <RbSubSection>
                <RbDataTable dataSource={trackerCategories} headerColumns={displayCategoriesHeaders}/>
                <RbConfirmationDialog onClose={() => setCategoryForDelete(undefined)}
                                      onConfirm={handleCategoryDelete} open={!!categoryForDelete} isDelete={true}>
                    Are you sure you want to delete <span
                    className="font-bold italic">{categoryForDelete?.name ?? ""}</span>?
                </RbConfirmationDialog>
            </RbSubSection>
            <RbColorPickerMenu anchorEl={anchorEl} handleMenuClose={handleCloseColorPicker}
                               onSelect={_updateTrackerColor}/>
        </div>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(TrackerCategoriesOrganizationSettings));

