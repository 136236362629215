import FuseUtils from '@fuse/utils/FuseUtils';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import userManager from "../authService";
import {setAuthHeader} from "../../auth/utils/axiosHeaders";
import jwtService from "./index";
import {saveSSUserData, StorageDataType} from "../storageBus";

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    constructor() {
        super();
        this.init();
    }

    setInterceptors = () => {
        axios.interceptors.request.use((config) => {
            userManager.getUser().then(user => {
                if (user && user.access_token && !user.expired) {
                    setAuthHeader(user.access_token);
                    return config;
                } else {
                    throw new axios.Cancel('API canceled.');
                }
            });
            return config;
        });
        axios.interceptors.response.use((response) => {
            if (response.data.error) {
                this.emit('error', response?.data?.message ?? "");
            }
            return response;
        }, (err) => {
            // return new Promise((resolve, reject) => {
            if (err?.response?.status === 401 && err.config && !err.config.__isRetryRequest) {
                if (window.location.pathname !== "/signin-oidc") {
                    saveSSUserData(StorageDataType.ReturnUrl, window.location.pathname);
                }
                jwtService.logout();
                userManager.signinRedirect();
            } else {
                this.emit('api_error', err?.response);
            }
            // throw err;
            // });
        });
    };

    handleAuthentication = () => {
        const access_token = this.getAccessToken();

        if (!access_token) {
            this.emit('onNoAccessToken');

            return;
        }

        if (this.isAuthTokenValid(access_token)) {
            this.setSession(access_token);
            this.emit('onAutoLogin', true);
        } else {
            this.setSession(null);
            this.emit('onAutoLogout', 'access_token expired');
        }
    };

    createUser = (data) => {
        return new Promise((resolve, reject) => {
            axios.post('/api/auth/register', data).then((response) => {
                if (response.data.user) {
                    this.setSession(response.data.access_token);
                    resolve(response.data.user);
                } else {
                    reject(response.data.error);
                }
            });
        });
    };

    signInWithEmailAndPassword = (email, password) => {
        return new Promise((resolve, reject) => {
            axios
                .get('/api/auth', {
                    data: {
                        email, password,
                    },
                })
                .then((response) => {
                    if (response.data.user) {
                        this.setSession(response.data.access_token);
                        resolve(response.data.user);
                    } else {
                        reject(response.data.error);
                    }
                });
        });
    };

    signInWithToken = () => {
        return new Promise((resolve, reject) => {
            axios
                .get('/api/auth/access-token', {
                    data: {
                        access_token: this.getAccessToken(),
                    },
                })
                .then((response) => {
                    if (response.data.user) {
                        this.setSession(response.data.access_token);
                        resolve(response.data.user);
                    } else {
                        this.logout();
                        reject(new Error('Failed to login with token.'));
                    }
                })
                .catch((error) => {
                    this.logout();
                    reject(new Error('Failed to login with token.'));
                });
        });
    };

    updateUserData = (user) => {
        return axios.post('/api/auth/user/update', {
            user,
        });
    };

    // setSession = (access_token) => {
    //     if (access_token) {
    //         localStorage.setItem('jwt_access_token', access_token);
    //         axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    //     } else {
    //         localStorage.removeItem('jwt_access_token');
    //         delete axios.defaults.headers.common.Authorization;
    //     }
    // };

    logout = () => {
        // this.setSession(null);
    };

    isAuthTokenValid = (access_token) => {
        if (!access_token) {
            return false;
        }
        const decoded = jwtDecode(access_token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
            console.warn('access token expired');
            return false;
        }

        return true;
    };

    getAccessToken = () => {
        // return window.localStorage.getItem('jwt_access_token');
        return "";
    };
}

const instance = new JwtService();

export default instance;
