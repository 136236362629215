import {useForm} from "react-hook-form";
import {useNavigate, useParams} from "react-router-dom";
import {FormControl, InputLabel, MenuItem, Select, Step, StepButton, Stepper} from "@mui/material";
import React, {useEffect, useState} from "react";
import {TabContext, TabPanel} from "@mui/lab";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {createTicket, getTicketSystemCategories, getTicketSystemSubcategories} from "../../../services/ticketsService";
import {ITicketSystemCategory} from "../../../types/tickets-types";
import RbSingleSelect from "../../../shared-components/rb-components/RbSingleSelect";
import {ChevronDownIcon, PencilIcon} from "@heroicons/react/outline";
import RbIconTitle from "../../../shared-components/rb-components/RbIconTitle";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";

export default function TicketCreate(props: {}) {
    const {id} = useParams();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState<number>(0);
    const [selectedSubcategory, setSelectedSubcategory] = useState<ITicketSystemCategory>();
    const [title, setTitle] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [selectedCategory, setSelectedCategory] = useState<ITicketSystemCategory>();
    const [categories, setCategories] = useState<ITicketSystemCategory[]>([]);
    const [subcategories, setSubcategories] = useState<ITicketSystemCategory[]>([]);
    const [anchorElSubcat, setAnchorElSubcat] = React.useState<HTMLElement | null>(null);
    const [anchorElCat, setAnchorElCat] = React.useState<HTMLElement | null>(null);
    const [shouldDisable, setShouldDisable] = useState<boolean>(false);

    useEffect(() => {
        _getTicketSystemCategories();
    }, [id]);

    useEffect(() => {
        _getTicketSystemSubcategories();
    }, [selectedCategory?.id]);

    function _getTicketSystemCategories() {
        if (!id) return;
        getTicketSystemCategories(id).then(res => {
            if (res?.data?.error) return;
            setCategories(res.data.data);
        });
    }

    function _getTicketSystemSubcategories() {
        if (!selectedCategory?.id) return;
        getTicketSystemSubcategories(selectedCategory.id).then(res => {
            if (res?.data?.error) return;
            setSubcategories(res.data.data);
        });
    }

    function isTicketValid(): boolean {
        if (activeStep === 0) {
            return !!title && !!description && !!selectedCategory?.id && !!selectedSubcategory?.id;
        } else {
            return false;
        }
    }

    function _createTicket() {
        if (!title || !description || !selectedSubcategory?.id) return;
        setShouldDisable(true);
        createTicket(title, selectedSubcategory.id, description).then(res => {
            if (res?.data?.error) return;
            navigate(`/ticket/details/${res.data.data}`);
        });
    }

    return (
        <div className="h-full w-full flex items-center text-center min-w-400">
            <div className="w-400 m-auto">
                <h1 className="font-bold mb-20">Submit a ticket</h1>
                <Stepper activeStep={activeStep}>
                    {
                        ["Basic info", "Ticket ID"].map((title, i) => {
                            return (
                                <Step completed={isTicketValid()} key={i}>
                                    <StepButton color="inherit" onClick={() => setActiveStep(i)}>
                                        {title}
                                    </StepButton>
                                </Step>
                            )
                        })
                    }
                </Stepper>


                <TabContext value={String(activeStep)}>
                    <TabPanel value={"0"} className="px-0">
                        <RbTextField autoFocus={true}
                                     className="mb-20"
                                     fullWidth
                                     placeholder="Ticket name"
                                     variant="outlined"
                                     label="Title"
                                     size="small"
                                     value={title}
                                     name="title"
                                     onChange={e => setTitle(e.target.value)}
                                     color="primary"/>

                        <RbTextField className="mb-20"
                                     fullWidth
                                     placeholder="Category"
                                     variant="outlined"
                                     label="Category"
                                     size="small"
                                     value={selectedCategory?.name ?? ""}
                                     name="category"
                                     InputProps={{endAdornment: <ChevronDownIcon className="w-14"/>}}
                                     onClick={e => setAnchorElCat(e.currentTarget)}
                                     color="primary"/>
                        <RbSingleSelect anchorEl={anchorElCat} onClose={() => setAnchorElCat(null)} items={categories}
                                        hasClear={false} search={false} renderItem={item => item.name}
                                        onSelect={item => setSelectedCategory(item)}/>

                        <RbTextField className="mb-20"
                                     fullWidth
                                     disabled={!selectedCategory?.id}
                                     placeholder="Subcategory"
                                     variant="outlined"
                                     label="Subcategory"
                                     size="small"
                                     value={selectedSubcategory?.name ?? ""}
                                     name="category"
                                     InputProps={{endAdornment: <ChevronDownIcon className="w-14"/>}}
                                     onClick={e => {
                                         if (!selectedCategory?.id) return;
                                         e.preventDefault();
                                         e.stopPropagation();
                                         setAnchorElSubcat(e.currentTarget);
                                     }}
                                     color="primary"/>
                        <RbSingleSelect anchorEl={anchorElSubcat} onClose={() => setAnchorElSubcat(null)}
                                        items={subcategories}
                                        hasClear={false} search={false} renderItem={item => item.name}
                                        onSelect={item => setSelectedSubcategory(item)}/>

                        <RbTextField className="mb-20"
                                     multiline={true}
                                     rows={4}
                                     fullWidth
                                     placeholder="Description"
                                     variant="outlined"
                                     label="Description"
                                     size="small"
                                     value={description}
                                     name="description"
                                     onChange={e => setDescription(e.target.value)}
                                     color="primary"/>

                        <div className="flex justify-between mb-10">
                            <RbIconTitle icon="PaperClipIcon" title="Attachments" container="h4" iconClassName="w-16"/>
                            <RbRoundButton variant="contained" color="secondary">+ Add</RbRoundButton>
                        </div>
                        <RbRoundButton variant="contained" color="primary"
                                       disabled={!isTicketValid()}
                                       onClick={() => setActiveStep(activeStep + 1)}>Next</RbRoundButton>
                    </TabPanel>
                    <TabPanel value={"1"} className="px-0">
                        <h4 className="mb-20">Do you want to track ticket progress? Please enter your email to get the
                            ticket ID!</h4>
                        <RbTextField autoFocus={true}
                                     className="mb-20"
                                     fullWidth
                                     placeholder="Enter your email"
                                     variant="outlined"
                                     label="Email"
                                     size="small"
                                     value={email}
                                     name="title"
                                     onChange={e => setEmail(e.target.value)}
                                     color="primary"/>
                        <div className="block text-right">
                            <RbRoundButton variant="text" className="mr-10" color="primary"
                                           onClick={_createTicket}>Skip</RbRoundButton>

                            <RbRoundButton variant="contained" color="primary" disabled={shouldDisable}
                                           onClick={_createTicket}>Done</RbRoundButton>
                        </div>
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    )


}
