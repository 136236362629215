import {DialogActions, DialogContent, DialogTitle, FormControl} from "@mui/material";
import {RbSubSectionItem} from "../../../shared-components/rb-components/RbSections";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {RbDialog} from "../../../shared-components/dialogs/RbDialog";
import React, {useEffect, useState} from "react";
import {ChevronDownIcon} from "@heroicons/react/outline";
import {createTeam, getOrganizationUsers} from "../../../services/organizationSettingsService";
import {IOrganizationUser} from "../../../types/organization-settings-types";
import RbMultiSelect from "../../../shared-components/rb-components/RbMultiSelect";
import Avatar from "@mui/material/Avatar";
import {getUserInitials} from "../../../utils/RbUtils";
import {width} from "@mui/system";

export default function TeamsCreateDialog(props: { organizationId: string, open: boolean, onClose: (update?: boolean) => void }) {
    const [teamName, setTeamName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [members, setMembers] = useState<IOrganizationUser[]>([]);
    const [selectedMembers, setSelectedMembers] = useState<IOrganizationUser[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    useEffect(() => {
        _getOrganizationUsers();
    }, [props?.organizationId])

    function handleCreateTeam() {
        if (!props?.organizationId || !teamName) return;
        createTeam(props.organizationId, teamName).then(res => {
            if (res?.data?.error) return;
            props.onClose(true);
        })
    }

    function _getOrganizationUsers() {
        if (!props?.organizationId) return;
        getOrganizationUsers(props.organizationId).then(res => {
            if (res?.data?.error) return;
            setMembers(res.data.data);
        })
    }

    function closeAndReset() {
        props.onClose();
        setTimeout(() => {
            setTeamName("");
            setDescription("");
        }, 1000);
    }

    function handeRenderMultiItem(user: IOrganizationUser) {
        return (
            <div className="flex items-center pb-10">
                <Avatar sx={{width: 24, height: 24}} className="text-sm">{getUserInitials(user.name)}</Avatar>
                <span className="ml-10">{user.name}</span>
            </div>
        )
    }

    return (
        <RbDialog
            open={props.open}
            onClose={() => props.onClose()}
        >
            <DialogTitle>Create project</DialogTitle>
            <DialogContent>
                <RbSubSectionItem>
                    <FormControl fullWidth>
                        <RbTextField className="w-320"
                                     variant="outlined"
                                     label="Team name"
                                     autoFocus={true}
                                     value={teamName}
                                     onChange={e => setTeamName(e.target.value)}
                                     color="primary"/>
                    </FormControl>
                </RbSubSectionItem>

                <RbSubSectionItem>
                    <FormControl fullWidth>
                        <RbTextField className="w-320"
                                     variant="outlined"
                                     label="Description"
                                     multiline={true}
                                     rows={5}
                                     value={description}
                                     onChange={e => setDescription(e.target.value)}
                                     color="primary"/>
                    </FormControl>
                </RbSubSectionItem>


                <RbSubSectionItem>
                    <FormControl fullWidth>
                        <RbTextField className="w-320"
                                     variant="outlined"
                                     label="Members"
                                     value={`${selectedMembers.length} selected`}
                                     InputProps={{
                                         startAdornment: (
                                             <ChevronDownIcon className="w-16"/>
                                         ),
                                         readOnly: true
                                     }}
                                     onClick={e => setAnchorEl(e.currentTarget)}
                                     color="primary"/>
                    </FormControl>
                    <RbMultiSelect onClose={() => setAnchorEl(null)} anchorEl={anchorEl} items={members}
                                   renderItem={handeRenderMultiItem} search={true} checkedItems={selectedMembers}
                                   onSelectChange={items => setSelectedMembers(items)}/>
                </RbSubSectionItem>
            </DialogContent>
            <DialogActions className="mr-20 mb-20">
                <RbRoundButton className="mr-10" variant="text" onClick={closeAndReset}>Create</RbRoundButton>
                <RbRoundButton disabled={!props?.organizationId || !teamName || !description}
                               variant="contained" onClick={handleCreateTeam}>Create</RbRoundButton>
            </DialogActions>
        </RbDialog>
    )
}
