import {styled} from "@mui/system";

export const RbSection = styled("div")(() => ({
    '&': {
        marginTop: "4.8rem"
    }
}));

export const RbSubSection = styled("div")(() => ({
    '&': {
        marginTop: "3.2rem"
    }
}));

export const RbSubSectionItem = styled("div")(() => ({
    '&': {
        marginTop: "1rem"
    }
}));
