import {FormControl, IconButton, MenuItem, Select} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/outline";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {styled} from "@mui/system";

export interface IRbPaginatorChange {
    pageSize: number,
    pageIndex: number
}

export function RbDataTablePaginator(props: { defaultPageSize?: number, pageSizes: number[], recordsSize: number | undefined, onChange?: (event: IRbPaginatorChange) => void }) {
    const theme: RbTheme = useTheme();
    const [pageSize, setPageSize] = useState(props?.defaultPageSize ?? props?.pageSizes[0] ?? 5);
    const [pageIndex, setPageIndex] = useState(0);

    useEffect(() => {
        if (!props?.onChange) return;
        props.onChange({pageSize, pageIndex});
    }, [pageSize, pageIndex]);

    const RbSelect = styled(Select)(() => ({
        "&:before": {
            borderBottom: "none",
        },
        "& .MuiInputBase-input": {
            paddingBottom: 0,
            paddingTop: 0,
        },
        "& svg": {
            color: theme.palette.text.secondary,
        },
    }));

    function handlePaginatorPageChange(dir: "left" | "right") {
        if (!props?.onChange) return;
        if (dir === "left" && (pageIndex - 1) >= 0) {
            setPageIndex(pageIndex - 1);
        } else if (dir === "right" && (pageIndex * pageSize + 1) <= (props?.recordsSize ?? 0)) {
            setPageIndex(pageIndex + 1);
        }
    }

    return (
        <div className="flex items-center justify-end" style={{color: theme.palette.text.secondary}}>
            <span className="mr-10">Items per page</span>

            <FormControl size="small">
                <RbSelect
                    variant="standard"
                    label="age"
                    className="w-40"
                    style={{
                        color: theme.palette.text.secondary,
                        borderBottom: `1px solid ${theme.palette.text.secondary}`
                    }}
                    value={pageSize}
                    onChange={(e) => setPageSize(e.target.value as number)}
                >
                    {
                        props.pageSizes.map((size, i) => {
                            return <MenuItem key={i} value={size}>{size}</MenuItem>
                        })
                    }
                </RbSelect>
            </FormControl>

            <span className="mx-10 text-center min-w-80">
                {pageIndex * pageSize + 1} - {pageIndex * pageSize + 1 + pageSize} of {props?.recordsSize ?? 0}
            </span>
            <div className="">
                <IconButton className="p-5" style={{color: theme.palette.text.secondary}}
                            onClick={() => handlePaginatorPageChange("left")}>
                    <ChevronLeftIcon className="w-16"/>
                </IconButton>
                <IconButton className="p-5" style={{color: theme.palette.text.secondary}}
                            onClick={() => handlePaginatorPageChange("right")}>
                    <ChevronRightIcon className="w-16"/>
                </IconButton>
            </div>
        </div>
    )
}

RbDataTablePaginator.defaultProps = {
    pageSizes: [5, 10, 15, 30]
}
