import Popover from "@mui/material/Popover";
import {Button, IconButton, Tooltip} from "@mui/material";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";
import RbBox from "../../shared-components/rb-components/RbBox";
import {useEffect, useState} from "react";
import {QuestionMarkCircleIcon} from "@heroicons/react/solid";

export default function BoardETCPicker(props: { defaultValue?: number, anchorEl: HTMLElement | SVGSVGElement | null, onClose: () => void, onConfirm: (hours: number | undefined) => void }) {
    const [hours, setHours] = useState<number>(0);
    const [type, setType] = useState<"days" | "hours">("hours");

    useEffect(() => {
        if (props.defaultValue && props.defaultValue > 0) {
            setHours(props.defaultValue % 8 === 0 ? props.defaultValue / 8 : props.defaultValue);
        }
    }, []);

    function handleSummit() {
        props.onConfirm(type === "days" ? hours * 8 : hours);
        props.onClose();
    }

    function handleClear() {
        props.onConfirm(undefined);
        props.onClose();
    }

    function handleScroll(e: any) {
        if (e.deltaY < 0) { // scroll up
            hoursUp();
        } else if (e.deltaY > 0) { // scroll down
            hoursDown();
        }
    }

    function hoursUp() {
        setHours(hours + 1);
    }

    function hoursDown() {
        if (hours > 0) {
            setHours(hours - 1);
        }
    }

    return (
        <Popover
            id="ETC_picker"
            open={!!props.anchorEl}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div className="p-20">
                <div className="flex">
                    <h4>Set time</h4>
                    <Tooltip title="One day equals 8 hours" className="ml-10 mb-10" placement="top">
                        <QuestionMarkCircleIcon className="w-20"/>
                    </Tooltip>
                </div>
                <div className="flex items-center justify-between">
                    <div className="flex flex-col items-center w-72">
                        <IconButton className="p-3 mb-2" onClick={hoursUp}>
                            <ChevronUpIcon className="w-16"/>
                        </IconButton>
                        <RbBox className="w-36 aspect-square flex items-center cursor-default"
                               onWheel={handleScroll}>
                            <span className="w-full text-center">{hours}</span>
                        </RbBox>
                        <IconButton className="p-3 mt-2" onClick={hoursDown}>
                            <ChevronDownIcon className="w-16"/>
                        </IconButton>
                    </div>
                    <div className="flex flex-col items-center w-72">
                        <Button color={type === "days" ? "primary" : "secondary"} variant="contained"
                                className="rounded-6 rounded-bl-none rounded-br-none w-72 h-24 text-12"
                                onClick={() => setType("days")}>Days</Button>
                        <Button color={type === "hours" ? "primary" : "secondary"} variant="contained"
                                className="rounded-6 rounded-tl-none rounded-tr-none w-72 h-24 text-12"
                                onClick={() => setType("hours")}>Hours</Button>
                    </div>
                </div>


                <div className="flex justify-between mt-10">
                    <Button color="secondary" variant="text"
                            className="rounded-6 w-72 h-24 text-12" onClick={handleClear}>Clear</Button>
                    <Button color="primary" variant="contained"
                            className="rounded-6 w-72 h-24 text-12 ml-10" onClick={handleSummit}>Select</Button>
                </div>
            </div>
        </Popover>
    )
}
