import {ITicket} from "../../../../types/tickets-types";
import RbBox from "../../../../shared-components/rb-components/RbBox";
import RbText from "../../../../shared-components/rb-components/RbText";
import {formatToEUDate} from "../../../../utils/utils";
import {styled} from "@mui/material/styles";
import {useTheme} from "@mui/styles";
import {RbTheme} from "../../../../types/common-types";
import {RbLink} from "app/shared-components/rb-components/RbLink";

export default function TicketsCardsView(props: { title: string, data: ITicket[], className?: string }) {
    const theme: RbTheme = useTheme();
    const RbH3 = styled("h3")(() => ({
        "&:after": {
            content: "''",
            height: "1px",
            backgroundColor: theme.palette.mode === "light" ? "#bbbec2" : theme.palette.primary.main,
            width: "100%",
            marginLeft: "2rem",
            marginRight: "1rem",
        }
    }));

    return (
        <div className="mb-16">
            <RbH3 className="mb-16 flex w-full items-center whitespace-nowrap">{props.title}</RbH3>
            <div className="flex flex-wrap">
                {
                    (props?.data ?? []).map((t, i) => {
                        return (
                            <RbLink to={`/ticket/${t.id}`} key={i}
                                    className="xl:w-[calc(16.67%-1.05rem)] lg:w-[calc(20%-1.05rem)] md:w-[calc(33%-1.05rem)] sm:w-[calc(50%-1.05rem)] aspect-square mb-10">
                                <RbBox
                                    className={`mb-10 mx-5 px-16 py-16 h-full flex flex-col justify-between ${props?.className ?? ""}`}>
                                    <div>
                                        <span className="mb-5 block">#{t.id}</span>
                                        <h2>{t.title}</h2>
                                        <RbText className="mb-5 text-ellipsis overflow-hidden"
                                                theme="secondary">{t.description}</RbText>
                                    </div>
                                    <div>
                                        <hr/>
                                        <span className="mt-10 mb-2 block">
                                        <RbText theme="secondary">Created at: </RbText>
                                        <span>{t?.createdAt ? formatToEUDate(t.createdAt) : ""}</span>
                                    </span>
                                    </div>
                                </RbBox>
                            </RbLink>
                        )
                    })
                }
            </div>
        </div>
    )
}
