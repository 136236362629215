import React, {useEffect, useState} from "react";
import {IBoardCardComment, IBoardCardDetails} from "../../../types/board-types";
import RbIconTitle from "../../../shared-components/rb-components/RbIconTitle";
import {Avatar, IconButton} from "@mui/material";
import {getUserInitials} from "../../../utils/RbUtils";
import {useSelector} from "react-redux";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {DotsVerticalIcon, XIcon} from "@heroicons/react/outline";
import moment from "moment";
import {addCommentToCard, deleteComment, getCardComments} from "../../../services/boards/boardsService";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import RbConfirmationDialog from "../../../shared-components/dialogs/RbConfirmationDialog";

export default function BoardCardComments(props: { card: IBoardCardDetails | undefined, comments: IBoardCardComment[], refreshComments: () => void }) {
    const {card, comments} = props;
    const user = useSelector((state: any) => state.auth.user).data;
    const theme: RbTheme = useTheme();
    const [commentId, setCommentId] = useState<string>("");
    const [comment, setComment] = useState<string>("");

    function handleKeyPress(e: any) {
        if (!card?.id) return;
        if (e.key === 'Enter' && !!comment) {
            e.preventDefault();
            addCommentToCard(card.id, comment).then(res => {
                if (res?.data?.error) return;
                setComment("");
                props.refreshComments();
            });
        }
    }

    function handleDeleteComment() {
        if (!commentId) return;
        deleteComment(commentId).then(res => {
            if (res?.data?.error) return;
            props.refreshComments();
        });
    }

    return (
        <>
            <RbIconTitle icon="ChatIcon" title="Comments" container="h4" className="mb-10"/>
            <div className="flex justify-between items-center">
                <Avatar src={user?.photoURL}
                        sx={{width: 38, height: 38}}
                        className="mt-10"
                        alt={user?.displayName}>{getUserInitials(user?.displayName)}</Avatar>
                <RbTextField className="w-[88%] mt-10"
                             variant="outlined"
                             size="small"
                             value={comment}
                             label="Comment"
                             onChange={e => setComment(e.target.value)}
                             onKeyDown={handleKeyPress}
                             multiline={true}
                             color="primary"/>
            </div>
            <div>
                {
                    comments.map(comm => {
                        return (
                            <div className="flex justify-between items-start mt-10" key={comm.id}>
                                <Avatar src={comm.avatar}
                                        alt={comm.name}>{getUserInitials(comm.name)}</Avatar>
                                <span className="w-[85%] ml-[1.8rem]">
                                    <span className="block">
                                    <span className="font-bold">{comm.name}</span>
                                        <span className="text-[1rem] ml-5"
                                              style={{color: theme.palette.text.secondary}}>
                                            {moment(comm.date).fromNow()}</span>
                                    </span>
                                    <span>{comm.comment}</span>
                                </span>
                                <span className="basis-[2rem]">
                                {
                                    user.uid === comm.userId &&
                                    <IconButton className="p-5" onClick={() => setCommentId(comm.id)}>
                                        <XIcon color={theme.priority.high} className="w-14"/>
                                    </IconButton>
                                }
                                </span>
                            </div>
                        )
                    })
                }
                <RbConfirmationDialog onClose={() => setCommentId("")} onConfirm={handleDeleteComment}
                                      open={!!commentId} isDelete={true}>
                    Are you sure you want to delete this comment?
                </RbConfirmationDialog>
            </div>
        </>
    )
}
