import RbBox from "../../../shared-components/rb-components/RbBox";
import {IReports} from "../../../types/report-types";
import Chart from 'react-apexcharts'
import {useEffect, useState} from "react";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import moment from "moment";
import {styled} from "@mui/system";
import ReportTrackerTimelineDialog from "./ReportTrackerTimelineDialog";


const RbChart = styled(Chart)(() => ({
    '& svg': {
        backgroundColor: "transparent !important"
    }
}));

export default function ReportTrackerReport(props: { data?: IReports, projectId?: string, workspaceId?: string }) {
    const theme: RbTheme = useTheme();
    const [timelineDate, setTimelineDate] = useState<string>("");
    const [series, setSeries] = useState<any>([]);
    const [options, setOptions] = useState<any>({
        chart: {
            id: "billing-bar",
            events: {
                dataPointSelection: handleChartClick
            },
            stacked: true
        },
        xaxis: {
            categories: (props.data?.trackersByDay?.days ?? []).map(day => moment(day?.date).format("ddd, DD MMM YY"))
        },
        active: {
            allowMultipleDataPointsSelection: false,
        },
        tooltip: {
            y: {
                show: true,
                formatter: function (hours: number) {
                    const date = new Date(0, 0);
                    date.setSeconds(hours * 60 * 60);
                    return date.toTimeString().slice(0, 8);
                }
            },
        },
        dataLabels: {
            enabled: false,
        },
        theme: {mode: theme.palette.mode}
    });

    useEffect(() => {
        const tmpBill: number[] = [];
        const tmpUnBill: number[] = [];

        props.data?.trackersByDay.days.forEach(day => {
            tmpBill.push(day.billableHours);
            tmpUnBill.push(day.unbillableHours);
        });

        setOptions({
            ...options,
            chart: {
                id: "billing-bar",
                events: {
                    dataPointSelection: handleChartClick
                },
                stacked: true
            },
            xaxis: {
                categories: (props.data?.trackersByDay?.days ?? []).map(day => moment(day.date).format("ddd, DD MMM YY"))
            },
        });

        setSeries([
            {
                name: "Billable",
                data: tmpBill,
                color: getBarColor(true)
            },
            {
                name: "Unbillable",
                data: tmpUnBill,
                color: getBarColor(false)
            },
        ]);
    }, [props.data]);

    function handleChartClick(e: any, chart?: any, options?: { dataPointIndex: number, seriesIndex: number }) {
        if (!props?.data || !options?.dataPointIndex) return;
        setTimelineDate(moment(props.data.trackersByDay.days[options.dataPointIndex].date).toISOString(true));
    }

    function getBarColor(billable: boolean): string {
        switch (theme.palette.name) {
            case "midnightBlue":
                return billable ? "#075985" : "#0284c7";
            case "darkNight":
            default:
                return billable ? "#06B6D4" : "#67e8f9";
        }
    }

    return (
        <RbBox className="px-32 py-24">
            <div className="flex justify-between mb-16">
                <h2 className="ml-0 font-bold">Tracker report</h2>
                <div className="flex justify-between w-208">
                    <div className="flex flex-col">
                        <span>Total: {props?.data?.trackersByDay?.totalTime}</span>
                        <span>Billable: {props?.data?.trackersByDay?.billableTime}</span>
                        <span>Unbillable: {props?.data?.trackersByDay?.unbillableTime}</span>
                    </div>
                    {/*<div className="flex flex-col">*/}
                    {/*    <span>Client total: {props?.data?.trackersByDay?.clientTotal}</span>*/}
                    {/*    <span>Project total: {props?.data?.trackersByDay?.projectTotal}</span>*/}
                    {/*</div>*/}
                    <div className="flex flex-col mr-0">
                        <span>Billable</span>
                        <span>{props?.data?.trackersByDay?.billablePercentage}</span>
                    </div>
                </div>
            </div>
            <ReportTrackerTimelineDialog date={timelineDate} projectId={props.projectId} workspaceId={props.workspaceId}
                                         onClose={() => setTimelineDate("")}/>

            <RbChart
                options={options}
                series={series}
                className="bg-transparent"
                type="bar"
                height="400"
            />
        </RbBox>
    )
}
