import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {
    addMemberToBoard,
    createBoardCard,
    createBoardList, deleteBoardCard,
    getBoardDetails, membersToInviteOnBoard, removeMemberFromBoard, renameBoard, updateBoardCardsOrder,
    updateBoardListName, updateBoardListOrder
} from "../../../services/boards/boardsService";
import {IBoard, IBoardCard, IBoardLabel, IBoardMember} from "../../../types/board-types";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {Avatar, AvatarGroup, Menu, MenuItem, Tooltip} from "@mui/material";
import {
    ClipboardCopyIcon,
    ClockIcon,
    DotsVerticalIcon,
    EyeIcon, MinusCircleIcon,
    PlusCircleIcon, PlusIcon,
    TrashIcon,
    UserGroupIcon
} from "@heroicons/react/outline";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {FlagIcon, ViewBoardsIcon} from "@heroicons/react/solid";
import BoardListActions from "./BoardListActions";
import RbContentEditable from "../../../shared-components/rb-components/RbContentEditable";
import BoardCardDetailsDialog from "./BoardCardDetailsDialog";
import {getUniqueItemsByProperty, getUserInitials, reorderListItem} from "../../../utils/RbUtils";
import moment from "moment";
import clsx from "clsx";
import BoardsListView from "./BoardsListView";
import RbMultiSelect from "../../../shared-components/rb-components/RbMultiSelect";
import BoardSettings from "../board-settings/BoardSettings";
import {DragDropContext, Draggable, Droppable, DropResult} from "react-beautiful-dnd";
import {useRbDispatch} from "../../../rb-hooks/hooks";
import {updateRbSearchCallBack} from "../../../store/rubicon/searchSlice";
import {formatToEUDate} from "../../../utils/utils";
import RbConfirmationDialog from "../../../shared-components/dialogs/RbConfirmationDialog";
import jwtService from "../../../services/jwtService/jwtService";

export default function BoardView() {
    const {id} = useParams();
    const theme: RbTheme = useTheme();
    const [board, setBoard] = useState<IBoard>({} as any);
    const [settingsOpen, setSettingsOpen] = useState<boolean>(false);
    const [isTileView, setIsTileView] = useState<boolean>(true);
    const [cardIdDialog, setCardIdDialog] = useState<string>("");
    const [cardIdDetail, setCardIdDetail] = useState<string>("");
    const [anchorElMembers, setAnchorElMembers] = useState<null | SVGSVGElement | HTMLElement>(null);
    const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement | HTMLElement>(null);
    const [membersToInvite, setMembersToInvite] = useState<IBoardMember[]>([]);
    const [filterUserId, setFilterUserId] = useState<string>("")
    const [deleteCardOpen, setDeleteCardOpen] = useState<boolean>(false);
    const [deleteCardId, setDeleteCardId] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const dispatch = useRbDispatch();

    useEffect(() => {
        // @ts-ignore
        dispatch(updateRbSearchCallBack(onSearchChange));
        _getBoardDetails();
        return () => {
            // @ts-ignore
            dispatch(updateRbSearchCallBack(undefined));
        }
    }, []);

    useEffect(() => {
        _membersToInviteOnBoard();
    }, [board.id]);

    function onSearchChange(val: string) {
        setSearch(val.toLowerCase());
    }

    function _getBoardDetails() {
        if (!id) return;
        getBoardDetails(id).then(res => {
            if (res?.data?.error) return;
            setBoard(res.data.data);
        });
    }

    function _createBoardCard(listId: string, name: string) {
        createBoardCard(listId, name).then(res => {
            if (res?.data?.error) return;
            _getBoardDetails();
        });
    }

    function _createBoardList(name: string) {
        createBoardList(board.id, name, board.lists.length).then(res => {
            if (res?.data?.error) return;
            _getBoardDetails();
        });
    }

    function getBoardMember(id: string): IBoardMember | undefined {
        const tmp = board?.members.filter(m => m.id === id);
        if (tmp && tmp?.length > 0) return tmp[0];
        return undefined;
    }

    function getBoardLabel(id: string): IBoardLabel | undefined {
        const tmp = board?.labels.filter(m => m.id === id);
        if (tmp && tmp?.length > 0) return tmp[0];
        return undefined;
    }


    function getPriorityColor(id: number | string | undefined = undefined): string {
        switch (id) {
            case 1:
            case "Low":
                return theme.priority.low;
            case 2:
            case "Medium":
                return theme.priority.medium;
            case 3:
            case "High":
                return theme.priority.high;
            default:
                return theme.palette.text.primary;
        }
    }

    function _updateBoardListName(listId: string, name: string) {
        updateBoardListName(listId, name).then(res => {
            if (res?.data?.error) return;
            _getBoardDetails();
        });
    }

    function getCardFooterIcons(card: IBoardCard, full: boolean = false): JSX.Element {
        return (
            <span className="flex">
                {(full && card.priorityId) &&
                    <Tooltip title="Priority">
                        <FlagIcon
                            style={{color: getPriorityColor(card.priorityId)}}
                            className="w-28 px-5"/>
                    </Tooltip>
                }
                {card?.hasBlocker &&
                    <Tooltip title="Blocking">
                        <span className="px-5" style={{color: "#FCA5A5"}}><MinusCircleIcon className="w-20"/></span>
                    </Tooltip>}
                {card?.hasParent &&
                    <Tooltip title="Parent"><span className="px-5"><UserGroupIcon
                        className="w-20"/></span></Tooltip>
                }
                {card?.dueDate &&
                    <Tooltip title="Due date">
                        <span className="px-5"><ClockIcon className="w-20"/></span>
                    </Tooltip>
                }
            </span>
        )
    }

    function shouldDisplayFooter(card: IBoardCard): boolean {
        return !!card?.priorityId || !!card?.dueDate || !!card?.hasParent || !!card?.hasBlocker;
    }

    function _addMemberToBoard(member: IBoardMember) {
        if (!board?.id || !member?.id) return;
        addMemberToBoard(board.id, member.id).then(res => {
            if (res?.data?.error) return;
            _getBoardDetails();
            _membersToInviteOnBoard();
        });
    }

    function _removeMemberFromBoard(member: IBoardMember) {
        if (!board?.id || !member?.id) return;
        removeMemberFromBoard(board.id, member.id).then(res => {
            if (res?.data?.error) return;
            _getBoardDetails();
            _membersToInviteOnBoard();
        });
    }

    function _membersToInviteOnBoard() {
        if (!board?.id) return;
        membersToInviteOnBoard(board.id).then(res => {
            if (res?.data?.error) return;
            setMembersToInvite(res.data.data);
        })
    }

    function handleBoardRename(name: string) {
        renameBoard(board.id, name).then(res => {
            if (res?.data?.error) return;
            _getBoardDetails();
        });
    }

    function filteredCards(list: { cards: IBoardCard[] }): IBoardCard[] {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        if (!search) list.cards;
        return list.cards.filter(c => c.title.toLowerCase().startsWith(search));
    }

    function handleDragEnd(dropRes: DropResult) {
        if (dropRes.type === "card") {
            const tmp = {...board} as IBoard;
            if (dropRes.source.droppableId === dropRes.destination?.droppableId) {
                if (dropRes.source.index === dropRes.destination.index) return;
                let listInd = board.lists.findIndex(l => l.id === dropRes.source.droppableId);
                tmp.lists[listInd].cards = reorderListItem(board.lists[listInd].cards, dropRes.source.index, dropRes.destination.index);

                const order = tmp.lists[listInd].cards.map((c, i) => {
                    return {cardId: c.id, orderNo: i}
                });
                updateBoardCardsOrder(board.lists[listInd].id, order).then(res => {
                    if (res?.data?.error) return;
                    _getBoardDetails();
                });
            } else {
                let srcId = board.lists.findIndex(l => l.id === dropRes.source.droppableId);
                let destId = board.lists.findIndex(l => l.id === dropRes.destination?.droppableId);
                if (srcId < 0 || destId < 0) return;
                const [card] = tmp.lists[srcId].cards.splice(dropRes.source.index, 1);
                tmp.lists[destId].cards.splice(dropRes.destination?.index ?? 0, 0, card);


                const orderSrc = tmp.lists[srcId].cards.map((c, i) => {
                    return {cardId: c.id, orderNo: i}
                });
                const orderDest = tmp.lists[destId].cards.map((c, i) => {
                    return {cardId: c.id, orderNo: i}
                });

                Promise.all([updateBoardCardsOrder(board.lists[srcId].id, orderSrc),
                    updateBoardCardsOrder(board.lists[destId].id, orderDest)]).then(res => {
                    if (res[0]?.data?.error || res[1]?.data?.error) return;
                    _getBoardDetails();
                });
            }
            setBoard(tmp);
        } else if (dropRes.type === "column") {
            const tmp = reorderListItem(board.lists, dropRes.source.index, dropRes.destination?.index ?? 0);
            // @ts-ignore
            setBoard({...board, lists: tmp});
            const order: any[] = [];
            tmp.forEach((list, index) => {
                order.push({listId: list.id, orderNo: index});
            });
            updateBoardListOrder(order).then();
        }
    }

    function generateDueDateStamp(card: IBoardCard): JSX.Element {
        const due = moment(card.dueDate);
        const safe = due > moment();
        return <span className="py-5 px-10 rounded-full"
                     style={{backgroundColor: safe ? "#DCFCE7" : "#FCA5A5", color: safe ? "#059669" : "#991B1B"}}>
            Due: {formatToEUDate(due.toISOString(true))}
        </span>

    }

    function _deleteBoardCard() {
        if (!deleteCardId) return;
        deleteBoardCard(deleteCardId).then(res => {
            if (res?.data?.error) return;
            _getBoardDetails();
        });
    }

    return (
        <>
            <div className="p-20 flex justify-between">
                <span>
                    <RbRoundButton className="pl-16 pr-16" onClick={() => setIsTileView(true)}
                                   variant={isTileView ? "contained" : "text"}
                                   style={{color: theme.palette.text.primary}}>Tile view</RbRoundButton>
                    <RbRoundButton className="pl-16 pr-16" onClick={() => setIsTileView(false)}
                                   style={{color: theme.palette.text.primary}}
                                   variant={isTileView ? "text" : "contained"}>List view</RbRoundButton>
                </span>
                <div>
                    <Link to="/boards">
                        <RbRoundButton className="border-1 border-solid pb-0 pt-0" onClick={() => null}
                                       style={{
                                           color: theme.palette.text.primary,
                                           borderColor: theme.palette.text.primary
                                       }}><ViewBoardsIcon
                            className="inline-block align-middle w-16 mr-5"/>
                            <span className="inline-block align-middle">Boards</span></RbRoundButton>
                    </Link>

                    <RbRoundButton className="ml-10" onClick={() => setSettingsOpen(true)}
                                   style={{color: theme.palette.text.primary}}>Settings</RbRoundButton>

                </div>
            </div>
            <RbBox className="rounded-none pl-20 pr-20 h-64 flex justify-between items-center" theme="primary">
                <AvatarGroup max={12}>
                    <Tooltip title="Manage members">
                        <Avatar
                            sx={{width: 32, height: 32}}
                            key="member-add"
                            className="text-16 cursor-pointer"
                            onClick={e => setAnchorElMembers(e.currentTarget)}
                            alt="Add member"><PlusIcon className="w-16"/></Avatar>
                    </Tooltip>
                    {
                        (board?.members ?? []).map(user => {
                            return <Tooltip title={user.name} key={user.id}>
                                <Avatar
                                    sx={{width: 32, height: 32}}
                                    className="text-14 cursor-pointer transition-colors outline-4"
                                    style={{
                                        outlineColor: user.id === filterUserId ? theme.palette.special : "transparent",
                                        outlineStyle: "solid",
                                    }}
                                    src={user?.avatar ?? ""}
                                    onClick={() => {
                                        if (user.id === filterUserId) {
                                            setFilterUserId("");
                                        } else {
                                            setFilterUserId(user.id);
                                        }
                                    }}
                                    alt={user.name}>{getUserInitials(user?.name)}</Avatar>
                            </Tooltip>
                        })
                    }
                </AvatarGroup>
                <RbContentEditable defaultValue={board?.name} onConfirm={handleBoardRename}
                                   className="w-320 text-center">
                    <h3>{board?.name}</h3>
                </RbContentEditable>
                <RbRoundButton className="pl-16 pr-16" onClick={() => null}
                               style={{color: theme.palette.text.primary}}
                               variant="text">Filter</RbRoundButton>
                <RbMultiSelect onClose={() => {
                    setAnchorElMembers(null);
                }} anchorEl={anchorElMembers}
                               items={getUniqueItemsByProperty([...board?.members ?? [], ...membersToInvite], "id")}
                               renderItem={item => {
                                   return <div className="flex items-center my-5">
                                       <Avatar sx={{width: 24, height: 24}} className="text-12 mr-10"
                                               src={item?.avatar ?? ""}>{getUserInitials(item?.name)}</Avatar>
                                       <span>{item.name}</span>
                                   </div>
                               }}
                               searchPlaceholder="Search for members"
                               checkedItems={board?.members}
                               onItemAdd={_addMemberToBoard}
                               onItemRemove={_removeMemberFromBoard}
                />
            </RbBox>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="all-columns" type="column" direction="horizontal">
                    {
                        (providedColumnDrop) => (
                            <div
                                className="p-10 whitespace-nowrap overflow-auto h-[75vh]" {...providedColumnDrop.droppableProps}
                                ref={providedColumnDrop.innerRef}>
                                {
                                    isTileView ?
                                        (board?.lists ?? []).map((list, index) => {
                                            return (
                                                <Draggable draggableId={list.id} index={index} key={list.id}>
                                                    {
                                                        (providedColumnDrag) => (
                                                            <div
                                                                className="w-fit inline-block align-top"
                                                                ref={providedColumnDrag.innerRef} {...providedColumnDrag.draggableProps}>
                                                                <Droppable droppableId={list.id} key={list.id}
                                                                           type="card">
                                                                    {
                                                                        (providedDrop) => (
                                                                            <RbBox theme="secondary"
                                                                                   {...providedDrop.droppableProps}
                                                                                   innerRef={providedDrop.innerRef}
                                                                                   className={"m-10 p-0 cursor-pointer h-fit max-h-full overflow-auto rounded-6 w-320 inline-block align-top"}>
                                                                                <div
                                                                                    {...providedColumnDrag.dragHandleProps}
                                                                                    className="flex justify-between items-center h-36"
                                                                                    style={{backgroundColor: list?.color}}>
                                                                                    <RbContentEditable
                                                                                        defaultValue={list?.name}
                                                                                        className="ml-10"
                                                                                        childClassName="w-full"
                                                                                        onConfirm={value => _updateBoardListName(list.id, value)}>
                                                                                        <h5 className="font-600 ml-20 w-full my-5">{`${list?.name} (${list?.cards?.length ?? 0})`}</h5>
                                                                                    </RbContentEditable>
                                                                                    <BoardListActions list={list}
                                                                                                      refreshBoards={_getBoardDetails}/>
                                                                                </div>
                                                                                <div
                                                                                    className="w-full mt-10 pl-10 pr-10">
                                                                                    <RbContentEditable
                                                                                        className="h-[4.1rem] pl-10"
                                                                                        childClassName="w-full"
                                                                                        style={{backgroundColor: theme.palette.primary.main}}
                                                                                        onConfirm={value => _createBoardCard(list.id, value)}>
                                                                                        <button
                                                                                            style={{backgroundColor: theme.palette.primary.main}}
                                                                                            className="rounded-6 p-10 w-full">
                                                                                            <PlusCircleIcon
                                                                                                className="mr-10 inline-block align-middle w-20"/>
                                                                                            <span
                                                                                                className="inline-block align-middle">Add a card</span>
                                                                                        </button>
                                                                                    </RbContentEditable>
                                                                                </div>
                                                                                <div
                                                                                    className="px-12 overflow-auto empty:mt-10">
                                                                                    {
                                                                                        (filterUserId ? filteredCards(list).filter(c => c.members.some(u => u === filterUserId)) : filteredCards(list)).map((card, index) => {
                                                                                            return (
                                                                                                <Draggable
                                                                                                    draggableId={card.id}
                                                                                                    index={index}
                                                                                                    key={card.id}>
                                                                                                    {
                                                                                                        (providedDrag) => (
                                                                                                            <div
                                                                                                                ref={providedDrag.innerRef}
                                                                                                                {...providedDrag.draggableProps}
                                                                                                                {...providedDrag.dragHandleProps}
                                                                                                            >
                                                                                                                <RbBox
                                                                                                                    key={card.id}
                                                                                                                    theme="primary"
                                                                                                                    className={clsx("min-h-64 mt-10 mb-10", card.statusId === 1 ? "task-done" : "")}
                                                                                                                    onClick={() => setCardIdDialog(card.id)}>
                                                                                                                    <div
                                                                                                                        className="flex justify-between mb-10">
                                                                                                                        <span>#{card.number}</span>
                                                                                                                        <span>{moment(card?.dateInsert).fromNow()}</span>
                                                                                                                        <DotsVerticalIcon
                                                                                                                            onClick={e => {
                                                                                                                                e.preventDefault();
                                                                                                                                e.stopPropagation();
                                                                                                                                setCardIdDetail(card.id);
                                                                                                                                setAnchorEl(e.currentTarget);
                                                                                                                                setDeleteCardId(card.id);
                                                                                                                            }}
                                                                                                                            className="w-16 cursor-pointer"/>
                                                                                                                    </div>
                                                                                                                    <div>
                                                                                                                        <Tooltip
                                                                                                                            title={card.title ?? ""}>

                                                                                                                            <p className="overflow-hidden text-ellipsis font-bold">{card.title}</p>
                                                                                                                        </Tooltip>
                                                                                                                        <div
                                                                                                                            className="mt-12 whitespace-normal">
                                                                                                                            {
                                                                                                                                card.labels.map(label => {
                                                                                                                                    return (
                                                                                                                                        <span
                                                                                                                                            key={label}
                                                                                                                                            style={{backgroundColor: getBoardLabel(label)?.color ?? ""}}
                                                                                                                                            className="inline-block align-middle my-5 mr-5 rounded-full p-5 pl-16 pr-16">{getBoardLabel(label)?.name ?? "unknown"}</span>
                                                                                                                                    )
                                                                                                                                })
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        <div
                                                                                                                            className="flex mt-7 justify-between items-center">
                                                                                                                            <AvatarGroup
                                                                                                                                max={6}>
                                                                                                                                {
                                                                                                                                    (card?.members ?? []).map(user => {
                                                                                                                                        return (
                                                                                                                                            <Avatar
                                                                                                                                                sx={{
                                                                                                                                                    width: 28,
                                                                                                                                                    height: 28
                                                                                                                                                }}
                                                                                                                                                key={user}
                                                                                                                                                className="text-14"
                                                                                                                                                src={getBoardMember(user)?.avatar ?? ""}
                                                                                                                                                alt={user}>{getUserInitials(getBoardMember(user)?.name)}</Avatar>
                                                                                                                                        )
                                                                                                                                    })
                                                                                                                                }
                                                                                                                            </AvatarGroup>

                                                                                                                            {
                                                                                                                                !!card?.dueDate && generateDueDateStamp(card)
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                        {
                                                                                                                            shouldDisplayFooter(card) && <>
                                                                                                                                <hr className="mt-10 mb-10"/>
                                                                                                                                <div
                                                                                                                                    className="flex justify-between">
                                                                                    <span>{card?.priorityId &&
                                                                                        <Tooltip title="Priority">
                                                                                            <FlagIcon
                                                                                                style={{color: getPriorityColor(card.priorityId)}}
                                                                                                className="w-20"/>
                                                                                        </Tooltip>}</span>
                                                                                                                                    {getCardFooterIcons(card)}
                                                                                                                                </div>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </RbBox>
                                                                                                            </div>

                                                                                                        )
                                                                                                    }
                                                                                                </Draggable>

                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                {providedDrop.placeholder}
                                                                            </RbBox>
                                                                        )
                                                                    }
                                                                </Droppable>
                                                            </div>
                                                        )
                                                    }
                                                </Draggable>
                                            )
                                        }) :
                                        <div className="m-10">
                                            <BoardsListView board={board} getBoardMember={getBoardMember}
                                                            getBoardDetails={_getBoardDetails}
                                                            filterUserId={filterUserId}
                                                            createBoardCard={_createBoardCard} setAnchorEl={setAnchorEl}
                                                            setCardIdDetail={setCardIdDetail}
                                                            setCardIdDialog={setCardIdDialog}
                                                            getBoardLabel={getBoardLabel}
                                                            getCardFooterIcons={getCardFooterIcons}/>
                                        </div>
                                }
                                <div key="add-another-list"
                                     className={clsx(isTileView ? "m-10 w-320 inline-block align-top" : "w-full p-10")}>
                                    <RbContentEditable className="h-[4.1rem] pl-10" childClassName="w-full"
                                                       style={{backgroundColor: theme.palette.primary.main}}
                                                       onConfirm={value => _createBoardList(value)}>
                                        <button style={{backgroundColor: theme.palette.primary.main}}
                                                className="rounded-6 p-10 w-full"><PlusCircleIcon
                                            className="mr-10 inline-block align-middle w-20"/>
                                            <span className="inline-block align-middle">Add another list</span></button>
                                    </RbContentEditable>
                                </div>
                                {providedColumnDrop.placeholder}
                            </div>
                        )
                    }
                </Droppable>

            </DragDropContext>
            <Menu
                id="card-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                    setCardIdDetail("");
                    setDeleteCardId("");
                }}
            >
                <MenuItem className="flex justify-between"
                          onClick={() => {
                              navigator.clipboard.writeText(`${window.location.origin}/board/card/details/${board.id}/${cardIdDetail}`).then(() => {
                                  setAnchorEl(null);
                                  jwtService.emit("success", "Link copied!");
                              })
                          }}>
                    <span className="inline-block align-middle">Copy card link</span>
                    <span className="inline-block align-middle"><ClipboardCopyIcon
                        className="w-20 ml-10"/></span>
                </MenuItem>
                <MenuItem className="flex justify-between" onClick={() => null}>
                    <Link to={`/board/card/details/${board.id}/${cardIdDetail}`}
                          className="border-none"
                          style={{color: theme.palette.text.primary}}>
                        <span className="inline-block align-middle"> Detail view</span>
                    </Link>
                    <span className="inline-block align-middle"><EyeIcon
                        className="w-20 ml-10"/></span>
                </MenuItem>
                <MenuItem style={{color: "firebrick"}} className="flex justify-between"
                          onClick={() => {
                              setAnchorEl(null);
                              setDeleteCardOpen(true);
                          }}>
                    <span className="inline-block align-middle">Delete card</span>
                    <span className="inline-block align-middle"><TrashIcon
                        className="w-20 ml-10"/></span>
                </MenuItem>
            </Menu>
            <BoardCardDetailsDialog onClose={() => setCardIdDialog("")} cardId={cardIdDialog} labels={board.labels}
                                    open={!!cardIdDialog} refreshBoard={_getBoardDetails}
                                    cards={(board?.lists ?? [])?.map(l => l?.cards ?? []).reduce((prev, curr) => prev.concat(curr ?? []), [])}/>
            <BoardSettings settingsOpen={settingsOpen} onClose={() => setSettingsOpen(false)}
                           handleBoardRename={handleBoardRename} board={board} refreshBoard={_getBoardDetails}/>
            <RbConfirmationDialog onClose={() => {
                setDeleteCardOpen(false);
                setDeleteCardId("");
            }} onConfirm={_deleteBoardCard} open={deleteCardOpen} isDelete={true}>
                Are you sure you want to delete this card?
            </RbConfirmationDialog>
        </>
    )
}
