import RbBox from "../../../shared-components/rb-components/RbBox";
import {IconButton} from "@mui/material";
import {DotsVerticalIcon} from "@heroicons/react/outline";
import React, {useState} from "react";

export default function ProjectOverviewCard(props: { className?: string, value: number | string | undefined, title: string, subTitle: string, subValue: number | string | undefined }) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    return (
        <RbBox className={`py-20 px-20 w-fit mr-10 mb-10 inline-block align-middle ${props?.className ?? ""}`}>
            <div className="flex justify-between">
                <h2>{props.title ?? ""}</h2>
                <IconButton onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setAnchorEl(e.currentTarget);
                }} className="p-5">
                    <DotsVerticalIcon className="w-16"/>
                </IconButton>
            </div>
            <span
                className={`flex justify-center font-bold items-center ${props?.className === undefined ? "w-192 h-136 " : ""}`}>
                    <span className="text-[7.2rem] font-bold">{props?.value ?? 0}</span>
                </span>
            <span className="block text-center w-full">{props?.subTitle ?? ""}: {props?.subValue ?? 0}</span>
        </RbBox>
    )
}
