import RbBox from "../../../shared-components/rb-components/RbBox";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {IRbDisplayHeader, RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {IReportTeamUserWorkload} from "../../../types/report-types";

export default function ReportTeamsWorkload(props: { data?: IReportTeamUserWorkload[] }) {
    const theme: RbTheme = useTheme();


    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "Name",
            objAttribute: "name"
        },
        {
            name: "Boards",
            objAttribute: "boards"
        },
        {
            name: "Assign tasks",
            objAttribute: "assignTasks"
        },
        {
            name: "Marked as done",
            objAttribute: "markedAsDone"
        },
        {
            name: "Overdue",
            objAttribute: "overdue"
        },
        {
            name: "Total time",
            objAttribute: "time"
        },
    ];

    return (
        <RbBox className="p-0 mb-20">
            <div className="py-12"
                 style={{borderBottom: `3px solid ${theme.palette.background.paper}`}}>
                <h2 className="px-20 font-bold flex items-center justify-between">User workload</h2>
            </div>
            <RbDataTable dataSource={props.data ?? []} headerColumns={displayHeaders}/>
        </RbBox>
    )
}
