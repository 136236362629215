import {RbTextField} from "app/shared-components/rb-components/RbTextField";
import React, {useEffect, useState} from "react";
import {
    toggleUserNotificationSetting,
    getUserNotificationSettings,
    getWorkingDays, toggleWorkDaySetting
} from "../../../services/user-settings/userSettingsService";
import {INotificationSetting, NotificationType, IWorkDay} from "../../../types/user-settings-types";
import {RbCheckBoxIcon} from "app/shared-components/rb-components/RbCheckBoxIcon";
import {CalendarIcon} from "@heroicons/react/outline";
import RbWorkDays from "../../../shared-components/rb-components/RbWorkDays";
import HeroIcon from "../../../../@fuse/core/HeroIcon/HeroIcon";
import {UserOrganization} from "../../../types/common-types";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";

function NotificationUserSettings(props: { organization: UserOrganization }) {
    const [workDays, setWorkDays] = useState<IWorkDay[]>([]);
    const [notifSettings, setNotifSettings] = useState<INotificationSetting[]>([]);

    useEffect(() => {
        _getUserNotificationSettings();
    }, []);

    useEffect(() => {
        _getWorkingDays();
    }, [props?.organization?.id]);

    function _getWorkingDays() {
        if (!props?.organization?.id) return;
        getWorkingDays(props.organization.id).then(res => {
            if (res && res?.data?.data) {
                setWorkDays(res?.data?.data);
            }
        })
    }

    function _toggleWorkDaySetting(workDayId: number) {
        if (!props?.organization?.id) return;
        toggleWorkDaySetting(props.organization.id, workDayId).then(res => {
            if (res?.data?.error) return;
            _getWorkingDays();
        })
    }

    function _getUserNotificationSettings() {
        getUserNotificationSettings().then(res => {
            if (res?.data?.data) {
                setNotifSettings(res?.data?.data);
            }
        })
    }


    function handleNotifChange(notifId: number, type: NotificationType) {
        toggleUserNotificationSetting(notifId, type).then(res => {
            // TODO: Show snackbar success message
            if (res?.data?.error) {
                _getUserNotificationSettings();
            }
        })
    }

    return (
        <div className="mt-48 md:ml-28 sm:ml-28 sm:mr-28 ml-64 mb-48">
            <div className="out-of-office mb-26">
                <h2 className="font-bold">Out of office</h2>
                <p>Have some vacation days or just want to disable notification for a period of time</p>
                <RbTextField className="max-w-288 mt-16 pointer-events-none" id="username"
                             variant="outlined"
                             placeholder="Net set, go to calendar"
                             InputProps={{
                                 startAdornment: (
                                     <CalendarIcon></CalendarIcon>
                                 ),
                                 readOnly: true
                             }}
                />
            </div>
            <RbWorkDays readonly={false} workDays={workDays} toggleWorkDaySetting={_toggleWorkDaySetting}/>
            <div className="mt-32 max-w-xl">
                <div className="mt-32">
                    <div className="mt-10">
                        <div className="flex mb-16">
                            <div className="basis-2/4 leading-[3rem]">
                                <h3 className="mt-32">Mute all notifications</h3>
                            </div>
                            <div className="basis-1/4 text-center">
                                <h3 className="mb-10">E-mail</h3>
                                <RbCheckBoxIcon className="w-28"
                                                icon={<HeroIcon icon="CheckCircleIcon" outline={true}/>}
                                                checkedIcon={<span><HeroIcon icon="CheckCircleIcon"
                                                                             outline={false}/></span>}/>
                            </div>

                            <div className="basis-1/4 text-center">
                                <h3 className="mb-10">In app</h3>
                                <RbCheckBoxIcon className="w-28"
                                                icon={<HeroIcon icon="CheckCircleIcon" outline={true}/>}
                                                checkedIcon={<HeroIcon icon="CheckCircleIcon"
                                                                       outline={false}/>}/>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    notifSettings.map(setting => {
                        return (
                            <div className="mt-32" key={`setting-${setting.label}`}>
                                <h2 className="font-bold">{setting.label}</h2>
                                <hr/>
                                <div className="mt-10">
                                    {setting.notifications.map(notif => {
                                        return (
                                            <div className="flex mb-16" key={`check-${notif.id}`}>
                                                <h3 className="basis-2/4 leading-[3rem]">{notif.name}</h3>
                                                <div className="basis-1/4 text-center">
                                                    <RbCheckBoxIcon
                                                        className="w-28"
                                                        // @ts-ignore
                                                        onChange={() => handleNotifChange(notif.id, "email")}
                                                        defaultChecked={notif.email}
                                                        icon={<HeroIcon icon="CheckCircleIcon" outline={true}/>}
                                                        checkedIcon={<HeroIcon icon="CheckCircleIcon"
                                                                               outline={false}/>}/>
                                                </div>

                                                <div className="basis-1/4 text-center">
                                                    <RbCheckBoxIcon
                                                        className="w-28"
                                                        // @ts-ignore
                                                        onChange={() => handleNotifChange(notif.id, "inapp")}
                                                        defaultChecked={notif.inApp}
                                                        icon={<HeroIcon icon="CheckCircleIcon" outline={true}/>}
                                                        checkedIcon={<HeroIcon icon="CheckCircleIcon"
                                                                               outline={false}/>}/>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(NotificationUserSettings));

