import Boards from "./Boards";
import BoardView from "./board-details/BoardView";
import BoardCardDetailsPreview from "./board-details/BoardCardDetailsPreview";

const BoardsConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'boards',
            element: <Boards/>,
        },
        {
            path: 'board-view/:id',
            element: <BoardView/>,
        },
        {
            path: 'board/card/details/:boardId/:id',
            element: <BoardCardDetailsPreview/>,
        },
    ],
};

export default BoardsConfig;
