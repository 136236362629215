import {RbToggleButton} from "./RbToggleButton";
import React from "react";
import {IWorkDay} from "../../types/user-settings-types";
import clsx from "clsx";
import {RbSubSection} from "./RbSections";

function RbWorkDays(props: { readonly: boolean, workDays: IWorkDay[], toggleWorkDaySetting: (workDayId: number) => void }) {
    function toggleWorkDay(workDay: IWorkDay) {
        if (!String(workDay?.id) || props.readonly) return;
        props.toggleWorkDaySetting(workDay.id);
    }

    return (
        <RbSubSection>
            <h2 className="mb-16 font-bold">Working days</h2>
            {
                props.workDays.map(day => {
                    return <RbToggleButton
                        className={clsx("w-64 h-64 mt-10", props.readonly ? "pointer-events-none" : "")}
                        key={day.id}
                        value={day.id}
                        onChange={() => toggleWorkDay(day)}
                        color="primary"
                        style={{color: "inherit"}}
                        selected={day.workDay}
                    >
                        <span className="font-bold text-[1.6rem]">{day.day}</span>
                    </RbToggleButton>
                })
            }
        </RbSubSection>
    );
}

export default RbWorkDays;
