import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface SearchState {
    value: string;
    searchCallBack?: (val: string) => void;
}

const initialState: SearchState = {
    value: "",
}

const searchSlice = createSlice({
    name: "search", initialState, reducers: {
        updateRbSearch(state, action: PayloadAction<string>) {
            state.value = action.payload;
            if (state.searchCallBack) {
                state.searchCallBack(action.payload);
            }
        },
        updateRbSearchCallBack(state, action: PayloadAction) {
            state.searchCallBack = action.payload as any;
        }
    }
});


export const {updateRbSearch, updateRbSearchCallBack} = searchSlice.actions;
export default searchSlice.reducer;
