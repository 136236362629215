import {ITodo} from "../../../../types/todo-types";
import {PaperClipIcon, PlusIcon, TrashIcon} from "@heroicons/react/outline";
import {IconButton} from "@mui/material";
import React from "react";

export default function TodoAttachments(props: { todo: ITodo | undefined }) {
    const {todo} = props;
    return (
        <>
            <div className="flex justify-between items-center mb-20">
                <h4 className="w-full"><PaperClipIcon className="w-20 mr-10 inline-block align-middle"/>
                    <span className="inline-block align-middle">Attachment</span></h4>
                <IconButton onClick={() => console.log("open attachments")}>
                    <PlusIcon className="w-20"/>
                </IconButton>
            </div>
            <div className="whitespace-nowrap overflow-y-auto">
                {/*<div className="w-1/2 p-10 inline-block align-top">*/}
                {/*    <img className="rounded-12 block w-full" src="/assets/images/avatars/Blair.jpg" alt="Desc"/>*/}
                {/*    <div className="flex justify-between items-center mt-5">*/}
                {/*        <span>test.jpg</span>*/}
                {/*        <TrashIcon className="w-20 transition-colors hover:text-red-600 cursor-pointer"/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="w-1/2 p-10 inline-block align-top">*/}
                {/*    <img className="rounded-12 block w-full" src="/assets/images/avatars/alice.jpg" alt="Desc"/>*/}
                {/*    <div className="flex justify-between items-center mt-5">*/}
                {/*        <span>test.jpg</span>*/}
                {/*        <TrashIcon className="w-20 transition-colors hover:text-red-600 cursor-pointer"/>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<div className="w-1/2 p-10 inline-block align-top">*/}
                {/*    <img className="rounded-12 block w-full" src="/assets/images/avatars/Barrera.jpg" alt="Desc"/>*/}
                {/*    <div className="flex justify-between items-center mt-5">*/}
                {/*        <span>test.jpg</span>*/}
                {/*        <TrashIcon className="w-20 transition-colors hover:text-red-600 cursor-pointer"/>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </>
    )
}
