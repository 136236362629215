import React from 'react';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {CurrencyDollarIcon} from "@heroicons/react/outline";
import {getTimeFromDate} from "../../../../../utils/utils";
import FormControl from "@mui/material/FormControl";

const TrackerRowMobile = (props: any) => {
    const {tracker, tableCellTextColor, tableRowBorderBottom} = props;
    return (
        <>
            {/*First part*/}
            <TableRow className={'hidden sm:table-row  font-bold'}>
                <TableCell
                    className={"font-bold"}
                    style={{color: tableCellTextColor}}
                    colSpan={2}>
                    Project/card
                </TableCell>
                <TableCell
                    className={"font-bold"}
                    style={{color: tableCellTextColor}}
                    colSpan={2}>
                    Category
                </TableCell>
                <TableCell
                    className={"font-bold"}
                    style={{color: tableCellTextColor}}
                >
                    Billable
                </TableCell>
            </TableRow>

            <TableRow
                sx={{'& > *': {borderBottom: 'unset text-sm'}}}
                className={'hidden sm:table-row text-sm'}
            >
                <TableCell colSpan={2}
                >{
                    tracker?.projectName} {tracker?.cardName}
                </TableCell>
                <TableCell colSpan={2}>
                    {tracker?.labelName}
                </TableCell>
                <TableCell align={"center"} className={"text-center"}>
                    <FormControl>
                        <CurrencyDollarIcon height={24} className={tracker?.billable ? "" : "opacity-30"}/>
                    </FormControl>
                </TableCell>
            </TableRow>
            {/*First part*/}

            {/*Second part*/}
            <TableRow className={'hidden sm:table-row text-sm'}>
                <TableCell
                    className={"font-bold"}
                    style={{color: tableCellTextColor}}
                    colSpan={2}>
                    Start time
                </TableCell>
                <TableCell
                    className={"font-bold"}
                    style={{color: tableCellTextColor}}
                    colSpan={2}>
                    End time
                </TableCell>
                <TableCell
                    className={"font-bold"}
                    style={{color: tableCellTextColor}}
                >
                    Duration
                </TableCell>
            </TableRow>

            <TableRow
                sx={{'& > *': {borderBottom: tableRowBorderBottom}}}
                className={'hidden sm:table-row text-sm'}
            >
                <TableCell
                    colSpan={2}
                >
                    {getTimeFromDate(tracker?.startDate)}
                </TableCell>
                <TableCell
                    colSpan={2}
                >
                    {getTimeFromDate(tracker?.endDate)}
                </TableCell>
                <TableCell
                >
                    {tracker?.duration}
                </TableCell>
            </TableRow>
            {/*Second part*/}
        </>
    );
};

export default TrackerRowMobile;
