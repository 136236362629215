import {ISystemTickets} from "../../../types/tickets-types";
import {Drawer, IconButton} from "@mui/material";
import React from "react";
import {RbSubSectionItem} from "../../../shared-components/rb-components/RbSections";
import {ArchiveIcon, PencilIcon, TemplateIcon, XIcon} from "@heroicons/react/outline";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";
import RbContentEditable from "../../../shared-components/rb-components/RbContentEditable";
import {archiveTicketSystem, editTicketSystemName} from "../../../services/ticketsService";

export default function TicketSystemSettings(props: { ticketSystem: ISystemTickets | undefined, updateTicketSettings: () => void, open: boolean, onClose: () => void, openCategories?: () => void, systemRename: (name: string) => void }) {
    function handleSystemRename(name: string) {
        if (!name || name === props?.ticketSystem?.name || !props?.ticketSystem?.id) return;
        editTicketSystemName(props.ticketSystem.id, name).then(res => {
            if (res?.data?.error) return;
            if (props?.systemRename) {
                props.systemRename(name);
            }
        })
    }

    function _archiveTicketSystem() {
        if (!props?.ticketSystem?.id) return;
        archiveTicketSystem(props.ticketSystem.id, !props.ticketSystem.active).then(res => {
            if (res?.data?.error) return;
            props.updateTicketSettings();
        });
    }

    return (
        <Drawer
            anchor="right"
            open={props?.open ?? false}
            onClose={props?.onClose}>
            <div className="p-20 w-320">
                <div className="flex justify-between items-center">
                    <h2>Settings</h2>
                    <IconButton onClick={props.onClose}>
                        <XIcon className="w-20"/>
                    </IconButton>
                </div>

                <hr/>

                <RbSubSectionItem className="flex flex-col">
                    <RbRoundButton color="primary" variant="text" onClick={props?.openCategories}>
                        <span className="flex items-center">
                         <TemplateIcon className="w-20 mr-8"/>
                        <span>Categories</span>
                        </span>
                    </RbRoundButton>
                    <span className="flex items-center py-8 px-6">
                         <PencilIcon className="w-20 mr-8"/>
                        <RbContentEditable onConfirm={handleSystemRename}
                                           defaultValue={props?.ticketSystem?.name ?? ""}>
                            <span className="cursor-pointer">Rename ticket system</span>
                        </RbContentEditable>
                    </span>
                    <RbRoundButton color="primary" variant="text" onClick={_archiveTicketSystem}>
                        <span className="flex items-center">
                         <ArchiveIcon className="w-20 mr-8"/>
                        <span>{props.ticketSystem?.active === true ? "Archive" : "Activate"} ticket system</span>
                        </span>
                    </RbRoundButton>
                </RbSubSectionItem>
            </div>
        </Drawer>
    )
}
