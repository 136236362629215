import FuseDialog from '@fuse/core/FuseDialog';
import {styled} from '@mui/material/styles';
import FuseMessage from '@fuse/core/FuseMessage';
import FuseSuspense from '@fuse/core/FuseSuspense';
import AppContext from 'app/AppContext';
import {useContext, useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {useNavigate, useRoutes} from 'react-router-dom';
import FooterLayout1 from './components/FooterLayout1';
import LeftSideLayout1 from './components/LeftSideLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import ToolbarLayout1 from './components/ToolbarLayout1';
import withRouter from "../../../@fuse/core/withRouter";
import HeroIcon from "../../../@fuse/core/HeroIcon/HeroIcon";
import {useTheme} from "@mui/styles";
import jwtService from "../../services/jwtService";
import userManager from "../../services/authService";
import FuseSplashScreen from "../../../@fuse/core/FuseSplashScreen";
import {clearSSUserData, getSSUserData, saveSSUserData, StorageDataType} from "../../services/storageBus";

const Root = styled('div')(({theme, config}) => ({
    backgroundColor: theme.palette.background.paper, ...(config.mode === 'boxed' && {
        clipPath: 'inset(0)',
        maxWidth: `${config.containerWidth}px`,
        margin: '0 auto',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    }), ...(config.mode === 'container' && {
        '& .container': {
            maxWidth: `${config.containerWidth}px`, width: '100%', margin: '0 auto',
        },
    }),
}));

function Layout1(props) {
    const config = useSelector(({fuse}) => fuse.settings.current.layout.config);
    const appContext = useContext(AppContext);
    const [shouldAuth, setShouldAuth] = useState(true);
    const {routes} = appContext;
    const navigate = useNavigate();


    useEffect(() => {
        if (window.location.pathname.includes("/ticket/manage/")) {
            setShouldAuth(false);
            return;
        }

        if (props?.user?.data) {
            const path = getSSUserData(StorageDataType.ReturnUrl);
            if (path && path !== "/signin-oidc" && path !== "dashboard" && window.location.pathname !== path) {
                navigate(path);
                clearSSUserData(StorageDataType.ReturnUrl);
            }
        }

        const tmp = setTimeout(() => {
            if (!props?.user?.data && shouldAuth) {
                if (window.location.pathname !== "/signin-oidc") {
                    saveSSUserData(StorageDataType.ReturnUrl, window.location.pathname);
                }
                jwtService.logout();
                userManager.signinRedirect();
            }
        }, 5000);
        return () => {
            clearTimeout(tmp);
        }
    }, [props?.user?.data]);

    function getMessageThemeBGColor(theme) {
        switch (theme ?? "") {
            case "error":
                return theme?.priority?.high ?? "#EF4444";
            case "info":
                return theme?.priority?.low ?? "#059669";
            default:
                return theme?.priority?.medium ?? "#FCD34D";
        }
    }

    return (<>
        {(!props?.user?.data && shouldAuth) && <FuseSplashScreen/>}
        {!!props?.appInfo?.message ? <div className="flex items-center px-32 py-5 w-full"
                                          style={{
                                              backgroundColor: getMessageThemeBGColor(props.appInfo.theme),
                                              color: props.appInfo.theme === "warn" ? "#18181B" : "whitesmoke"
                                          }}>
            <span className="flex items-center">
                <span className="w-16 mr-10"><HeroIcon outline={true}
                                                       icon={props.appInfo.icon ?? "ShieldExclamationIcon"}/></span>
                <span className="font-bold whitespace-nowrap">{props?.appInfo?.title ?? "idk"}</span>
            </span>

            <span className="w-full text-center">{props?.appInfo?.message ?? "idk"}</span>
        </div> : <></>}
        <Root id="fuse-layout" config={config} className="w-full flex">
            {config.leftSidePanel.display && <LeftSideLayout1/>}

            <div className="flex flex-auto min-w-0">
                {config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1/>}

                <main id="fuse-main" className="flex flex-col flex-auto min-h-screen min-w-0 relative z-10">
                    {config.toolbar.display && (
                        <ToolbarLayout1 className={config.toolbar.style === 'fixed' && 'sticky top-0'}/>)}

                    <div className="flex flex-col flex-auto min-h-0 relative z-10">
                        <FuseDialog/>

                        <FuseSuspense>{useRoutes(routes)}</FuseSuspense>

                        {props.children}
                    </div>

                    {config.footer.display && (
                        <FooterLayout1 className={config.footer.style === 'fixed' && 'sticky bottom-0'}/>)}
                </main>

                {config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1/>}
            </div>

            {config.rightSidePanel.display && <RightSideLayout1/>}
            <FuseMessage/>
        </Root>
    </>);
}

// @ts-ignore
function mapStateToProps({fuse, auth}) {
    return {
        appInfo: fuse.appInfo.value, user: auth?.user
    }
}

export default withRouter(connect(mapStateToProps)(Layout1));
