import WidgetBase, {WidgetWidth} from "./WidgetBase";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {useEffect, useState} from "react";
import RbWidgetDatePicker from "../../../shared-components/rb-components/RbWidgets/RbWidgetDatePicker";
import {RbTableSelect} from "app/shared-components/rb-components/RbDataTable/RbTableSelect";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import {getOrganizationTrackersForUser} from "../../../services/dashboard/dashboardService";
import {getOrganizationUsers} from "../../../services/organizationSettingsService";
import {IOrganizationUser} from "../../../types/organization-settings-types";
import {IRbDisplayHeader, UserOrganization} from "../../../types/common-types";
import {ITrackerActivity} from "../../../types/dashboard-types";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";
import moment from "moment";
import {RbLink} from "../../../shared-components/rb-components/RbLink";

function WidgetTrackerActivity(props: { organization: UserOrganization, id: number, defaultWidth: WidgetWidth }) {
    const [trackers, setTrackers] = useState<ITrackerActivity[]>([]);
    const [selectedUserId, setSelectedUserId] = useState<string>("none");
    const [dateTo, setDateTo] = useState<string>("");
    const [dateFrom, setDateFrom] = useState<string>("");
    const [organizationUsers, setOrganizationUsers] = useState<IOrganizationUser[]>([]);

    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "Name",
            objAttribute: "user"
        },
        {
            name: "Project",
            objAttribute: "project"
        },
        {
            name: "Board",
            objAttribute: "board",
            handleRendering: renderBoardLink
        },
        {
            name: "Card",
            objAttribute: "card",
            handleRendering: renderCard
        },
        {
            name: "Category",
            objAttribute: "category",
            handleRendering: renderLabel
        },
        {
            name: "Type",
            objAttribute: "type"
        },
        {
            name: "Edited",
            objAttribute: "edited",
        },
        {
            name: "Start",
            objAttribute: "start",
            handleRendering: renderStart
        },
        {
            name: "Duration",
            objAttribute: "duration"
        },
    ];

    useEffect(() => {
        if (!props?.organization?.id) return;
        getOrganizationUsers(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setOrganizationUsers(res.data.data);
        });
    }, [props?.organization?.id]);

    useEffect(() => {
        _getOrganizationTrackersForUser();
    }, [dateFrom, dateTo, selectedUserId]);

    function renderStart(tracker: ITrackerActivity) {
        return moment(tracker.startDate).format("DD.MM.yyy HH:mm:ss");
    }

    function renderCard(tracker: ITrackerActivity) {
        return tracker?.cardId ? <RbLink to={`/board/card/details/${tracker.boardId}/${tracker.cardId}`}>
            <span className="underline underline-offset-4">{tracker?.card}</span></RbLink> : "";
    }

    function renderLabel(tracker: ITrackerActivity) {
        return <span className="px-10 py-5 rounded-8"
                     style={{backgroundColor: tracker?.categoryColor ?? "transparent"}}>{tracker?.category}</span>
    }

    function renderBoardLink(tracker: ITrackerActivity) {
        return <RbLink to={`/board-view/${tracker?.boardId}`}>
            <span className="underline underline-offset-4">{tracker?.board}</span></RbLink>
    }


    function _getOrganizationTrackersForUser() {
        if (!dateFrom || !props?.organization?.id) return;
        const user = selectedUserId !== "none" ? selectedUserId : "";
        getOrganizationTrackersForUser(props.organization.id, dateFrom, dateTo, user).then(res => {
            if (res?.data?.error) return;
            setTrackers(res.data.data);
        });
    }

    function headerControls() {
        return (
            <>
                <RbWidgetDatePicker onChange={(from, to) => {
                    setDateFrom(from);
                    setDateTo(to);
                }}/>
                <RbTableSelect
                    defaultValue={"none"}
                    className="ml-10"
                    variant="filled"
                    disableUnderline={true}
                    onChange={e => setSelectedUserId(e.target.value as any)}>
                    <MenuItem value={"none"}>Select user</MenuItem>
                    {
                        organizationUsers.map(user => {
                            return (
                                <MenuItem value={user.id}>{user.name}</MenuItem>
                            )
                        })
                    }
                </RbTableSelect>
            </>
        )
    }


    return (
        <WidgetBase id={props.id} title="Tracker activity" defaultWidth={props.defaultWidth}
                    headerControl={headerControls}>
            <RbDataTable dataSource={trackers} headerColumns={displayHeaders}/>
        </WidgetBase>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(WidgetTrackerActivity));

