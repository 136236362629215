import {FlagIcon} from "@heroicons/react/solid";
import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/styles";
import {IBoardCardDetails} from "../../../types/board-types";
import {updateBoardCardName, updateBoardCardPriority} from "../../../services/boards/boardsService";
import {RbTheme} from "../../../types/common-types";
import {RbTextField} from "app/shared-components/rb-components/RbTextField";
import {Menu, MenuItem} from "@mui/material";
import HeroIcon from "../../../../@fuse/core/HeroIcon/HeroIcon";

export default function BoardCardName(props: { card: IBoardCardDetails | undefined, refreshCard: (all?: boolean) => void, variant?: "outlined" | "filled" | "standard" | "text" }) {
    const theme: RbTheme = useTheme();
    const {card} = props;
    const prioritiesString: string[] = ["None", "Low", "Medium", "High"];
    const [anchorElFlag, setAnchorElFlag] = useState<null | HTMLElement>(null);
    const [title, setTitle] = useState<string>("");

    useEffect(() => {
        if (card?.title !== title) {
            setTitle(card?.title ?? "");
        }
    }, [props.card?.title]);


    function getPriorityColor(i: number | undefined = undefined): string {
        switch (i ?? card?.priorityId) {
            case 1:
                return theme.priority.low;
            case 2:
                return theme.priority.medium;
            case 3:
                return theme.priority.high;
            default:
                return theme.palette.text.primary;
        }
    }

    function handleBoardCardNameUpdate(e: any) {
        if (e.target.value === card?.title || !card?.id) return;
        updateBoardCardName(card.id, e.target.value).then(res => {
            if (res?.data?.error) return;
            props.refreshCard();
        });
    }

    function handlePriority(i: number) {
        if (!card?.id) return;
        setAnchorElFlag(null);
        updateBoardCardPriority(card.id, i === 0 ? null : i).then(res => {
            if (res?.data?.error) return;
            props.refreshCard();
        });
    }

    return (
        <>
            <RbTextField className="w-full block"
                         variant={(props.variant ?? "outlined") as any}
                         label="Task"
                         size="small"
                         value={title}
                         InputProps={{
                             endAdornment:
                                 <span className="w-20" style={{color: getPriorityColor()}}
                                       onClick={e => setAnchorElFlag(e.currentTarget)}>
                             <HeroIcon icon="FlagIcon" outline={!card?.priorityId || card?.priorityId === 0}/>
                             </span>
                         }}
                         onBlur={handleBoardCardNameUpdate}
                         onChange={e => setTitle(e.target.value)}
                         color="primary"/>
            <Menu
                id="priority-menu"
                anchorEl={anchorElFlag}
                open={!!anchorElFlag}
                onClose={() => setAnchorElFlag(null)}
            >
                {
                    prioritiesString.map((p, i) => {
                        return (
                            <MenuItem key={p} onClick={() => handlePriority(i)}>
                                <FlagIcon className="w-20 mr-10" color={getPriorityColor(i)}/>
                                {p}</MenuItem>
                        )
                    })
                }
            </Menu>
        </>
    )
}
