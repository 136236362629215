import clsx from "clsx";
import {RbColorButton} from "./RbColorPickerMenu";


export const RbColors = [
    "#f5f5f4", "#ffedd5", "#fde047", "#fcd34d", "#fdba74",
    "#ecfccb", "#bef264", "#dcfce7", "#86efac", "#5eead4",
    "#a5f3fc", "#67e8f9", "#bae6fd", "#93c5fd", "#76b2ff",
    "#a5b4fc", "#c4b5fd", "#d8b4fe", "#f0abfc", "#f9a8d4"
];

export default function RbColorMenu(props: { onSelect: (color: string) => void; className?: string, colorClassName?: string }) {
    return <div className={clsx(props.className, "p-10", "max-w-[17rem]", "align-middle")}>
        {
            RbColors.map(color => {
                return (
                    <RbColorButton key={`btn-${color}`}
                                   className={clsx("w-24 h-24 rounded-full", props?.colorClassName ?? "")}
                                   style={{backgroundColor: color}}
                                   onClick={() => props.onSelect(color)}></RbColorButton>
                )
            })
        }
    </div>;
}

