import RbBox from "../../../shared-components/rb-components/RbBox";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {ChevronDownIcon} from "@heroicons/react/outline";
import RbSingleSelect from "../../../shared-components/rb-components/RbSingleSelect";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import React, {useState} from "react";
import {IProjectOverviewOfTracker} from "../../../types/project-overview-types";
import {ITodoFriend} from "../../../types/todo-types";
import {IRbDisplayHeader} from "../../../types/common-types";
import {getUniqueItemsByProperty} from "../../../utils/RbUtils";

export default function ProjectOverviewBoardTrackers(props: { data: IProjectOverviewOfTracker[] | undefined }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [user, setUser] = useState<ITodoFriend>();
    const displayHeaders: IRbDisplayHeader[] = [
        {
            name: "Name",
            objAttribute: "user.name"
        },
        {
            name: "Board",
            objAttribute: "boardName"
        },
        {
            name: "Card",
            objAttribute: "cardName"
        },
        {
            name: "Description",
            objAttribute: "trackerDescription"
        },
        {
            name: "Category",
            objAttribute: "categoryName"
        },
        {
            name: "Start",
            objAttribute: "startDate",
        },
        {
            name: "Duration",
            objAttribute: "endDate",
            handleRendering: handleStartDateRender
        },
    ];

    function getFilteredItems(): ITodoFriend[] {
        return getUniqueItemsByProperty(props.data?.map(c => c.user), "id");
    }

    function handleStartDateRender(item: IProjectOverviewOfTracker) {
        return <span>{new Date(item.startDate).toLocaleTimeString("de-DE")}</span>;
    }

    return (
        <RbBox className="px-0 my-12">
            <div className="flex justify-between px-16">
                <h2 className="">Tracker</h2>
                <RbRoundButton onClick={e => setAnchorEl(e.currentTarget)} variant="contained" className="flex justify-between items-center px-10 w-fit">
                    <span>{user?.name ?? "Select user"}</span>
                    <ChevronDownIcon className="ml-10 w-20"/>
                </RbRoundButton>
            </div>
            <RbSingleSelect anchorEl={anchorEl} onClose={() => setAnchorEl(null)} onSelect={user => setUser(user)}
                            items={getFilteredItems()} renderItem={item => item.name}/>
            <RbDataTable dataSource={props.data ?? []} headerColumns={displayHeaders}/>
        </RbBox>
    )
}
