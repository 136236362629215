import {styled} from "@mui/system";
import {RbFilledSelect} from "./RbFilledSelect";

export const RbTableSelect = styled(RbFilledSelect)(({theme}) => ({
    "&": {
        borderRadius: "0.6rem",
        paddingLeft: "1rem",
        backgroundColor: `${theme.palette.mode === "dark" ? "#374151" : "#dbeafe"}`
    },
}));
export const RbTableSelectStyleButton = styled("button")(({theme}) => ({
    "&": {
        backgroundColor: `${theme.palette.mode === "dark" ? "#374151" : "#dbeafe"}`
    },
}));
