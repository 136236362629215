/* eslint import/no-extraneous-dependencies: off */
import {createSlice} from '@reduxjs/toolkit';
import 'firebase/compat/auth';
import history from '@history';
import _ from '@lodash';
import {setDefaultSettings, setInitialSettings} from 'app/store/fuse/settingsSlice';
import auth0Service from 'app/services/auth0Service';
import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';
import settingsConfig from 'app/fuse-configs/settingsConfig';
import {getProfile, signoutRedirect} from "../../services/authService";
import axios from "axios";
import jwtDecode from "jwt-decode";
// export const setUserDataAuth0 = (tokenData) => async (dispatch) => {
//     const user = {
//         role: ['admin'],
//         from: 'auth0',
//         data: {
//             displayName: tokenData.username || tokenData.name,
//             photoURL: tokenData.picture,
//             email: tokenData.email,
//             settings:
//                 tokenData.user_metadata && tokenData.user_metadata.settings
//                     ? tokenData.user_metadata.settings
//                     : {},
//             shortcuts:
//                 tokenData.user_metadata && tokenData.user_metadata.shortcuts
//                     ? tokenData.user_metadata.shortcuts
//                     : [],
//         },
//     };
//
//     return dispatch(createUserSettingsRubicon(tokenData));
// };

// export const setUserDataFirebase = (user, authUser) => async (dispatch) => {
//   if (
//     user &&
//     user.data &&
//     user.data.settings &&
//     user.data.settings.theme &&
//     user.data.settings.layout &&
//     user.data.settings.layout.style
//   ) {
//     // Set user data but do not update
//     return dispatch(setUserData(user));
//   }
//
//   // Create missing user settings
//   return dispatch(createUserSettingsFirebase(authUser));
// };
//
// export const createUserSettingsFirebase = (authUser) => async (dispatch, getState) => {
//   const guestUser = getState().auth.user;
//   const fuseDefaultSettings = getState().fuse.settings.defaults;
//   const { currentUser } = firebase.auth();
//
//   /**
//    * Merge with current Settings
//    */
//   const user = _.merge({}, guestUser, {
//     uid: authUser.uid,
//     from: 'firebase',
//     role: ['admin'],
//     data: {
//       displayName: authUser.displayName,
//       email: authUser.email,
//       settings: { ...fuseDefaultSettings },
//     },
//   });
//   currentUser.updateProfile(user.data);
//
//   dispatch(updateUserData(user));
//
//   return dispatch(setUserData(user));
// };


export const setToLS = (key, value) => {
    window.localStorage.setItem(key, JSON.stringify(value));
}

export const getFromLS = key => {
    const value = window.localStorage.getItem(key);

    if (value) {
        return JSON.parse(value);
    }
}
// export const getFromCookies = key => {
//     const cookies = new Cookies();
//     const value = cookies.get(key);
//
//     if (value) {
//         return value;
//     }
// }
//
// export const setToCookies = (key, value) => {
//     const cookies = new Cookies();
//     cookies.set(key, JSON.stringify(value));
// }


export const setUserDataRubicon = (user, settings) => async (dispatch) => {

    console.log(user, "USERRUBICON");
    const key = user.profile.email + '-theme';
    const decoded = jwtDecode(user.access_token);

    const u = {
        role: [], from: 'rubicon', data: {
            uid: decoded.sub,
            displayName: user.profile.username || user.profile.name,
            photoURL: 'assets/images/avatars/avatar_5.svg',
            email: user.profile.email,
            settings: settings,
        },
    };
    console.log(user?.access_token, "ACCESS")
    setAccessToken(user?.access_token);
    setToLS(key, u?.data)

    return dispatch(setUserData(u));
};

export const setAccessToken = (token) => {
    if (token) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common.Authorization;
    }
}

export const createUserSettingsRubicon = (authUser) => async (dispatch, getState) => {
    const guestUser = getState().auth.user;
    const fuseDefaultSettings = getState().fuse.settings.defaults;
    const {currentUser} = getProfile();

    /**
     * Merge with current Settings
     */
    const user = _.merge({}, guestUser, {
        uid: authUser.uid, from: 'rubicon', role: ['admin'], data: {
            uid: authUser.uid,
            displayName: authUser.displayName,
            email: authUser.email,
            settings: {...fuseDefaultSettings},
        },
    });
    currentUser.updateProfile(user.data);

    dispatch(updateUserData(user));

    return dispatch(setUserData(user));
};

export const setUserData = (user) => async (dispatch, getState) => {

    console.log(user, "setUser")
    /*
    You can redirect the logged-in user to a specific route depending on his role
    */
    if (user.loginRedirectUrl) {
        settingsConfig.loginRedirectUrl = user.loginRedirectUrl; // for dashboard 'apps/academy'
    }
    /*
    Set User Settings
    */
    dispatch(setDefaultSettings(user.data.settings));

    dispatch(setUser(user));
};

export const updateUserSettings = (settings) => async (dispatch, getState) => {
    const oldUser = getState().auth.user;
    const user = _.merge({}, oldUser, {data: {settings}});

    dispatch(updateUserData(user));

    return dispatch(setUserData(user));
};

export const updateUserShortcuts = (shortcuts) => async (dispatch, getState) => {
    const {user} = getState().auth;
    const newUser = {
        ...user, data: {
            ...user.data, shortcuts,
        },
    };

    dispatch(updateUserData(newUser));

    return dispatch(setUserData(newUser));
};

export const logoutUser = () => async (dispatch, getState) => {
    const {user} = getState().auth;

    if (!user.role || user.role.length === 0) {
        // is guest
        return null;
    }

    history.push({
        pathname: '/',
    });

    switch (user.from) {
        case 'firebase': {
            firebaseService.signOut();
            break;
        }
        case 'auth0': {
            auth0Service.logout();
            break;
        }
        case 'rubicon': {
            await signoutRedirect();
            break;
        }
        default: {
            jwtService.logout();
        }
    }

    dispatch(setInitialSettings());

    return dispatch(userLoggedOut());
};

export const updateUserData = (user) => async (dispatch, getState) => {
    const key = user?.data?.email + "-theme";
    setToLS(key, user?.data)
};

const initialState = {
    role: [], data: null,
};

const userSlice = createSlice({
    name: 'auth/user', initialState, reducers: {
        setUser: (state, action) => action.payload, userLoggedOut: (state, action) => initialState,
    }, extraReducers: {},
});

export const {setUser, userLoggedOut} = userSlice.actions;

export default userSlice.reducer;
