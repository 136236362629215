import {useEffect, useState} from "react";
import moment from "moment";
import {IProjectOverviewByUser, IProjectOverviewByUserWorkload} from "../../../types/project-overview-types";
import {RbTheme} from "../../../types/common-types";
import {useTheme} from "@mui/material/styles";
import {styled} from "@mui/system";
import Chart from "react-apexcharts";
import RbBox from "app/shared-components/rb-components/RbBox";

const RbChart = styled(Chart)(() => ({
    '& svg': {
        backgroundColor: "transparent !important"
    }
}));

export default function ProjectOverviewUserWorkload(props: { data: IProjectOverviewByUserWorkload[] | undefined }) {
    const theme: RbTheme = useTheme();
    const [series, setSeries] = useState<any>([]);
    const [options, setOptions] = useState<any>({
        chart: {
            id: "workload-bar",
            stacked: true
        },
        xaxis: {
            categories: (props.data ?? []).map(u => u?.user?.name ?? "")
        },
        dataLabels: {
            enabled: false
        },
        plotOptions: {
            bar: {
                horizontal: true,
            },
        },
        legend: {
            position: 'top',
        },
        theme: {mode: theme.palette.mode}
    });

    useEffect(() => {
        const markedDone: number[] = [];
        const inProgress: number[] = [];
        const scheduled: number[] = [];
        const overdue: number[] = [];

        setOptions({
            ...options,
            xaxis: {
                categories: (props.data ?? []).map(u => u?.user?.name ?? "")
            }
        });

        props.data?.forEach(u => {
            markedDone.push(u?.done ?? 0);
            inProgress.push(u?.inProgress ?? 0);
            scheduled.push(u?.scheduled ?? 0);
            overdue.push(u?.overdue ?? 0);
        })

        setSeries([
            {
                name: "Marked as done",
                data: markedDone,
                color: "#38BDF8"
            },
            {
                name: "In progress",
                data: inProgress,
                color: "#0369A1"
            },
            {
                name: "Scheduled",
                data: scheduled,
                color: "#075985"
            },
            {
                name: "Overdue",
                data: overdue,
                color: "#EF4444"
            },
        ])


    }, [props.data])

    return (
        <RbBox className="mb-10 flex flex-1 flex-col px-20 py-20">
            <h2 className="mb-10">User workload</h2>
            <RbChart
                options={options}
                series={series}
                type="bar"
                width="100%"
                height="360"
            />
        </RbBox>
    )
}
