import TrackerAdd from "./tracker-add/TrackerAdd";
import FilterTracker from "./filter-tracker/FilterTracker";
import BoardTracker from "./board-tracker/BoardTracker";
import {getAllTrackerFromTo} from "../../services/tracker/trackerService";
import {useEffect, useState} from "react";
import {formatDate} from "../../utils/utils";
import moment from "moment";
import {UserOrganization} from "../../types/common-types";
import {connect} from "react-redux";
import withRouter from "../../../@fuse/core/withRouter";

function Tracker(props: { organization: UserOrganization }) {
    const [tables, setTables] = useState([]);
    const [from, setFrom] = useState(moment().startOf('isoWeek'));
    const [to, setTo] = useState(moment().endOf('isoWeek'));

    useEffect(() => {
        getAllTrackers();
    }, [props?.organization?.id]);

    const getAllTrackers = (dateFrom?: any, dateTo?: any) => {
        if (!props?.organization?.id) return;
        if (dateFrom && dateTo) {
            setFrom(dateFrom);
            setTo(dateTo);
        }
        getAllTrackerFromTo(props.organization.id, from.toISOString(true), to.toISOString(true)).then(res => {
            if (!res?.data?.error) {
                setTables(res.data.data);
            }
        })
    }

    const filterTrackers = (dateFrom: Date, dateTo: Date) => {
        if (!props?.organization?.id) return;
        if (dateFrom && dateTo) {
            setFrom(moment(dateFrom));
            setTo(moment(dateTo));
        }
        getAllTrackerFromTo(props.organization.id, formatDate(dateFrom), formatDate(dateTo)).then(res => {
            if (!res?.data?.error) {
                setTables(res.data.data);
            }
        });
    }

    return (
        <div className={"flex flex-col w-full max-w-screen-xl mx-auto sm:px-8 mt-3"}>
            <FilterTracker refreshTrackers={filterTrackers}/>
            <TrackerAdd refreshTrackers={getAllTrackers}/>
            <BoardTracker refreshTrackers={getAllTrackers} tables={tables}/>
        </div>
    );
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(Tracker));
