import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {ChatIcon, ChevronDownIcon, CurrencyDollarIcon} from "@heroicons/react/outline";
import * as React from "react";
import {IReportTrackerActivity} from "../../../types/report-types";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {useEffect, useState} from "react";
import {IconButton, MenuItem, TextField} from "@mui/material";
import {IProjectDropDown, UserOrganization} from "../../../types/common-types";
import {getAllOrganizationProjects} from "../../../services/common/common";
import {connect} from "react-redux";
import withRouter from "../../../../@fuse/core/withRouter";
import {
    editBillable,
    editCard, editCategory,
    editDescription, editDuration,
    editProject, editStartDate,
    getAllCardsByProjectId,
    getTrackerCategories
} from "../../../services/tracker/trackerService";
import {ITrackerCategory} from "../../../types/tracker-types";
import moment from "moment";
import {DesktopDatePicker} from "@mui/lab";
import {createAdminTracker} from "../../../services/resportsService";
import {getOrganizationUsers} from "../../../services/organizationSettingsService";
import RbSingleSelect from "../../../shared-components/rb-components/RbSingleSelect";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {ITodoFriend} from "../../../types/todo-types";

export interface IAdminTracker {
    "id"?: string;
    "description"?: string;
    "projectId": string;
    "cardId": string;
    "labelId": number | undefined;
    "durationHour": number;
    "durationMinute": number;
    "durationSecond": number;
    "billable": boolean;
    "userId"?: string;
    "startDate": moment.Moment | undefined;
}

function ReportTrackerActivityPreview(props: { userId?: string, refreshActivity?: () => void, organization?: UserOrganization, data?: IReportTrackerActivity, onClose?: () => void }) {
    const [openFrom, setOpenFrom] = useState<boolean>(false);
    const [projects, setProjects] = useState<IProjectDropDown[]>([]);
    const [manualTracker, setManualTracker] = useState<IAdminTracker>();
    const [cards, setCards] = useState([]);
    const [categories, setCategories] = useState<ITrackerCategory[]>([]);
    const [description, setDescription] = useState("");
    const [startTime, setStartTime] = useState("00:00:00");
    const [duration, setDuration] = useState("00:00:00");
    const [endTime, setEndTime] = useState("00:00:00");
    const [organizationUsers, setOrganizationUsers] = useState<ITodoFriend[]>([]);
    const [usersAnchor, setUsersAnchor] = useState<HTMLElement | null>(null);
    const [selectedUser, setSelectedUser] = useState<ITodoFriend>();

    useEffect(() => {
        _getAllProjects();
        _getTrackerCategories();
        _getOrganizationUsers();
    }, [props?.organization?.id]);

    useEffect(() => {
        if (props?.data) {
            const dur = props.data.duration.split(":");
            const start = props?.data?.startDate ? moment(props.data.startDate) : moment();

            setDuration(props.data.duration);
            setDescription(props.data?.description ?? "");
            setStartTime(start.format("HH:mm:ss"));

            const tmp = {
                id: props?.data?.id,
                durationHour: props.data.durationHour ?? 0,
                durationMinute: props.data.durationMinute ?? 0,
                durationSecond: props.data.durationSecond ?? 0,
                cardId: props.data.cardId ?? "",
                billable: props?.data?.billable ?? true,
                labelId: +props.data?.labelId,
                projectId: props.data?.projectId ?? "",
                userId: "",
                startDate: start.clone()
            }

            setManualTracker(tmp);
            start.add(+dur[0] ?? 0, "hours");
            start.add(+dur[1] ?? 0, "minutes");
            start.add(+dur[2] ?? 0, "seconds");


            if (props?.data?.user) {
                setSelectedUser(props.data.user)
            } else {
                setSelectedUser(organizationUsers.find(u => u.id === props.userId));
            }
            setEndTime(start.format("HH:mm:ss"));
        } else {
            setManualTracker({...(manualTracker as any), billable: true});
        }
    }, [props?.data]);

    useEffect(() => {
        _getAllCardByProject();
    }, [manualTracker?.projectId]);

    function handleClose() {
        if (props?.onClose) {
            props.onClose();
        }
    }


    function _getOrganizationUsers() {
        if (!props?.organization?.id) return;
        getOrganizationUsers(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setOrganizationUsers(res.data.data);
        });
    }

    const handleFormValueChange = (event: { target: { value: string; name: string; }; }) => {
        switch (event.target.name) {
            case "description":
                setDescription(event.target.value);
                return;
            case "projectId":
                setManualTracker({...(manualTracker as any), cardId: ""});
                if (manualTracker?.id) {
                    editProject(manualTracker.id, event.target.value).then(res => {
                        if (!res?.data?.error && props?.refreshActivity) {
                            props.refreshActivity();
                        }
                    });
                } else {
                    setManualTracker({...(manualTracker as any), projectId: event.target.value});
                }
                return;
            case "cardId":
                if (!manualTracker?.id) break;
                editCard(manualTracker.id, event.target.value).then(res => {
                    if (!res?.data?.error && props?.refreshActivity) {
                        props.refreshActivity();
                    }
                })
                break;
            case "labelId":
                if (!manualTracker?.id) break;
                editCategory(manualTracker.id, event.target.value).then(res => {
                    if (!res?.data?.error && props?.refreshActivity) {
                        props.refreshActivity();
                    }
                })
                break;
            case "startTime":
                let start = event.target.value;
                start = start.replaceAll(":", "").padEnd(6, "0");

                const date = manualTracker?.startDate ?? moment();
                date.set({hours: +(`${start[0]}${start[1]}`)});
                date.set({minutes: +(`${start[2]}${start[3]}`)});
                date.set({seconds: +(`${start[4]}${start[5]}`)});

                if (manualTracker?.id) {
                    editStartDate(manualTracker.id, date.toISOString(true)).then(res => {
                        if (!res?.data?.error && props?.refreshActivity) {
                            props.refreshActivity();
                        }
                    });
                }

                setManualTracker({...(manualTracker as any), startDate: date});
                return;
            // case "endTime":
            //     if (!endTime) return;
            //     let end = endTime;
            //     end = end.replaceAll(":", "").padEnd(6, "0");
            //     setEndTime(`${end[0]}${end[1]}:${end[2]}${end[3]}:${end[4]}${end[5]}`);
            //
            //     const dateE = manualTracker?.startDate?.clone() ?? moment();
            //     dateE.set({hours: +(`${end[0]}${end[1]}`)});
            //     dateE.set({minutes: +(`${end[2]}${end[3]}`)});
            //     dateE.set({seconds: +(`${end[4]}${end[5]}`)});
            //     const durr = moment.duration(dateE.diff((manualTracker?.startDate ?? moment())));
            //
            //     if (manualTracker?.id) {
            //         editDuration({
            //             trackerId: manualTracker.id,
            //             hour: durr.hours(),
            //             minute: durr.minutes(),
            //             second: durr.seconds()
            //         }).then(res => {
            //             if (!res?.data?.error && props?.refreshActivity) {
            //                 props.refreshActivity();
            //             }
            //         });
            //     }
            //
            //     setManualTracker({
            //         ...(manualTracker as any),
            //         durationHour: durr.hours(),
            //         durationMinute: durr.minutes(),
            //         durationSecond: durr.seconds()
            //     });
            //     return;

            // case "duration":
            //     let dur = event.target.value.split(":");
            //     const tmp = manualTracker?.startDate?.clone() ?? moment();
            //     tmp.add(+dur[0] ?? 0, "hours");
            //     tmp.add(+dur[1] ?? 0, "minutes");
            //     tmp.add(+dur[2] ?? 0, "seconds");
            //     setEndTime(tmp.format("HH:mm:ss"));
            //     setManualTracker({
            //         ...(manualTracker as any),
            //         durationHour: +dur[0],
            //         durationMinute: +dur[1],
            //         durationSecond: +dur[2]
            //     });
            //     return;
        }

        setManualTracker({...(manualTracker as any), [event.target.name]: event?.target?.value ?? ""});
    }

    const _getAllProjects = () => {
        if (!props?.organization?.id) return;
        getAllOrganizationProjects(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setProjects(res?.data?.data);
        });
    }

    const _getAllCardByProject = () => {
        if (!manualTracker?.projectId) {
            setCards([]);
            return;
        }
        getAllCardsByProjectId(manualTracker.projectId).then(res => {
            if (res?.data?.error) return;
            setCards(res.data.data);
        });
    }

    function _getTrackerCategories() {
        if (!props.organization?.id) return;
        getTrackerCategories(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setCategories(res.data.data);
        });
    }

    function updateTrackerDescription(event: { target: { value: string; name: string; } }) {
        if (description === manualTracker?.description) return;
        setManualTracker({...(manualTracker as any), description: description});

        if (manualTracker?.id) {
            editDescription(manualTracker.id, description).then(res => {
                if (!res?.data?.error && props?.refreshActivity) {
                    props.refreshActivity();
                }
            });
        }
    }

    function saveTrackerStartTime() {
        let start = startTime.replaceAll(":", "").padEnd(6, "0");
        setStartTime(`${start[0]}${start[1]}:${start[2]}${start[3]}:${start[4]}${start[5]}`);

        const tmp = manualTracker?.startDate?.clone() ?? moment();
        tmp.set("hours", +`${start[0]}${start[1]}`);
        tmp.set("minutes", +`${start[2]}${start[3]}`);
        tmp.set("seconds", +`${start[4]}${start[5]}`);
        setManualTracker({...(manualTracker as any), startDate: tmp})

        if (manualTracker?.id) {
            editStartDate(manualTracker.id, tmp.toISOString(true)).then(res => {
                if (!res?.data?.error && props?.refreshActivity) {
                    props.refreshActivity();
                }
            });
        }
    }

    function saveTrackerDuration() {
        let dur = duration.replaceAll(":", "").padEnd(6, "0");
        setDuration(`${dur[0]}${dur[1]}:${dur[2]}${dur[3]}:${dur[4]}${dur[5]}`);
        if (manualTracker?.id) {
            editDuration({
                trackerId: manualTracker.id,
                hour: +String(`${dur[0]}${dur[1]}`),
                minute: +String(`${dur[2]}${dur[3]}`),
                second: +String(`${dur[4]}${dur[5]}`)
            }).then(res => {
                if (!res?.data?.error && props?.refreshActivity) {
                    props.refreshActivity();
                }
            });
        }
    }

    function saveTracker() {
        if (!manualTracker || !selectedUser?.id || !manualTracker?.startDate) return;
        const dur = duration.split(":");
        const tracker = {
            ...manualTracker,
            userId: selectedUser?.id,
            durationHour: +dur[0],
            durationMinute: +dur[1],
            durationSecond: +dur[2],
            description: description
        }

        createAdminTracker(tracker).then(res => {
            if (res?.data?.error) return;
            if (props?.refreshActivity) {
                props.refreshActivity();
            }
            if (props?.onClose) {
                props.onClose();
            }
        })
    }

    function handleDateChange(date: Date | null | undefined) {
        const tmp = moment(date);
        const time = startTime.split(":");
        tmp.set("hours", +time[0] ?? 0);
        tmp.set("minutes", +time[1] ?? 0);
        tmp.set("seconds", +time[2] ?? 0);
        setManualTracker({...(manualTracker as any), startDate: tmp});

        if (manualTracker?.id) {
            editStartDate(manualTracker.id, tmp.toISOString(true)).then(res => {
                if (res?.data?.error) return;
                if (props.refreshActivity) {
                    props.refreshActivity();
                }
            })
        }
    }

    function toggleBillable() {
        if (manualTracker?.id) {
            editBillable(manualTracker.id, !manualTracker?.billable).then(res => {
                if (res?.data?.error) return;
                if (props.refreshActivity) {
                    props.refreshActivity();
                }
            });
        }
        setManualTracker({...(manualTracker as any), billable: !manualTracker?.billable});
    }

    return (
        <div className="p-0 min-w-512">
            {
                (!props?.userId && !props.data?.user?.id) &&
                <RbBox onClick={e => setUsersAnchor(e.currentTarget)} theme="secondary"
                       className="mb-10 px-10 w-full h-28 cursor-pointer whitespace-nowrap justify-between overflow-hidden flex items-center">
                    <span>{selectedUser?.name ?? "User"}</span>
                    <ChevronDownIcon className="w-20"/>
                </RbBox>
            }
            <RbTextField className="pb-10 mb-10 w-full"
                         label="Description"
                         variant="standard"
                         name="description"
                         value={description ?? ""}
                         onChange={handleFormValueChange}
                         onBlur={updateTrackerDescription}
                         InputProps={{endAdornment: <ChatIcon className="w-20"/>}}
                         color="primary"/>
            <div className="flex justify-between items-center">

                <RbTextField className="pb-10 w-1/3 mx-5"
                             label="Project"
                             variant="standard"
                             select
                             name="projectId"
                             onChange={handleFormValueChange}
                             value={manualTracker?.projectId ?? ""}
                             color="primary">
                    <MenuItem value={undefined}>Clear</MenuItem>
                    {projects && projects.map((project, i) => (
                        <MenuItem key={i}
                                  value={project?.id}>{project?.name}</MenuItem>
                    ))}
                </RbTextField>

                <RbTextField className="pb-10 w-1/3 mx-5"
                             label="Card"
                             variant="standard"
                             select
                             name="cardId"
                             onChange={handleFormValueChange}
                             value={manualTracker?.cardId ?? ""}
                             color="primary">
                    <MenuItem value={undefined}>Clear</MenuItem>
                    {cards && cards.map((card: any, i) => (
                        <MenuItem key={i}
                                  value={card?.cardId}>{card?.cardName}</MenuItem>
                    ))}
                </RbTextField>

                <RbTextField className="pb-10 w-1/3 mx-5"
                             label="Category"
                             variant="standard"
                             select
                             name="labelId"
                             onChange={handleFormValueChange}
                             value={manualTracker?.labelId ?? ""}
                             color="primary">
                    {
                        categories && categories.map((cat, i) => {
                            return <MenuItem key={i}
                                             value={cat?.id}>{cat?.name}</MenuItem>
                        })
                    }
                </RbTextField>
            </div>
            <div className="flex justify-between">
                <DesktopDatePicker
                    open={openFrom}
                    label="Date"
                    value={manualTracker?.startDate?.toDate()}
                    inputFormat="dd.MM.yyyy"
                    onClose={() => {
                        setOpenFrom(false);
                    }}
                    ignoreInvalidInputs={true}
                    disableMaskedInput={true}
                    onChange={handleDateChange}
                    renderInput={(params: any) => <TextField {...params}
                                                             variant="standard"
                                                             className="mx-5"
                                                             onClick={() => {
                                                                 setOpenFrom(true);
                                                             }}
                    />}/>

                <RbTextField className="pb-10 mx-5"
                             label="Start"
                             variant="standard"
                             name="startTime"
                             onBlur={saveTrackerStartTime}
                             onChange={e => setStartTime(e.target.value)}
                             value={startTime ?? "00:00:00"}
                             color="primary"/>
                {/*<RbTextField className="pb-10 mx-5"*/}
                {/*             label="End"*/}
                {/*             name="endTime"*/}
                {/*             onChange={e => setEndTime(e.target.value)}*/}
                {/*             onBlur={handleFormValueChange}*/}
                {/*             variant="standard"*/}
                {/*             value={endTime}*/}
                {/*             color="primary"/>*/}
                <RbTextField className="pb-10 mx-5"
                             label="Duration"
                             name="duration"
                    // onChange={handleFormValueChange}
                             onBlur={saveTrackerDuration}
                             onChange={e => setDuration(e.target.value)}
                             value={duration ?? "00:00:00"}
                             variant="standard"
                    // value={`${String(manualTracker?.durationHour ?? 0).padStart(2, "0")}:${String(manualTracker?.durationMinute ?? 0).padStart(2, "0")}:${String(manualTracker?.durationSecond ?? 0).padStart(2, "0")}`}
                             color="primary"/>
                <span className="flex flex-col justify-center">
                    <IconButton className="p-5" onClick={toggleBillable}>
                    <CurrencyDollarIcon className="w-20"
                                        style={{opacity: manualTracker?.billable === true ? 1 : 0.3}}/>
                    </IconButton>
                </span>
            </div>
            {
                !props.data &&
                <div className="mt-10 text-right">
                    <RbRoundButton color="primary" className="mr-10"
                                   variant="text" onClick={handleClose}>Close</RbRoundButton>
                    <RbRoundButton color="primary" disabled={!selectedUser?.id || !manualTracker?.startDate}
                                   variant="contained" onClick={saveTracker}>Save</RbRoundButton>
                </div>
            }

            <RbSingleSelect items={organizationUsers} anchorEl={usersAnchor} onClose={() => setUsersAnchor(null)}
                            onSelect={u => setSelectedUser(u)} renderItem={item => item.name}/>
        </div>
    )
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value,
    }
}

export default withRouter(connect(mapStateToProps)(ReportTrackerActivityPreview));
