import React from "react";
import {IBoardCardDetails} from "../../../types/board-types";
import RbIconTitle from "../../../shared-components/rb-components/RbIconTitle";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";
import BoardETCPicker from "../BoardETCPicker";
import {updateBoardETC} from "../../../services/boards/boardsService";

export default function BoardCardETC(props: { card: IBoardCardDetails | undefined, refreshCard: () => void }) {
    const {card} = props;
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    function handleHoursSelect(hours?: number) {
        if (!card?.id) return;
        updateBoardETC(card.id, hours ?? null).then(res => {
            if (res?.data?.error) return;
            props.refreshCard();
        });
    }

    function formatETC(): string | undefined {
        if (!card?.estimatedTime) return undefined;

        if (card.estimatedTime % 8 === 0) {
            return `${card.estimatedTime / 8} day${card.estimatedTime / 8 > 1 ? "s" : ""}`;
        } else {
            return `${card.estimatedTime} hours`;
        }
    }

    return (
        <>
            <RbIconTitle icon="ClockIcon" title="ETC" container="h4" className="mb-10"/>
            <RbRoundButton color="secondary"
                           variant="contained"
                           className="min-w-96"
                           onClick={e => setAnchorEl(e.currentTarget)}>{formatETC() ?? "Not set"}</RbRoundButton>
            <BoardETCPicker defaultValue={card?.estimatedTime} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}
                            onConfirm={handleHoursSelect}/>
        </>
    )
}
