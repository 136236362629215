import FuseNavigation from '@fuse/core/FuseNavigation';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import clsx from 'clsx';
import {memo} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {selectNavigation} from 'app/store/fuse/navigationSlice';
import {navbarCloseMobile} from '../../store/fuse/navbarSlice';
import withRouter from "../../../@fuse/core/withRouter";
import {updateSubNavigation} from "../../store/rubicon/subNavigationSlice";
import {organizationSettingsSubNavigation} from "../../main/organization-settings/OrganizationSettingsConfig";

function Navigation(props) {
    const navigation = useSelector(selectNavigation);
    const theme = useTheme();
    const mdDown = useMediaQuery(theme.breakpoints.down('lg'));
    const dispatch = useDispatch();

    function handleItemClick(item) {
        switch (item?.id) {
            case "organization-settings-component":
                dispatch(updateSubNavigation(organizationSettingsSubNavigation));
                break;
            default:
                break;
        }

        if (mdDown) {
            dispatch(navbarCloseMobile());
        }
    }

    function filterNavigation() {
        return (props?.subNavigation ?? navigation).map(nav => {
            try {
                nav.children = (nav?.children ?? []).filter(item => {
                    if (!item?.roles) return true;
                    if (props?.user?.role?.length === 0 && item?.roles?.length > 0) return false;

                    return props?.user?.role.some(role => item.roles.some(r => r === role?.id))
                });
            } finally {
                return nav;
            }
        });
    }

    return (<FuseNavigation
        className={clsx('navigation', props.className)}
        navigation={filterNavigation()}
        layout={props.layout}
        dense={props.dense}
        active={props.active}
        onItemClick={handleItemClick}
    />);
}

Navigation.defaultProps = {
    layout: 'vertical',
};

// @ts-ignore
function mapStateToProps({fuse, auth}) {
    return {
        subNavigation: fuse.subNavigation.value, user: auth?.user
    }
}

export default withRouter(connect(mapStateToProps)(memo(Navigation)));
