import {IProjectOverviewBoard} from "../../../types/project-overview-types";
import ProjectOverviewBoardInfos from "./ProjectOverviewBoardInfos";
import ProjectOverviewBoardCategories from "./ProjectOverviewBoardCategories";
import ProjectOverviewBoardTrackers from "./ProjectOverviewBoardTrackers";

export default function ProjectOverviewBoards(props: { data: IProjectOverviewBoard | undefined }) {
    return (
        <div>
            <ProjectOverviewBoardInfos data={props.data}/>
            <ProjectOverviewBoardCategories data={props.data?.categoryDistribution}/>
            <ProjectOverviewBoardTrackers data={props.data?.trackers}/>
        </div>
    )
}
