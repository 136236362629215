import React, {useEffect, useState} from 'react';
import {FormControl, Grid, MenuItem, Select, TextField} from "@mui/material";
import {DesktopDatePicker} from "@mui/lab";
import moment from "moment";

const FilterTracker = (props: any) => {
    const {refreshTrackers} = props;
    const [from, setFrom] = useState<Date | null>(moment().startOf('isoWeek').toDate());
    const [to, setTo] = useState<Date | null>(moment().endOf('isoWeek').toDate());
    const [choice, setChoice] = useState(2);
    const choiceCondition = choice === 6;

    const handleChangeFrom = (newValue: Date | null) => {
        setFrom(newValue);
        refreshTrackers(newValue, to);
    };

    const handleChangeTo = (newValue: Date | null) => {
        setTo(newValue);
        refreshTrackers(from, newValue);
    };

    const [openFrom, setOpenFrom] = useState(false);
    const [openTo, setOpenTo] = useState(false);


    const selectChange = (option: number) => {
        setChoice(option);
        let tmpFrom: Date;
        let tmpTo: Date;

        switch (option) {
            case 2:
                tmpFrom = moment().startOf('isoWeek').toDate();
                tmpTo = moment().endOf('isoWeek').toDate();
                break;
            case 3:
                tmpFrom = moment().startOf('isoWeek').subtract(7, 'days').toDate();
                tmpTo = moment().endOf('isoWeek').subtract(7, 'days').toDate();
                break;
            case 4:
                tmpFrom = moment().startOf('month').toDate();
                tmpTo = moment().endOf('month').toDate();
                break
            case 5:
                tmpFrom = moment().startOf('month').subtract(1, 'month').toDate();
                tmpTo = moment().endOf('month').subtract(1, 'month').toDate();
                break
            default:
                tmpFrom = moment().toDate();
                tmpTo = moment().toDate();
                break;

        }
        setFrom(tmpFrom);
        setTo(tmpTo);
        refreshTrackers(tmpFrom, tmpTo);
    }

    return (
        <div className={"p-12"}>
            <form className={"space-x-4"}>
                <Grid container columns={3} spacing={2} className={"flex xl:w-1/2 lg:w-3/4 sm:block"}>
                    <Grid item xs={1} className={"sm:max-w-full"}>
                        <FormControl fullWidth>
                            <Select
                                variant="filled"
                                value={choice}
                                onChange={e => selectChange(+e.target.value)}
                            >
                                <MenuItem value={2}>This week</MenuItem>
                                <MenuItem value={3}>Last week</MenuItem>
                                <MenuItem value={4}>This month</MenuItem>
                                <MenuItem value={5}>Last month</MenuItem>
                                <MenuItem value={6}>Custom</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} className={"sm:max-w-full"}>
                        <FormControl fullWidth>
                            <DesktopDatePicker
                                open={openFrom}
                                label="From"
                                minDate={moment().startOf('month').subtract(2, 'month').toDate()}
                                onOpen={() => {
                                    if (choiceCondition) {
                                        setOpenFrom(true)
                                    }
                                }}
                                onClose={() => {
                                    setOpenFrom(false)
                                }}
                                inputFormat="dd.MM.yyyy"
                                value={from}
                                onChange={handleChangeFrom}
                                disabled={!choiceCondition}
                                renderInput={(params) => <TextField {...params}
                                                                    InputProps={
                                                                        {
                                                                            endAdornment: null,
                                                                            readOnly: !choiceCondition
                                                                        }
                                                                    }
                                                                    onClick={() => {
                                                                        if (choiceCondition) {
                                                                            setOpenFrom(true)
                                                                        }
                                                                    }}/>}/>
                        </FormControl>
                    </Grid>
                    <Grid item xs={1} className={"sm:max-w-full"}>
                        <FormControl fullWidth>
                            <DesktopDatePicker
                                open={openTo}
                                label="To"
                                minDate={moment().startOf('month').subtract(2, 'month').toDate()}
                                onOpen={() => {
                                    if (choiceCondition) {
                                        setOpenTo(true)
                                    }
                                }}
                                onClose={() => {
                                    setOpenTo(false)
                                }}
                                inputFormat="dd.MM.yyyy"
                                value={to}
                                onChange={handleChangeTo}
                                disabled={!choiceCondition}
                                renderInput={(params) => <TextField {...params}
                                                                    InputProps={
                                                                        {
                                                                            endAdornment: null,
                                                                            readOnly: !choiceCondition
                                                                        }
                                                                    }
                                                                    onClick={() => {
                                                                        if (choiceCondition) {
                                                                            setOpenTo(true)
                                                                        }
                                                                    }}/>}/>
                        </FormControl>
                    </Grid>

                </Grid>
            </form>
        </div>
    );
};

export default FilterTracker;
