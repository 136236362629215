import {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import RbWorkDays from "../../../shared-components/rb-components/RbWorkDays";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {PencilIcon, ShieldCheckIcon, TrashIcon} from "@heroicons/react/outline";
import * as React from "react";
import {RbSubSection, RbSubSectionItem} from "app/shared-components/rb-components/RbSections";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {FormControl, IconButton} from "@mui/material";
import {
    IOrganizationSeat,
    IOrganizationUser,
    ISearchedUser,
    IUserOrganizationInfo
} from "../../../types/organization-settings-types";
import {
    deleteOrganization,
    getFormerOrganizationUsers,
    getMemberTypes, getOrganizationInfo,
    getOrganizationSeats,
    getOrganizationUsers,
    getWorkingDays,
    inviteUserToOrganization,
    removeUserFromOrganization, toggleWorkDaySetting, updateOrganizationEmail, updateOrganizationName,
    updateUserMemberTypeInOrganization
} from "../../../services/organizationSettingsService";
import Typography from "@mui/material/Typography";
import RbDeleteOrganizationSeatsDialog from "./RbDeleteOrganizationSeatsDialog";
import RbDataTable from "../../../shared-components/rb-components/RbDataTable/RbDataTable";
import {IMemberType, IRbDisplayHeader, RbTheme, UserOrganization} from "../../../types/common-types";
import MenuItem from "@mui/material/MenuItem";
import {RbProgressBar} from "app/shared-components/rb-components/RbProgressBar";
import {RbTableSelect} from "app/shared-components/rb-components/RbDataTable/RbTableSelect";
import {searchOrganizationUsers, triggerBackendError} from "../../../services/common/common";
import RbAutoComplete from "../../../shared-components/rb-components/RbDataTable/RbAutoComplete";
import RbTableActionButton from "../../../shared-components/rb-components/RbDataTable/RbTableActionButton";
import {connect} from "react-redux";
import withRouter from "@fuse/core/withRouter";
import RbConfirmationDialog from "../../../shared-components/dialogs/RbConfirmationDialog";
import {IWorkDay} from "../../../types/user-settings-types";
import RbProtectedTextField from "../../../shared-components/rb-components/RbProtectedTextField";
import {RbLink} from "app/shared-components/rb-components/RbLink";
import Avatar from "@mui/material/Avatar";
import {getUserInitials} from "../../../utils/RbUtils";
import {useRbDispatch} from "../../../rb-hooks/hooks";
import {updateUserOrganization} from "../../../store/rubicon/organizationSlice";
import {useNavigate} from "react-router-dom";
import RbCreateOrganizationSeatsDialog from "./RbCreateOrganizationSeatsDialog";

function GeneralOrganizationSettings(props: { organization: UserOrganization }) {
    const [organizationSeats, setOrganizationSeats] = useState<IOrganizationSeat>();
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [addSeatsOpen, setAddSeatsOpen] = useState(false);
    const [deleteSeatsOpen, setDeleteSeatsOpen] = useState(false);
    const [organizationInfo, setOrganizationInfo] = useState<IUserOrganizationInfo>();
    const [searchedUser, setSearchedUser] = useState<ISearchedUser>();
    const [userForDelete, setUserForDelete] = useState<IOrganizationUser>();
    const [formerOrganizationUsers, setFormerOrganizationUsers] = useState<IOrganizationUser[]>([]);
    const [organizationUsers, setOrganizationUsers] = useState<IOrganizationUser[]>([]);
    const [memberTypes, setMemberTypes] = useState<IMemberType[]>([]);
    const [workDays, setWorkDays] = useState<IWorkDay[]>([]);
    const [deleteOrganizationOpen, setDeleteOrganizationOpen] = useState(false);

    const theme: RbTheme = useTheme();
    const dispatch = useRbDispatch();
    const navigate = useNavigate();

    const displayHeadersUsers: IRbDisplayHeader[] = [
        {
            name: "User",
            objAttribute: "name"
        },
        {
            name: "Email",
            objAttribute: "email"
        },
        {
            name: "Type",
            objAttribute: "memberType",
            handleRendering: renderTableMemberType
        },
        {
            name: "Permissions",
            objAttribute: "permissions",
            handleRendering: renderTablePermissions,
            className: "text-center"
        },
        {
            name: "Invite date",
            objAttribute: "dateInsert"
        },
        {
            name: "Status date",
            objAttribute: "dateStatusChanged"
        },
        {
            name: "Status",
            objAttribute: "invitationStatus"
        },
        {
            name: "",
            objAttribute: "",
            handleRendering: renderTableActions,
            className: "text-right"
        }
    ];

    const displayHeadersFormerUsers: IRbDisplayHeader[] = [
        {
            name: "User",
            objAttribute: "name"
        },
        {
            name: "Email",
            objAttribute: "email"
        },
        {
            name: "Invite date",
            objAttribute: "dateInsert",
        },
        {
            name: "Status change date",
            objAttribute: "dateStatusChanged",
        },
        {
            name: "",
            objAttribute: "",
            handleRendering: renderTableActionFormer,
            className: "text-right"
        }
    ];

    useEffect(() => {
        _getOrganizationSeats();
        _getOrganizationUsers();
        _getOrganizationInfo();
        _getMemberTypes();
        _getWorkingDays();
    }, [props?.organization?.id]);


    function _getWorkingDays() {
        if (!props?.organization?.id) return;
        getWorkingDays(props.organization.id).then(res => {
            if (res && res?.data?.data) {
                setWorkDays(res?.data?.data);
            }
        })
    }

    function _toggleWorkDaySetting(workDayId: number) {
        if (!props?.organization?.id) return;
        toggleWorkDaySetting(props.organization.id, workDayId).then(res => {
            if (res?.data?.error) return;
            _getWorkingDays();
        })
    }

    function _getOrganizationInfo() {
        if (!props?.organization?.id) return;
        getOrganizationInfo(props.organization.id).then(res => {
            if (res?.data?.error) return;
            setOrganizationInfo(res.data.data);
        });
    }

    function handleChangeUserMemberType(e: any, userId: string) {
        if (!props?.organization?.id) return;
        updateUserMemberTypeInOrganization(props.organization.id, userId, e.target.value).then(res => {
            if (!res?.data?.error) {
                _getOrganizationUsers();
            }
        });
    }

    function renderTableMemberType(data: IOrganizationUser) {
        return (<FormControl key={data.id} size="small">
            <RbTableSelect
                value={data.typeId}
                placeholder="Member type"
                variant="filled"
                disableUnderline={true}
                onChange={e => handleChangeUserMemberType(e, data.id)}
            >
                {
                    memberTypes.map(type => {
                        return (
                            <MenuItem key={type?.id} value={type?.id}>{type?.name}</MenuItem>
                        )
                    })
                }
            </RbTableSelect>
        </FormControl>)
    }

    function renderTableActions(obj: IOrganizationUser) {
        return <RbTableActionButton icon="TrashIcon"
                                    onClick={() => {
                                        setUserForDelete(obj);
                                        setDeleteUserDialog(true);
                                    }}/>;
    }

    function _removeUserFromOrganization() {
        if (!userForDelete?.id) return;
        removeUserFromOrganization(props.organization.id, userForDelete.id).then(res => {
            if (res?.data?.error) return;
            _getOrganizationUsers();
        })
    }

    function renderTableActionFormer(obj: IOrganizationUser) {
        return <RbRoundButton variant="contained" color="primary" className=""
                              onClick={() => handleInviteUserForOrganization(obj?.id)}>Re-invite</RbRoundButton>
    }

    function renderTablePermissions(obj: IOrganizationUser) {
        return (
            <RbLink to={`/organization-settings/user-permissions?userId=${obj.id}`}>
                <IconButton color="inherit" aria-label="Permissions" component="span">
                    <ShieldCheckIcon className="w-20" style={{opacity: obj.permissions ? 1 : 0.4}}/>
                </IconButton>
            </RbLink>
        )
    }


    function _getMemberTypes() {
        getMemberTypes().then(res => {
            if (!res?.data?.error) {
                setMemberTypes(res.data.data);
            }
        })
    }

    function _getOrganizationSeats() {
        if (!props?.organization?.id) return;
        getOrganizationSeats(props.organization.id).then(res => {
            if (res?.data?.data) {
                setOrganizationSeats(res.data.data);
            }
        })
    }

    function _getOrganizationUsers() {
        if (!props?.organization?.id) return;
        getOrganizationUsers(props.organization.id).then(res => {
            if (!res?.data?.error) {
                setOrganizationUsers(res.data.data);
            }
        });
        getFormerOrganizationUsers(props.organization.id).then(res => {
            if (!res?.data?.error) {
                setFormerOrganizationUsers(res.data.data);
            }
        });
    }

    // TODO: finish delete seats dialog & make new organization dialog
    function _deleteOrganizationSeat() {
        // deleteOrganizationSeats(getSelectedUserOrganization().id,).then(res => {
        //     if (!res.data?.error) {
        //         _getOrganizationSeats();
        //     }
        // });
    }

    function calculateProgressRatio() {
        if (!organizationSeats?.occupied || !organizationSeats?.total) return 0;
        return (100 * organizationSeats.occupied ?? 0) / organizationSeats.total ?? 1;
    }

    function handleInviteUserForOrganization(userId: string | undefined = undefined) {
        if (!props?.organization?.id || !(userId || searchedUser?.id)) return;
        // @ts-ignore
        inviteUserToOrganization(props.organization.id, userId ?? searchedUser.id).then(res => {
            if (res?.data?.error) return;
            _getOrganizationUsers();
        });
        setSearchedUser(undefined);
    }

    function _updateOrganizationName(name: string) {
        if (!organizationInfo?.name || !props?.organization?.id) return;
        updateOrganizationName(props.organization.id, name).then(() => {
            _getOrganizationInfo();
        })
    }

    function _updateOrganizationEmail(email: string) {
        if (!organizationInfo?.email || !props?.organization?.id) return;
        updateOrganizationEmail(props.organization.id, email).then(() => {
            _getOrganizationInfo();
        })
    }

    function _handleRowStyle(data: IOrganizationUser) {
        return data.invitationStatus === "Pending" ? "opacity-50" : "";
    }

    function autocompleteItemRender(user: ISearchedUser) {
        return (
            <div className="flex items-center">
                <Avatar src={user?.avatar ?? ""} sx={{width: 30, height: 30}}
                        className="mr-10 text-sm">{getUserInitials(`${user.firstName} ${user.lastName}`)}</Avatar>
                <span>{user?.firstName ?? ""} {user?.lastName ?? ""}</span>
                <span className="ml-5">({user?.userName ?? ""})</span>
            </div>
        );
    }

    function handleDeleteOrganization() {
        if (!props?.organization?.id) return;

        deleteOrganization(props.organization.id).then(res => {
            if (res?.data?.error) return;
            dispatch(updateUserOrganization(null));
            navigate("/");
        });
    }

    return (
        <div className="mt-48 md:ml-28 sm:ml-28 sm:mr-28 ml-64 mr-64 mb-48">
            <div className="w-256">
                <RbProtectedTextField size="small" slim={true} value={organizationInfo?.name ?? ""}
                                      onSave={_updateOrganizationName}/>
                <RbProtectedTextField size="small" slim={true} value={organizationInfo?.email ?? ""}
                                      onSave={_updateOrganizationEmail}/>
                <RbConfirmationDialog onConfirm={() => _removeUserFromOrganization()} onClose={() => {
                    setDeleteUserDialog(false);
                    setTimeout(() => setUserForDelete(undefined), 1500);
                }} title="Remove user from organization?" open={deleteUserDialog} isDelete={true}>
                    <p>Are you sure you want to remove
                        <span className="font-bold italic px-5">{userForDelete?.name ?? "no_name"}</span>from
                        <span className="font-bold italic pl-5">{props?.organization?.name ?? "organization"}</span>?
                    </p>
                </RbConfirmationDialog>
            </div>
            <RbWorkDays readonly={false} workDays={workDays} toggleWorkDaySetting={_toggleWorkDaySetting}/>
            <RbSubSection>
                <RbRoundButton onClick={() => setAddSeatsOpen(true)} variant="contained">Buy
                    seats</RbRoundButton>
                <RbRoundButton style={{color: theme.palette.text.primary}}
                               onClick={() => setDeleteSeatsOpen(true)}>Remove seats</RbRoundButton>
                <RbSubSectionItem className="text-right mt-20 mb-20">
                    <Typography>{organizationSeats?.occupied ?? 0} seats
                        of {organizationSeats?.total ?? 0} used</Typography>
                    <RbProgressBar className="mt-5 mb-5 rounded-4" color="primary" variant="determinate"
                                   value={calculateProgressRatio()}/>
                    <Typography
                        color={`${theme.palette.text.secondary}`}>{organizationSeats?.available ?? 0} available</Typography>
                </RbSubSectionItem>
            </RbSubSection>
            <RbSubSection>
                <RbAutoComplete variant="outlined"
                                displayString={searchedUser?.firstName ? `${searchedUser?.firstName} ${searchedUser?.lastName}` : ""}
                                className="inline-block align-middle mr-28 sm:mr-0 md:mt-10 sm:mt-10 w-256"
                                handleItemPreview={autocompleteItemRender}
                    // @ts-ignore
                                onSelect={res => {
                                    setSearchedUser(res)
                                }} autoDataAPIFetching={{
                    method: searchOrganizationUsers,
                    args: [props?.organization?.id ?? ""],
                    searchPosition: 1
                }}/>

                <RbRoundButton
                    onClick={() => handleInviteUserForOrganization()}
                    style={{color: theme.palette.text.primary}}
                    disabled={!searchedUser?.id}
                    variant="contained">Invite</RbRoundButton>
            </RbSubSection>
            <RbSubSection>
                <h2 className="mb-20">Organization members</h2>
                <RbDataTable search={true} dataSource={organizationUsers}
                             headerColumns={displayHeadersUsers} rowClassName={_handleRowStyle}/>
            </RbSubSection>
            <RbSubSection>
                <h2 className="mb-20">Former organization members</h2>
                <RbDataTable search={true} dataSource={formerOrganizationUsers}
                             headerColumns={displayHeadersFormerUsers}/>
            </RbSubSection>
            <RbDeleteOrganizationSeatsDialog open={deleteSeatsOpen} onClose={() => setDeleteSeatsOpen(false)}
                                             organizationId={props?.organization?.id}
                                             onSuccess={_getOrganizationSeats}/>
            <RbCreateOrganizationSeatsDialog open={addSeatsOpen} onClose={() => setAddSeatsOpen(false)}
                                             organizationId={props?.organization?.id}
                                             onSuccess={_getOrganizationSeats}/>
            <RbSubSection>
                <RbConfirmationDialog onClose={() => setDeleteOrganizationOpen(false)}
                                      onConfirm={handleDeleteOrganization}
                                      open={deleteOrganizationOpen} isDelete={true}>
                    Are you sure you want to delete <span
                    className="font-bold italic">{props?.organization?.name ?? ""}</span>?
                </RbConfirmationDialog>
                <RbRoundButton
                    onClick={() => setDeleteOrganizationOpen(true)}
                    disabled={!props?.organization?.id}
                    variant="text" color="error" className="mb-10 px-20 text-left">
                    <span style={{color: theme.priority.high}}>Delete organization</span>
                    <p>Caution! All related data to this organization will not be available anymore.</p>
                </RbRoundButton>
            </RbSubSection>
        </div>
    );
}

// @ts-ignore
function mapStateToProps({fuse}) {
    return {
        organization: fuse.organization.value
    }
}

export default withRouter(connect(mapStateToProps)(GeneralOrganizationSettings));
