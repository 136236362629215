import {IReportUserActivity} from "../../../types/report-types";
import RbBox from "../../../shared-components/rb-components/RbBox";
import {useTheme} from "@mui/material/styles";
import {RbTheme} from "../../../types/common-types";
import moment from "moment";

export default function ReportUsersActivities(props: { data: IReportUserActivity[], className?: string }) {
    const theme: RbTheme = useTheme();
    return (
        <RbBox className={`p-0 mb-20 h-320  ${props.className ?? ""}`}>
            <div className="px-20 py-12"
                 style={{borderBottom: `3px solid ${theme.palette.background.paper}`}}>
                <h2 className="flex items-center">Activities</h2>
            </div>
            <div className="p-10 pl-20 max-h-256 overflow-y-auto">
                {
                    (props?.data ?? []).map((a, i) => {
                        return (
                            <div key={i} className="py-6 flex justify-between items-center">
                                <span>{a.description}</span>
                                <span>{moment(a.createdAt).fromNow()}</span>
                            </div>
                        )
                    })
                }
            </div>
        </RbBox>
    )
}
