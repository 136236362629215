import {
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Step,
    StepButton,
    StepLabel,
    Stepper
} from "@mui/material";
import {RbDialog} from "app/shared-components/dialogs/RbDialog";
import React, {useEffect, useState} from "react";
import {RbRoundButton} from "../../../shared-components/rb-components/RbRoundButton";
import {RbTextField} from "../../../shared-components/rb-components/RbTextField";
import {TabContext, TabPanel} from "@mui/lab";
import {MinusCircleIcon, PlusCircleIcon} from "@heroicons/react/outline";
import {
    addOrganizationSeats, getOrganizationSeatsInfo
} from "../../../services/organizationSettingsService";
import {useTheme} from "@mui/material/styles";
import {RbTheme} from "../../../types/common-types";
import {IOrganizationSeatsInfo} from "../../../types/organization-settings-types";

export default function RbCreateOrganizationSeatsDialog(props: { organizationId: string, open: boolean, onClose: () => void, onSuccess: () => void }) {
    const [seats, setSeats] = useState<number>(0);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [seatsInfo, setSeatsInfo] = useState<IOrganizationSeatsInfo>();
    const theme: RbTheme = useTheme();

    useEffect(() => {
        if (!props.open) return;
        _getOrganizationSeatsInfo();
    }, [props?.open]);


    function _getOrganizationSeatsInfo() {
        if (!props?.organizationId) return;
        getOrganizationSeatsInfo(props.organizationId).then(res => {
            if (res?.data?.error) return;
            setSeatsInfo(res.data.data);
            setSeats((res.data.data as IOrganizationSeatsInfo).min);
        });
    }

    function handleNext() {
        switch (activeStep) {
            case 0:
                _addOrganizationSeats();
                break;
            case 1:
                props.onClose();
                resetDialogData();
                break;
            default:
                setActiveStep(activeStep + 1);
                break;
        }
    }

    function _addOrganizationSeats() {
        if (!props?.organizationId) return;
        addOrganizationSeats(props.organizationId, seats).then(res => {
            if (res?.data?.error) return;
            props.onSuccess();
            setActiveStep(activeStep + 1);
        })
    }


    function resetDialogData() {
        setTimeout(() => {
            setSeats(5);
            setActiveStep(0);
            setSeatsInfo(undefined);
        }, 1000);
    }

    const getSeatObject = React.useMemo(
        () => () => {
            return (seatsInfo?.seats ?? []).find(s => s.seatNo === seats);
        },
        [seats]
    );

    return (
        <RbDialog
            open={props.open}
            onClose={() => props.onClose()}
        >
            <DialogTitle>Add seats</DialogTitle>

            <DialogContent className="w-512 pb-0">
                <Stepper activeStep={activeStep}>
                    {
                        ["Seats", "Finalizing"].map((title, i) => {
                            return (
                                <Step key={i}>
                                    <StepButton onClick={() => setActiveStep(i)}>
                                        <StepLabel StepIconComponent={() => {
                                            return (
                                                <span
                                                    className="w-24 aspect-square flex justify-center items-center rounded-full"
                                                    style={{backgroundColor: i <= activeStep ? theme.palette.special : theme.palette.secondary.main}}>{i + 1}</span>
                                            )
                                        }}>{title}</StepLabel>
                                    </StepButton>
                                </Step>
                            )
                        })
                    }
                </Stepper>
                <TabContext value={String(activeStep)}>
                    <TabPanel value={"0"} className="px-0">
                        <div className="flex items-center">
                            <div className="flex items-center m-auto">
                                <IconButton className="p-5" disabled={!seatsInfo}>
                                    <MinusCircleIcon className="w-36" strokeWidth="1.5" onClick={() => {
                                        if (seatsInfo?.min && seats > seatsInfo.min) {
                                            setSeats(seats - 1);
                                        }
                                    }}/>
                                </IconButton>
                                <RbTextField className="mx-10"
                                             disabled={true}
                                             label="Seats"
                                             InputLabelProps={{
                                                 shrink: true,
                                             }}
                                             InputProps={{
                                                 inputProps: {className: "text-center"}
                                             }}
                                             variant="outlined"
                                             value={seats}
                                             color="primary"/>
                                <IconButton className="p-5" disabled={!seatsInfo}>
                                    <PlusCircleIcon className="w-36" strokeWidth="1.5"
                                                    onClick={() => {
                                                        if (seatsInfo?.max && seats < seatsInfo.max) {
                                                            setSeats(seats + 1);
                                                        }
                                                    }}/>
                                </IconButton>
                            </div>
                        </div>

                        <hr className="my-24"/>
                        <p className="flex justify-between items-center mb-24">
                            <span>Seat price</span>
                            <span>{(getSeatObject()?.priceMonthly ?? 0) / seats}$</span>
                        </p>
                        <p className="flex justify-between items-center">
                            <span>Total</span>
                            <span>{getSeatObject()?.priceMonthly ?? 0}$</span>
                        </p>
                    </TabPanel>

                    <TabPanel value={"1"} className="px-0">
                        <h2>Thank you. Your organization has been created successfully</h2>
                    </TabPanel>
                </TabContext>
            </DialogContent>

            <DialogActions className="mb-10 px-24">
                {
                    activeStep === 0 && <RbRoundButton disabled={false} variant="text" className="mr-10"
                                                       onClick={() => {
                                                           resetDialogData();
                                                           props.onClose();
                                                       }}>Cancel</RbRoundButton>
                }
                <RbRoundButton variant="contained" disabled={seats === 0}
                               onClick={handleNext}>{activeStep === 0 ? "Next" : "Finish"}</RbRoundButton>
            </DialogActions>
        </RbDialog>
    )
}
