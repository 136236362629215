import {styled} from "@mui/system";
import {TextField} from "@mui/material";

export const RbTextField = styled(TextField)(({theme}) => ({
    '&.slim input': {
        paddingTop: "0.7rem",
        paddingBottom: "0.7rem",
    },
    '& .MuiFormControl-fullWidth': {
        marginTop: 0,
        backgroundColor: "red"
    },
    '& .MuiInputBase-adornedStart': {
        display: "flex",
        flexDirection: "row-reverse",
        paddingRight: "1rem",
        "& label": {
            position: "absolute",
            top: "-3rem",
            left: 0,
            fontSize: "1.5rem"
        }
    },
    '&:not(.slim) .MuiInputBase-adornedStart': {
        marginTop: "3rem",
    },
    "& fieldset": {
        borderColor: `${theme.palette.mode === 'light' ? "#94A4B8" : "inherit"} !important`
    },
    "& svg": {
        width: "3rem",
        padding: "0.5rem",
        strokeWidth: "1.5",
        transition: "transform 150ms linear",
        cursor: "pointer",
        "&:hover": {
            transform: "scale(1.1)"
        }
    }
}));
