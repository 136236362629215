import axios from 'axios'
import {Params} from "react-router-dom";

const API_BASE_URL = 'https://master.rubicontrack.com/';


export const getUserInfo = () => {
    const response = axios.get('https://login.rubicontrack.com/connect/userinfo');
    return response;
}

export const _get = (endpointURL: string) => {
    return axios.get(createEndpointURL(endpointURL));
}

export const _post = (endpointURL: string, dataObject: any) => {
    return axios.post(
        createEndpointURL(endpointURL),
        dataObject
    );
}

export const _put = (endpointURL: string, dataObject: any) => {
    return axios.put(createEndpointURL(endpointURL), dataObject);
}

export const _delete = (endpointURL: string, identifier?: IdentifierTypes) => {
    return axios.delete(createEndpointURL(endpointURL));
}

export const _patch = (endpointURL: string, dataObject: any) => {
    return axios.patch(
        createEndpointURL(endpointURL),
        dataObject
    );

}

type IdentifierTypes = string | number | null | undefined;

export const createEndpointURL = (endpointURL: string, queryParams?: Params) => {
    return `${API_BASE_URL}${endpointURL}`;
};
