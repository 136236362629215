import React, {useEffect, useRef} from 'react';
import {storeUser} from '../../actions/authActions'
import {setAuthHeader} from './axiosHeaders';

// @ts-ignore
export default function AuthProvider({ userManager: manager, store, children }) {

    let userManager: any = useRef();

    useEffect(() => {
        userManager.current = manager
        console.log(userManager, "Current")

        const onUserLoaded = (user: any) => {
            console.log(`user loaded: ${user}`)
            store.dispatch(storeUser(user))
        }

        const onUserUnloaded = () => {
            setAuthHeader(null)
            console.log(`user unloaded`)
        }

        const onAccessTokenExpiring = () => {
            console.log(`user token expiring`)
        }

        const onAccessTokenExpired = () => {
            console.log(`user token expired`)
        }

        const onUserSignedOut = () => {
            console.log(`user signed out`)
        }

        // events for user
        userManager?.current?.events.addUserLoaded(onUserLoaded)
        userManager?.current?.events.addUserUnloaded(onUserUnloaded)
        userManager?.current?.events.addAccessTokenExpiring(onAccessTokenExpiring)
        userManager?.current?.events.addAccessTokenExpired(onAccessTokenExpired)
        userManager?.current?.events.addUserSignedOut(onUserSignedOut)



        // Specify how to clean up after this effect:
        return function cleanup() {
            userManager?.current?.events.removeUserLoaded(onUserLoaded);
            userManager?.current?.events.removeUserUnloaded(onUserUnloaded);
            userManager?.current?.events.removeAccessTokenExpiring(onAccessTokenExpiring)
            userManager?.current?.events.removeAccessTokenExpired(onAccessTokenExpired)
            userManager?.current?.events.removeUserSignedOut(onUserSignedOut)
        };
    }, [userManager, store]);

    return (
        React.Children.only(children)
    )
}
