import WidgetList from "./WidgetList";
import {useEffect, useState} from "react";
import {IMyDay} from "../../../types/dashboard-types";
import {getMyDayList} from "../../../services/dashboard/dashboardService";
import clsx from "clsx";
import {ChevronRightIcon} from "@heroicons/react/outline";
import {WidgetWidth} from "./WidgetBase";

export default function WidgetMyDay(props: { id: number, defaultWidth: WidgetWidth }) {
    const [myDayList, setMyDayList] = useState<IMyDay[]>([]);

    useEffect(() => {
        _getMyDayList();
    }, []);

    const _getMyDayList = () => {
        getMyDayList().then(res => {
            if (res?.data?.error) return;
            setMyDayList(res.data.data);
        })
    }

    const renderItem = (item: IMyDay) => {
        return (
            <>
                <span
                    className={clsx("flex flex-1 justify-between pb-3 pt-3 cursor-pointer", item.isDone ? "line-through" : "")}>
                    <span>{item.name}</span>
                    <ChevronRightIcon className="w-12"/>
                </span>
                <hr/>
            </>
        )
    }

    return (
        <WidgetList id={props.id} title="My day" defaultWidth={props.defaultWidth} titleIcon="SunIcon"
                    listSource={myDayList}
                    displayAttributes={["name"]} renderItem={renderItem}/>
    )
}
