import {useContext, useEffect, useState} from "react";
import {getUserInfo} from "../../../services/user-settings/userSettingsService";
import {IUserInfo} from "../../../types/user-settings-types";
import {useTheme} from "@mui/material/styles";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {PencilIcon} from "@heroicons/react/outline";
import {RbTextField} from "app/shared-components/rb-components/RbTextField";
import {RbDialog} from "app/shared-components/dialogs/RbDialog";
import {getLSUserData, StorageDataType} from "../../../services/storageBus";
import {UserOrganization} from "../../../types/common-types";
import {userSettingsSubNavigation} from "../UserSettingsConfig";
import {MessageBusLane, navbarContent} from "../../../services/messageBus";
import {RbRoundButton} from "app/shared-components/rb-components/RbRoundButton";


function AccountUserSettings() {
    const [userInfo, setUserinfo] = useState<IUserInfo | null>(null);
    const [changePass, setChangePass] = useState<boolean>(false);
    const [openDialogState, setOpenDialogState] = useState<boolean>(false);
    const theme = useTheme();
    // const organization: UserOrganization = getLSUserData(LSDataType.Organization);

    useEffect(() => {
        getUserInfo().then(res => setUserinfo(res.data.data));
    }, []);

    function getNameInitials() {
        return `${userInfo?.firstName[0].toUpperCase()}${userInfo?.lastName[0].toUpperCase()}`;
    }

    function togglePasswordChange(value: boolean) {
        if (value) {
            setChangePass(value);
        } else {
            setChangePass(!changePass);
        }
    }

    function handleChangePassword() {
        // TODO: poziv servisa za izmenu sifre
        setChangePass(false);
    }

    function handleSendResetLink() {
        // TODO: send reset link
        console.log("sending reset link");
        setOpenDialogState(false);
    }

    return (
        <div className="mt-48 md:ml-28 sm:ml-28 ml-64 mb-48 sm:mr-28">
            <div className="avatar-controls">
                <div className="max-w-96 max-h-96">
                    {
                        (userInfo?.avatar) ?
                            <img src={userInfo.avatar} alt="User avatar" className="w-96 h-96 rounded-full"/> :
                            <div className="rounded-full text-center text-5xl leading-[96px] h-96 line-h-96"
                                 style={{backgroundColor: theme.palette.secondary.main}}>{getNameInitials()}</div>
                    }
                </div>
                <div className="avatar-controls mt-32">
                    <RbRoundButton style={{color: theme.palette.text.primary}} variant="contained">Change
                        avatar</RbRoundButton>
                    <RbRoundButton style={{color: theme.palette.text.primary}}>Remove</RbRoundButton>
                </div>
            </div>

            <div className="user-info-controls mt-48">
                <div className="email-section">
                    <h3 className="mb-28 font-bold">E-mail address</h3>
                    <span className="">Your email address is <b>{userInfo?.email}</b></span>
                    <hr className="w-5/12 mt-28 sm:w-full"/>
                </div>
                <div className="name-section mt-28">
                    <RbTextField className="max-w-288 pointer-events-none" id="username"
                                 variant="outlined"
                                 InputProps={{
                                     startAdornment: (
                                         <label>Username</label>
                                     ), readOnly: true
                                 }}
                                 value={userInfo?.username}/>
                    <div className="first-last-name block mt-28">
                        <RbTextField className="max-w-288" id="name"
                                     variant="outlined"
                                     InputProps={{
                                         startAdornment: (
                                             <>
                                                 <label>First name</label>
                                                 <PencilIcon></PencilIcon>
                                             </>
                                         ), value: userInfo?.firstName
                                     }}/>

                        <RbTextField className="max-w-288 md:ml-48 xl:ml-56 lg:ml-56" id="lastname"
                                     variant="outlined"
                                     InputProps={{
                                         startAdornment: (
                                             <>
                                                 <label>Last name</label>
                                                 <PencilIcon></PencilIcon>
                                             </>
                                         ), value: userInfo?.lastName
                                     }}/>
                    </div>

                    <div className="password block mt-28">
                        <RbTextField className="max-w-288" id="password"
                                     variant="outlined"
                                     type="password"
                                     InputProps={{
                                         startAdornment: (
                                             <>
                                                 <label>Password</label>
                                                 <PencilIcon
                                                     onClick={() => togglePasswordChange(true)}></PencilIcon>
                                             </>
                                         ), readOnly: true, value: "password123"
                                     }}/>
                    </div>

                    {
                        changePass ?

                            <div className="password-reset block mt-28">
                                <RbTextField className="max-w-288 mr-10" id="currentPassword"
                                             variant="outlined"
                                             type="password"
                                             placeholder="password123"
                                             InputProps={{
                                                 startAdornment: (
                                                     <label>Current password</label>
                                                 )
                                             }}/>

                                <RbTextField className="max-w-288 mr-10" id="newPassword"
                                             variant="outlined"
                                             type="password"
                                             placeholder="password123"
                                             InputProps={{
                                                 startAdornment: (
                                                     <label>New password</label>
                                                 )
                                             }}/>

                                <RbTextField className="max-w-288 mr-10" id="repeatPassword"
                                             variant="outlined"
                                             type="password"
                                             placeholder="password123"
                                             InputProps={{
                                                 startAdornment: (
                                                     <label>Repeat password</label>
                                                 )
                                             }}/>
                            </div> : <></>
                    }
                    <span className="cursor-default block mt-20">Can’t remeber your current password?<span
                        className="underline cursor-pointer ml-10" onClick={() => setOpenDialogState(true)}
                        style={{color: "#06B6D4"}}>
                        Reset your password</span></span>
                </div>
            </div>
            {
                changePass ?
                    <div className="password-actions mt-16">
                        <Button style={{color: theme.palette.text.primary}} variant="contained"
                                className="rounded-[5rem] inline-block" onClick={handleChangePassword}>Change
                            password</Button>
                        <Button style={{color: theme.palette.text.primary}}
                                className="rounded-[5rem] ml-10 inline-block"
                                onClick={() => setChangePass(false)}>Cancel</Button>
                    </div> : <></>
            }

            <RbDialog
                open={openDialogState}
                onClose={() => setOpenDialogState(false)}
            >
                <DialogTitle className="p-10 pl-20">
                    {"Reset password"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        We will send a link to your e-mail address.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{color: theme.palette.text.primary}} variant="contained"
                            className="rounded-[5rem] inline-block" onClick={handleSendResetLink}>Send link</Button>
                </DialogActions>
            </RbDialog>
        </div>
    );
}

export default AccountUserSettings;
