import {ICustomTodoList} from "../../types/todo-types";
import {DialogActions, DialogContent, DialogContentText, FormControl, Menu, MenuItem} from "@mui/material";
import {RbTextField} from "../../shared-components/rb-components/RbTextField";
import React, {useEffect, useState} from "react";
import {RbRoundButton} from "../../shared-components/rb-components/RbRoundButton";
import {useTheme} from "@mui/material/styles";
import {HomeIcon} from "@heroicons/react/outline";
import RbContentEditable from "../../shared-components/rb-components/RbContentEditable";
import {createTodoList, getCustomLists} from "../../services/todo/todoService";
import Popover from "@mui/material/Popover";

export default function AddTodoTask(props: { customLists: ICustomTodoList[], getCustomLists: () => void, defaultList?: ICustomTodoList, onSave: (listId: string, value: string) => void }) {
    const [customList, setCustomList] = useState<ICustomTodoList>();
    const [anchorEl, setAnchorEl] = useState<null | Element>(null);
    const [value, setValue] = useState<string>("");

    useEffect(() => {
        if (props?.defaultList) {
            setCustomList(props.defaultList);
        }
    }, []);

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function handleKeyPress(e: any) {
        if (e.key === 'Enter' && props.onSave) {
            e.preventDefault();
            setAnchorEl(null);
            props.onSave(customList?.listId ?? "", e.target.value);
        }
    }

    function handleSaveList(name: string) {
        createTodoList(name).then(res => {
            if (res?.data?.error) return;
            props.getCustomLists();
        });
    }

    function handleValueChange(e: any) {
        setValue(e.target.value);
    }

    return (
        <DialogContent>
            <DialogContentText>
                <FormControl fullWidth>
                    <RbTextField className="w-360 block"
                                 variant="outlined"
                                 label="Task"
                                 value={value}
                                 autoFocus={true}
                                 InputProps={{
                                     endAdornment:
                                         <HomeIcon color={customList?.listId ? "#06B6D4" : "initial"}
                                                   onClick={e => setAnchorEl(e.currentTarget)}/>
                                 }}
                                 onChange={handleValueChange}
                                 onKeyDown={handleKeyPress}
                                 color="primary"/>
                </FormControl>
            </DialogContentText>
            <Popover
                id="add-task-menu"
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleMenuClose}
                className="w-full"
            >
                {
                    props.customLists.map(el => {
                        return (
                            <MenuItem key={el.listId} className="whitespace-nowrap overflow-hidden" onClick={() => {
                                setAnchorEl(null);
                                setCustomList(el);
                            }}>{customList?.listId === el.listId ? <b>{el.name}</b> : el.name}</MenuItem>
                        )
                    })
                }
                <div className="p-10 pt-5">
                    <hr className="w-256 mb-10"/>
                    <RbContentEditable className="w-full mb-6" onConfirm={name => handleSaveList(name)}>
                        <span className="block cursor-pointer pt-6 pb-6 w-256">+ Add a list</span>
                    </RbContentEditable>
                </div>
            </Popover>
        </DialogContent>
    )
}
